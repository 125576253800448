import React, { useContext } from 'react';
import '../OfferTag/OfferTag.css'
import { useState, useEffect } from 'react';
import '../BoostUp/BoostUp.css';
import { regLottOffer } from '../../Wax/Transactions';
import { UALContext } from 'ual-reactjs-renderer';
import { ToastContainer, toast } from 'react-toastify';

import { Link } from 'react-router-dom';

const OfferTagDetalInfo = ({ data, chooseDate, selectedBlock, userInGameState, openModalRegisterTickets, id, index }) => {

  //console.log("data", data)
  // console.log("id", id)

  let info = chooseDate(data.tmpl_prise)
  let allcount = 0;
  let myreg = 0;

  if (data && data.userlist !== undefined && data.userlist.length) {
    myreg = data?.userlist.filter(value => value === userInGameState.user).length;
    allcount = data?.userlist.length;
  }

  const typeBlock = selectedBlock == "active-lottery" && data.id !== undefined && index == id ? 1 : selectedBlock == "winner-list" && index == id ? 2 : 0;

  if (typeBlock) {
    return (
      <div className="OfferTag__block rightAssedsCard">

        <div className={`topRightAssedsCard ${info.cardNan}`}>
          <div className="iconTikets wave-animation"></div>
          <div className={`cardChange ${info.cardChangeIcon}`} />
          <div className=""></div>
          <div className="fonCircle2"></div>
        </div>
        <div className="">
          <div className='AssedsCardMenuTop'>
            <div className="">  {selectedBlock ? info.title : ''}</div>

          </div>
          <div className="AssedsCardMenubottom">
            <div className="infoProgressRegist">
              <div className="BlockProgressInfo">
                <button className={`circtbtn ${selectedBlock ? info.colorCircle : ''}`}></button>
                <span>{typeBlock === 1 ? "Registration is open" : "RAFFLED"}</span>
              </div>
              {/* progress-bar */}
              <div className="progressFonOfferTag">
                <div
                  id="progress-bar"
                  className="progress__fon"
                  style={{
                    height: '6',
                    width: `${100 / data.max_number * allcount}%`,
                    borderRadius: '3px',
                    background: `${selectedBlock ? info.background : ''}`,
                    transition: 'width 0.5s',
                  }}
                ></div>
              </div>
              <div className="progressSpeed">
                <div className="speed">Total registered</div>
                <div id="speed-progress" className="speed progress">
                  {allcount}/{data.max_number}
                </div>
              </div>
            </div>
            {selectedBlock == "winner-list" ?
          
              <Link to={`/winnerslistoffer`} className="RegistlistOffer">View registered list</Link>
              :
              <Link to={`/registeredlistoffer/${index}`} className="RegistlistOffer">View registered list</Link>
            }

            <div id='infoRightAsseds' className="info__card__uncommon">
              <div className="BlockMyRegist"><div className="MyRegist">My registrations</div><div className="reg__t">{myreg}</div></div>
              <div className="BlockMyChance"><div className="MyChance">MyChance</div><div className="reg__t">{(100 / data.max_number * myreg).toFixed(1)}%</div></div>
            </div>
            {selectedBlock != "winner-list" ?
              <div className="Group">
                <button onClick={() => openModalRegisterTickets("open", data.id)} className='DropBuy'>Register</button>
              </div>
              :
              <div className="Group">
                <Link to={`/winnerslistoffer`} className='DropBuy'>Winners</Link>
              </div>
            }
          </div>
        </div>
      </div>)
  }
}

export default OfferTagDetalInfo;
