import React from 'react';
import '../Spinney/Spinney.css';
import Navcontent from '../Navcontent/Navcontent'
import FirstPage from '../FirstPage/FirstPage';
import RefPage from '../RefPage/RefPage';


const Spinney = (props) => {
    return (<>
    
      
        <div className="content FirstContent">
        <Navcontent handleModalActivation={props.handleModalActivation} setModal2Active={props.setModal2Active} setModal8Active={props.setModal8Active} setModal9Active={props.setModal9Active} />
            <FirstPage  setModal2Active={props.setModal2Active} handleModalActivation={props.handleModalActivation} setModal8Active={props.setModal8Active} setModal9Active={props.setModal9Active} />
        </div>
     
   
    </>
    )
}


export default Spinney;