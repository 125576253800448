import React from 'react';
import './FirstPage.css'
import { Link } from 'react-router-dom';



const FirstPage = (props, updateActiveItem) => {
    return (<div className='FirstPage__content'>
        <div className="FirstPage__block">
            <div className="playerSpin">There are only three<br /> steps to get started</div>
            <Link
                to="/bankpage/assets"
                //updateActiveItem={updateActiveItem}
                className="FirstPage">
                <div className="title__time">Step 1</div>
                <div className="title__time">Deposit your spinney into the game</div>
                <button className='Step1'><div className='link_step'>Deposit</div></button>
            </Link>
            <div className="FirstPage">
                <div className="title__time">Step 2</div>
                <div className="title__time">Open slots for your Spinneys </div>
                <button onClick={() => props.setModal9Active(true)} className='Step2'></button>
            </div>
            <div className="FirstPage">
                <div className="title__time">Step 3</div>
                <div className="title__time">Stake them into your slots and your Spinney is ready to spin.</div>
                <button onClick={() => props.setModal2Active(true)} className='Step3'></button>
            </div>
        </div>


    </div>
    )
}


export default FirstPage;