import React from 'react'
import '../Modal/Modal.css'
import '../Modal/ModalListRuna.css'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Scrollbar } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/scrollbar'
import 'swiper/css/mousewheel'
import { Mousewheel } from 'swiper'

const ModalListSpinney = ({
  active,
  setActive,
  props,
  allSpinnerInGame,
  userInGameState,
  addToSlotTrans,
}) => {
  //console.log('props', props)
  //console.log('allSpinnerInGame', allSpinnerInGame)

  const [isActive, setIsActive] = useState(active)
  const slideData = [
    { id: 0, className: 'slide__player_1' },
    { id: 1, className: 'slide__player_2' },
    { id: 2, className: 'slide__player_3' },
    { id: 3, className: 'slide__player_4' },
    { id: 4, className: 'slide__player_5' },
    { id: 5, className: 'slide__player_6' },
  ]

  const [activeButtonSpin, setActiveButtonSpin] = useState(1)
  const [stakeClicked, setStakeClicked] = useState(false)
  const [selectedSlide, setSelectedSlide] = useState(null)
  const [activeIds, setActiveIds] = useState([])



  const [availSlots, setAvailSlots] = useState(active);

  const navigate = useNavigate()

  const [selectedSlides, setSelectedSlides] = useState([]);

  // const handleClickSpin = (index, id, rarity) => {
  //   setSelectedSlide(index)
  //   // setActiveIds(id)
  //   console.log("rarity", rarity);
  //   if (selectedSlides.length === availSlots && !selectedSlides.includes(index) && rarity !== 0) {
  //     return; // Предотвращаем добавление класса "selected"
  //   }

  //   const selected = selectedSlides.includes(index);
  //   if (selected) {
  //     setSelectedSlides(prevSelectedSlides => prevSelectedSlides.filter(item => item !== index));
  //   } else {
  //     setSelectedSlides(prevSelectedSlides => [...prevSelectedSlides, index]);
  //   }

  //   if (activeIds.includes(id)) {
  //     setActiveIds(activeIds.filter((slideIndex) => slideIndex !== id));
  //   } else {
  //     setActiveIds([...activeIds, id]);
  //   }

  // };

  const handleClickSpin = (index, id, rarity) => {
    console.log("index", index);
    console.log("id", id);
    console.log("rarity", rarity);

    setSelectedSlides(prevChooses => {
      if (prevChooses.includes(id)) {
        return prevChooses.filter(item => item !== id);
      } else if (selectedSlides.length < availSlots && rarity !== 0 && rarity !== 6) {
        return [...prevChooses, id];
      } else if (rarity === 0 || rarity === 6) {
        return [...prevChooses, id];
      } else {
        return [...prevChooses];
      }
    });
  };

  const handleStakeClick = () => {
    setStakeClicked(true)
    //navigate('/boxspinners', { state: { selectedObjects: [selectedSlide] } });
    addToSlotTrans(selectedSlides)
    setActive(false)
  }

  const handleClose = () => {
    setActive(false)
  }
  const disabledButtonStyle = {
    pointerEvents: 'none',
  }

  useEffect(() => {
    setAvailSlots(userInGameState?.open_slots - userInGameState?.all_slot.length);

  }, [userInGameState]);

  return (
    <>
      <div
        id="modal4"
        className={active ? 'modalRuna activerun' : 'modalRuna'} onClick={() => setActive(false)}

      >
        <div
          className={
            active ? 'modal__contentRuna activerun' : 'modal__contentRuna'
          }
          onClick={(e) => e.stopPropagation()}
        >
          <div className="icon__modalRuna"></div>
          <div className="title__modalRuna">Select Spinney for Staking</div>
          <div className="parag__modalRuna" id="sp">
            (NFT’s must be in the game to be displayed here)
          </div>
          <div className="slots__block">
            <div className="parag__modalRuna" id="sp">
              Available slots:
            </div>
            <div className="slots">{availSlots}</div>
          </div>
          <div className="slider__modalRuna">
            <Swiper
              id="spinn__slider"
              modules={[Scrollbar, Mousewheel]}
              mousewheel={true}
              spaceBetween={8}
              direction={'horizontal'}
              scrollbar={{ draggable: true }}
              breakpoints={{

                610: {
                  slidesPerView: 1
                },
                1024: {
                  slidesPerView: 4
                }
              }}
            >

              {console.log("selectedSlides", selectedSlides)}
              {allSpinnerInGame &&
                allSpinnerInGame.length &&
                allSpinnerInGame
                  .filter((item) => item.in_slot === 0 && item.inlottery === 0)
                  .map((item, index) => (

                    <SwiperSlide
                      key={index}
                      onClick={() => handleClickSpin(index, item.asset_id, item.rarity)}
                    // style={{ maxWidth: '104px' }}
                    >
                      <div className={`slide__player_${item.rarity + 1} ${selectedSlides && selectedSlides.length && selectedSlides.includes(item.asset_id) ? 'selected' : ''}`}>
                        {/* <div
                        className={`slide__player_${item.rarity + 1}${selectedSlide === index ? ' selected' : ''}`}
                      > */}
                        <div className="bg__id__runs"></div>
                        <div className="id__runs">{item.asset_id}</div>
                      </div>
                    </SwiperSlide>
                  ))
              }
              <div className="swiper-scrollbar"></div>
            </Swiper>
          </div>
          <div className="btn__runs">
            <button onClick={handleClose} className="cancelRuna">
              Cancel
            </button>
            {!selectedSlides.length ? (
              <button disabled className='stakeRuna'>Stake</button>
            ) : (
              <button
                onClick={handleStakeClick}
                className='stakeRuna'
                disabled={!selectedSlides.length}
                style={!selectedSlides.length ? disabledButtonStyle : null}
              >Stake</button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ModalListSpinney;
