import React from 'react';
import '../Modal/ModalClaim.css';
import '../Modal/Modal.css';
import '../Chance/Chance.css';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const ModalJackpot = ({ active, openModalJackpot, children, loadingPage, oneUserTop, jackpotlocal, setActive }) => {
  const [isActive, setIsActive] = useState(active);
  const [count, setCount] = useState(0);
  const [endValue, setEndVal] = useState(0);

  const OnClose = () => {
    openModalJackpot(false);
  }

  const duration = 2000; // Длительность анимации в миллисекундах
  //const endValue = 100;

  useEffect(() => {
    let start;
    let animationFrameId;

    const frame = (timestamp) => {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      const currentCount = Math.min(endValue * (progress / duration), endValue);

      setCount(currentCount);

      if (progress < duration) {
        animationFrameId = requestAnimationFrame(frame);
      }
    };

    animationFrameId = requestAnimationFrame(frame);

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [loadingPage]);

  useEffect(() => {
    if (oneUserTop) {

      var points = 0;
      var getr = oneUserTop.rand;
      if (getr <= 9500) {
        points = 1;
      } else if (getr > 9500 && getr <= 9950) {
        points = 10;
      } else if (getr > 9950 && getr <= 9999) {
        points = 100;
      } else {
        points = jackpotlocal?.prise;
      }
      setEndVal(points);
    }

  }, [oneUserTop]);


  return (
    <div id="ModalTicket" className={active ? "modal__content activerun" : "modal__content"} >
      <div id="modal20">
        {children}
        <div className="WinPageFon ">
          <div className="WinPage ">
            <div className="circleFon"> </div>


            <div className={`container__game ${active ? "modal activerun" : "modal"}`}>
              <div style={{ margin: '0 auto' }} className="title Jack"> {loadingPage ? "Loading" : "Congratulations"}</div>
              {loadingPage ? <p>We are waiting for the result </p> : <p>You received</p>}
              {loadingPage ?
                <div className="animNumberJack animDot"></div>
                :
                <div className="animNumberJack">
                  {count.toFixed(2)} <div className="animSp">SPN</div>
                </div>
              }

              <div className="btn__modal"><button onClick={() => setActive(false)}>Confirm</button></div>
            </div>



          </div>
        </div>
      </div>
    </div>
  )
}






export default ModalJackpot;