import React, { useState, useEffect } from "react";
import "../Modal/Modal.css";
import "../Modal/ModalPartners.css";
import useGetAllNftPartner from "./../Wax/useGetAllNftPartner";
import { InView } from "react-intersection-observer";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ModalPartners = ({
  props,
  active,
  setActive,
  openModalPartners,
  transferNftToTheGame,
  userInGameState,
  allNftModal,
  swichOtherCol,
  typeModal,
  actTmpl,
}) => {
  const [activeCards, setActiveCards] = useState([]);

  const handleCardClick = (id) => {
    const index = activeCards.indexOf(id);
    if (index === -1) {
      setActiveCards([...activeCards, id]);
    } else {
      const updatedActiveCards = [...activeCards];
      updatedActiveCards.splice(index, 1);
      setActiveCards(updatedActiveCards);
    }
  };

  const isCardActive = (id) => {
    return activeCards.includes(id);
  };
  return (
    <>
      <div
        id="modal25"
        className={active ? "modal__content activerun" : "modal__content"}
        onClick={() => setActive(false)}
      >
        <div
          className={`modal ${active ? "activerun" : ""} ${typeModal}`}
          onClick={(event) => event.stopPropagation()}
        >
          <div className="topTitlModal">
            {typeModal == "burns"
              ? "Burn Premium Access"
              : "Our partners' NFTs on your wallet"}
          </div>
          <div className="botContModal">
            <div className="contCardsPart">
              {allNftModal && allNftModal.length ? (
                allNftModal.map((item, index) => {
                  if (typeModal === "burns") {
                    if (actTmpl === item.template.template_id) {
                      return (
                        <div
                          key={index}
                          style={
                            {
                              //backgroundImage: `url(https://cloudflare-ipfs.com/ipfs/${item.data.img})`
                            }
                          }
                          className={`cNftPartA onebgSP ${
                            isCardActive(item.asset_id) ? "activPart" : ""
                          }`}
                          onClick={() => handleCardClick(item.asset_id)}
                        >
                          <LazyLoadImage
                            src={`https://cloudflare-ipfs.com/ipfs/${item.data.img}`}
                            effect="blur"
                          />
                          <div className="idCards">{item.asset_id}</div>
                        </div>
                      );
                    }
                  } else {
                    return (
                      <div
                        key={index}
                        style={
                          {
                            //backgroundImage: `url(https://cloudflare-ipfs.com/ipfs/${item.data.img})`
                          }
                        }
                        className={`cNftPartA onebgSP ${
                          isCardActive(item.asset_id) ? "activPart" : ""
                        }`}
                        onClick={() => handleCardClick(item.asset_id)}
                      >
                        <LazyLoadImage
                          src={`https://cloudflare-ipfs.com/ipfs/${item.data.img}`}
                          effect="blur"
                        />
                        <div className="idCards">{item.asset_id}</div>
                        <div className="NmbPartName">
                          <div className="PartNum">
                            {swichOtherCol(item.template.template_id)}
                          </div>
                          <div className="usPartN">{item.collection}</div>
                        </div>
                      </div>
                    );
                  }
                })
              ) : (
                <div className="preloader-local123"></div>
              )}

              {/*              
              <div className={`cNftPartA onebgSP ${isCardActive(1) ? 'activPart' : ''}`}
                onClick={() => handleCardClick(1)}
              >
                <div className="idCards">1209381212312343</div>
                <div className="NmbPartName">
                  <div className="PartNum">0.01</div>
                  <div className="usPartN">SpinniaWorld</div>
                </div>
              </div>
             
              <div className={`cNftPartA onebgWAp ${isCardActive(2) ? 'activPart' : ''}`}
                onClick={() => handleCardClick(2)}
              >
                <div className="idCards">1209381212312343</div>
                <div className="NmbPartName">
                  <div className="PartNum">0.01</div>
                  <div className="usPartN">WaxApes</div>
                </div>
              </div> */}
            </div>

            {activeCards.length ? (
              <button
                className="pt btnRig"
                onClick={() => transferNftToTheGame(activeCards)}
              >
                {typeModal == "burns" ? "Burn" : "Deposit"}
              </button>
            ) : (
              <button className="pt btnRig dis" disabled>
                {typeModal == "burns" ? "Burn" : "Deposit"}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalPartners;
