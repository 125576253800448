import React, { useState, useEffect } from "react";
import "../Modal/Modal.css";
import "../Modal/ModalPartners.css";
import "../Modal/ModalLiquidity.css";

const ModalLiquidity = ({
  active,
  setActive,
  openModalLiquidity,
  localListRateGroup,
  soldSpn,
  addliquidityTrans,
}) => {
  const [lockoutDays, setLockoutDays] = useState(0);
  const [activeWaxPrice, setActiveWaxPrice] = useState(0);
  const [activeInd, setActiveInd] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [requiresTokens, setRequiresTokens] = useState({
    withIconWp: "0000.0000",
    withIconSp: "0000.0000",
  });
  const [boxIcCardsClass, setBoxIcCardsClass] = useState(
    "violetta__one__image"
  );

  const handleClickInd = (index) => {
    setActiveInd(index);
    setActivePeriods(localListRateGroup[index]);
    setActiveIndex(0);
    curChanger(localListRateGroup[index][0].pricespn / 10000);

    // if (index === 0) {
    //   setLockoutDays(120);
    //   setRequiresTokens({
    //     withIconWp: '9999.9995',
    //     withIconSp: '9999.9996'
    //   });
    //   setBoxIcCardsClass('violetta__one__image');
    // } else if (index === 1) {
    //   setLockoutDays(150);
    //   setRequiresTokens({
    //     withIconWp: '9999.9997',
    //     withIconSp: '9999.9997'
    //   });
    //   setBoxIcCardsClass('leaf__one__image');

    // } else {
    //   setLockoutDays(140);
    //   setRequiresTokens({
    //     withIconWp: '9999.9999',
    //     withIconSp: '9999.9999'
    //   });
    //   setBoxIcCardsClass('frost__one__image');

    // }
  };

  const liqCars = [1, 2, 3];
  const handleClick = (index, value) => {
    // if (activeIndex === index) {
    //   setActiveIndex(null);
    // } else {
    setActiveIndex(index);
    curChanger(value);

    console.log("curChanger", curChanger(value));
    // }
  };

  const [activePeriods, setActivePeriods] = useState([]);

  useEffect(() => {
    if (
      activePeriods &&
      activePeriods.length == 0 &&
      localListRateGroup &&
      localListRateGroup.length != 0
    ) {
      setActivePeriods(localListRateGroup[0]);
      //console.log("localListRateGroup[0].pricespn", localListRateGroup[0][0])
      curChanger(localListRateGroup[0][0].pricespn / 10000);
    }
  }, [localListRateGroup]);

  const curChanger = (value) => {
    console.log("aaaa", value);
    // setFormData((prevData) => ({
    //     ...prevData,
    //     [name]: value,
    //     [name == "wax" ? "spn" : "wax"]: soldSpn(value, "spn")
    // }));

    let obj = {
      ["spn"]: value,
      ["wax"]: soldSpn(value, "spn"),
    };
    setActiveWaxPrice(obj);
    return {
      obj,
    };
  };

  return (
    <>
      <div
        id="modal26"
        className={active ? "modal__content activerun" : "modal__content"}
        onClick={() => setActive(false)}
      >
        <div
          className={`modal ${active ? "activerun" : ""}`}
          onClick={(event) => event.stopPropagation()}
        >
          <div className="centerContModal">
            <div className="leftLiquid">
              <div className="topLiquid">
                <div className="pt">Choose your staking plan</div>
                <div className="pt infCont">
                  Get your chosen NFT by providing liquidity in the SPN/WAX
                  pair. At the end of the lockout period, you will receive your
                  tokens and NFT.
                </div>
                <div id="pt">
                  <div className="slLiquid ">
                    <div id="activeBg">1</div>
                    <div className="pt">
                      Select the NFT you want to receive.
                    </div>
                  </div>
                  <div className="slLiquid">
                    <div id="activeBg">2</div>
                    <div className="pt">Select the locking tokens period.</div>
                  </div>
                  <div className="slLiquid">
                    <div id="activeBg">3</div>
                    <div className="pt">
                      Click Stake. Confirm the transaction.
                    </div>
                  </div>
                </div>
                <div className="pt infCont">
                  The amount of WAX and SPN tokens for lock will be calculated
                  depending on the selected NFT and the selected lock period. It
                  is important to realize that your amount of tokens may change
                  depending on the price of the SPN token, either towards WAX or
                  towards SPN.{" "}
                </div>
              </div>

              <div className="bottomLiqSl">
                {localListRateGroup.map((car, index) => (
                  <div
                    key={index}
                    className={`liqCars ${
                      activeInd === index ? "activPart" : ""
                    }`}
                    onClick={() => handleClickInd(index)}
                    style={{
                      backgroundImage: `url(/img/tmpl/${car[0].tmpl}.webp)`,
                    }}
                  ></div>
                ))}
              </div>
            </div>

            <div className="rightLiquid">
              {/* card */}
              <div className="cardPartFirst">
                <div className="bodyCardFirst">
                  <div
                    className={`boxIcCards`}
                    style={{
                      backgroundImage: `url(/img/tmpl/${activePeriods[activeIndex]?.tmpl}.webp)`,
                    }}
                  ></div>
                  <div className={`lines`}>
                    <div className="left l1">
                      <span></span>
                    </div>
                    <div className="left l2">
                      <span></span>
                    </div>
                    <div className="left l3">
                      <span></span>
                    </div>
                    <div className="left l4">
                      <span></span>
                    </div>
                    <div className="left l5">
                      <span></span>
                    </div>

                    <div className="right l6">
                      <span></span>
                    </div>
                    <div className="right l7">
                      <span></span>
                    </div>
                    <div className="right l8">
                      <span></span>
                    </div>
                    <div className="right l9">
                      <span></span>
                    </div>
                  </div>
                  <div className="fonViolCircl"></div>
                </div>
                <div className="bottomCardFirst">
                  <div className="topC">
                    <div className="userPart">
                      <div className="usIdWax">Lockout period:</div>
                    </div>

                    <div className="boxPeriod">
                      {activePeriods.map((item, index) => (
                        <div
                          key={index}
                          className="pt pDay"
                          id={activeIndex === index ? "activeBg" : ""}
                          onClick={() =>
                            handleClick(
                              index,
                              parseFloat(item.pricespn / 10000)
                            )
                          }
                        >
                          {parseInt(item.duration / 86400)}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="botC">
                    <div className="BoxTokens">
                      <div className="pt">Requires tokens:</div>
                      <div className="pt">
                        <div className="iconWp"></div>
                        {activeWaxPrice.wax}
                      </div>
                      <div className="pt">
                        <div className="iconSp"></div>

                        {activePeriods[activeIndex]?.pricespn / 10000}
                      </div>
                    </div>
                    <div className="boxDays">
                      <div className="pt">Lockout Days:</div>
                      <div className="pt">
                        {parseInt(activePeriods[activeIndex]?.duration / 86400)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ----- */}
              <button
                className="pt btnRig"
                onClick={() =>
                  addliquidityTrans(
                    "spn",
                    activePeriods[activeIndex]?.pricespn / 10000,
                    activePeriods[activeIndex]?.idnum
                  )
                }
              >
                {" "}
                Stake
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalLiquidity;
