import React, { useContext, useState, useEffect } from 'react';
import '../Modal/ModalClaim.css';
import '../Modal/Modal.css';
import { Link } from 'react-router-dom';
import { Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/mousewheel';
import { Mousewheel } from 'swiper';

import { UALContext } from 'ual-reactjs-renderer';
import { ToastContainer, toast } from 'react-toastify';
import { transferToTheGame } from '../../components/Wax/Transactions';

const ModalBorn = ({ active, setActive, children, setModal11Active, userInGameState, allLeftPoints, allSpinner, transferProToTheGame, dispatchRemSpinneyFromWallet, dispMultProfMult }) => {

  const ual = useContext(UALContext);

  const [selectedSlide, setSelectedSlide] = useState(null);
  const [selectedSlides, setSelectedSlides] = useState([]);
  const [selectedID, setSelectedID] = useState(null);
  const [creditNums, setCreditNum] = useState(null);
  const [activeIds, setActiveIds] = useState([])
  const [totalCoinBurn, setTotalCoinBurn] = useState(0);


  const handleClickSpin = (index, id, creditNum) => {
    console.log("selectedSlide", index);
    setSelectedSlide(index);
    setSelectedID(id);
    setCreditNum(creditNum);
  };

  const slideData = [
    { id: 0, className: 'slide__1' },
    { id: 1, className: 'slide__2' },
    { id: 2, className: 'slide__3' },
    { id: 3, className: 'slide__4' },
    { id: 4, className: 'slide__5' },
    { id: 5, className: 'slide__6' },
  ];

  const OnClose = () => {
    setActive(false);
  }

  const [mergedData, setMergedData] = useState([]);

  useEffect(() => {

    let combined = [];

    if (allSpinner && allSpinner.spinney !== undefined) {
      combined = [...combined, ...allSpinner.spinney];
    }

    setMergedData(combined.filter(item => item.template.template_id !== process.env.REACT_APP_SPINNEY_PROMO && item.template.template_id !== process.env.REACT_APP_SPINNEY_PROMO2));
  }, [allSpinner]);

  const chooseRar = (rar) => {
    var number;
    let coinBurn;
    switch (rar) {
      case 0: case "Promo":
        number = 0;
        coinBurn = 0;
        break;
      case 1: case "Common":
        number = 1;
        coinBurn = 480;
        break;
      case 2: case "Uncommon":
        number = 2;
        coinBurn = 960;
        break;
      case 3: case "Rare":
        number = 3;
        coinBurn = 1920;
        break;
      case 4: case "Epic":
        number = 4;
        coinBurn = 3840;
        break;
      case 5: case "Legendary": case "Legend":
        number = 5;
        coinBurn = 7680;
        break;
      default:
        break;
    }
    return {
      number: number + 1,
      coinBurn: coinBurn,
    };
  }

  //  выводим значение CoinBurn выбранного слайда totalCoinBur

  const handleClickSpinBurn = (event, id, rar) => {
    setActiveIds(prevChooses => {
      if (prevChooses.includes(id)) {
        // Удаление id из массива, если оно уже есть
        setTotalCoinBurn(
          prevKey => prevKey - chooseRar(rar).coinBurn
        )
        return prevChooses.filter(item => item !== id);
      } else {
        // Добавление id в массив, если его нет
        setTotalCoinBurn(
          prevKey => prevKey + chooseRar(rar).coinBurn
        )
        return [...prevChooses, id];
      }
    });


  };

  const burn = async (ids) => {
    try {
      const { activeUser } = ual;
      const { accountName } = activeUser;

      const transactionPayload = transferToTheGame(accountName, ids, "burning spn ");

      console.log("transactionPayload", transactionPayload);

      const transaction = await activeUser.signTransaction(transactionPayload, {
        blocksBehind: 3,
        expireSeconds: 30,
      });

      dispatchRemSpinneyFromWallet(ids);
      dispMultProfMult(totalCoinBurn);
      toast("Transaction ID: " + transaction?.transactionId);
      setActive(false);

    } catch (e) {
      toast(e.toString(), {
        progressClassName: 'error-progress',
      });
      console.log(e);
    }
  }


  return (
    <div
      id="modal17"
      className={`modal__content ${active ? 'activerun' : ''}`} onClick={() => setActive(false)}>
      <div id="premium" className={`modal ${active ? 'activerun' : ''}`} onClick={(event) => event.stopPropagation()}>
        {children}
        <div className="icon__claim icon_bornNft"></div>
        <div className="q server">Select NFT’s for burning</div>
        <div className="q content_premium">
          <div className="fonSpn">Common Spinney
            <div className='contPriceSpn'>
              <div className="icon__spn"> <p><span className='priceSpnColor'>{chooseRar(1).coinBurn}</span> SPN</p></div>
            </div>
          </div>
          <div className="fonSpn">Uncommon Spinney
            <div className='contPriceSpn'>
              <div className="icon__spn"> <p><span className='priceSpnColor'>{chooseRar(2).coinBurn}</span> SPN</p></div>
            </div>
          </div>
          <div className="fonSpn">Rare Spinney
            <div className='contPriceSpn'>
              <div className="icon__spn"> <p><span className='priceSpnColor'>{chooseRar(3).coinBurn}</span> SPN</p></div>
            </div>
          </div>
          <div className="fonSpn">Epic Spinney
            <div className='contPriceSpn'>
              <div className="icon__spn"> <p><span className='priceSpnColor'>{chooseRar(4).coinBurn}</span> SPN</p></div>
            </div></div>
          <div className="fonSpn">Legend Spinney
            <div className='contPriceSpn'>
              <div className="icon__spn"> <p><span className='priceSpnColor'>{chooseRar(5).coinBurn}</span> SPN</p></div>
            </div>
          </div>
        </div>
        <div className="contentBlockTicket">
          <div className="textTicket"><span>Will be counted as burned:</span></div>
          <div className="fonTicketTop">
            {/* Когда выбираем NFT ниже,  здесь 7680 просчет суммы SPN от общего количества
             выбранных NFT. Выше значения для каждой редкости. */}
            <div className="nambTicket">{totalCoinBurn}</div>
            <div className="icon__spn"> <p>SPN</p></div>
          </div>
        </div>


        <div className="slider__modalRuna">
          <Swiper
            id='spinn__slider'
            modules={[Scrollbar, Mousewheel]}
            mousewheel={true}
            spaceBetween={8}
            breakpoints={{

              610: {
                slidesPerView: 1
              },
              1024: {
                slidesPerView: 4
              }
            }}
            direction={'horizontal'}
            scrollbar={{ draggable: true }}>

            {mergedData && mergedData.length ? mergedData.map((item, index) => (
              <SwiperSlide
                key={index}
                onClick={() => handleClickSpinBurn(index, item.asset_id, item.data.rarity)}
              >

                <div className={`slide__player_${chooseRar(item.data.rarity).number} ${activeIds.includes(item.asset_id) ? 'selected' : ''}`}>

                  <div className="bg__id__runs"></div>
                  <div className="id__runs">{item.asset_id}</div>
                </div>
              </SwiperSlide>
            )) : null}
            <div className="swiper-scrollbar"></div>
          </Swiper>
        </div>
        <div className="btn__modal">
          <button onClick={OnClose}>
            <span className='tokenDiscont'>Cancel</span>
          </button>
          <button onClick={() => burn(activeIds)}>
            <span className='tokenDiscont'>Burn</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalBorn;
