import React from 'react';
import '../Modal/Modal.css';
import '../Modal/ModalListRuna.css';
import { useState, useEffect } from 'react'
import { Scrollbar, Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/mousewheel';




const ModalPremiumAccess = ({ active, setActive, children }) => {
  const [isActive, setIsActive] = useState(active);

  const OnClose = () => {
    setActive(false);
  }
  const [selectedSlide, setSelectedSlide] = useState(0);
  const handleSlideClick = (index) => {
    setSelectedSlide(index);
  };



  return (
    <>
      <div id="modal7" className={active ? "modalRuna activerun" : "modalRuna"}   onClick={() => setActive(false) }>
        <div className={active ? "modal__contentRuna activerun" : "modal__contentRuna"} onClick={e => e.stopPropagation()}>
          {children}
          <div className="icon__modalRuna"></div>
          <div className="title__modalRuna">Choose the Infinity Rune</div>
          <div className="parag__modalRuna">(NFT’s must be in the game to be displayed here)</div>
          <div className="slider__modalRuna">
            <Swiper id='runs__slider'

              modules={[Scrollbar]}
              spaceBetween={10}
              slidesPerView={4}
              mousewheel={true}

              direction={'horizontal'}
              scrollbar={{ draggable: true }}
            // onSwiper={(swiper) => console.log(swiper)}
            // onSlideChange={() => console.log('slide change')}
            >
              <SwiperSlide onClick={() => handleSlideClick(0)}><div className={
                'slide__1' + (selectedSlide === 0 ? ' selected' : '')}>
                <div className="bg__id__runs" ></div>
                <div className="id__runs">#123456</div>
              </div>
              </SwiperSlide>
              <SwiperSlide onClick={() => handleSlideClick(1)}><div className={
                'slide__2' + (selectedSlide === 1 ? ' selected' : '')}>
                <div className="bg__id__runs" ></div>
                <div className="id__runs">#123456</div>
              </div>
              </SwiperSlide>
              <SwiperSlide onClick={() => handleSlideClick(2)}><div className={
                'slide__3' + (selectedSlide === 2 ? ' selected' : '')}>
                <div className="bg__id__runs" ></div>
                <div className="id__runs">#123456</div>
              </div>
              </SwiperSlide>
              <SwiperSlide onClick={() => handleSlideClick(3)}><div className={
                'slide__4' + (selectedSlide === 3 ? ' selected' : '')}>
                <div className="bg__id__runs" ></div>
                <div className="id__runs">#123456</div>
              </div>
              </SwiperSlide>
              <SwiperSlide onClick={() => handleSlideClick(4)}><div className={
                'slide__5' + (selectedSlide === 4 ? ' selected' : '')}>
                <div className="bg__id__runs" ></div>
                <div className="id__runs">#123456</div>
              </div>
              </SwiperSlide>

            </Swiper>
          </div>
          <div className="btn__runs">
            <button onClick={OnClose} className='cancelRuna'>Cancel</button>
            <button className='stakeRuna' >Stake</button>
          </div>

        </div>
      </div>
    </>
  );
};




export default ModalPremiumAccess;