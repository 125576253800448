import React from 'react';
import '../Modal/ModalClaim.css';
import '../Modal/Modal.css';
import '../Chance/Chance.css';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

const ModalWinner = ({ active, openModalWinner, children, claimAsset, claimTrans, activePrise, type, user }) => {
  const [isActive, setIsActive] = useState(active);
  const OnClose = () => {
    openModalWinner(false);
  }
  const [selectedBlock, setSelectedBlock] = useState(3);
  const [ticket, setTicket] = useState(3);

  useEffect(() => {
    if (activePrise && activePrise.userlist !== undefined) {
      const count = activePrise.userlist.filter(name => name === user).length - 1;
      setTicket(count);
    }
  }, [activePrise]);

  // const pointNoRunas = (rar) => {
  //   let points = 0;

  //   switch (rar) {
  //     case 0:
  //       points = 1;
  //       break;
  //     case 1:
  //       points = 5;
  //       break;
  //     case 2:
  //       points = 10;
  //       break;
  //     case 3:
  //       points = 20;
  //       break;
  //     case 4:
  //       points = 40;
  //       break;
  //     case 5:
  //       points = 80;
  //       break;

  //     default:
  //       points = 5;
  //       break;
  //   }
  //   return points;
  // }










  const tmplChoose = (rar) => {
    let cardChangeIcon = 0;
    console.log("rar", rar);
    console.log("type", type);
    console.log("activePrise1", activePrise);
    switch (rar) {
      case parseInt(process.env.REACT_APP_SPINNEY_COMMON):
        cardChangeIcon = 'commonca__2'
        break;
      case parseInt(process.env.REACT_APP_SPINNEY_UNCOMMON):
        cardChangeIcon = 'leaf__3'
        break;
      case parseInt(process.env.REACT_APP_SPINNEY_RARE):
        cardChangeIcon = 'rar__4'
        break;
      case parseInt(process.env.REACT_APP_SPINNEY_EPIC):
        cardChangeIcon = 'viol__3'
        break;
      case parseInt(process.env.REACT_APP_SPINNEY_LEGEND):
        cardChangeIcon = 'sunsh__5'
        break;
      case parseInt(process.env.REACT_APP_BOX_STARTER):
        cardChangeIcon = 'starter__box'
        break;
      case parseInt(process.env.REACT_APP_BOX_MYSTERY):
        cardChangeIcon = 'mystery__box'
        break;
      case parseInt(process.env.REACT_APP_BOX_INFINITY):
        cardChangeIcon = 'infinity__box'
        break;


      default:
        cardChangeIcon = '';
        break;
    }
    return cardChangeIcon;
  }
  // показывать тайтл в зависимости с какой страницы Boost UP || Offer Tag
  const titleData = [
    {
      id: 0,
      title: 'Boost UP'

    },
    {
      id: 1,
      title: 'Offer Tag'
    },


  ];

  return (
    <div id="ModalTicket" className={active ? "modal__content activerun" : "modal__content"} >
      <div id="modal16">
        {children}
        <div className="WinPageFon ">
          <div className="WinPage ">
            <div className="circleFon"> </div>
            <div className="titleWinner">Winner</div>
            <div className={`container__game ${active ? "modal activerun" : "modal"}`}>
              <div className="title titlBoostUp">
                <span>Congratulations to the winner of the
                  {type === "offer" ?
                    " Offer Tag "
                    :
                    " Boost up "
                  }

                  {/* {titleData.title} 0 если Boost up/1 если Offer tag*/}
                  game!</span>
              </div>

              <p>NFT was sent to your wallet</p>
              <div className="iconWinner">
                <div className={`fonWinnerIcon ${selectedBlock ? tmplChoose(activePrise ? type === "offer" ? activePrise.tmpl_prise : activePrise.tmpl_access : 0) : ''}`}></div>
                <div className="fonWinner"></div>
              </div>
              {/* Добавить билеты если выиграл но рег было больше 1 и отобразить количество получаемых билетов */}

              {ticket > 0 && type !== "offer" ?
                <>
                  <div className="winnerBoxTicker">
                    <div className="winnersTiclet"></div>
                    <div className="cirklFonTicket"></div>
                  </div>

                  <div className="winnerTicket modalWin"> x{ticket}</div>
                </>
                :
                null}

              <div className="btn__modal"><button onClick={OnClose}>Confirm</button></div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}






export default ModalWinner;