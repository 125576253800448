import React from 'react';
import '../Modal/ModalClaim.css';
import '../Modal/Modal.css';
import Modal from './Modal';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import BoxSpinners from '../BoxSpinners/BoxSpinners';

const ModalUnstakeSpinney = ({ id, active, setActive, children, remFromSlotTrans }) => {
    const [isActive, setIsActive] = useState(active);
    const OnClose = () => {
        setActive(false);
    }


    return <div id="modal11" className={active ? "modal__content activerun" : "modal__content"}    onClick={() => setActive(false) }>
    
        <div id='claim__unstake' className={active ? "modal activerun" : "modal"}  onClick={(e) => e.stopPropagation()}>
            <div className="icon__unstake"></div>
            <div className="title_purchase unstake">unstake Spinney</div>
            <div className="u">To be able to unstake Spinney, you must use the Stop & Claim<br /> button. Are you sure you want to unstake your Spinney? </div>
            <div className="btn__modal unruna">
                <button onClick={OnClose}><spin className='tokenDiscont'>Cancel</spin></button>
                <button id='confirm' onClick={() => remFromSlotTrans() && setActive(false)} style={{ color: "#fff", fontFamily: "'Dongle', sans-serif", fontSize: "20px" }}>Confirm</button>
            </div>
        </div>

    </div>
}


export default ModalUnstakeSpinney;