import React, { useContext, useState, useEffect } from 'react';
import '../Modal/ModalClaim.css';
import '../Modal/Modal.css';
import { Link } from 'react-router-dom';
import { Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/mousewheel';
import { Mousewheel } from 'swiper';

import { UALContext } from 'ual-reactjs-renderer';
import { ToastContainer, toast } from 'react-toastify';
import { transferToTheGame } from '../../components/Wax/Transactions';
import { chooseRar } from '../../components/Wax/Variables';

const ModalPremium = ({ props, active, setActive, children, setModal11Active, userInGameState, allLeftPoints, allSpinner, transferProToTheGame, allSpinneyWorkPoints, dispatchRemSpinneyFromWallet, dispAddProPoint }) => {
  const ual = useContext(UALContext);

  const [selectedSlides, setSelectedSlides] = useState([]);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [creditNums, setCreditNum] = useState(null);


  const slideData = [
    { id: 0, className: 'slide__1' },
    { id: 1, className: 'slide__2' },
    { id: 2, className: 'slide__3' },
    { id: 3, className: 'slide__4' },
    { id: 4, className: 'slide__5' },
    { id: 5, className: 'slide__6' },
  ];

  const OnClose = () => {
    setActive(false);
  }


  const [allPro, setAllPro] = useState([]);
  const [allSpiney, setAllSpinney] = useState([]);

  useEffect(() => {

    if (allSpinner && allSpinner.consumables !== undefined) {
      setAllPro(allSpinner.consumables.filter((item) => item.template.template_id == process.env.REACT_APP_ELIT_PASS || item.template.template_id == process.env.REACT_APP_BASIC_PASS || item.template.template_id == process.env.REACT_APP_SUPREME_PASS));
    }

    if (allSpinner && allSpinner.spinney !== undefined) {
      setAllSpinney(allSpinner.spinney.filter(item => item.template.template_id !== process.env.REACT_APP_SPINNEY_PROMO && item.template.template_id !== process.env.REACT_APP_SPINNEY_PROMO2));
    }


  }, [allSpinner]);

  const [activeIdsPass, setActiveIdsPass] = useState([]);
  const [totalCoinBurnPass, setTotalCoinBurnPass] = useState(0);

  const handleClickPassBurn = (event, id, points) => {
    setActiveIdsPass(prevChooses => {
      if (prevChooses.includes(id)) {
        // Удаление id из массива, если оно уже есть
        setTotalCoinBurnPass(
          prevKey => prevKey - points
        )
        return prevChooses.filter(item => item !== id);
      } else {
        // Добавление id в массив, если его нет
        setTotalCoinBurnPass(
          prevKey => prevKey + points
        )
        return [...prevChooses, id];
      }
    });

    //dispatchRemSpinneyFromWallet([id]);
  };

  const [activeIds, setActiveIds] = useState([]);
  const [totalCoinBurn, setTotalCoinBurn] = useState(0);

  const handleClickSpinBurn = (event, id, rar) => {
    setActiveIds(prevChooses => {
      if (prevChooses.includes(id)) {
        // Удаление id из массива, если оно уже есть
        setTotalCoinBurn(
          prevKey => prevKey - chooseRar(rar).coinBurn
        )
        return prevChooses.filter(item => item !== id);
      } else {
        // Добавление id в массив, если его нет
        setTotalCoinBurn(
          prevKey => prevKey + chooseRar(rar).coinBurn
        )
        return [...prevChooses, id];
      }
    });

    //dispatchRemSpinneyFromWallet([id]);
  };

  const [manyLeftDays, setManyLeftDays] = useState(0);
  useEffect(() => {
    if (allSpinneyWorkPoints == 0) {
      setManyLeftDays(0);
    } else {
      setManyLeftDays(Math.floor(allLeftPoints / (allSpinneyWorkPoints / 10)));
    }
  }, [allLeftPoints, allSpinneyWorkPoints]);

  const burn = async (ids, points) => {
    try {
      const { activeUser } = ual;
      const { accountName } = activeUser;

      const transactionPayload = transferToTheGame(accountName, ids, "burning pap ");

      console.log("transactionPayload", transactionPayload);

      const transaction = await activeUser.signTransaction(transactionPayload, {
        blocksBehind: 3,
        expireSeconds: 30,
      });

      dispatchRemSpinneyFromWallet(ids);
      dispAddProPoint(points);
      toast("Transaction ID: " + transaction?.transactionId);
      setActive(false);

    } catch (e) {
      toast(e.toString(), {
        progressClassName: 'error-progress',
      });
      console.log(e);
    }
  }

  return (
    <div
      id="modal6"
      className={`modal__content ${active ? 'activerun' : ''}`} onClick={() => setActive(false)}>
      <div className="premModal">
        <div id="premium" className={`modal ${active ? 'activerun' : ''}`} onClick={(event) => event.stopPropagation()}>
          {children}
          <div className="icon__claim icon_premium"></div>
          <div className="q server titleActiv">Premium account activation</div>
          <div className="q content_premium">
            By Burning Premium Access, you will receive PAP (Premium Access Points). PAP's allows you to use a Premium Account. The cost of PAP's depends on the rarity of your Spinney and rune availability.
            <br />
            <br />
            Activated Premium allows you to use Infinity Runes, referral multiplier and profile multiplier.
          </div>
          <div className="papBoxHeader">
            <div className="titlePapBlock">PAP</div>

            <div className="boxCurrentBalance">
              <div className="titleCurrentBalance">Current <br />balance</div>
              <div className="nambTicket">{allLeftPoints ? allLeftPoints <= 0 ? 0 : allLeftPoints : 0}</div>
            </div>
            <div className="boxCurrentBalance">
              <div className="titleCurrentBalance">Usage<br /> Per Day</div>
              <div className="nambTicket">{allSpinneyWorkPoints / 10}</div>
            </div>
            <div className="boxCurrentBalance">
              <div className="titleCurrentBalance">Expiration<br /> days</div>
              <div id={`${manyLeftDays > 5 ? "activeBg" : allSpinneyWorkPoints !== 0 ? "activeBg2" : ""}`} className="nambTicket">{manyLeftDays > 0 ? manyLeftDays : 0}</div>
            </div>
          </div>
          {/* #activeBg- подсветка зеленым когда больше 5 дней для према./ #activeBg2 - когда меньше либо равно 5 дням */}
          {/* Расчитывается по формуле. Текущий баланс/Использование в день= */}

          <div className="contentBlockTicket">
            <div className="textTicket"><span>PAP accrual for NFT burning </span></div>
          </div>
          <div className="fon__born__tocket">
            <div className="nameSpinneyBurn">Basic Premium Access</div>
            <div className="ticketAll">
              50  PAP’s
            </div>
          </div>
          <div className="fon__born__tocket">
            <div className="nameSpinneyBurn">Elite Premium Access</div>
            <div className="ticketAll">
              150  PAP’s
            </div>
          </div>
          <div className="fon__born__tocket">
            <div className="nameSpinneyBurn">Supreme Premium Access</div>
            <div className="ticketAll">
              450  PAP’s
            </div>
          </div>
          {/* <div className="fon__born__tocket">
            <div className="nameSpinneyBurn">Ancient Spinney</div>
            <div className="ticketAll">
              60  PAP’s
            </div>
          </div> */}
          <div className="fon__born__tocket">
            <div className="nameSpinneyBurn">Common Spinney</div>
            <div className="ticketAll">
              40  PAP’s
            </div>
          </div>
          <div className="fon__born__tocket">
            <div className="nameSpinneyBurn">Uncommon Spinney</div>
            <div className="ticketAll">
              80  PAP’s
            </div>
          </div>
          <div className="fon__born__tocket">
            <div className="nameSpinneyBurn">Rare Spinney</div>
            <div className="ticketAll">
              160  PAP’s
            </div>
          </div>
          <div className="fon__born__tocket">
            <div className="nameSpinneyBurn">Epic Spinney</div>
            <div className="ticketAll">
              320 PAP’s
            </div>
          </div>
          <div className="fon__born__tocket">
            <div className="nameSpinneyBurn">Legend Spinney</div>
            <div className="ticketAll">
              640 PAP’s
            </div>
          </div>



        </div>

        <div className={`rightTopPass ${active ? 'activerun' : ''}`} onClick={(event) => event.stopPropagation()}>
          <div className={`rightBoxPass ${active ? 'activerun' : ''}`} onClick={(event) => event.stopPropagation()}>
            <div className="q server titleActiv">Burn Spinney</div>
            <div className="slider__modalRuna">
              <Swiper
                id='spinn__slider'
                modules={[Scrollbar, Mousewheel]}
                mousewheel={true}
                spaceBetween={8}
                breakpoints={{

                  1100: {
                    slidesPerView: 1
                  },
                  1200: {
                    slidesPerView: 4
                  }
                }}
                direction={'horizontal'}
                scrollbar={{ draggable: true }}>
                {console.log("allSpinner", allSpinner)}

                {allSpiney && allSpiney.length ? allSpiney.map((item, index) => (
                  <SwiperSlide
                    key={index}
                    onClick={() => handleClickSpinBurn(index, item.asset_id, item.data.rarity)}
                  >

                    <div className={`slide__player_${chooseRar(item.data.rarity).number} ${activeIds.includes(item.asset_id) ? 'selected' : ''}`}>

                      <div className="bg__id__runs"></div>
                      <div className="id__runs">{item.asset_id}</div>
                    </div>
                  </SwiperSlide>
                )) : null}

                <div className="swiper-scrollbar"></div>
              </Swiper>
              {/*  */}
            </div>
            <div className="btn__modal">
              <div className="contentBlockTicket">
                <div className="textTicket"><span>You'll get:</span>
                </div>
                <div className="fonTicketTop">
                  <div className="nambTicket">{totalCoinBurn} PAP</div>
                </div>
              </div>
              <button onClick={() => burn(activeIds, totalCoinBurn)}>
                <span className='tokenDiscont'>Burn</span>
              </button>
            </div>
          </div>
          <div className={`rightBoxPass ${active ? 'activerun' : ''}`} onClick={(event) => event.stopPropagation()}>
            <div className="q server titleActiv">Burn Premium Access</div>
            <div className="slider__modalRuna">
              <Swiper
                id='spinn__slider'
                modules={[Scrollbar, Mousewheel]}
                mousewheel={true}
                spaceBetween={8}
                breakpoints={{

                  1100: {
                    slidesPerView: 1
                  },
                  1200: {
                    slidesPerView: 4
                  }
                }}
                direction={'horizontal'}
                scrollbar={{ draggable: true }}>

                {allPro && allPro.length ? allPro.map((item, index) => (
                  // supreme__player -картинка для супрем пасса
                  <SwiperSlide key={index} onClick={() => handleClickPassBurn(index, item.asset_id, item.template.template_id == process.env.REACT_APP_ELIT_PASS ? 150 : item.template.template_id == process.env.REACT_APP_SUPREME_PASS ? 450 : 50)}>
                    <div className={`slide__1Prem ${activeIdsPass.includes(item.asset_id) ? 'selected' : ''} ${item.template.template_id == process.env.REACT_APP_ELIT_PASS ? "gold__player" : item.template.template_id == process.env.REACT_APP_SUPREME_PASS ? "supreme__player" : ""}`}>
                      <div className="bg__id__runs"></div>
                      <div className="id__runs">{item.asset_id}</div>
                    </div>
                  </SwiperSlide>
                )) : null}
                <div className="swiper-scrollbar"></div>
              </Swiper>
            </div>
            <div className="btn__modal">
              <div className="contentBlockTicket">
                <div className="textTicket"><span>You'll get:</span>
                </div>
                <div className="fonTicketTop">
                  <div className="nambTicket">{totalCoinBurnPass} PAP</div>
                </div>
              </div>
              <button onClick={() => burn(activeIdsPass, totalCoinBurnPass)}>
                <span className='tokenDiscont'>Burn</span>
              </button>
            </div>
          </div>
        </div>

      </div>
      {/*--  */}
    </div>
  );
};

export default ModalPremium;
