import React from 'react';
import '../Modal/ModalClaim.css';
import '../Modal/Modal.css';



const ModalAttention = ({ active, props, setActive, children, localActiveSpiner, totalRewardState, claimAsset, spinTrans }) => {
  const OnClose = () => {
    setActive(false);
  }

  return (
    <div id="modal18" className={active ? "modal__content activerun" : "modal__content"} onClick={() => setActive(false)} >
      <div id='claim' className={active ? "modal activerun" : "modal"} onClick={(event) => event.stopPropagation()}>
        <div className="q server">Attention</div>
        <div className="contAttention">Are you sure you want to spin your Spinney when you have a rune in the slot? This action will not use your rune and you will have to pay for consumption. To activate the runes you need to activate premium on this Spinney. </div>
        <div className="btn__modal">
          <button onClick={OnClose}><span className='tokenDiscont'>Cancel</span></button>
          <button
            onClick={() => {
              spinTrans("one", [claimAsset.asset_id])
              setActive(false);
            }}
          ><span className='tokenDiscont'>Confirm</span></button>
        </div>
      </div>
    </div>
  );

}
export default ModalAttention;