export const registration = (accountName, referral) => {
    return {
        actions: [{
            account: process.env.REACT_APP_CONTRACT,
            name: "registration",
            authorization: [
                {
                    actor: accountName,
                    permission: "active",
                }
            ],
            data: {
                user: accountName,
                referral: referral
            }
        }]
    };
};

export const withdrawNftFromGame = (accountName, ids) => {
    return {
        actions: [{
            account: process.env.REACT_APP_CONTRACT,
            name: "withdrawnft",
            authorization: [
                {
                    actor: accountName,
                    permission: "active",
                }
            ],
            data: {
                to: accountName,
                asset_ids: ids
            }
        }]
    };
};

export const withdrawCollNftFromGame = (accountName, ids) => {
    return {
        actions: [{
            account: process.env.REACT_APP_CONTRACT,
            name: "withdnftcol",
            authorization: [
                {
                    actor: accountName,
                    permission: "active",
                }
            ],
            data: {
                to: accountName,
                asset_ids: ids
            }
        }]
    };
};

export const transferToTheGame = (accountName, ids, memo) => {
    return {
        actions: [
            {
                account: "atomicassets",
                name: "transfer",
                authorization: [
                    {
                        actor: accountName, // use account that was logged in
                        permission: "active",
                    },
                ],
                data: {
                    from: accountName,
                    to: process.env.REACT_APP_CONTRACT,
                    asset_ids: ids,
                    memo: memo,
                },
            },
        ],
    };
};

export const buySlot = (accountName, sum) => {
    return {
        actions: [
            {
                account: "eosio.token",
                name: "transfer",
                authorization: [
                    {
                        actor: accountName, // use account that was logged in
                        permission: "active",
                    },
                ],
                data: {
                    from: accountName,
                    to: process.env.REACT_APP_CONTRACT,
                    quantity: sum,
                    memo: "buyslots ",
                },
            },
        ],
    };
};

export const addToSlot = (accountName, asset_id) => {
    return {
        actions: [{
            account: process.env.REACT_APP_CONTRACT,
            name: "addtoslot",
            authorization: [
                {
                    actor: accountName,
                    permission: "active",
                }
            ],
            data: {
                user: accountName,
                asset_id: asset_id,
                slot: 0,
            }
        }]
    };
};


export const addRuna = (accountName, asset_id, runa_id) => {
    return {
        actions: [{
            account: process.env.REACT_APP_CONTRACT,
            name: "addruna",
            authorization: [
                {
                    actor: accountName,
                    permission: "active",
                }
            ],
            data: {
                user: accountName,
                asset_id: asset_id,
                runa_id: runa_id,
            }
        }]
    };
};


export const addSpecRuna = (accountName, asset_id, memo) => {
    return {
        actions: [
            {
                account: "atomicassets",
                name: "transfer",
                authorization: [
                    {
                        actor: accountName, // use account that was logged in
                        permission: "active",
                    },
                ],
                data: {
                    from: accountName,
                    to: process.env.REACT_APP_CONTRACT,
                    asset_ids: [asset_id],
                    memo: memo,
                },
            },
        ],
    };
};


export const remRuna = (accountName, runa_id) => {
    return {
        actions: [{
            account: process.env.REACT_APP_CONTRACT,
            name: "outruna",
            authorization: [
                {
                    actor: accountName,
                    permission: "active",
                }
            ],
            data: {
                user: accountName,
                asset_id: runa_id
            }
        }]
    };
};

export const remFromSlot = (accountName, asset_id) => {
    return {
        actions: [{
            account: process.env.REACT_APP_CONTRACT,
            name: "remfromslot",
            authorization: [
                {
                    actor: accountName,
                    permission: "active",
                }
            ],
            data: {
                user: accountName,
                asset_id: asset_id
            }
        }]
    };
};

export const claim = (accountName, asset_id) => {
    return {
        actions: [{
            account: process.env.REACT_APP_CONTRACT,
            name: "claim",
            authorization: [
                {
                    actor: accountName,
                    permission: "active",
                }
            ],
            data: {
                user: accountName,
                asset_id: asset_id
            }
        }]
    };
};

export const spin = (accountName, asset_ids) => {
    return {
        actions: [{
            account: process.env.REACT_APP_CONTRACT,
            name: "spin",
            authorization: [
                {
                    actor: accountName,
                    permission: "active",
                }
            ],
            data: {
                user: accountName,
                asset_ids: asset_ids,
            }
        }]
    };
};

export const actvipspin = (accountName, asset_id, e) => {
    return {
        actions: [{
            account: process.env.REACT_APP_CONTRACT,
            name: "actvipspin",
            authorization: [
                {
                    actor: accountName,
                    permission: "active",
                }
            ],
            data: {
                user: accountName,
                asset_id: asset_id,
                status: e,
            }
        }]
    };
};

export const tokenDeposite = (accountName, sum, memo) => {
    return {
        actions: [
            {
                account: process.env.REACT_APP_TOKEN,
                name: "transfer",
                authorization: [
                    {
                        actor: accountName, // use account that was logged in
                        permission: "active",
                    },
                ],
                data: {
                    from: accountName,
                    to: process.env.REACT_APP_CONTRACT,
                    quantity: sum,
                    memo: memo,
                },
            },
        ],
    };
};


export const craft = (accountName, sum, type) => {
    return {
        actions: [{
            account: process.env.REACT_APP_CONTRACT,
            name: "craft",
            authorization: [
                {
                    actor: accountName,
                    permission: "active",
                }
            ],
            data: {
                user: accountName,
                quantity: sum,
                type: type,
            }
        }]
    };
};



export const tokenWithdraw = (accountName, sum, fee, waxprice) => {
    return {
        actions: [
            {
                account: process.env.REACT_APP_TOKEN_WAX,
                name: "transfer",
                authorization: [
                    {
                        actor: accountName, // use account that was logged in
                        permission: "active",
                    },
                ],
                data: {
                    from: accountName,
                    to: process.env.REACT_APP_CONTRACT,
                    quantity: waxprice + " WAX",
                    //quantity: process.env.REACT_APP_PRICE_WITHDRAW,
                    memo: "withdraw SPN - paying 5% commission for withdrawing a token from the game",
                },
            },
            {
                account: process.env.REACT_APP_CONTRACT,
                name: "withdrawt",
                authorization: [
                    {
                        actor: accountName,
                        permission: "active",
                    }
                ],
                data: {
                    to: accountName,
                    quantity: sum,
                    fee: fee
                }
            },
        ],
    };
};



export const itemBuy = (accountName, item, wax, spn) => {
    return {
        actions: [
            {
                account: process.env.REACT_APP_TOKEN_WAX,
                name: "transfer",
                authorization: [
                    {
                        actor: accountName, // use account that was logged in
                        permission: "active",
                    },
                ],
                data: {
                    from: accountName,
                    to: process.env.REACT_APP_CONTRACT,
                    quantity: wax + " WAX",
                    memo: "buyitem " + item + " ",
                },
            },
            {
                account: process.env.REACT_APP_CONTRACT,
                name: "buyitems",
                authorization: [
                    {
                        actor: accountName,
                        permission: "active",
                    }
                ],
                data: {
                    user: accountName,
                    quantity: spn + " " + process.env.REACT_APP_VAL,
                    memo: item
                }
            },
        ],
    };
};

export const buyInStorage = (accountName, asset_id, type, count) => {
    return {
        actions: [{
            account: process.env.REACT_APP_CONTRACT,
            name: "buyinstorage",
            authorization: [
                {
                    actor: accountName,
                    permission: "active",
                }
            ],
            data: {
                user: accountName,
                asset_id: asset_id,
                type: type,
                count: count
            }
        }]
    };
};

export const buyMult = (accountName, quantity) => {
    return {
        actions: [{
            account: process.env.REACT_APP_CONTRACT,
            name: "buymult",
            authorization: [
                {
                    actor: accountName,
                    permission: "active",
                }
            ],
            data: {
                user: accountName,
                quantity: quantity,
            }
        }]
    };
};

export const buyPack = (accountName, sum, memo) => {
    return {
        actions: [
            {
                account: "eosio.token",
                name: "transfer",
                authorization: [
                    {
                        actor: accountName, // use account that was logged in
                        permission: "active",
                    },
                ],
                data: {
                    from: accountName,
                    to: process.env.REACT_APP_CONTRACT,
                    quantity: sum,
                    memo: memo,
                },
            },
        ],
    };
};



export const regLottBoost = (accountName, asset_ids, tmpl_access) => {
    return {
        actions: [{
            account: process.env.REACT_APP_CONTRACT,
            name: "reglottb",
            authorization: [
                {
                    actor: accountName,
                    permission: "active",
                }
            ],
            data: {
                user: accountName,
                asset_ids: asset_ids,
                tmpl_access: tmpl_access
            }
        }]
    };
};

export const regLottOffer = (accountName, id, number) => {
    return {
        actions: [{
            account: process.env.REACT_APP_CONTRACT,
            name: "reglottoff",
            authorization: [
                {
                    actor: accountName,
                    permission: "active",
                }
            ],
            data: {
                user: accountName,
                id: id,
                number: number
            }
        }]
    };
};

export const withdrawTicket = (accountName, count) => {
    return {
        actions: [{
            account: process.env.REACT_APP_CONTRACT,
            name: "withdrtick",
            authorization: [
                {
                    actor: accountName,
                    permission: "active",
                }
            ],
            data: {
                user: accountName,
                count: count
            }
        }]
    };
};

export const gettopfprise = (accountName, asset_id) => {
    return {
        actions: [{
            account: process.env.REACT_APP_CONTRACT,
            name: "gettopfprise",
            authorization: [
                {
                    actor: accountName,
                    permission: "active",
                }
            ],
            data: {
                user: accountName
            }
        }]
    };
};

export const getbonus = (accountName) => {
    return {
        actions: [{
            account: process.env.REACT_APP_CONTRACT,
            name: "getbonus",
            authorization: [
                {
                    actor: accountName,
                    permission: "active",
                }
            ],
            data: {
                user: accountName
            }
        }]
    };
};

// From
// "z1wk..c.wam"
// To
// "swap.taco"
// Quantity
// "1.0000 SPN"
// Memo
// "deposit"


// From
// "z1wk..c.wam"
// To
// "swap.taco"
// Quantity
// "0.66107582 WAX"
// Memo
// "deposit"


// User
// "z1wk..c.wam"
// To_buy
// "79.7129 SPNWAX"

export const addliquidity = (accountName, wax, spn, spnwax, idnum) => {
    return {
        actions: [
            {
                account: process.env.REACT_APP_TOKEN_WAX,
                name: "transfer",
                authorization: [
                    {
                        actor: accountName, // use account that was logged in
                        permission: "active",
                    },
                ],
                data: {
                    from: accountName,
                    to: process.env.REACT_APP_CONTRACT,
                    quantity: wax + " WAX",
                    memo: "liquidity ",
                },
            },
            // {
            //     account: process.env.REACT_APP_TOKEN,
            //     name: "transfer",
            //     authorization: [
            //         {
            //             actor: accountName, // use account that was logged in
            //             permission: "active",
            //         },
            //     ],
            //     data: {
            //         from: accountName,
            //         to: process.env.REACT_APP_CONTRACT,
            //         quantity: spn + " " + process.env.REACT_APP_VAL,
            //         memo: "deposit",
            //     },
            // },
            {
                // account: "swap.taco",
                // name: "addliquidity",
                account: process.env.REACT_APP_CONTRACT,
                name: "spintaco",
                authorization: [
                    {
                        actor: accountName,
                        permission: "active",
                    }
                ],
                data: {
                    user: accountName,
                    //to_buy: spnwax + " SPNWAX",
                    //quantity: spnwax + " SPNWAX",
                    lptoken: spnwax + " SPNWAX",
                    waxtoken: wax + " WAX",
                    spntoken: spn + " " + process.env.REACT_APP_VAL,
                    //idnum: idnum
                    idnum: idnum
                }
            }]
    };
};

export const remliquidity = (accountName, enddate) => {
    return {
        actions: [
            {
                // account: "swap.taco",
                // name: "addliquidity",
                account: process.env.REACT_APP_CONTRACT,
                name: "remstake",
                authorization: [
                    {
                        actor: accountName,
                        permission: "active",
                    }
                ],
                data: {
                    user: accountName,
                    enddate: enddate,
                    num: 9999
                }
            }]
    };
};

