import React from 'react';
import '../CraftDropNav/CraftDropNav.css';
import './Chance.css'
import BoostUp from './BoostUp/BoostUp';
import OfferTag from './OfferTag/OfferTag';
import { useState, useEffect } from 'react';

const Chance = (props) => {

  const [activeMenuChance, setActiveMenuChance] = useState("offer1");

  const handleMenuClickChance = (menu) => {
    setActiveMenuChance(menu);
    //localStorage.setItem('activeMenuChance', menu);
  };

  useEffect(() => {
    //const savedActiveMenuChance = localStorage.getItem('activeMenuChance');
    // if (savedActiveMenuChance) {
    //   setActiveMenuChance(savedActiveMenuChance);
    // } else {
    setActiveMenuChance("offer1");
    //localStorage.setItem('activeMenuChance', "boost1");
    //}
  }, []);



  return (
    <div className="main__content">
      <div className="contentBankpage">
        <div className="chance__nav">

          <div className="nav__top">
            {/* <button
              className={`bt__nav ${activeMenuChance === "boost1" ? "active__top" : ""} `}
              onClick={() => handleMenuClickChance("boost1")} ><span className='tokenDiscont'>Boost UP</span>
              </button> */}
            <button
              className={`bt__nav ${activeMenuChance === "offer1" ? "active__top" : ""} `}
              onClick={() => handleMenuClickChance("offer1")}><span className='tokenDiscont'>Offer Tag</span>
            </button>

          </div>
          {/* {activeMenuChance === "boost1" && (
            <div className="boost1">
              <BoostUp
                openModalNftRegistration={props.openModalNftRegistration}
                openModalTicket={props.openModalTicket}
                openModalWinner={props.openModalWinner}
                userInGameState={props.userInGameState}
                regLottBoostTrans={props.regLottBoostTrans}
                balanceDisp={props.balanceDisp}
                allSpinnerInGame={props.allSpinnerInGame} />
            </div>
          )} */}
          {activeMenuChance === "offer1" && (
            <div className="offer1">
              <OfferTag
                userInGameState={props.userInGameState}
                allResInGameLocal={props.allResInGameLocal}
                resDisp={props.resDisp}
              />
            </div>
          )}
        </div>
      </div>
    </div>


  )
}


export default Chance;

