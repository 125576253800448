import React, { useState, useEffect } from 'react';
import {
    Link,
    useParams,
} from "react-router-dom";
import '../RefPage/RefPage.css';
import '../RegisteredList/RegisteredList.css';
import '../WinnersList/WinnersList.css';
import ModalTicket from '../Modal/ModalTicket';
import ModalWinner from '../Modal/ModalWinner';

import useLotteryOfferListWinners from '../Wax/useLotteryOfferListWinners';


const WinnersListOffer = ({ userInGameState, props }) => {



    const choosePriseNft = (priseTmpl) => {
        let imagesWinner;
        switch (priseTmpl) {
            case parseInt(process.env.REACT_APP_SPINNEY_COMMON):
                imagesWinner = 'Winner__common';
                break;
            case parseInt(process.env.REACT_APP_SPINNEY_UNCOMMON):
                imagesWinner = 'Winner__leaf';
                break;
            case parseInt(process.env.REACT_APP_SPINNEY_RARE):
                imagesWinner = 'Winner__frosty';
                break;
            case parseInt(process.env.REACT_APP_SPINNEY_EPIC):
                imagesWinner = 'Winner__violet';
                break;
            case parseInt(process.env.REACT_APP_SPINNEY_LEGEND):
                imagesWinner = 'Winner__sunch';
                break;
            case parseInt(process.env.REACT_APP_BOX_STARTER):
                imagesWinner = 'Winner__starter';
                break;
            case parseInt(process.env.REACT_APP_BOX_MYSTERY):
                imagesWinner = 'Winner__mystery';
                break;
            case parseInt(process.env.REACT_APP_BOX_INFINITY):
                imagesWinner = 'Winner__infinity';
                break;

            default:
                break;
        }
        return imagesWinner;
    }


    // let { id } = useParams();

    // useEffect(() => {
    // }, [id]);

    const [isCopied, setIsCopied] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [allLotteryOfferListWinnersGetLocal, setAllLotteryOfferListWinnersGetLocal] = useState(null);
    const [allRefferalsLocal, setAllRefferals] = useState(null);

    const { allLotteryOfferListWinnersGet } = useLotteryOfferListWinners(userInGameState?.user);
    //console.log("allLotteryOfferListWinnersGet", allLotteryOfferListWinnersGet)
    //console.log("userInGameState", userInGameState)

    useEffect(() => {
        console.log("allLotteryOfferListWinnersGet", allLotteryOfferListWinnersGet);
        if (allLotteryOfferListWinnersGet !== null) {
            setAllLotteryOfferListWinnersGetLocal(allLotteryOfferListWinnersGet);
        }

    }, [allLotteryOfferListWinnersGet]);

    // console.log("allRefferalsLocal", allRefferalsLocal)
    const handleNextClick = () => {
        if (currentPage < 2) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevClick = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const chooseList = (e, num) => {
        if (e) {
            setActiveList(num);
        } else {
            setActiveList(null);
        }

    };

    const [activeList, setActiveList] = useState(null);
    const totalBlocks = 10;


    //let allRefCount = 0;

    const renderPageContent = () => {
        if (currentPage === 1) {
            return (
                <>
                    {allLotteryOfferListWinnersGet && allLotteryOfferListWinnersGet.length ? allLotteryOfferListWinnersGet.map((user, index) => {
                        //allRefCount += user.count > 0 ? 1 : 0;\
                        const icon = choosePriseNft(user.tmpl_prise);
                        return (
                            <div key={index} id={`WaxRef${index}`} className={`block__ref`} onClick={() => chooseList(true, index)} style={{ cursor: "pointer" }}>
                                <div id='WaxRef' className='block__ref  bg__block__ref'>
                                    <div className='title__link'>{user.users[0]}</div>
                                    <div className={`${icon} iconWinnerBoostUp`}></div>
                                    <div className='blockPoints'>
                                        {/* <div className='title__link winnerTimer'>{new Date(user.time * 1000).toLocaleString()}</div> */}
                                        <div className='title__link winnerTimer'>
                                            {new Date(user.time * 1000).toLocaleString('en-US', {
                                                year: 'numeric',
                                                month: '2-digit',
                                                day: '2-digit',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit',
                                                hour12: false
                                            }).replace(/[-,./]/g, '.')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    ) : null
                    }

                </>
            );
        }

    };

    const renderPageContentList = () => {
        if (currentPage === 1) {
            return (
                <>
                    {activeList >= 0 && activeList !== null && allLotteryOfferListWinnersGet && allLotteryOfferListWinnersGet.length ? allLotteryOfferListWinnersGet[activeList].userlist.map((user, index) => {
                        //allRefCount += user.count > 0 ? 1 : 0;
                        const icon = choosePriseNft(allLotteryOfferListWinnersGet[activeList].tmpl_prise);
                        return (
                            <div key={index} id={`WaxRef${index}`} className={`block__ref ${allLotteryOfferListWinnersGet[activeList].winnum.includes(index) ? 'winner' : ''}`}>
                                <div id='WaxRef' className='block__ref'>
                                    <div className={`title__link ${!allLotteryOfferListWinnersGet[activeList].winnum.includes(index) && user == userInGameState?.user ? "bgTextPlayer" : ""}`}>{index + 1}. {user}</div>
                                    {/* <div className='blockPoints'>
                                        <div className='title__link'>{user}</div>
                                    </div> */}

                                </div>
                            </div>
                        )
                    }
                    ) : null

                    }

                </>
            );
        }

    };
    return (
        <div className="main__content">
            <div id='Lotery' className='refPage ContentList'>


                {activeList === null ?
                    <>
                        {/* класс bgTextPlayer Оранжевым если тоже моя NFT но она не выигрышная. */}
                        <div className="blockTopLotery">
                            <div className="RefMenu">Winners list</div>
                            <Link className='Back' to="/games">
                                <div className="iconBack"></div>
                                <div className='titleBack'>Back</div>
                            </Link>
                        </div>

                        <div id='BoUp' className='ref__content boostUpWinnerList'>
                            <div className="ref__info RegisteredList">
                                <div className="block__ref bottom__width ">
                                    <div className="refCount OfferUpIcon"></div>
                                    <div id='count' className="block__ref2">
                                        <div className="title__link centerLink">Offer Tag Winners list</div>
                                    </div>
                                </div>

                            </div>

                            {renderPageContent()}
                        </div>
                    </>
                    :

                    <>
                        {/* <div className="RefMenu" style={{ position: "relative" }}>
            <button className="bt__nav active__top" style={{ position: "absolute", right: "110%", top: 0, width: "auto", padding: "0 10px" }} onClick={() => chooseList(false, 0)}> back</button>
            Winners list

        </div> */}
                        <div className="blockTopLotery">
                            <div className="RefMenu">Winners list</div>
                            <div className='Back' onClick={() => chooseList(false, 0)}>
                                <div className="iconBack"></div>
                                <div className='titleBack'>Back</div>
                            </div>
                        </div>
                        <div className='ref__content'>
                            <div className="ref__info RegisteredList bgWinner">
                                <div className="block__ref">
                                    <div id='count' className="block__ref2">
                                        <div className="title__link OfferUpIcon">Winner Wallet</div>
                                    </div>
                                    <div className={`iconWinnerBoostUp ${choosePriseNft(allLotteryOfferListWinnersGet[activeList].tmpl_prise)}`}></div>
                                    <div className="title__link winnerTimer">{allLotteryOfferListWinnersGet[activeList].users[0]}</div>
                                    {/*  */}
                                </div>
                            </div>


                            {renderPageContentList()}
                        </div>
                    </>

                }



                {
                    allRefferalsLocal && allRefferalsLocal.length > 10 ?
                        <div className='bottomRef'>
                            <button className='refbtn' onClick={handlePrevClick}></button>
                            <button className='refbtn refRight' onClick={handleNextClick}></button>

                        </div>
                        : null
                }


            </div >
        </div>


    );
};

export default WinnersListOffer;