import React from 'react';
import '../Modal/Modal.css';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ModalUnstake = ({ 
    active,
    setActive, 
    children, 
    props, 
    remRunaTrans,
    allRunesInGame,
    addRunaTrans,
    localActiveSpiner,
    allSpinner,
    addSpecRunaTrans,
    activeTable,
    activeBtnBurn,
    runes
 }) => {

  
    const [isActive, setIsActive] = useState(active);
    const [isStaked, setIsStaked] = useState(true);
    const OnClose = () => {
        setActive(false);
    }

    const navigate = useNavigate();

    const handleStakeClick = () => {
        setActive(false);
        setIsStaked(!isStaked);
        navigate('/BoxSpinners');

    };
   

  

    return <div id="modal10" className={active ? "modal__content activerun" : "modal__content"}   onClick={() => setActive(false) } >
        {children}
      
        <div id='claim__unstake' className={active ? "modal activerun" : "modal"} onClick={() => setActive(false)}>
            <div className="icon__unstake"></div>
            {activeBtnBurn ?
            <div className="title_purchase unstake">Soul extraction</div>
            :
            <div className="title_purchase unstake">Unstake the Infinity Rune</div>
            }
             {activeBtnBurn ?
            <div className="u">The soul will be extracted and burned irretrievably. All the benefits it provides will be canceled. Are you sure you want to do this?</div>
            :
            <div className="u">When you unstaking the Runes of Infinity, all your advantages are lost.<br /> Are you sure you want to do this?</div>
             }
            <div className="btn__modal unruna">
                <button onClick={OnClose}><span className='tokenDiscont'>Cancel</span></button>
                <button onClick={() => remRunaTrans() && setActive(false)} style={{ color: "#fff", fontFamily: "'Dongle', sans-serif", fontSize: "20px" }}>Unstake</button>
            </div>
        </div>
    
    </div>
}


export default ModalUnstake;