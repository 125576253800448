import React, { useContext, useState, useEffect } from 'react';
import '../RefPage/RefPage.css';
import { useRef } from 'react';
import { UALContext } from 'ual-reactjs-renderer';
import { ToastContainer, toast } from 'react-toastify';
import {
    Link,
    useParams,
} from "react-router-dom";
import { gettopfprise } from '../../components/Wax/Transactions';

const Leaderbord = ({ userInGameState, resDisp, allTopLocal, userInfoTop, setUserInfoTop }) => {

    const notify = (e) => toast(e);

    const ual = useContext(UALContext);

    const [onlyTicket, setOnlyTicket] = useState(null);

    const [place, setPlace] = useState(0);

    useEffect(() => {
        if (allTopLocal && allTopLocal.length) {
            let spinnerIndex = allTopLocal.findIndex(user => user.user == userInGameState.user);
            setPlace(spinnerIndex + 1);
        }

    }, [allTopLocal]);

    const getPrise = async (user, prisecount) => {
        try {
            const { activeUser } = ual;
            const { accountName } = activeUser;

            const transactionPayload = gettopfprise(accountName);

            const transaction = await activeUser.signTransaction(transactionPayload, {
                blocksBehind: 3,
                expireSeconds: 30,
            });

            setUserInfoTop(prevState => ({
                ...prevState,
                prisecount: [0, 0]
            }));
            resDisp(true, prisecount);
            toast("Transaction ID: " + transaction?.transactionId);

        } catch (e) {
            toast(e.toString(), {
                progressClassName: 'error-progress',
            });
            console.log(e);
        }
    }

    return (

        <div id='Leaderbord' className="leaderboard-wrapper">
            <div className="Leaderbord">
                <div className="blockTopLeaderbord">
                </div>
                <div className="boxTopBord">
                    <div className="titleLeaderbord"> </div>


                    <div className="playerLeaderbord winnerLoaderBg">
                        {/* свой номер в лидерборде */}
                        <div className="boxNumdLeader">{place ? place : "-"}</div>

                        {/* имя своего кошелька */}
                        <div className="walletNameWax">{userInGameState?.user}</div>
                        <div className="rew">Rewards:</div>
                        {/* сколько билетов накопилось для клейма */}
                        <div className="bgRW"><div className="tickFon"></div>{userInfoTop && userInfoTop.prisecount && userInfoTop.prisecount.length ? userInfoTop.prisecount[0] : 0}</div>

                        {userInfoTop && userInfoTop.prisecount && userInfoTop.prisecount[0] > 0 ?
                            <button onClick={() => getPrise(userInfoTop.user, userInfoTop.prisecount)} className='btn__claim'>Claim</button>
                            :
                            <button className='btn__claim disabled'>Claim</button>
                        }
                    </div>
                    <div className="boxInfoLeaderbord">
                        <div className="infoLeaderbord">
                            Tickets are awarded each day to those who hold the top 30 positions by referral points. Referral points you can get from stacked Spinney by your referral. <Link className='linkLeader' target="_blank" to="https://wp.spinnia.io/other-info/referral-system">More info</Link>
                        </div>
                    </div>


                    <div className="bgBlock"></div>
                    <div className="infoTopContent">
                        <div className="infoNumb">№</div>
                        <div className="infoWallet">Wallet</div>
                        <div className="infoReward">Reward</div>
                        <div className="infoPoints">Referral Points</div>
                    </div>

                </div>
                <div className="wrapperContentLeader">


                    <div className="contentLeaderbord ">
                        {/* winnerLoaderBg класс добавляем к boxLeaderbord только для своего ака в списке   */}
                        {allTopLocal && allTopLocal.length ?
                            allTopLocal.map((user, index) => {

                                return (
                                    <div key={user} className={`boxLeaderbord ${user.user == userInGameState?.user ? "winnerLoaderBg" : ""}`}>
                                        {/* <div key={index} className={`boxLeaderbord ${index < 30 ? "winnerLoaderBg" : 0}`}> */}
                                        <div className="boxNumdLeader">{index + 1}</div>
                                        <div className="walletNameWax">{user.user}</div>
                                        {index < 30 ? <div className="winnerIconTicket"></div> : <div className="winnerIconTicket" style={{ opacity: 0 }}></div>}
                                        <div className="borderBox"></div>
                                        <div className="numberLeader">{user.number}</div>
                                        <div className="buttonRp">RP</div>
                                    </div>)
                            })
                            : null
                        }

                    </div>
                </div>
            </div>
        </div >








    );
};

export default Leaderbord;