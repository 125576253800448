import { JsonRpc } from 'eosjs';
import { useEffect, useState } from 'react';
import { arrRpc, savedOption } from './../Wax/Variables';
var numRpc = 0;
//import fetch from 'node-fetch';

const UserBalance = (accountName) => {
    const [balance, setBalance] = useState(null);
    //console.log("process.env.REACT_APP_TOKEN", process.env.REACT_APP_TOKEN);



    useEffect(() => {
        var link = !savedOption || savedOption === "dynamic" ? arrRpc[numRpc] : savedOption;
        const getBalance = async () => {
            if (accountName && accountName != null) { // Добавляем эту проверку

                const rpc = new JsonRpc('https://' + link);
                const balance = await rpc.get_currency_balance(process.env.REACT_APP_TOKEN, accountName, process.env.REACT_APP_VAL);
                //console.log("balance", balance);
                setBalance(parseFloat(balance[0]).toFixed(4));
            }
        }
        getBalance();

    }, [accountName]);

    return balance;
}


export default UserBalance;
