import React from 'react';
import '../Modal/ModalClaim.css';
import '../Modal/Modal.css';
import { Link } from 'react-router-dom';
import { useState, useEffect } from "react";

const useKeyPress = (targetKey) => {
  const [keyPressed, setKeyPressed] = useState(false);

  const handleKeyDown = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  };

  const handleKeyUp = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);



  return keyPressed;
};

const ModalSlots = ({ active, setActive, children, userInGameState, buySlotTrans }) => {
  const [isActive, setIsActive] = useState(active);
  const [openSlots, setOpenSlots] = useState(active);
  const [availSlots, setAvailSlots] = useState(active);

  const OnClose = () => {
    setActive(false);
  }

  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  }

  const handleSelectClick = (event) => {
    event.stopPropagation();
  }

  const PriceSlot = 8000000000

  const [totalS, setTotalS] = useState(1);
  const [iconSum, setIconSum] = useState(PriceSlot);


  const handleIncreaseIconSum = () => {
    setIconSum((prevIconSum) => prevIconSum + PriceSlot);
    setTotalS((prevTotalS) => prevTotalS + 1);
  };

  const handleDecreaseIconSum = () => {
    if (iconSum > PriceSlot) {
      setIconSum((prevIconSum) => prevIconSum - PriceSlot);
      setTotalS((prevTotalS) => prevTotalS - 1);
    }
  };

  //  

  const increaseKey = 'ArrowUp';
  const decreaseKey = 'ArrowDown';

  const increaseKeyPressed = useKeyPress(increaseKey);
  const decreaseKeyPressed = useKeyPress(decreaseKey);

  useEffect(() => {
    if (increaseKeyPressed) {
      handleIncreaseIconSum();
    } else if (decreaseKeyPressed) {
      handleDecreaseIconSum();
    }
  }, [increaseKeyPressed, decreaseKeyPressed]);

  useEffect(() => {
    setOpenSlots(userInGameState?.open_slots ?? 0);
    setAvailSlots(userInGameState.open_slots - userInGameState.all_slot.length);

  }, [userInGameState]);



  return (
    <div id="modal9" className={active ? "modal__content activerun" : "modal__content"} onClick={() => setActive(false)}>
      <div id='slots' className={active ? "modal activerun" : "modal"} onClick={(event) => event.stopPropagation()}>
        <div className="icon__claim icon_slots"></div>
        <div className="title__slots">Slots Purchase</div>
        <div className="sots__block">
          <div className="content__slots">Total of your slots: <div className="total__s">{openSlots}</div></div>
          <div className="content__slots">Available slots: <div className="total__s">{availSlots}</div></div>
        </div>
        <div className="number__slots">
          <div className="content__slot">How many slots you want to buy?</div>
          <div className="box__slots">
            <span className='minus' onClick={handleDecreaseIconSum}></span>
            <div className="total__add">{totalS}</div>
            <span className='plus' onClick={handleIncreaseIconSum}></span>
          </div>

        </div>

        <div className='wallet__price'>
          <div className="wallet__info__price">
            <div className='name__wallet price'>
              <p>Price</p>
            </div>
            <div className="block__sum__slots">
              <div className='icon__sum__wax' >
                <p>{(iconSum / 100000000).toFixed(4)}</p>
              </div>
              <div className='icon__wax__p'>
                <p>WAX</p>
              </div>
            </div>
            <div className="icon__modal__wax"><div className="price__icon"></div></div>
          </div>
        </div>

        <div className="btn__modal">
          <button onClick={OnClose}><span className='tokenDiscont'>Cancel</span></button>
          <button onClick={() => buySlotTrans(iconSum) && setActive(false)}>Purchase</button>
        </div>
      </div>
    </div>
  );

}
export default ModalSlots;
