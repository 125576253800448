import React, { useState, useEffect } from 'react';
import {
    Link,
    useParams,
} from "react-router-dom";
import '../RefPage/RefPage.css';
import '../RegisteredList/RegisteredList.css';
import '../WinnersList/WinnersList.css';
import ModalTicket from '../Modal/ModalTicket';
import ModalWinner from '../Modal/ModalWinner';

import useLotteryBoostListWinners from '../Wax/useLotteryBoostListWinners';


const WinnersListBoostUp = ({ userInGameState, props }) => {

    const chooseActiveInLotteryNft = (priseTmpl) => {
        let images;
        switch (priseTmpl) {
            case parseInt(process.env.REACT_APP_SPINNEY_COMMON):
                break;
            case parseInt(process.env.REACT_APP_SPINNEY_UNCOMMON):
                images = 'common__1';
                break;
            case parseInt(process.env.REACT_APP_SPINNEY_RARE):
                images = 'uncommon__2';
                break;
            case parseInt(process.env.REACT_APP_SPINNEY_EPIC):
                images = 'frosty__3';
                break;
            case parseInt(process.env.REACT_APP_SPINNEY_LEGEND):
                images = 'violett__4';
                break;

            default:
                break;
        }
        return images;
    }

    const choosePriseNft = (priseTmpl) => {
        let imagesWinner;
        switch (priseTmpl) {
            case parseInt(process.env.REACT_APP_SPINNEY_COMMON):
                imagesWinner = 'Winner__common';
                break;
            case parseInt(process.env.REACT_APP_SPINNEY_UNCOMMON):
                imagesWinner = 'Winner__leaf';
                break;
            case parseInt(process.env.REACT_APP_SPINNEY_RARE):
                imagesWinner = 'Winner__frosty';
                break;
            case parseInt(process.env.REACT_APP_SPINNEY_EPIC):
                imagesWinner = 'Winner__violet';
                break;
            case parseInt(process.env.REACT_APP_SPINNEY_LEGEND):
                imagesWinner = 'Winner__sunch';
                break;

            default:
                break;
        }
        return imagesWinner;
    }

    // const iconData = [
    //     {
    //         images: 'common__1',
    //         imagesWinner: 'Winner__common',
    //     },
    //     {
    //         images: 'uncommon__2',
    //         imagesWinner: 'Winner__leaf',
    //     },
    //     {
    //         images: 'frosty__3',
    //         imagesWinner: 'Winner__frosty',
    //     },
    //     {
    //         images: 'violett__4',
    //         imagesWinner: 'Winner__violet',
    //     },
    //     {
    //         images: 'legend__5',
    //         imagesWinner: 'Winner__sunch',
    //     },
    //     {
    //         images: 'starter__box',
    //         imagesWinner: 'Winner__starter',
    //     },
    //     {
    //         images: 'mystery__box',
    //         imagesWinner: 'Winner__mystery',
    //     },
    //     {
    //         images: 'infinity__box',
    //         imagesWinner: 'Winner__infinity',
    //     }
    // ];
    // let { id } = useParams();

    // useEffect(() => {
    // }, [id]);

    const [isCopied, setIsCopied] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [allLotteryBoostListWinnersGetLocal, setAllLotteryBoostListWinnersGetLocal] = useState(null);
    const [allRefferalsLocal, setAllRefferals] = useState(null);

    const { allLotteryBoostListWinnersGet } = useLotteryBoostListWinners(userInGameState?.user);
    //console.log("allLotteryBoostListWinnersGet", allLotteryBoostListWinnersGet)
    //console.log("userInGameState", userInGameState)

    useEffect(() => {
        console.log("allLotteryBoostListWinnersGet", allLotteryBoostListWinnersGet);
        if (allLotteryBoostListWinnersGet !== null) {
            setAllLotteryBoostListWinnersGetLocal(allLotteryBoostListWinnersGet);
        }

    }, [allLotteryBoostListWinnersGet]);

    // console.log("allRefferalsLocal", allRefferalsLocal)
    const handleNextClick = () => {
        if (currentPage < 2) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevClick = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const chooseList = (e, num) => {
        if (e) {
            setActiveList(num);
        } else {
            setActiveList(null);
        }

    };

    const [activeList, setActiveList] = useState(null);
    const totalBlocks = 10;
    //let allRefCount = 0;

    const renderPageContent = () => {
        if (currentPage === 1) {
            return (
                <>
                    {allLotteryBoostListWinnersGet && allLotteryBoostListWinnersGet.length ? allLotteryBoostListWinnersGet.map((user, index) => {
                        //allRefCount += user.count > 0 ? 1 : 0;\
                        const icon = choosePriseNft(user.tmpl_access);
                        return (
                            <div key={index} id={`WaxRef${index}`} className={`block__ref`} onClick={() => chooseList(true, index)} style={{ cursor: "pointer" }}>
                                <div id='WaxRef' className='block__ref bg__block__ref'>
                                    <div className='title__link'>{user.user}</div>
                                    <div className={`${icon} iconWinnerBoostUp`}></div>
                                    <div className='blockPoints'>
                                        {/* <div className='title__link winnerTimer'>
                                            {new Date(user.time * 1000).toLocaleString()}
                                        </div> */}
                                        <div className='title__link winnerTimer'>
                                            {new Date(user.time * 1000).toLocaleString('en-US', {
                                                year: 'numeric',
                                                month: '2-digit',
                                                day: '2-digit',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit',
                                                hour12: false
                                            }).replace(/[-,./]/g, '.')}
                                        </div>


                                    </div>
                                </div>
                            </div>
                        )
                    }
                    ) : null
                    }

                </>
            );
        }

    };

    const renderPageContentList = () => {
        if (currentPage === 1) {
            return (
                <>
                    {activeList >= 0 && activeList !== null && allLotteryBoostListWinnersGet && allLotteryBoostListWinnersGet.length ? allLotteryBoostListWinnersGet[activeList].userlist.map((user, index) => {
                        //allRefCount += user.count > 0 ? 1 : 0;
                        const icon = chooseActiveInLotteryNft(allLotteryBoostListWinnersGet[activeList].tmpl_access);
                        return (
                            <div key={index} id={`WaxRef${index}`} className={`block__ref ${allLotteryBoostListWinnersGet[activeList].winnum.includes(index) ? 'bgTextPlayer' : ''}`}>
                                <div id='WaxRef' className='block__ref'>
                                    <div className='title__link myProff'>{user}</div>

                                    <div className='blockPoints'>
                                        <div className='title__link myProff'>{allLotteryBoostListWinnersGet[activeList].assetIds[index]}</div>
                                        <div className={`${icon} iconPlayerInList`}></div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    ) : null

                    }

                </>
            );
        }

    };
    return (
        <div className="main__content">
            <div id='Lotery' className='refPage ContentList'>

                {/* класс bgTextPlayer Оранжевым если тоже моя NFT но она не выигрышная. */}
                {activeList === null ?
                    <>
                        <div className="blockTopLotery">
                            <div className="RefMenu">Winners list</div>
                            <Link className='Back' to="/games">
                                <div className="iconBack"></div>
                                <div className='titleBack'>Back</div>
                            </Link>
                        </div>

                        <div id='BoUp' className='ref__content boostUpWinnerList'>
                            <div className="ref__info RegisteredList">
                                <div className="block__ref bottom__width ">
                                    <div className="refCount BoostUpIcon"></div>
                                    <div id='count' className="block__ref2">
                                        <div className="title__link centerLink">Boost Up Winners list</div>
                                    </div>
                                    <div className='blockPoints'>

                                    </div>
                                </div>

                            </div>

                            {renderPageContent()}
                        </div>
                    </>
                    :

                    <>
                        {/* <div className="RefMenu" style={{ position: "relative" }}>
            <button className="bt__nav active__top" style={{ position: "absolute", right: "110%", top: 0, width: "auto", padding: "0 10px" }} onClick={() => chooseList(false, 0)}> back</button>
            Winners list

        </div> */}

                        <div className="blockTopLotery">
                            <div className="RefMenu">Winners list</div>
                            <div className='Back' onClick={() => chooseList(false, 0)}>
                                <div className="iconBack"></div>
                                <div className='titleBack'>Back</div>
                            </div>
                        </div>
                        <div className='ref__content'>
                            <div className="ref__info RegisteredList bgWinner">
                                <div className="block__ref">
                                    <div id='count' className="block__ref2">
                                        <div id='BoostUpList' className="title__link">{allLotteryBoostListWinnersGet[activeList].user}</div>
                                    </div>
                                    {/* .Winner__leaf подтягивать нужную картинку  {iconData.imagesWinner} */}
                                    <div className={`iconWinnerBoostUp ${choosePriseNft(allLotteryBoostListWinnersGet[activeList].tmpl_access)}`}></div>
                                    <div className='title__link winnerTimer'>22.10.2023 19:59:10</div>

                                </div>
                            </div>

                            {renderPageContentList()}
                        </div>

                    </>

                }



                {
                    allRefferalsLocal && allRefferalsLocal.length > 10 ?
                        <div className='bottomRef'>
                            <button className='refbtn' onClick={handlePrevClick}></button>
                            <button className='refbtn refRight' onClick={handleNextClick}></button>

                        </div>
                        : null
                }


            </div >
        </div>


    );
};

export default WinnersListBoostUp;