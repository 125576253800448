import React, { useState, useEffect, useRef, useContext } from 'react';

import './App.css'
import Header from './components/Header/Header'
import Spinney from './components/Spinney/Spinney'
import Navfooter from './components/Navfooter/Navfooter'
import Bankpage from './components/Bankpage/Bankpage'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
//import Tokenpage from './components/Bankpage/Token/Tokenpage'
import Assetspage from './components/Bankpage/Assets/Assetspage'
import Modal from './components/Modal/Modal'
import ModalClaim from './components/Modal/ModalClaim'
//import Craft from './components/Craft/Craft'
//import Drop from './components/Drop/Drop'
import CraftDropNav from './components/CraftDropNav/CraftDropNav'
import ModalUnstake from './components/Modal/ModalUnstake'
import ModalUnstakeSpinney from './components/Modal/ModalUnstakeSpinney'
import ModalListRuna from './components/Modal/ModalListRuna'
import ModalListSpinney from './components/Modal/ModalListSpinney'
import Chance from './components/Chance/Chance'
import ModalServer from './components/Modal/ModalServer'
import ModalLogin from './components/Modal/ModalLogin'
import ModalPremium from './components/Modal/ModalPremium'
import ModalSlots from './components/Modal/ModalSlots'
import ModalPremiumAccess from './components/Modal/ModalPremiumAccess'
import ModalRuna from './components/Modal/ModalRuna'

import Admin from './components/Admin/Admin'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//import Navcontent from './components/Navcontent/Navcontent'
import FirstPage from './components/FirstPage/FirstPage'
import UserBalance from './components/Wax/UserBalance';
import useGetAllNftSpinnerOnWallet from './components/Wax/useGetAllNftSpinnerOnWallet';
import useGetAllSpinnerInGame from './components/Wax/useGetAllSpinnerInGame';
import useGetAllResInGame from './components/Wax/useGetAllResInGame';
import useGetAllRunesInGame from './components/Wax/useGetAllRunesInGame';
import useGetAllSpecRunesInGame from './components/Wax/useGetAllSpecRunesInGame';
import useGetUserInfoInGame from './components/Wax/useGetUserInfoInGame';
import useGetFirstTop from './components/Wax/useGetFirstTop';
import { UALContext } from 'ual-reactjs-renderer';
import { transferToTheGame, withdrawNftFromGame, registration, buySlot, addToSlot, tokenDeposite, remFromSlot, claim, addRuna, remRuna, actvipspin, tokenWithdraw, buyInStorage, craft, buyMult, spin, addSpecRuna } from './components/Wax/Transactions';
import { mult, specRunTimeDisp } from './components/Wax/Variables';
import { wombattrans } from './components/Wax/Wombat';
import LoginPage from './components/StartPage/LoginPage'
import StartPage from './components/StartPage/StartPage'
import UniversalSpin from './components/Spinney/Spinner/UniversalSpin'
import BoxSpinners from './components/BoxSpinners/BoxSpinners'
import RefPage from './components/RefPage/RefPage'
import RegisterForm from './components/RegisterForm/RegisterForm'
import backgroundVideo from './video/BGAnimation_2.mp4';
import ProfilLevelPage from './components/ProfileLevelPage/ProfilLevelPage';

import RegisteredListBoost from './components/RegisteredList/RegisteredListBoost';
import RegisteredListOffer from './components/RegisteredList/RegisteredListOffer';
import WinnersList from './components/WinnersList/WinnersList';
import WinnersListBoostUp from './components/WinnersList/WinnersListBoostUp';
import WinnersListOffer from './components/WinnersList/WinnersListOffer';
import SpnToken from './components/SpnToken/SpnToken';

import Preloader from './components/Preloader/Preloader'
import BoostUp from './components/Chance/BoostUp/BoostUp';


import soundFile from '../src/components/audio/Switch Plastic.mp3';
//import soundFon from '../src/components/winterAudio/w1.mp3';
//import soundFon1 from '../src/components/winterAudio/w2.mp3';
//import soundFon2 from '../src/components/winterAudio/w3.mp3';

import ModalBorn from './components/Modal/ModalBorn';
import ModalAttention from './components/Modal/ModalAttention';
import ModalSpinAll from './components/Modal/ModalSpinAll';

import Leaderbord from './components/RefPage/Leaderbord';
import Jackpot from './components/Jackpot/Jackpot';
import Taco from './components/Taco/Taco';
import AllRefPage from './components/RefPage/AllRefPage';
import PartnersPage from './components/Partners/PartnersPage';
import ProvideLiquidity from './components/Partners/ProvideLiquidity';
import BurnSalePage from './components/Burnsale/BurnSalePage';

// push 2
const chooseSpinParam = (tmpl) => {
  switch (tmpl) {
    case process.env.REACT_APP_SPINNEY_PROMO: case 0: // promo
      return {
        rarity: 6,
        cons: 0.2,
        countdown: 3600 * 3,
        speed_up: 40,
        mult: 1.1,
        template: {
          template_id: process.env.REACT_APP_SPINNEY_PROMO
        }
      }

      break;

    case process.env.REACT_APP_SPINNEY_PROMO2: case 6: // promo
      return {
        rarity: 6,
        cons: 0.2,
        countdown: 3600 * 3,
        speed_up: 40,
        mult: 1.1,
        template: {
          template_id: process.env.REACT_APP_SPINNEY_PROMO2
        }
      }

      break;
    case process.env.REACT_APP_SPINNEY_COMMON: case 1: // common
      return {
        rarity: 1,
        cons: 1,
        countdown: 3600 * 3,
        speed_up: 40,
        mult: 1.1,
        template: {
          template_id: process.env.REACT_APP_SPINNEY_COMMON
        }
      }

      break;
    case process.env.REACT_APP_SPINNEY_UNCOMMON: case 2: // Uncomon
      return {
        rarity: 2,
        cons: 2,
        countdown: 3600 * 3,
        speed_up: 40,
        mult: 1.1,
        template: {
          template_id: process.env.REACT_APP_SPINNEY_UNCOMMON
        }
      }
      break;
    case process.env.REACT_APP_SPINNEY_RARE: case 3: // Rare
      return {
        rarity: 3,
        cons: 8,
        countdown: 3600 * 6,
        speed_up: 20,
        mult: 1.1,
        template: {
          template_id: process.env.REACT_APP_SPINNEY_RARE
        }
      }
      break;
    case process.env.REACT_APP_SPINNEY_EPIC: case 4: // Epic
      return {
        rarity: 4,
        cons: 16,
        countdown: 3600 * 6,
        speed_up: 20,
        mult: 1.1,
        template: {
          template_id: process.env.REACT_APP_SPINNEY_EPIC
        }
      }
      break;
    case process.env.REACT_APP_SPINNEY_LEGEND: case 5: // Legend
      return {
        rarity: 5,
        cons: 64,
        countdown: 3600 * 12,
        speed_up: 10,
        mult: 1.1,
        template: {
          template_id: process.env.REACT_APP_SPINNEY_LEGEND
        }
      }
      break;

    default:

      break;
  }
}

const chooseRunasParam = (tmpl) => {

  switch (tmpl) {
    case process.env.REACT_APP_RUNES_PROMO:
      case 0:
      return {
        rarity: 0,
        template: {
          template_id: process.env.REACT_APP_RUNES_PROMO
        }
      }
      break;
    case process.env.REACT_APP_RUNES_PROMO2:
      case 6:
      return{
        rarity: 6,
        template: {
          template_id: process.env.REACT_APP_RUNES_PROMO2
        }
      }
      break;
    case process.env.REACT_APP_RUNES_COMMON: // common
    case 1:
    return{
      rarity: 1,
      template: {
        template_id: process.env.REACT_APP_RUNES_COMMON
      }
    }
      break;
    case process.env.REACT_APP_RUNES_UNCOMMON: // Uncomon
    case 2:
    return{
      rarity: 2,
      template: {
        template_id: process.env.REACT_APP_RUNES_UNCOMMON
      }
    }
      break;
    case process.env.REACT_APP_RUNES_RARE: // Rare
    case 3:
    return{
      rarity: 3,
      template: {
        template_id: process.env.REACT_APP_RUNES_RARE
      }
    }
      break;
    case process.env.REACT_APP_RUNES_EPIC: // Epic
    case 4:
    return{
      rarity: 4,
      template: {
        template_id: process.env.REACT_APP_RUNES_EPIC
      }
    }
      break;
    case process.env.REACT_APP_RUNES_LEGEND: // Legend
    case 5:
    return{
      rarity: 5,
      template: {
        template_id: process.env.REACT_APP_RUNES_LEGEND
      }
    }
      break;
    default:
      break;
  }
 

}

const pointNoRunas = (rar) => {
  let points = 0;

  switch (rar) {
    case 0: case 6:
      points = 1;
      break;
    case 1:
      points = 5;
      break;
    case 2:
      points = 10;
      break;
    case 3:
      points = 20;
      break;
    case 4:
      points = 40;
      break;
    case 5:
      points = 80;
      break;

    default:
      points = 5;
      break;
  }
  return points;
}

const pointWithRunas = (rar) => {
  let points = 0;

  switch (rar) {
    case 0: case 6:
      points = 3;
      break;
    case 1:
      points = 15;
      break;
    case 2:
      points = 30;
      break;
    case 3:
      points = 60;
      break;
    case 4:
      points = 120;
      break;
    case 5:
      points = 240;
      break;

    default:
      points = 15;
      break;
  }
  return points;
}

const pointWhenAddRunas = (rar) => {
  let points = 0;

  switch (rar) {
    case 0: case 6:
      points = 2;
      break;
    case 1:
      points = 10;
      break;
    case 2:
      points = 20;
      break;
    case 3:
      points = 40;
      break;
    case 4:
      points = 80;
      break;
    case 5:
      points = 160;
      break;

    default:
      points = 10;
      break;
  }
  return points;
}

const App = () => {

  // 
  // const canvasRef = useRef(null);
  // const ctxRef = useRef(null);
  // const animationFrameRef = useRef(null);

  // useEffect(() => {
  //   const canvas = canvasRef.current;
  //   const ctx = canvas.getContext('2d');
  //   ctxRef.current = ctx;

  //   let width, height, lastNow;
  //   let snowflakes;
  //   let maxSnowflakes = 200;

  //   function init() {
  //     snowflakes = [];
  //     resize();
  //     render((lastNow = performance.now()));
  //   }

  //   function render(now) {
  //     animationFrameRef.current = requestAnimationFrame(render);

  //     const elapsed = now - lastNow;
  //     lastNow = now;

  //     ctx.clearRect(0, 0, width, height);
  //     if (snowflakes.length < maxSnowflakes)
  //       snowflakes.push(new Snowflake());

  //     ctx.fillStyle = ctx.strokeStyle = '#fff';

  //     snowflakes.forEach((snowflake) => snowflake.update(elapsed, now));
  //   }

  //   function pause() {
  //     cancelAnimationFrame(animationFrameRef.current);
  //   }
  //   function resume() {
  //     lastNow = performance.now();
  //     requestAnimationFrame(render);
  //   }

  //   class Snowflake {
  //     constructor() {
  //       this.spawn();
  //     }

  //     spawn(anyY = false) {
  //       this.x = rand(0, width);
  //       this.y = anyY === true ? rand(-50, height + 50) : rand(-50, -10);
  //       this.xVel = rand(-0.05, 0.05);
  //       this.yVel = rand(0.02, 0.1);
  //       this.angle = rand(0, Math.PI * 2);
  //       this.angleVel = rand(-0.001, 0.001);
  //       this.size = rand(7, 12);
  //       this.sizeOsc = rand(0.01, 0.5);
  //     }

  //     update(elapsed, now) {
  //       const xForce = rand(-0.001, 0.001);

  //       if (Math.abs(this.xVel + xForce) < 0.075) {
  //         this.xVel += xForce;
  //       }

  //       this.x += this.xVel * elapsed;
  //       this.y += this.yVel * elapsed;
  //       this.angle += this.angleVel * elapsed;

  //       if (
  //         this.y - this.size > height ||
  //         this.x + this.size < 0 ||
  //         this.x - this.size > width
  //       ) {
  //         this.spawn();
  //       }

  //       this.render();
  //     }

  //     render() {
  //       const { x, y, size } = this;
  //       ctxRef.current.save();
  //       ctxRef.current.beginPath();
  //       ctxRef.current.arc(x, y, size * 0.2, 0, Math.PI * 2, false);
  //       ctxRef.current.fill();
  //       ctxRef.current.restore();
  //     }
  //   }
  //   // Utils
  //   const rand = (min, max) => min + Math.random() * (max - min);

  //   function resize() {
  //     width = canvas.width = window.innerWidth;
  //     height = canvas.height = window.innerHeight;
  //     if (window.innerWidth < 768) {
  //       maxSnowflakes = 70;
  //     } else {
  //       maxSnowflakes = 200;
  //     }
  //   }

  //   window.addEventListener('resize', resize);
  //   window.addEventListener('focus', resume);
  //   init();

  //   return () => {
  //     cancelAnimationFrame(animationFrameRef.current);
  //     window.removeEventListener('resize', resize);
  //     window.removeEventListener('blur', pause);
  //     window.removeEventListener('focus', resume);
  //   };
  // }, []);

  const [typeuser, setIsWombatUser] = useState("");

  useEffect(() => {
    if (window.__wombat__) {
      //console.log("wombat")
      setIsWombatUser("wombat");
    } else {
      //console.log("other")
      setIsWombatUser("other");
      //setIsWombatUser("wombat");
    }
  }, []);
  // 
  // audio btn

  const audio = new Audio(soundFile);

  const handleClickAudioBtn = () => {
    audio.volume = isMuted ? 0 : 0.3;
    audio.play();
  };

  const [isMuted, setIsMuted] = useState(() => {
    const storedIsMuted = localStorage.getItem('isMuted');
    return storedIsMuted ? JSON.parse(storedIsMuted) : false;

  });

  const handleToggleMute = () => {
    setIsMuted(prevIsMuted => !prevIsMuted);
    // console.log("isMuted updated:", isMuted);
  };

  useEffect(() => {
    localStorage.setItem('isMuted', isMuted.toString());
  }, [isMuted]);
  // audio btn

  // audio2 

  //const playlist = [soundFon, soundFon1, soundFon2];

  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  //const [music, setMusic] = useState(new Audio(playlist[currentTrackIndex]));
  const [wasClick, setWasClick] = useState(false);
  const [isMuted2, setIsMuted2] = useState(() => {
    const storedIsMuted2 = localStorage.getItem('isMuted2');
    return storedIsMuted2 ? JSON.parse(storedIsMuted2) : false;
  });

  // const playRandomTrack = () => {
  //   const randomIndex = Math.floor(Math.random() * playlist.length);
  //   setCurrentTrackIndex(randomIndex);
  //   setMusic(new Audio(playlist[randomIndex]));
  //   setWasClick(true);
  // };

  //const handleClickAudioBtn2 = () => {
  //music.volume = isMuted2 ? 0 : 0.02;
  //music.play();
  //setWasClick(true);
  //music.addEventListener('ended', playRandomTrack);
  //};

  const handleToggleMute2 = () => {
    setIsMuted2((prevMuteState) => {
      const newMuteState = !prevMuteState;
      localStorage.setItem('isMuted2', JSON.stringify(newMuteState));
      return newMuteState;
    });
  };

  // const handleFirstInteraction = () => {
  //   handleClickAudioBtn2();
  //   window.removeEventListener('click', handleFirstInteraction);
  // };

  // useEffect(() => {
  //   window.addEventListener('click', handleFirstInteraction);

  //   return () => {
  //     window.removeEventListener('click', handleFirstInteraction);
  //     music.removeEventListener('ended', playRandomTrack);
  //   };
  // }, [music, isMuted2]);

  // 


  // video 

  const [showCanvas, setShowCanvas] = useState(() => {
    const storedIsCanvas = localStorage.getItem('showCanvas');
    return storedIsCanvas ? JSON.parse(storedIsCanvas) : true;

  });



  const openModal17 = () => {
    setModal17Active(true);
  };

  const closeModal17 = () => {
    setModal17Active(false);
  };


  const handleToggleDisplay = () => {
    setShowCanvas(!showCanvas);
    //  console.log("showCanvas updated:", showCanvas);
  };

  useEffect(() => {
    localStorage.setItem('showCanvas', showCanvas.toString());
  }, [showCanvas]);

  const [isMuted3, setIsMuted3] = useState(() => {
    const storedIsMuted3 = localStorage.getItem('isMuted3');
    return storedIsMuted3 ? JSON.parse(storedIsMuted3) : true;

  });

  const handleToggleMute3 = () => {
    setIsMuted3(prevIsMuted3 => !prevIsMuted3);

    //  console.log("isMuted3 updated:", isMuted3);
  };

  useEffect(() => {
    localStorage.setItem('isMuted3', isMuted3.toString());
  }, [isMuted3]);



  // video 


  const [activeItem, setActiveItem] = useState(() => {
    const value = localStorage.getItem('activeItem');
    return value !== null ? value : 'one';
  });

  const [menuPosition, setMenuPosition] = useState(() => {
    const value = localStorage.getItem('menuPosition');
    return value !== null ? parseInt(value, 10) : -1;
  });

  const updateActiveItem = (newActiveItem, newPosition) => {
    //setActiveItem(newActiveItem);
    //setMenuPosition(newPosition);
  };

  const [activePositionMenu, setActivePositionMenu] = useState(0);

  const getActiveMenuItem = (to, position) => {
    setActivePositionMenu({ position: position, to: to });
    handleClickAudioBtn();
  }
  // navfooter-----------

  const notify = (e) => toast(e);

  // const [modal3Active, setModal3Active] = useState(false);
  const [activeSpinner, setActiveSpinner] = useState(null);
  const [nowScore, setNowScore] = useState(null);


  const openModalMarket = (type, spinner, score) => {
    if (type === "open") {
      setNowScore(score);
      setModal3Active(true);
      setActiveSpinner(spinner);
    }
  };
  const openModalMarketRuna = (type, spinner) => {
    if (type === "open") {
      setModal13Active(true);
      setActiveSpinner(spinner);
    }
  };

  const ual = useContext(UALContext);
  const [accountName, setAccountName] = useState(null);
  const [accountBalanceState, setAccountBalanceState] = useState(null);
  const [userInGameState, setUserInGame] = useState(null);
  const [allSpinner, setAllSpinner] = useState(null);
  const [allSpinnerInGame, setAllSpinnerInGame] = useState([]);
  const [allResInGameLocal, setAllResInGameLocal] = useState(null);
  const [allRunesInGame, setAllRunesInGame] = useState([]);
  const [allSpecRunesInGame, setAllSpecRunesInGame] = useState([]);
  const [choosesAssetOnWallet, setChoosesAssetOnWallet] = useState([]);
  const [choosesAssetOnGame, setChoosesAssetOnGame] = useState([]);
  const [allNftSpinerGame, setAllNftSpinerGame] = useState(null);
  const [allNftSpinerGameFiltered, setAllNftSpinerGameFiltered] = useState(null);
  const [allNftRunesGame, setAllNftRunesGame] = useState(null);
  const [allLeftPoints, setAllLeftPoints] = useState(0);
  const [reallSpendCredit, setReallSpendCredit] = useState(0);
  //const [allSingleWorkDays, setAllSingleWorkDays] = useState(0);
  const [allRunesWorkMoney, setAllRunesWorkMoney] = useState(0);
  const [allRunesWorkDays, setAllRunesWorkDays] = useState(0);
  const [allSpinneyWorkPoints, setAllSpinneyWorkPoints] = useState(0);
  const [manyLeftDays, setManyLeftDays] = useState(0);
  const [allTopLocal, setAllTopLocal] = useState([]);

  // 

  useEffect(() => {
    if (allSpinneyWorkPoints == 0) {
      setManyLeftDays(0);
    } else {
      setManyLeftDays(Math.floor(allLeftPoints / (allSpinneyWorkPoints / 10)));
    }
  }, [allLeftPoints, allSpinneyWorkPoints]);
  // 
  const logout = () => {
    ual.logout();
    setAccountName(null);
  }

  useEffect(() => {
    if (ual?.activeUser?.accountName !== null && ual?.activeUser?.accountName !== accountName && ual?.activeUser?.accountName !== undefined) {
      setAccountName(ual.activeUser.accountName);
    }
  }, [ual]);

  const [refreshKey1, setRefreshKey1] = useState(0);
  const [refreshKey2, setRefreshKey2] = useState(0);
  const [refreshKey3, setRefreshKey3] = useState(0);
  const { allSpinnerGet } = useGetAllNftSpinnerOnWallet(accountName, refreshKey1);
  const { allSpinnerInGameGet } = useGetAllSpinnerInGame(accountName, refreshKey2);
  const { allResInGame } = useGetAllResInGame(accountName);
  const { allRunesInGameGet } = useGetAllRunesInGame(accountName);
  const { allSpecRunesInGameGet } = useGetAllSpecRunesInGame(accountName);
  let { userInGame } = useGetUserInfoInGame(accountName, refreshKey3);
  const { allTop } = useGetFirstTop(userInGameState?.user);
  const [userInfoTop, setUserInfoTop] = useState({});

  useEffect(() => {
    if (allTop && allTop.length && !allTopLocal.length) {
      setAllTopLocal(allTop);
      let userInfo = allTop.filter((user) => user.user == userInGameState?.user);
      //console.log("userInfo", userInfo)
      setUserInfoTop(...userInfo);
    }

  }, [allTop]);


  const refreshAssets = (type) => {
    if (type === "gamespin") {
      setRefreshKey2(prevKey => prevKey + 1);
    } else if (type === "walletspin") {
      setRefreshKey1(prevKey => prevKey + 1);
    } else if (type === "userinfo") {
      setRefreshKey3(prevKey => prevKey + 1);
    }
  };

  useEffect(() => {
    if (userInGame && userInGame.user !== undefined && userInGame.user == accountName && userInGameState === null) {
      setUserInGame(userInGame);

    }
  }, [userInGame]);

  const hasUpdated = useRef(false);

  useEffect(() => {
    //console.log("allSpinnerInGameGet", allSpinnerInGameGet)
    //console.log("userInGameState", userInGameState)
    if (allSpinnerInGameGet && allSpinnerInGameGet.length && !hasUpdated.current && userInGameState !== null) {
      const objectsCopy = allSpinnerInGameGet.map(obj => {
        const newObj = { ...obj };
        newObj.lastact = userInGameState?.lastact;
        return newObj;
      });

      setAllSpinnerInGame(objectsCopy);
      hasUpdated.current = true;
    }
  }, [allSpinnerInGameGet, userInGameState?.lastact]);


  useEffect(() => {
    if (allResInGame) {
      setAllResInGameLocal(allResInGame);
      //console.log("test3");
    }
  }, [allResInGame]);

  useEffect(() => {
    if (allRunesInGameGet && allRunesInGameGet.length) {
      setAllRunesInGame(allRunesInGameGet);
      //console.log("test4");
    }
  }, [allRunesInGameGet]);

  useEffect(() => {
    if (allSpecRunesInGameGet && allSpecRunesInGameGet.length) {
      setAllSpecRunesInGame(allSpecRunesInGameGet);
      //console.log("test4Spec");
    }
  }, [allSpecRunesInGameGet]);

  useEffect(() => {
    if (allSpinnerGet) {
      setAllSpinner(allSpinnerGet);
      //console.log("test5"
    }
  }, [allSpinnerGet]);

  // useEffect(() => {

  //   if (allSpinnerInGame && allSpinnerInGame.length && userInGameState && userInGameState.user == accountName && allSpecRunesInGame) {

  //     console.log("test6");
  //     let currentTime = Math.floor(Date.now() / 1000);

  //     let all_single_work_days = 0;
  //     let all_runes_work_days = 0;

  //     let all_single_work_money = 0;
  //     let all_runes_work_money = 0;

  //     let workers_single = [];
  //     let workers_runas = [];
  //     let all_workers = [];

  //     let activeUseADay = 0;
  //     let globalLastActive = userInGameState.lastact;


  //     allSpinnerInGame.filter((item) => item.vip !== 0).forEach((item) => {

  //       let work_days = 0;
  //       if (item.vip && item.runes == 0 && item.lastact != 0) {
  //         let day_lastact = Math.floor(item.lastacttime / 86400);
  //         let day_currentTime = Math.floor(currentTime / 86400);

  //         for (; day_lastact < day_currentTime; day_lastact++) {
  //           work_days++;
  //           all_single_work_days++;
  //           all_single_work_money += pointNoRunas(item.rarity);
  //         }

  //         workers_single.push({ asset_id: item.asset_id, days_worked: work_days, last_time: item.lastacttime, runes: 0, rarity: item.rarity });
  //         all_workers.push({ asset_id: item.asset_id, days_worked: work_days, last_time: item.lastacttime, runes: 0, rarity: item.rarity });

  //         activeUseADay += pointNoRunas(item.rarity);
  //       } else if (item.vip && item.runes != 0) {
  //         let day_lastact = Math.floor(item.lastact / 86400);  // Целочисленное деление
  //         let day_currentTime = Math.floor(currentTime / 86400);  // Целочисленное деление

  //         if (item.runes == 111111111111111) {
  //           let specIndex = allSpecRunesInGame.findIndex(spinner => spinner.spinner_id == item.asset_id);

  //           //let specrun_itr = allSpecRunesInGame.find(spinner => spinner.spinner_id == item.asset_id);

  //           console.log("allSpecRunesInGame[specIndex]", allSpecRunesInGame[specIndex])

  //           if (specIndex !== -1) {
  //             if (allSpecRunesInGame[specIndex].endtime > currentTime) { // если срок больше чем курент тайм
  //               for (; day_lastact < day_currentTime; day_lastact++) {
  //                 work_days++;
  //                 all_runes_work_days++;
  //                 all_runes_work_money += pointWithRunas(item.rarity);
  //               }
  //               activeUseADay += pointWithRunas(item.rarity);
  //             }
  //             else {
  //               let day_lastact_spec = Math.floor(allSpecRunesInGame[specIndex].endtime / 86400);
  //               for (; day_lastact < day_currentTime; day_lastact++) {
  //                 if (day_lastact < day_lastact_spec) {
  //                   work_days++;
  //                   all_runes_work_days++;
  //                   all_runes_work_money += pointWithRunas(item.rarity);
  //                 }
  //                 else {
  //                   work_days++;
  //                   all_single_work_days++;
  //                   all_single_work_money += pointNoRunas(item.rarity);

  //                   workers_single.push({ asset_id: item.asset_id, days_worked: work_days, last_time: item.lastacttime, runes: 0, rarity: item.rarity });
  //                 }

  //               }
  //               activeUseADay += pointNoRunas(item.rarity);
  //               // console.log("i am here");
  //               // console.log("day_lastact < day_lastact_spec", day_lastact < day_lastact_spec);
  //             }


  //           }
  //         } else {
  //           for (; day_lastact < day_currentTime; day_lastact++) {
  //             work_days++;
  //             all_runes_work_days++;
  //             all_runes_work_money += pointWithRunas(item.rarity);
  //           }
  //           activeUseADay += pointWithRunas(item.rarity);
  //         }

  //         workers_runas.push({ asset_id: item.asset_id, days_worked: work_days, last_time: item.lastact, runes: item.runes, rarity: item.rarity });
  //         all_workers.push({ asset_id: item.asset_id, days_worked: work_days, last_time: item.lastact, runes: item.runes, rarity: item.rarity });


  //       }

  //     });

  //     function compareRarity(a, b) {
  //       return b.rarity - a.rarity;
  //     }

  //     let type;
  //     if (workers_runas.length > 0 && workers_single.length > 0) {
  //       workers_single.sort(compareRarity);
  //       workers_runas.sort(compareRarity);

  //       if (workers_single[0].last_time < workers_runas[0].last_time) {
  //         type = "single";
  //         //console.log("1-1");
  //       } else if (workers_single[0].last_time > workers_runas[0].last_time) {
  //         type = "withRunes";
  //         //console.log("1-2");
  //       } else {
  //         type = "nooactive";
  //         //console.log("1-2-2");
  //       }
  //     } else if (workers_runas.length > 0 && workers_single.length == 0) {
  //       workers_single.sort(compareRarity);
  //       type = "single";
  //       //console.log("1-3");
  //     } else if (workers_runas.length == 0 && workers_single.length > 0) {
  //       workers_runas.sort(compareRarity);
  //       type = "withRunes";

  //       //console.log("1-4");
  //     } else {
  //       workers_single.sort(compareRarity);
  //       workers_runas.sort(compareRarity);
  //       type = "nooactive";
  //       //console.log("1-5");
  //     }

  //     let maxDaysWork = all_single_work_days > all_runes_work_days ? all_single_work_days : all_runes_work_days;

  //     let vipcreditForLoop = userInGameState?.vipcredit;
  //     let singleForCurrentDay = 0;
  //     let runesForCurrentDay = 0;
  //     let singleForCurrentMoney = 0;
  //     let runesForCurrentMoney = 0;
  //     let allWorkersNum = all_workers.length;

  //     console.log("all_workers", all_workers)
  //     let totalPayForCurrentDay = 0;
  //     // for (let day = 1; day <= maxDaysWork && vipcreditForLoop > 0; ++day) {
  //     //   console.log("for 1");
  //     //   let totalPayForCurrentDay = 0;

  //     //   console.log("day", day)
  //     //   console.log("maxDaysWork", maxDaysWork)

  //     // Подсчет общей стоимости оплаты всех работников за день
  //     for (let i = 0; i < allWorkersNum; ++i) {

  //       if (all_workers[i].runes) {
  //         if (all_workers[i].runes == 111111111111111) {
  //           var specrun_itr = allSpecRunesInGame.find(spinner => spinner.spinner_id == all_workers[i].asset_id);
  //           if (specrun_itr) {
  //             let day_currentTime_for_loop = Math.floor(currentTime / 86400);
  //             let day_lastact = Math.floor(globalLastActive / 86400);
  //             if (specrun_itr.endtime > currentTime) {
  //               totalPayForCurrentDay += pointWithRunas(all_workers[i].rarity);
  //               console.log("worker number ", i, " - ", all_workers[i].asset_id, " totalPayForCurrentDay 10 pointWithRunas", pointWithRunas(all_workers[i].rarity));
  //             } else {
  //               let day_lastact_spec = Math.floor(specrun_itr.endtime / 86400);
  //               for (; day_lastact < day_currentTime_for_loop; day_lastact++) {
  //                 if (day_lastact < day_lastact_spec) {
  //                   totalPayForCurrentDay += pointWithRunas(all_workers[i].rarity);
  //                   console.log("worker number ", i, " - ", all_workers[i].asset_id, " totalPayForCurrentDay 20 pointWithRunas", pointWithRunas(all_workers[i].rarity));
  //                 } else {
  //                   totalPayForCurrentDay += pointNoRunas(all_workers[i].rarity)
  //                   console.log("worker number ", i, " - ", all_workers[i].asset_id, " totalPayForCurrentDay 30  pointNoRunas ", pointNoRunas(all_workers[i].rarity));
  //                 }
  //               }
  //             }
  //             console.log("totalPayForCurrentDay", totalPayForCurrentDay)
  //           }
  //         } else {
  //           totalPayForCurrentDay += pointWithRunas(all_workers[i].rarity)
  //           console.log("worker number ", i, " - ", all_workers[i].asset_id, " totalPayForCurrentDay 40 pointWithRunas", pointWithRunas(all_workers[i].rarity));
  //         }

  //       } else {
  //         totalPayForCurrentDay += pointNoRunas(all_workers[i].rarity)
  //         console.log("worker number ", i, " - ", all_workers[i].asset_id, " totalPayForCurrentDay 50 pointNoRunas", pointWithRunas(all_workers[i].rarity));
  //       }
  //       //}
  //     }

  //     console.log("totalPayForCurrentDay", totalPayForCurrentDay);

  //     if (vipcreditForLoop >= totalPayForCurrentDay) {
  //       // Если денег хватает, то узнаем, кто из работников работал и сколько он стоит
  //       for (let i = 0; i < allWorkersNum; ++i) {
  //         //if (all_workers[i].days_worked >= (maxDaysWork - i + 1)) {

  //         console.log("all_workers", all_workers);
  //         console.log("allSpecRunesInGame", allSpecRunesInGame);

  //         if (all_workers[i].runes) {
  //           // if (all_workers[i].runes == 111111111111111) {
  //           //   var specrun_itr = allSpecRunesInGame.find(spinner => spinner.spinner_id == all_workers[i].asset_id);
  //           //   if (specrun_itr && specrun_itr.endtime > currentTime) {
  //           //     runesForCurrentMoney += pointWithRunas(all_workers[i].rarity);
  //           //   } else {
  //           //     singleForCurrentMoney += pointNoRunas(all_workers[i].rarity);
  //           //   }
  //           // } 
  //           if (all_workers[i].runes == 111111111111111) {
  //             var specrun_itr = allSpecRunesInGame.find(spinner => spinner.spinner_id == all_workers[i].asset_id);
  //             if (specrun_itr) {
  //               let day_currentTime_for_loop = Math.floor(currentTime / 86400);
  //               let day_lastact = Math.floor(globalLastActive / 86400);
  //               if (specrun_itr.endtime > currentTime) {
  //                 runesForCurrentMoney += pointWithRunas(all_workers[i].rarity);
  //                 console.log("worker number ", i, " - ", all_workers[i].asset_id, " runesForCurrentMoney 1 pointWithRunas", pointWithRunas(all_workers[i].rarity));
  //               } else {
  //                 /////////
  //                 let day_lastact_spec = Math.floor(specrun_itr.endtime / 86400);
  //                 for (; day_lastact < day_currentTime_for_loop; day_lastact++) {
  //                   if (day_lastact < day_lastact_spec) {
  //                     runesForCurrentMoney += pointWithRunas(all_workers[i].rarity);
  //                     console.log("worker number ", i, " - ", all_workers[i].asset_id, " runesForCurrentMoney 2 pointWithRunas", pointWithRunas(all_workers[i].rarity));
  //                   } else {
  //                     singleForCurrentMoney += pointNoRunas(all_workers[i].rarity)
  //                     console.log("worker number ", i, " - ", all_workers[i].asset_id, " singleForCurrentMoney 3 pointNoRunas", pointNoRunas(all_workers[i].rarity));
  //                   }
  //                 }
  //               }

  //               console.log("runesForCurrentMoney", runesForCurrentMoney)
  //               console.log("singleForCurrentMoney", singleForCurrentMoney)

  //             }
  //           }
  //           else {
  //             runesForCurrentMoney += pointWithRunas(all_workers[i].rarity);
  //             console.log("worker number ", i, " - ", all_workers[i].asset_id, " runesForCurrentMoney 4 pointWithRunas", pointWithRunas(all_workers[i].rarity));

  //           }
  //           runesForCurrentDay++;
  //         }

  //             /*if (all_workers[i].runes) {
  //               runesForCurrentDay++;
  //               runesForCurrentMoney += pointWithRunas(all_workers[i].rarity);
  //             }*/ else {
  //           singleForCurrentDay++;
  //           singleForCurrentMoney += pointNoRunas(all_workers[i].rarity);
  //           console.log("worker number ", i, " - ", all_workers[i].asset_id, " singleForCurrentMoney 5 pointNoRunas", pointNoRunas(all_workers[i].rarity));
  //         }
  //         //}
  //       }
  //       vipcreditForLoop -= totalPayForCurrentDay;
  //     }
  //     //   else {
  //     //     // Если денег не хватает на оплату всех за данный день, то пропускаем этот день
  //     //     continue;
  //     //   }
  //     // }


  //     console.log("all_runes_work_days", all_runes_work_days);

  //     console.log("vipcreditForLoop", vipcreditForLoop)
  //     console.log("singleForCurrentMoney", singleForCurrentMoney); // 240
  //     console.log("runesForCurrentMoney", runesForCurrentMoney); // 855

  //     console.log("-----------------");

  //     console.log("all_single_work_money", all_single_work_money); // 0
  //     console.log("all_runes_work_money", all_runes_work_money); // 1575

  //     console.log("vipcreditForLoop", vipcreditForLoop)
  //     console.log("singleForCurrentDay", singleForCurrentDay);
  //     console.log("runesForCurrentDay", runesForCurrentDay);

  //     console.log("reallSpendCredit", (all_single_work_money + all_runes_work_money) - (singleForCurrentMoney + runesForCurrentMoney));

  //     if ((all_single_work_money + all_runes_work_money) > vipcreditForLoop) {
  //       setReallSpendCredit((all_single_work_money + all_runes_work_money) - (singleForCurrentMoney + runesForCurrentMoney));
  //     }

  //     let day_lastact = userInGameState?.lastact / 86400; // Находим день последнего действия с рунами
  //     let day_currentTime = currentTime / 86400; // Находим текущий день
  //     let seconds_passed_today_head = userInGameState?.lastact % 86400; // Количество секунд, прошедших с начала дня до lastact
  //     let seconds_until_midnight = 86400 - seconds_passed_today_head; // Количество секунд от lastact до полуночи


  //     setAllRunesWorkDays(runesForCurrentDay);
  //     setAllRunesWorkMoney(runesForCurrentMoney);
  //     setAllSpinneyWorkPoints(activeUseADay);

  //     let restFinal = userInGameState?.vipcredit - (singleForCurrentMoney + runesForCurrentMoney);
  //     if (restFinal <= 0) {
  //       setAllLeftPoints(0);
  //     } else {
  //       setAllLeftPoints(restFinal / 10);
  //     }


  //   }

  // }, [allSpinnerInGame, userInGameState, allSpecRunesInGame]);

  useEffect(() => {

    if (allSpinnerInGame && allSpinnerInGame.length && userInGameState && userInGameState.user == accountName && allSpecRunesInGame) {

      //console.log("test6");
      let currentTime = Math.floor(Date.now() / 1000);

      let all_single_work_days = 0;
      let all_runes_work_days = 0;

      let all_single_work_money = 0;
      let all_runes_work_money = 0;

      let workers_single = [];
      let workers_runas = [];
      let all_workers = [];

      let activeUseADay = 0;


      allSpinnerInGame.filter((item) => item.vip !== 0).forEach((item) => {

        let work_days = 0;
        if (item.vip && item.runes == 0 && item.lastact != 0) {
          let day_lastact = Math.floor(item.lastacttime / 86400);
          let day_currentTime = Math.floor(currentTime / 86400);

          for (; day_lastact < day_currentTime; day_lastact++) {
            work_days++;
            all_single_work_days++;
            all_single_work_money += pointNoRunas(item.rarity);
          }

          workers_single.push({ asset_id: item.asset_id, days_worked: work_days, last_time: item.lastacttime, runes: 0, rarity: item.rarity });
          all_workers.push({ asset_id: item.asset_id, days_worked: work_days, last_time: item.lastacttime, runes: 0, rarity: item.rarity });

          activeUseADay += pointNoRunas(item.rarity);
        } else if (item.vip && item.runes != 0) {
          let day_lastact = Math.floor(item.lastact / 86400);  // Целочисленное деление
          let day_currentTime = Math.floor(currentTime / 86400);  // Целочисленное деление

          if (item.runes == 111111111111111) {
            let specIndex = allSpecRunesInGame.findIndex(spinner => spinner.spinner_id == item.asset_id);

            if (specIndex !== -1) {
              if (allSpecRunesInGame[specIndex].endtime > currentTime) { // если срок больше чем курент тайм
                for (; day_lastact < day_currentTime; day_lastact++) {
                  work_days++;
                  all_runes_work_days++;
                  all_runes_work_money += pointWithRunas(item.rarity);
                }
                activeUseADay += pointWithRunas(item.rarity);
              }
              else {
                let day_lastact_spec = Math.floor(allSpecRunesInGame[specIndex].endtime / 86400);
                for (; day_lastact < day_currentTime; day_lastact++) {
                  if (day_lastact < day_lastact_spec) {
                    work_days++;
                    all_runes_work_days++;
                    all_runes_work_money += pointWithRunas(item.rarity);
                  }
                  else {
                    work_days++;
                    all_single_work_days++;
                    all_single_work_money += pointNoRunas(item.rarity);

                    workers_single.push({ asset_id: item.asset_id, days_worked: work_days, last_time: item.lastacttime, runes: 0, rarity: item.rarity });
                  }

                }
                activeUseADay += pointNoRunas(item.rarity);
              }

            }
          } else {
            for (; day_lastact < day_currentTime; day_lastact++) {
              work_days++;
              all_runes_work_days++;
              all_runes_work_money += pointWithRunas(item.rarity);
            }
            activeUseADay += pointWithRunas(item.rarity);
          }

          workers_runas.push({ asset_id: item.asset_id, days_worked: work_days, last_time: item.lastact, runes: item.runes, rarity: item.rarity });
          all_workers.push({ asset_id: item.asset_id, days_worked: work_days, last_time: item.lastact, runes: item.runes, rarity: item.rarity });


        }

      });

      function compareRarity(a, b) {
        return b.rarity - a.rarity;
      }

      let type;
      if (workers_runas.length > 0 && workers_single.length > 0) {
        workers_single.sort(compareRarity);
        workers_runas.sort(compareRarity);

        if (workers_single[0].last_time < workers_runas[0].last_time) {
          type = "single";
          //console.log("1-1");
        } else if (workers_single[0].last_time > workers_runas[0].last_time) {
          type = "withRunes";
          //console.log("1-2");
        } else {
          type = "nooactive";
          //console.log("1-2-2");
        }
      } else if (workers_runas.length > 0 && workers_single.length == 0) {
        workers_single.sort(compareRarity);
        type = "single";
        //console.log("1-3");
      } else if (workers_runas.length == 0 && workers_single.length > 0) {
        workers_runas.sort(compareRarity);
        type = "withRunes";

        //console.log("1-4");
      } else {
        workers_single.sort(compareRarity);
        workers_runas.sort(compareRarity);
        type = "nooactive";
        //console.log("1-5");
      }

      let maxDaysWork = all_single_work_days > all_runes_work_days ? all_single_work_days : all_runes_work_days;

      let vipcreditForLoop = userInGameState?.vipcredit;
      let singleForCurrentDay = 0;
      let runesForCurrentDay = 0;
      let singleForCurrentMoney = 0;
      let runesForCurrentMoney = 0;
      let allWorkersNum = all_workers.length;
      let day_currentTime_for_loop = Math.floor(currentTime / 86400);

      for (let day = 1; day <= maxDaysWork && vipcreditForLoop > 0; ++day) {
        let totalPayForCurrentDay = 0;

        // Подсчет общей стоимости оплаты всех работников за день
        for (let i = 0; i < allWorkersNum; ++i) {
          if (all_workers[i].days_worked >= (maxDaysWork - day + 1)) {
            let specNumber = maxDaysWork - day + 1;
            if (all_workers[i].runes) {
              if (all_workers[i].runes == 111111111111111) {
                var specrun_itr = allSpecRunesInGame.find(spinner => spinner.spinner_id == all_workers[i].asset_id);
                if (specrun_itr) {
                  let day_lastact_spec = Math.floor(specrun_itr.endtime / 86400);

                  if (day_lastact_spec > day_currentTime_for_loop - specNumber) {
                    totalPayForCurrentDay += pointWithRunas(all_workers[i].rarity)
                  } else {
                    totalPayForCurrentDay += pointNoRunas(all_workers[i].rarity);
                  }
                }
              } else {
                totalPayForCurrentDay += pointWithRunas(all_workers[i].rarity)
              }
            } else {
              totalPayForCurrentDay += pointNoRunas(all_workers[i].rarity);
            }
          }
        }

        if (vipcreditForLoop >= totalPayForCurrentDay) {
          for (let i = 0; i < allWorkersNum; ++i) {
            if (all_workers[i].days_worked >= (maxDaysWork - day + 1)) {
              let specNumber = maxDaysWork - day + 1;
              if (all_workers[i].runes) {
                if (all_workers[i].runes == 111111111111111) {
                  var specrun_itr = allSpecRunesInGame.find(spinner => spinner.spinner_id == all_workers[i].asset_id);
                  if (specrun_itr) {
                    let day_lastact_spec = Math.floor(specrun_itr.endtime / 86400);
                    if (day_lastact_spec > day_currentTime_for_loop - specNumber) {
                      runesForCurrentDay++;
                      runesForCurrentMoney += pointWithRunas(all_workers[i].rarity);
                    } else {
                      singleForCurrentDay++;
                      singleForCurrentMoney += pointNoRunas(all_workers[i].rarity);
                    }
                  }
                } else {
                  runesForCurrentDay++;
                  runesForCurrentMoney += pointWithRunas(all_workers[i].rarity);
                }
              } else {
                singleForCurrentDay++;
                singleForCurrentMoney += pointNoRunas(all_workers[i].rarity);
              }
            }
          }
          vipcreditForLoop -= totalPayForCurrentDay;
        } else {
          // Если денег не хватает на оплату всех за данный день, то пропускаем этот день
          continue;
        }
      }


      // console.log("all_runes_work_days", all_runes_work_days);
      // console.log("vipcreditForLoop", vipcreditForLoop)

      // console.log("singleForCurrentMoney", singleForCurrentMoney);
      // console.log("runesForCurrentMoney", runesForCurrentMoney);

      // console.log("-----------------");

      // console.log("all_single_work_money", all_single_work_money);
      // console.log("all_runes_work_money", all_runes_work_money);

      // console.log("vipcreditForLoop", vipcreditForLoop)
      // console.log("singleForCurrentDay", singleForCurrentDay);
      // console.log("runesForCurrentDay", runesForCurrentDay);

      if ((all_single_work_money + all_runes_work_money) > vipcreditForLoop) {
        setReallSpendCredit((all_single_work_money + all_runes_work_money) - (singleForCurrentMoney + runesForCurrentMoney));
      }

      let day_lastact = userInGameState?.lastact / 86400; // Находим день последнего действия с рунами
      let day_currentTime = currentTime / 86400; // Находим текущий день
      let seconds_passed_today_head = userInGameState?.lastact % 86400; // Количество секунд, прошедших с начала дня до lastact
      let seconds_until_midnight = 86400 - seconds_passed_today_head; // Количество секунд от lastact до полуночи


      setAllRunesWorkDays(runesForCurrentDay);
      setAllRunesWorkMoney(runesForCurrentMoney);
      setAllSpinneyWorkPoints(activeUseADay);

      let restFinal = userInGameState?.vipcredit - (singleForCurrentMoney + runesForCurrentMoney);
      if (restFinal <= 0) {
        setAllLeftPoints(0);
      } else {
        setAllLeftPoints(restFinal / 10);
      }


    }

  }, [allSpinnerInGame, userInGameState, allSpecRunesInGame]);

  const balance = UserBalance(accountName);

  useEffect(() => {
    setAccountBalanceState(balance);
    //console.log("test7");
  }, [balance]);

  const balanceDisp = (type, quant) => {
    //console.log("parseFloat(quant) * 10000", parseFloat(quant) * 10000);
    if (type) {
      setUserInGame(prevState => ({
        ...prevState,
        balance: prevState.balance + parseFloat(quant) * 10000
      }));
    } else {
      setUserInGame(prevState => ({
        ...prevState,
        balance: prevState.balance - parseFloat(quant) * 10000
      }));
    }

  }

  const resDisp = (type, count) => {
    var updateRes = [...allResInGameLocal.res]
    //console.log("updateRes", updateRes);
    if (type) {
      updateRes[0] += parseInt(count);
      setAllResInGameLocal(prevState => ({
        ...prevState,
        res: updateRes
      }));
    } else {
      updateRes[0] -= parseInt(count);
      setAllResInGameLocal(prevState => ({
        ...prevState,
        res: updateRes
      }));
    }


  }

  const reguser = async (referral) => {

    //console.log("reguser", reguser);
    try {
      const { activeUser } = ual;
      const { accountName } = activeUser;

      const transactionPayload = registration(accountName, referral);

      const transaction = await activeUser.signTransaction(transactionPayload, {
        blocksBehind: 3,
        expireSeconds: 30,
      });

      console.log(transaction);
      setUserInGame({
        activespin: 0,
        all_slot: [],
        balance: 0,
        lastact: 0,
        mult_bonus: "1.00000000000000000",
        numberref: 0,
        open_slots: 0,
        user: accountName,
        vipcredit: 0
      });

      notify("Transaction ID: " + transaction?.transactionId);

      if (transaction && typeuser === "wombat") {
        wombattrans(accountName, "quest1", transaction?.transactionId, typeuser);
      }
    } catch (e) {
      toast(e.toString(), {
        progressClassName: 'error-progress',
      });
      console.log(e);
      // if (e instanceof RpcError)
      //   console.log(JSON.stringify(e.json, null, 2));
    }
  }

  const transferNftToTheGame = async () => {
    try {
      const { activeUser } = ual;
      const { accountName } = activeUser;

      const transactionPayload = transferToTheGame(accountName, choosesAssetOnWallet, "");

      console.log("transactionPayload", transactionPayload);

      if (activeUser) {
        const transaction = await activeUser.signTransaction(transactionPayload, {
          blocksBehind: 3,
          expireSeconds: 30,
        });

        let remainingObjectsSpinners = [];
        let remainingObjectsRunas = [];

        const filteredAndTransformedObjects = allNftSpinerGameFiltered.reduce((acc, subject) => {
          if (choosesAssetOnWallet.includes(subject.asset_id)) {
            // Преобразовать объект
            if (subject.schema.schema_name === process.env.REACT_APP_SCHEMA_SPINER) {
              let nftInfo = chooseSpinParam(subject.template.template_id);
              const transformed = {
                ...nftInfo,
                asset_id: subject.asset_id,
                in_slot: 0, // и т. д.
                mult: "1.100",
                runes: 0,
                speed: 1,
                spins: 0,
                storage: 0,
                timer: 0,
                user: accountName,
                vip: false,
                //rarity: 
              };
              remainingObjectsSpinners.push(transformed);
            } else if (subject.schema.schema_name === process.env.REACT_APP_SCHEMA_RUNAS) {
              let nftInfo = chooseRunasParam(subject.template.template_id);
              console.log("nftInfo", nftInfo)
              const transformed = {
                ...nftInfo,
                asset_id: subject.asset_id,
                in_spiner: 0,
                spinner_id: 0,
                starttimer: 0,
                user: accountName,

              };
              remainingObjectsRunas.push(transformed);
            }

          } else {
            acc.push(subject);
          }
          return acc;
        }, []);

        setAllSpinnerInGame(prevAllSpinnerInGame => [...prevAllSpinnerInGame, ...remainingObjectsSpinners]);
        setAllRunesInGame(prevAllRunasInGame => [...prevAllRunasInGame, ...remainingObjectsRunas]);
        setAllNftSpinerGameFiltered(filteredAndTransformedObjects);

      }
    } catch (e) {
      toast(e.toString(), {
        progressClassName: 'error-progress',
      });
      console.log(e);
    }
  }

  const transferProToTheGame = async (choosesAssetOnWallet, creditNums) => {
    try {
      const { activeUser } = ual;
      const { accountName } = activeUser;

      const transactionPayload = transferToTheGame(accountName, choosesAssetOnWallet, "");

      console.log("transactionPayload", transactionPayload);

      if (activeUser) {
        const transaction = await activeUser.signTransaction(transactionPayload, {
          blocksBehind: 3,
          expireSeconds: 30,
        });

        const items = { ...allSpinner };
        const updatedItems = items[process.env.REACT_APP_SCHEMA_PRO].filter(item => item.asset_id !== choosesAssetOnWallet[0]);
        items[process.env.REACT_APP_SCHEMA_PRO] = updatedItems;
        //console.log("items", items);
        setAllSpinner(items);

        let updatedUserSpiners = userInGameState.vipcredit;
        updatedUserSpiners += creditNums;
        setUserInGame({
          ...userInGameState,
          vipcredit: updatedUserSpiners
        });
        setModal8Active(false);

      }
    } catch (e) {
      toast(e.toString(), {
        progressClassName: 'error-progress',
      });
      console.log(e);
    }
  }

  const dispatchRemSpinneyFromWallet = (ids) => {

    let combined = [];

    // if (allSpinner && allSpinner.consumables !== undefined) {
    //   const copyCons = [...allSpinner.consumables].filter((item) => item.template.template_id == process.env.REACT_APP_ELIT_PASS || item.template.template_id == process.env.REACT_APP_BASIC_PASS);
    //   combined = [...combined, ...copyCons];
    // }

    if (allSpinner && allSpinner.spinney !== undefined) {
      combined = [...combined, ...allSpinner.spinney];
    }

    const filteredAndTransformedObjects = combined.filter(subject => !ids.includes(subject.asset_id));

    //console.log("filteredAndTransformedObjects", filteredAndTransformedObjects);
    setAllSpinner(prevState => ({
      ...prevState,
      spinney: filteredAndTransformedObjects
    }));

  }

  const dispMultProfMult = (quant) => {
    setUserInGame(prevState => ({
      ...prevState,
      balance: prevState.balance - parseInt(quant) * 10000,
      many_spnmult: prevState.many_spnmult + parseInt(quant) * 10000,
    }));
  }

  const dispAddProPoint = (creditNums) => {
    let updatedUserSpiners = userInGameState.vipcredit;
    updatedUserSpiners += creditNums * 10;
    setUserInGame({
      ...userInGameState,
      vipcredit: updatedUserSpiners
    });
  }

  const withdrawnft = async () => {
    try {
      const { activeUser } = ual;

      const { accountName } = activeUser;

      const transactionPayload = withdrawNftFromGame(accountName, choosesAssetOnGame);

      console.log("transactionPayload", transactionPayload);

      const transaction = await activeUser.signTransaction(transactionPayload, {
        blocksBehind: 3,
        expireSeconds: 30,
      });

      //console.log(transaction);
      notify("Transaction ID: " + transaction?.transactionId);

      if (transaction?.transactionId) {
        let allNftInGame = [...allSpinnerInGame, ...allRunesInGame]
        let remainingObj = [];

        const filteredAndTransformedObjects = allNftInGame.reduce((acc, subject) => {
          if (choosesAssetOnGame.includes(subject.asset_id)) {
            // Преобразовать объект

            console.log("subject", subject)

            let nftInfo = subject.in_spiner !== undefined ? chooseRunasParam(parseInt(subject.rarity)) : chooseSpinParam(parseInt(subject.rarity));
            const transformed = {
              ...nftInfo,
              asset_id: subject.asset_id,
              data: {
                rarity: subject.rarity
              },
              schema: {
                schema_name: subject.in_spiner !== undefined ? process.env.REACT_APP_SCHEMA_RUNAS : process.env.REACT_APP_SCHEMA_SPINER,
              }
            };
            remainingObj.push(transformed);
          } else {
            acc.push(subject);
          }
          return acc;
        }, []);

        let upadeFilteredAndTransformedObjects = [...allNftSpinerGameFiltered];
        upadeFilteredAndTransformedObjects.unshift(...remainingObj);
        setAllNftSpinerGameFiltered(upadeFilteredAndTransformedObjects);

        // remove withdrawn nfts from allNftSpinerGame state
        setAllNftSpinerGame(prevState => {
          return prevState.filter(nft => !choosesAssetOnGame.includes(nft.asset_id));
        });
        setAllNftRunesGame(prevState => {
          return prevState.filter(nft => !choosesAssetOnGame.includes(nft.asset_id));
        });
      }

      console.log("asdadsasdada");
      setChoosesAssetOnGame([]);

    } catch (e) {
      toast(e.toString(), {
        progressClassName: 'error-progress',
      });
      console.log(e);
    }
  }

  const buySlotTrans = async (quant) => {
    try {
      const { activeUser } = ual;
      const { accountName } = activeUser;
      const many = quant / 8;
      const detalPrice = parseFloat(quant / 100000000).toFixed(8) + " WAX"

      const transactionPayload = buySlot(accountName, detalPrice);

      const transaction = await activeUser.signTransaction(transactionPayload, {
        blocksBehind: 3,
        expireSeconds: 30,
      });

      console.log(transaction);

      notify("Transaction ID: " + transaction?.transactionId);

      if (transaction && typeuser === "wombat") {
        if (parseInt(userInGameState.open_slots) === 0) {
          wombattrans(accountName, "quest2", transaction?.transactionId, typeuser);
        } else {
          wombattrans(accountName, "quest5", transaction?.transactionId, typeuser);
        }
      }

      setUserInGame(prevState => ({
        ...prevState,
        open_slots: prevState.open_slots + many
      }));

    } catch (e) {
      toast(e.toString(), {
        progressClassName: 'error-progress',
      });
      console.log(e);
      // if (e instanceof RpcError)
      //   console.log(JSON.stringify(e.json, null, 2));
    }
  }

  const tokenDepositeTrans = async (quant, memo, type) => {
    try {
      const { activeUser } = ual;
      const { accountName } = activeUser;
      const detalPrice = parseFloat(quant).toFixed(4) + " " + process.env.REACT_APP_VAL

      const transactionPayload = tokenDeposite(accountName, detalPrice, memo);

      console.log("quant", quant);
      console.log("memo", memo);
      console.log("type", type);
      //console.log("transactionPayload", transactionPayload);

      const transaction = await activeUser.signTransaction(transactionPayload, {
        blocksBehind: 3,
        expireSeconds: 30,
      });

      //console.log(transaction);
      if (type == "buy") {
        setAccountBalanceState(prevState => (
          (prevState - parseInt(quant)).toFixed(4)
        ));
      } else if (type == "deposit") {
        setAccountBalanceState(prevState => (
          (prevState - parseInt(quant)).toFixed(4)
        ));
        balanceDisp(true, quant);
      } else {
        balanceDisp(true, quant);
        // setUserInGame(prevState => ({
        //   ...prevState,
        //   balance: prevState.balance + parseInt(quant) * 10000
        // }));
      }


      notify("Transaction ID: " + transaction?.transactionId);

      if (transaction && typeuser === "wombat") {
        wombattrans(accountName, "quest4", transaction?.transactionId, typeuser);
      }

    } catch (e) {
      toast(e.toString(), {
        progressClassName: 'error-progress',
      });
      console.log(e);
      // if (e instanceof RpcError)
      //   console.log(JSON.stringify(e.json, null, 2));
    }
  }

  const craftTrans = async (quant, type) => {
    try {
      const { activeUser } = ual;
      const { accountName } = activeUser;
      const detalPrice = parseFloat(quant).toFixed(4) + " " + process.env.REACT_APP_VAL

      const transactionPayload = craft(accountName, detalPrice, type);

      //console.log("quant", quant);
      //console.log("transactionPayload", transactionPayload);

      const transaction = await activeUser.signTransaction(transactionPayload, {
        blocksBehind: 3,
        expireSeconds: 30,
      });

      console.log(transaction);
      balanceDisp(false, quant);
      // setUserInGame(prevState => ({
      //   ...prevState,
      //   balance: prevState.balance - parseInt(quant) * 10000
      // }));
      notify("Transaction ID: " + transaction?.transactionId);
    } catch (e) {
      toast(e.toString(), {
        progressClassName: 'error-progress',
      });
      console.log(e);
      // if (e instanceof RpcError)
      //   console.log(JSON.stringify(e.json, null, 2));
    }
  }

  const buyMultTrans = async (quant) => {
    try {
      const { activeUser } = ual;
      const { accountName } = activeUser;
      const detalPrice = parseFloat(quant).toFixed(4) + " " + process.env.REACT_APP_VAL

      const transactionPayload = buyMult(accountName, detalPrice);

      //console.log("quant", quant);
      console.log("transactionPayload", transactionPayload);

      const transaction = await activeUser.signTransaction(transactionPayload, {
        blocksBehind: 3,
        expireSeconds: 30,
      });

      //console.log(transaction);
      setUserInGame(prevState => ({
        ...prevState,
        balance: prevState.balance - parseInt(quant) * 10000,
        many_spnmult: prevState.many_spnmult + parseInt(quant) * 10000,
      }));
      notify("Transaction ID: " + transaction?.transactionId);
    } catch (e) {
      toast(e.toString(), {
        progressClassName: 'error-progress',
      });
      console.log(e);
      // if (e instanceof RpcError)
      //   console.log(JSON.stringify(e.json, null, 2));
    }
  }

  const tokenWithdrawTrans = async (quant, fee, waxprice) => {
    try {
      const { activeUser } = ual;
      const { accountName } = activeUser;
      const detalPrice = parseFloat(quant).toFixed(4) + " " + process.env.REACT_APP_VAL

      const transactionPayload = tokenWithdraw(accountName, detalPrice, fee, waxprice);
      //const transactionPayload = tokenWithdraw(accountName, detalPrice, fee);

      console.log("transactionPayload", transactionPayload);

      console.log("quant", quant);
      const transaction = await activeUser.signTransaction(transactionPayload, {
        blocksBehind: 3,
        expireSeconds: 30,
      });

      //console.log(transaction);
      setAccountBalanceState(prevState => (
        (parseFloat(prevState) + parseFloat(quant * ((100 - fee) / 100))).toFixed(4)
      ));
      balanceDisp(false, quant);
      // setUserInGame(prevState => ({
      //   ...prevState,
      //   balance: prevState.balance - quant * 10000
      // }));

      notify("Transaction ID: " + transaction?.transactionId);
    } catch (e) {
      toast(e.toString(), {
        progressClassName: 'error-progress',
      });
      console.log(e);
      // if (e instanceof RpcError)
      //   console.log(JSON.stringify(e.json, null, 2));
    }
  }

  const addToSlotTrans = async (asset_ids) => {
    try {
      const { activeUser } = ual;
      const { accountName } = activeUser;

      const transactionPayload = addToSlot(accountName, asset_ids);

      const transaction = await activeUser.signTransaction(transactionPayload, {
        blocksBehind: 3,
        expireSeconds: 30,
      });

      //console.log(transaction);

      let updatedAllSinnersInGameLocal = [...allSpinnerInGame];
      let updatedUserSpiners = [...userInGameState.all_slot];
      for (let index = 0; index < asset_ids.length; index++) {
        //const element = activeIds[index];
        let spinnerIndex = allSpinnerInGame.findIndex(spinner => spinner.asset_id == asset_ids[index]);
        if (spinnerIndex !== -1) {
          const updatedSpinner = {
            ...allSpinnerInGame[spinnerIndex],
            in_slot: 1
          };
          updatedAllSinnersInGameLocal[spinnerIndex] = updatedSpinner;
        }
        updatedUserSpiners.push(asset_ids[index]);
      }

      setAllSpinnerInGame(updatedAllSinnersInGameLocal);
      setUserInGame({
        ...userInGameState,
        all_slot: updatedUserSpiners
      });
      notify("Transaction ID: " + transaction?.transactionId);

      if (transaction && typeuser === "wombat") {
        wombattrans(accountName, "quest3", transaction?.transactionId, typeuser);
      }
    } catch (e) {
      toast(e.toString(), {
        progressClassName: 'error-progress',
      });
      console.log(e);
      // if (e instanceof RpcError)
      //   console.log(JSON.stringify(e.json, null, 2));
    }
  }

  const addRunaTrans = async (runa_id) => {
    try {
      const { activeUser } = ual;
      const { accountName } = activeUser;

      const transactionPayload = addRuna(accountName, activeSpinnerForRuna.asset_id, runa_id);

      const transaction = await activeUser.signTransaction(transactionPayload, {
        blocksBehind: 3,
        expireSeconds: 30,
      });

      //console.log(transaction);

      let spinnerIndex = allSpinnerInGame.findIndex(spinner => spinner.asset_id == activeSpinnerForRuna.asset_id);
      if (spinnerIndex !== -1) {
        const updatedSpinner = {
          ...allSpinnerInGame[spinnerIndex],
          runes: runa_id,
          lastact: Math.floor(Date.now() / 1000)
        };
        const updatedAllSinnersInGameLocal = [...allSpinnerInGame];
        updatedAllSinnersInGameLocal[spinnerIndex] = updatedSpinner;
        setAllSpinnerInGame(updatedAllSinnersInGameLocal);

        if (allSpinnerInGame[spinnerIndex].vip) {

          let manyPoints = 0;

          manyPoints = pointWhenAddRunas(allSpinnerInGame[spinnerIndex].rarity);

          let updatedUserSpiners = userInGameState.vipcredit;
          updatedUserSpiners -= manyPoints;
          //console.log("userInGameState.vipcredit", userInGameState.vipcredit)
          //console.log("updatedUserSpiners", updatedUserSpiners)
          setUserInGame({
            ...userInGameState,
            vipcredit: updatedUserSpiners
          });
        }
      }

      let runesIndex = allRunesInGame.findIndex(runes => runes.asset_id == runa_id);
      if (runesIndex !== -1) {
        const updateRunes = {
          ...allRunesInGame[runesIndex],
          in_spiner: 1,
          spinner_id: activeSpinnerForRuna.asset_id
        };
        const updatedAllRunesInGameLocal = [...allRunesInGame];
        updatedAllRunesInGameLocal[runesIndex] = updateRunes;
        setAllRunesInGame(updatedAllRunesInGameLocal);
      }

      setModal1Active(false);
      notify("Transaction ID: " + transaction?.transactionId);


    } catch (e) {
      // notify(e.toStrin"g());
      // toast(`Transaction ID ${<a > Hi</a>}`, {
      //   progressClassName: 'error-progress',
      // });
      toast(e.toString(), {
        progressClassName: 'error-progress',
      });

      console.log(e);
      // if (e instanceof RpcError)
      //   console.log(JSON.stringify(e.json, null, 2));
    }
  }

  const addSpecRunaTrans = async (runa_id) => {
    try {
      const { activeUser } = ual;
      const { accountName } = activeUser;

      const memo = "burning specrunes " + activeSpinnerForRuna.asset_id + " "

      const transactionPayload = addSpecRuna(accountName, runa_id, memo);

      const transaction = await activeUser.signTransaction(transactionPayload, {
        blocksBehind: 3,
        expireSeconds: 30,
      });


      //console.log(transaction);
      let spinnerInSlotRar = 0;
      let spinnerBurnRar = allSpinner.spinney.find(spinner => spinner.asset_id == runa_id);
      let spinnerIndex = allSpinnerInGame.findIndex(spinner => spinner.asset_id == activeSpinnerForRuna.asset_id);

      let runesIndex = allSpecRunesInGame.findIndex(runes => runes.spinner_id == activeSpinnerForRuna.asset_id);

      if (spinnerIndex !== -1) {
        spinnerInSlotRar = allSpinnerInGame[spinnerIndex].rarity;
        const updatedSpinner = {
          ...allSpinnerInGame[spinnerIndex],
          runes: 111111111111111,
          lastact: Math.floor(Date.now() / 1000)
        };
        const updatedAllSinnersInGameLocal = [...allSpinnerInGame];
        updatedAllSinnersInGameLocal[spinnerIndex] = updatedSpinner;
        setAllSpinnerInGame(updatedAllSinnersInGameLocal);

        if (allSpinnerInGame[spinnerIndex].vip) {

          let manyPoints = 0;

          if (runesIndex !== -1 && allSpecRunesInGame[runesIndex].endtime > Math.floor(Date.now() / 1000)) {

          } else {
            manyPoints = pointWhenAddRunas(allSpinnerInGame[spinnerIndex].rarity);
          }

          let updatedUserSpiners = userInGameState.vipcredit;
          updatedUserSpiners -= manyPoints;
          //console.log("userInGameState.vipcredit", userInGameState.vipcredit)
          //console.log("updatedUserSpiners", updatedUserSpiners)
          setUserInGame({
            ...userInGameState,
            vipcredit: updatedUserSpiners
          });
        }
      }


      let timeToAdd = specRunTimeDisp(spinnerInSlotRar, spinnerBurnRar.data.rarity);

      if (runesIndex !== -1) {
        const updateRunes = {
          ...allSpecRunesInGame[runesIndex],
          endtime: allSpecRunesInGame[runesIndex].endtime > Math.floor(Date.now() / 1000) ? allSpecRunesInGame[runesIndex].endtime + timeToAdd : Math.floor(Date.now() / 1000) + timeToAdd,
          spinner_id: activeSpinnerForRuna.asset_id
        };
        const updatedAllRunesInGameLocal = [...allSpecRunesInGame];
        updatedAllRunesInGameLocal[runesIndex] = updateRunes;
        setAllSpecRunesInGame(updatedAllRunesInGameLocal);
      } else {
        let updatedAllRunesInGameLocal = [...allSpecRunesInGame];
        updatedAllRunesInGameLocal.push({
          endtime: Math.floor(Date.now() / 1000) + timeToAdd,
          spinner_id: activeSpinnerForRuna.asset_id
        });
        setAllSpecRunesInGame(updatedAllRunesInGameLocal);
      }



      const copyFilterNft = JSON.parse(JSON.stringify(allSpinner));

      let uodateWalletFiltNft = copyFilterNft.spinney.filter(spinney => spinney.asset_id !== runa_id);
      copyFilterNft.spinney = uodateWalletFiltNft;
      setAllSpinner(copyFilterNft);
      setModal1Active(false);

      notify("Transaction ID: " + transaction?.transactionId);


    } catch (e) {
      // notify(e.toStrin"g());
      // toast(`Transaction ID ${<a > Hi</a>}`, {
      //   progressClassName: 'error-progress',
      // });
      toast(e.toString(), {
        progressClassName: 'error-progress',
      });

      console.log(e);
      // if (e instanceof RpcError)
      //   console.log(JSON.stringify(e.json, null, 2));
    }
  }

  const remRunaTrans = async () => {
    try {
      const { activeUser } = ual;
      const { accountName } = activeUser;

      console.log("remRunaTrans");
      console.log("scoreClame", scoreClame);
      console.log("activeSpinnerForRuna", activeSpinnerForRuna.asset_id);

      const transactionPayload = remRuna(accountName, activeSpinnerForRuna.asset_id);

      console.log(transactionPayload);

      const transaction = await activeUser.signTransaction(transactionPayload, {
        blocksBehind: 3,
        expireSeconds: 30,
      });

      let spinnerIndex = allSpinnerInGame.findIndex(spinner => spinner.asset_id == activeSpinnerForRuna.asset_id);
      if (spinnerIndex !== -1) {

        if (allSpinnerInGame[spinnerIndex].runes == 111111111111111) {
          let updatedAllRunesInGameLocal = allSpecRunesInGame.filter(runes => runes.spinner_id != activeSpinnerForRuna.asset_id);
          setAllSpecRunesInGame(updatedAllRunesInGameLocal);
        }

        const updatedSpinner = {
          ...allSpinnerInGame[spinnerIndex],
          runes: 0,
          //storage: scoreClame,
          storage: scoreClame * 10000,
          lastact: Math.floor(Date.now() / 1000),
          speed: activeSpinnerForRuna.speed,
          spins: activeSpinnerForRuna.spins,
        };
        const updatedAllSinnersInGameLocal = [...allSpinnerInGame];
        updatedAllSinnersInGameLocal[spinnerIndex] = updatedSpinner;
        setAllSpinnerInGame(updatedAllSinnersInGameLocal);

      }

      let runesIndex = allRunesInGame.findIndex(runes => runes.spinner_id == activeSpinnerForRuna.asset_id);
      if (runesIndex !== -1) {
        const updateRunes = {
          ...allRunesInGame[runesIndex],
          in_spiner: 0,
          spinner_id: 0
        };
        const updatedAllRunesInGameLocal = [...allRunesInGame];
        updatedAllRunesInGameLocal[runesIndex] = updateRunes;
        setAllRunesInGame(updatedAllRunesInGameLocal);
      }
      notify("Transaction ID: " + transaction?.transactionId);
    } catch (e) {
      toast(e.toString(), {
        progressClassName: 'error-progress',
      });
      console.log(e);
      // if (e instanceof RpcError)
      //   console.log(JSON.stringify(e.json, null, 2));
    }
  }

  const claimTrans = async (asset_id, storage) => {
    try {
      const { activeUser } = ual;
      const { accountName } = activeUser;

      const transactionPayload = claim(accountName, asset_id);

      const transaction = await activeUser.signTransaction(transactionPayload, {
        blocksBehind: 3,
        expireSeconds: 30,
      });

      //console.log(transaction);
      let spinnerIndex = allSpinnerInGame.findIndex(spinner => spinner.asset_id == asset_id);
      if (spinnerIndex !== -1) {
        console.log("allSpinnerInGame[spinnerIndex].storage", allSpinnerInGame[spinnerIndex].storage);
        console.log("storage", storage);
        console.log("allSpinnerInGame[spinnerIndex].storage - storage * 10000", allSpinnerInGame[spinnerIndex].storage - storage * 10000);
        const updatedSpinner = {
          ...allSpinnerInGame[spinnerIndex],
          storage: 0,
          lastact: Math.floor(Date.now() / 1000),
          speed: 1,
          spins: 0,
        };
        const updatedAllSinnersInGameLocal = [...allSpinnerInGame];
        updatedAllSinnersInGameLocal[spinnerIndex] = updatedSpinner;
        setAllSpinnerInGame(updatedAllSinnersInGameLocal);
        // тут для вип + руна чтобы дальше все крутило
        /*if (allSpinnerInGame[spinnerIndex].vip && allSpinnerInGame[spinnerIndex].runes !== 0) {
          let updatedUserSpiners = userInGameState.lastact;
          updatedUserSpiners = Math.floor(Date.now() / 1000);
          setUserInGame({
            ...userInGameState,
            lastact: updatedUserSpiners
          });
        }*/
      }
      balanceDisp(true, storage);

      notify("Transaction ID: " + transaction?.transactionId);
    } catch (e) {
      toast(e.toString(), {
        progressClassName: 'error-progress',
      });
      console.log(e);
      // if (e instanceof RpcError)
      //   console.log(JSON.stringify(e.json, null, 2));
    }
  }

  const remFromSlotTrans = async () => {
    try {
      const { activeUser } = ual;
      const { accountName } = activeUser;

      const transactionPayload = remFromSlot(accountName, remId);

      const transaction = await activeUser.signTransaction(transactionPayload, {
        blocksBehind: 3,
        expireSeconds: 30,
      });

      setModal6Active(false);

      //console.log(transaction);
      // setUserInGame(prevState => ({
      //   ...prevState,
      //   all_slot: prevState.all_slot.push(remId)
      // }));

      let spinnerIndex = allSpinnerInGame.findIndex(spinner => spinner.asset_id == remId);
      let spinnerIndexUser = userInGameState.all_slot.findIndex(spinner => spinner == remId);
      if (spinnerIndex !== -1) {
        const updatedSpinner = {
          ...allSpinnerInGame[spinnerIndex],
          in_slot: 0
        };
        const updatedAllSinnersInGameLocal = [...allSpinnerInGame];
        updatedAllSinnersInGameLocal[spinnerIndex] = updatedSpinner;
        setAllSpinnerInGame(updatedAllSinnersInGameLocal);
      }
      if (spinnerIndexUser !== -1) {
        const updatedUserSpiners = [...userInGameState.all_slot];

        //console.log("updatedUserSpiners", updatedUserSpiners);
        updatedUserSpiners.splice(spinnerIndexUser, 1);
        setUserInGame({
          ...userInGameState,
          all_slot: updatedUserSpiners
        });
      }



      notify("Transaction ID: " + transaction?.transactionId);

    } catch (e) {
      toast(e.toString(), {
        progressClassName: 'error-progress',
      });
      console.log(e);
      // if (e instanceof RpcError)
      //   console.log(JSON.stringify(e.json, null, 2));
    }
  }

  const actvipspinTrans = async (score, localActiveSpiner, e, spinSpeedBuffer, allSumSpinsBuffer) => {
    try {
      const { activeUser } = ual;
      const { accountName } = activeUser;

      const transactionPayload = actvipspin(accountName, localActiveSpiner.asset_id, e);

      const transaction = await activeUser.signTransaction(transactionPayload, {
        blocksBehind: 3,
        expireSeconds: 30,
      });

      //console.log(transaction);
      let spinnerIndex = allSpinnerInGame.findIndex(spinner => spinner.asset_id == localActiveSpiner.asset_id);
      if (spinnerIndex !== -1) {
        const updatedSpinner = {
          ...allSpinnerInGame[spinnerIndex],
          vip: e,
          storage: e ? allSpinnerInGame[spinnerIndex].storage : parseInt((score.toFixed(4)) * 10000),
          speed: spinSpeedBuffer,
          spins: allSumSpinsBuffer,
          lastact: e ? Math.floor(Date.now() / 1000) : allSpinnerInGame[spinnerIndex].lastact,
          lastacttime: e ? Math.floor(Date.now() / 1000) : allSpinnerInGame[spinnerIndex].lastacttime,

        };
        const updatedAllSinnersInGameLocal = [...allSpinnerInGame];
        updatedAllSinnersInGameLocal[spinnerIndex] = updatedSpinner;
        setAllSpinnerInGame(updatedAllSinnersInGameLocal);
      }

      if (e) {

        let manyPoints = 0;
        if (allSpinnerInGame[spinnerIndex].runes !== 0) {
          manyPoints = pointWithRunas(allSpinnerInGame[spinnerIndex].rarity);
        } else {
          manyPoints = pointNoRunas(allSpinnerInGame[spinnerIndex].rarity);
        }
        let updatedUserSpiners = userInGameState.vipcredit;
        console.log("updatedUserSpiners", updatedUserSpiners);
        updatedUserSpiners -= manyPoints;
        console.log("updatedUserSpiners2", updatedUserSpiners);

        console.log("manyPoints", manyPoints);
        //console.log("userInGameState.vipcredit", userInGameState.vipcredit)
        //console.log("updatedUserSpiners", updatedUserSpiners)
        setUserInGame({
          ...userInGameState,
          vipcredit: updatedUserSpiners,
          //lastact: allSpinnerInGame[spinnerIndex].runes !== 0 ? Math.floor(Date.now() / 1000) : userInGameState.lastact
        });
      }
      notify("Transaction ID: " + transaction?.transactionId);
    } catch (e) {
      toast(e.toString(), {
        progressClassName: 'error-progress',
      });

      console.log(e);
      // if (e instanceof RpcError)
      //   console.log(JSON.stringify(e.json, null, 2));
    }
  }

  const buyInStorageTrans = async (spinney, type, price, count) => {
    //console.log("buyInStorageTrans");
    try {
      const { activeUser } = ual;
      const { accountName } = activeUser;

      const transactionPayload = buyInStorage(accountName, spinney.asset_id, type, count);

      const transaction = await activeUser.signTransaction(transactionPayload, {
        blocksBehind: 3,
        expireSeconds: 30,
      });

      var countTicket = 0;

      // if (spinney.rarity == 0) {
      //   countTicket = 1;
      // } else if (spinney.rarity == 1) {
      //   countTicket = 2;
      // } else if (spinney.rarity == 2) {
      //   countTicket = 3;
      // } else if (spinney.rarity == 3) {
      //   countTicket = 4;
      // } else if (spinney.rarity == 4) {
      //   countTicket = 5;
      // } else if (spinney.rarity == 5) {
      //   countTicket = 6;
      // }

      //(prevState - parseInt(quant)).toFixed(4)
      //console.log(transaction);
      let spinnerIndex = allSpinnerInGame.findIndex(spinner => spinner.asset_id == spinney.asset_id);
      let activeStorage = activeSpinner.storage;
      if (spinnerIndex !== -1) {
        const updatedSpinner = {
          ...allSpinnerInGame[spinnerIndex],
          storage: activeStorage - parseInt((price * count * 10000).toFixed(4))
        };
        const updatedAllSinnersInGameLocal = [...allSpinnerInGame];
        updatedAllSinnersInGameLocal[spinnerIndex] = updatedSpinner;
        setAllSpinnerInGame(updatedAllSinnersInGameLocal);
      }

      if (type == "ticket") {
        resDisp(true, count);
      }

      setModal3Active(false);

      // if (e) {
      //   let updatedUserSpiners = userInGameState.vipcredit;
      //   updatedUserSpiners -= 1;
      //   setUserInGame({
      //     ...userInGameState,
      //     vipcredit: updatedUserSpiners,
      //     lastact: Math.floor(Date.now() / 1000)
      //   });
      // }
      notify("Transaction ID: " + transaction?.transactionId);
    } catch (e) {
      toast(e.toString(), {
        progressClassName: 'error-progress',
      });
      console.log(e);
      // if (e instanceof RpcError)
      //   console.log(JSON.stringify(e.json, null, 2));
    }
  }

  const confirmUnstake = (e, asset_id) => {
    setModal6Active(e);
    setRemId(asset_id);
  }

  const confirmClaim = (e, asset, scoreClame) => {
    handleModalActivation(e);
    setClaimAsset(asset);
    setScoreClame(scoreClame);
  }
  const [rightPartVisible, setRightPartVisible] = useState(false);
  const [leftPartVisible, setLeftPartVisible] = useState(false);

  const [activeBtnBurn, setActiveBtnBurn] = useState(null);

  function openRunaModal(type, e, asset, score) {
    console.log("score", score)
    if (type === "stake") {
      setModal1Active(e);
      setActiveSpinnerForRuna(asset);
      setRightPartVisible(true);
      setLeftPartVisible(true);

    } if (type === "stake1") {
      setActiveSpinnerForRuna(asset);
      setModal1Active(e);
      setRightPartVisible(true);
      setLeftPartVisible(false);
    }
    else if (type === "unstake") {
      setModal5Active(e);
      setActiveSpinnerForRuna(asset);
      setActiveBtnBurn(false)
      setScoreClame(score);
    }
    else if (type === "unstake2") {
      setModal5Active(e);
      setActiveSpinnerForRuna(asset);
      setActiveBtnBurn(true)
      setScoreClame(score);

    }
  }



  const [spinAllId, setSpinAllId] = useState([]);
  const [spinAllPoints, setSpinAllPoints] = useState([]);
  const spinAllOpenModal = () => {

    let copyAllSinnersInGameLocal = [...allSpinnerInGame];
    let allPoints = 0;
    const ids = copyAllSinnersInGameLocal.filter(item => item.timer < Math.floor(Date.now() / 1000) && item.runes == 0 && item.in_slot).map((item) => {
      allPoints += parseFloat(item.cons);
      return parseInt(item.asset_id)
    }
    );
    setSpinAllPoints(allPoints);
    setSpinAllId(ids);
    //console.log("ids", ids);

    handleModalActivation(17);
    //spinTrans(ids);
  }

  const spinAll = () => {
    spinTrans("all", spinAllId);
  }

  const spinTrans = async (type, asset_ids) => {
    try {

      //console.log("type", type);
      const { activeUser } = ual;
      const { accountName } = activeUser;

      const transactionPayload = spin(accountName, asset_ids);

      const transaction = await activeUser.signTransaction(transactionPayload, {
        blocksBehind: 3,
        expireSeconds: 30,
      });

      let money = 0;
      let updatedAllSinnersInGameLocal = [...allSpinnerInGame];
      //let updatedUserSpiners = [...userInGameState.all_slot];
      for (let index = 0; index < asset_ids.length; index++) {
        //const element = activeIds[index];
        let spinnerIndex = allSpinnerInGame.findIndex(spinner => spinner.asset_id == asset_ids[index]);
        if (spinnerIndex !== -1) {

          var newSpin = allSpinnerInGame[spinnerIndex].spins;
          var newSpeed = allSpinnerInGame[spinnerIndex].speed;
          var newSpeedUp = allSpinnerInGame[spinnerIndex].speed_up;

          if (allSpinnerInGame[spinnerIndex].spins == newSpeedUp - 1) {
            var x = allSpinnerInGame[spinnerIndex].speed;
            if (x < 5) {
              newSpeed += 1;
            }
            newSpin = 0;
          } else {
            newSpin += 1;
          }

          var totalRewardNew
          if (allSpinnerInGame[spinnerIndex].vip) {

            var mult_bonus = userInGameState.mult_bonus;
            var ref_bonus = userInGameState.ref_bonus;
            var sum_bonus = parseFloat(mult_bonus) + parseFloat(ref_bonus) - 2;

            if (allSpinnerInGame[spinnerIndex].speed == 1) {
              sum_bonus = 0;
            } else if (allSpinnerInGame[spinnerIndex].speed == 2) {
              sum_bonus = sum_bonus * 0.25;
            } else if (allSpinnerInGame[spinnerIndex].speed == 3) {
              sum_bonus = sum_bonus * 0.5;
            } else if (allSpinnerInGame[spinnerIndex].speed == 4) {
              sum_bonus = sum_bonus * 0.75;
            } else if (allSpinnerInGame[spinnerIndex].speed == 5) {
              sum_bonus = sum_bonus;
            }

            //console.log("sum_bonus", sum_bonus)
            totalRewardNew = parseFloat(allSpinnerInGame[spinnerIndex].cons) * (parseFloat(allSpinnerInGame[spinnerIndex].mult) + sum_bonus);
          } else {
            totalRewardNew = parseFloat(allSpinnerInGame[spinnerIndex].cons) * parseFloat(allSpinnerInGame[spinnerIndex].mult);
          }

          const updatedSpinner = {
            ...allSpinnerInGame[spinnerIndex],
            spins: newSpin,
            speed: newSpeed,
            storage: allSpinnerInGame[spinnerIndex].storage + parseInt(totalRewardNew * 10000),
            timer: Math.floor(Date.now() / 1000) + allSpinnerInGame[spinnerIndex].countdown,
            mult: mult[allSpinnerInGame[spinnerIndex].rarity][newSpeed],
          };
          ////////////////////////////////////////
          // const updatedSpinner = {
          //   ...allSpinnerInGame[spinnerIndex],
          //   in_slot: 1
          // };
          updatedAllSinnersInGameLocal[spinnerIndex] = updatedSpinner;
          money += parseFloat(allSpinnerInGame[spinnerIndex].cons)
        }
        //updatedUserSpiners.push(asset_ids[index]);
      }

      setAllSpinnerInGame(updatedAllSinnersInGameLocal);
      if (type == "all") {
        handleModalActivation(17);
      } else {
        setModal18Active(false);

        console.log("type123123", type);
      }

      // setUserInGame({
      //   ...userInGameState,
      //   all_slot: updatedUserSpiners
      // });



      //setLocalctiveSpiner(updatedSpinner);
      //setSpinSpeedBuffer(newSpeed);
      console.log("money", money);
      balanceDisp(false, money);
      setModal19Active(false);
      toast("Transaction ID: " + transaction?.transactionId);
    } catch (e) {
      toast(e.toString());
      console.log(e);
    }
  }

  // console.log("balance", balance);
  // console.log("accountName", accountName);

  const [remId, setRemId] = useState(0)
  const [claimAsset, setClaimAsset] = useState(0)
  const [scoreClame, setScoreClame] = useState(0)
  const [activeSpinnerForRuna, setActiveSpinnerForRuna] = useState(0)


  const [modal1Active, setModal1Active] = useState(false)
  const [modal2Active, setModal2Active] = useState(false)
  const [modal3Active, setModal3Active] = useState(false)
  const [modal4Active, setModal4Active] = useState(false)
  const [modal5Active, setModal5Active] = useState(false)
  const [modal6Active, setModal6Active] = useState(false)
  const [modal7Active, setModal7Active] = useState(false)
  const [modal8Active, setModal8Active] = useState(false)
  const [modal9Active, setModal9Active] = useState(false)
  const [modal10Active, setModal10Active] = useState(false)
  const [modal11Active, setModal11Active] = useState(false)
  const [modal12Active, setModal12Active] = useState(false)
  const [modal13Active, setModal13Active] = useState(false)
  const [modal15Active, setModal15Active] = useState(false)
  const [modal16Active, setModal16Active] = useState(false)
  const [modal17Active, setModal17Active] = useState(false)
  const [modal18Active, setModal18Active] = useState(false)
  const [modal19Active, setModal19Active] = useState(false)


  const handleModalActivation = (modalNumber) => {
    const modalStates = [
      setModal1Active,
      setModal2Active,
      setModal3Active,
      setModal4Active,
      setModal5Active,
      setModal6Active,
      setModal7Active,
      setModal8Active,
      setModal9Active,
      setModal10Active,
      setModal11Active,
      setModal12Active,
      setModal13Active,
      setModal15Active,
      setModal16Active,
      setModal17Active,
      setModal18Active,
      setModal19Active
    ]

    modalStates.forEach((setModalState, index) => {
      if (index === modalNumber) {
        setModalState(true)
      } else {
        setModalState(false)
      }
    })
  }

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [])



  return (
    <>

      <div className="video-background">
        <video autoPlay loop muted controls={false}>
          <source src={backgroundVideo} type="video/mp4" />
        </video>
      </div>

      {isMuted3 ? (
        <div className="video-background">
          <video autoPlay loop muted controls={false}>
            <source src={backgroundVideo} type="video/mp4" />
          </video>
        </div>

      ) : (
        <div className='video-background'>
          <div className="video-background-stop"></div>
        </div>

      )}

      {/* <div className='winterFonMain'></div> */}

      {loading ? <Preloader /> : ''}

      <BrowserRouter>
        <div className="app-wrapper">

          {accountName && accountName != undefined && accountName != null ?
            <Header
              activeItem={activeItem}
              menuPosition={menuPosition}
              updateActiveItem={updateActiveItem}
              setShowCanvas={showCanvas}
              handleModalActivation={handleModalActivation}
              balance={accountBalanceState}
              userInGameState={userInGameState}
              logout={logout}
              allLeftPoints={allLeftPoints}
              getActiveMenuItem={getActiveMenuItem}
              allResInGameLocal={allResInGameLocal}
              handleToggleMute={handleToggleMute}
              handleToggleMute2={handleToggleMute2}
              handleToggleMute3={handleToggleMute3}
              handleClickAudio={handleClickAudioBtn}
              handleToggleDisplay={handleToggleDisplay}
              isMuted={isMuted}
              isMuted2={isMuted2}
              isMuted3={isMuted3}
              allSpinneyWorkPoints={allSpinneyWorkPoints}
              manyLeftDays={manyLeftDays}
              accountName={accountName}
              reallSpendCredit={reallSpendCredit}
            />
            : null}


          <ToastContainer
            toastClassName='foo-bar'
            progressClassName='success-progress'
            // progressClassName='`${messageType}-progress`'
            zIndex={1000}
            autoClose={5000}
            pauseOnHover
          />
          {/* <canvas className='fonWinterH' ref={canvasRef} /> */}
          {/* {isMuted3 ? (
            <canvas className='fonWinterH' ref={canvasRef} />
            ) : (
            <div className='winterFonMainStop'> </div>
          )} */}

          <div className="wrapper">

            {/* {accountName && accountName != undefined && accountName != null ? */}
            <Routes>

              <Route path="bankpage/:id"
                element={userInGameState && <Bankpage
                  allSpinner={allSpinner}
                  allSpinnerInGame={allSpinnerInGame}
                  allRunesInGame={allRunesInGame}
                  balance={accountBalanceState}
                  userInGameState={userInGameState}
                  tokenDepositeTrans={tokenDepositeTrans}
                  setChoosesAssetOnWallet={setChoosesAssetOnWallet}
                  setChoosesAssetOnGame={setChoosesAssetOnGame}
                  choosesAssetOnGame={choosesAssetOnGame}
                  setAllNftSpinerGame={setAllNftSpinerGame}
                  handleModalActivation={handleModalActivation}
                  setAllNftSpinerGameFiltered={setAllNftSpinerGameFiltered}
                  setAllNftRunesGame={setAllNftRunesGame}
                  allNftRunesGame={allNftRunesGame}
                  allNftSpinerGameFiltered={allNftSpinerGameFiltered}
                  transferNftToTheGame={transferNftToTheGame}
                  withdrawnft={withdrawnft}
                  allNftSpinerGame={allNftSpinerGame}
                  tokenWithdrawTrans={tokenWithdrawTrans}
                  allResInGameLocal={allResInGameLocal}
                  resDisp={resDisp}
                  handleClickAudio={handleClickAudioBtn}
                  refreshAssets={refreshAssets}
                  dispatchRemSpinneyFromWallet={dispatchRemSpinneyFromWallet}
                />}

              />

              <Route path="leaderboard" element={
                <Leaderbord
                  allTopLocal={allTopLocal}
                  resDisp={resDisp}
                  userInGameState={userInGameState}
                  refreshAssets={refreshAssets}
                  userInfoTop={userInfoTop}
                  setUserInfoTop={setUserInfoTop}
                />} />
              <Route path="allleaderboard" element={
                <AllRefPage
                  // canvasRef={canvasRef}
                  allTopLocal={allTopLocal}
                  resDisp={resDisp}
                  userInGameState={userInGameState}
                  refreshAssets={refreshAssets}
                  userInfoTop={userInfoTop}
                  setUserInfoTop={setUserInfoTop}
                />} />

              <Route path="firstpage" element={<FirstPage
                activeItem={activeItem}
                menuPosition={menuPosition}
                updateActiveItem={updateActiveItem} />} />

              <Route path="spntoken" element={<SpnToken balanceDisp={balanceDisp} userInGameState={userInGameState} />} />
              <Route path="startpage" element={<StartPage />} />
              <Route path="refpage" element={<RefPage userInGameState={userInGameState} refreshAssets={refreshAssets} />} />

              <Route path="freespn" element={<Jackpot userInGameState={userInGameState} />} />

              <Route path="taco" element={<Taco userInGameState={userInGameState} />} />

              <Route path="freespn" element={<Jackpot userInGameState={userInGameState}
              //  canvasRef={canvasRef} 
              />} />
              <Route path="registeredlistboost/:id" element={<RegisteredListBoost userInGameState={userInGameState} />} />
              <Route path="registeredlistoffer/:id" element={<RegisteredListOffer userInGameState={userInGameState} />} />
              <Route path="winnerlist" element={<WinnersList userInGameState={userInGameState} />} />
              <Route path="winnerslistboostup" element={<WinnersListBoostUp userInGameState={userInGameState} />} />

              <Route path="winnerslistoffer" element={<WinnersListOffer userInGameState={userInGameState} />} />
              <Route path="partners" element={<PartnersPage userInGameState={userInGameState} />} />
              <Route path="liquidity" element={<ProvideLiquidity userInGameState={userInGameState} balanceDisp={balanceDisp} />} />
              {/* <Route path="burnsale" element={<BurnSalePage userInGameState={userInGameState} allSpinner={allSpinner} />} /> */}
              <Route
                path="modallistspinney"
                element={
                  <ModalListSpinney
                    handleModalActivation={handleModalActivation}
                    setModal1Active={setModal1Active}
                    setModal2Active={setModal2Active}
                    setModal3Active={setModal3Active}
                    setModal4Active={setModal4Active}
                    allSpinnerInGame={allSpinnerInGame}
                  />
                }
              />

              <Route path="assetspage" element={<Assetspage allSpinner={allSpinner} allSpinnerInGame={allSpinnerInGame} allRunesInGame={allRunesInGame} />} />

              <Route path="/profillevelpage" element={<ProfilLevelPage handleModalActivation={handleModalActivation} buyMultTrans={buyMultTrans} userInGameState={userInGameState} />} />
              <Route path="/craft" element={<CraftDropNav userInGameState={userInGameState} tokenDepositeTrans={tokenDepositeTrans} craftTrans={craftTrans} balanceDisp={balanceDisp} />} />

              <Route path="/admin/:id" element={<Admin />} />

              <Route path="games" element={
                <Chance
                  userInGameState={userInGameState}
                  balanceDisp={balanceDisp}
                  allSpinnerInGame={allSpinnerInGame}
                  allResInGameLocal={allResInGameLocal}
                  resDisp={resDisp}
                />}
              />

              <Route path="loginpage" element={<LoginPage />} />



              {accountName && accountName != undefined && accountName != null ?
                userInGameState && userInGameState.user == undefined || userInGameState == null ?
                  <Route path="" element={<RegisterForm accountName={accountName} reguser={reguser} />} />
                  : userInGameState && userInGameState.all_slot !== undefined && userInGameState.all_slot.length
                    || allSpinnerInGame.filter(item => item.in_slot).length
                    ?
                    <Route
                      path=""
                      element={
                        <BoxSpinners
                          handleModalActivation={handleModalActivation}
                          setModal1Active={setModal1Active}
                          setModal2Active={setModal2Active}
                          setModal3Active={setModal3Active}
                          setModal4Active={setModal4Active}
                          setModal5Active={setModal5Active}
                          setModal6Active={setModal6Active}
                          setModal8Active={setModal8Active}
                          setModal9Active={setModal9Active}
                          setModal13Active={setModal13Active}
                          setModal19Active={setModal19Active}
                          allSpinnerInGame={allSpinnerInGame}
                          userInGameState={userInGameState}
                          confirmUnstake={confirmUnstake}
                          confirmClaim={confirmClaim}
                          openRunaModal={openRunaModal}
                          openModalMarket={openModalMarket}
                          openModalMarketRuna={openModalMarketRuna}
                          actvipspinTrans={actvipspinTrans}
                          allLeftPoints={allLeftPoints}
                          activeSpinnerForRuna={activeSpinnerForRuna}
                          reallSpendCredit={reallSpendCredit}
                          handleClickAudio={handleClickAudioBtn}
                          balanceDisp={balanceDisp}
                          showCanvas={showCanvas}
                          allRunesWorkDays={allRunesWorkDays}
                          allRunesWorkMoney={allRunesWorkMoney}
                          spinAllOpenModal={spinAllOpenModal}
                          manyLeftDays={manyLeftDays}
                          allSpecRunesInGame={allSpecRunesInGame}
                        />
                      }
                    />
                    : <Route
                      path=""
                      element={
                        <Spinney
                          handleModalActivation={handleModalActivation}
                          setModal1Active={setModal1Active}
                          setModal2Active={setModal2Active}
                          setModal3Active={setModal3Active}
                          setModal4Active={setModal4Active}
                          setModal5Active={setModal5Active}
                          setModal6Active={setModal6Active}
                          setModal9Active={setModal9Active}
                          setModal13Active={setModal13Active}
                          //setModal14Active={setModal4Active}
                          setModal15Active={setModal5Active}
                          setModal16Active={setModal6Active}
                          setModal17Active={setModal7Active}
                        />
                      }

                    /> : <Route path="" element={<StartPage />} />
              }



            </Routes>
            
          </div>

          {accountName && accountName != undefined && accountName != null ?
            <Navfooter
              activePositionMenu={activePositionMenu}
              activeItem={activeItem}
              menuPosition={menuPosition}
              updateActiveItem={updateActiveItem}
              handleClickAudio={handleClickAudioBtn}
              userInfoTop={userInfoTop}
            />
            : null}
          {modal8Active ? (
            <ModalPremium
              active={modal8Active}
              setActive={setModal8Active}
              userInGameState={userInGameState}
              allLeftPoints={allLeftPoints}
              allSpinner={allSpinner}
              transferProToTheGame={transferProToTheGame}
              allSpinneyWorkPoints={allSpinneyWorkPoints}
              dispatchRemSpinneyFromWallet={dispatchRemSpinneyFromWallet}
              dispAddProPoint={dispAddProPoint}
            ></ModalPremium>
          ) : null}

          {modal10Active ? <UniversalSpin /> : null}
          {modal1Active ? (
            <ModalListRuna
              active={modal1Active}
              setActive={setModal1Active}
              addRunaTrans={addRunaTrans}
              addSpecRunaTrans={addSpecRunaTrans}
              allRunesInGame={allRunesInGame}
              activeSpinnerForRuna={activeSpinnerForRuna}
              allSpinner={allSpinner}
              rightPartVisible={rightPartVisible}
              leftPartVisible={leftPartVisible}
            ></ModalListRuna>
          ) : null}

          {modal2Active ? (
            <ModalListSpinney
              active={modal2Active}
              setActive={setModal2Active}
              allSpinnerInGame={allSpinnerInGame}
              userInGameState={userInGameState}
              addToSlotTrans={addToSlotTrans}
              remFromSlotTrans={remFromSlotTrans}
            ></ModalListSpinney>
          ) : null}

          {modal3Active ? (
            <Modal
              active={modal3Active}
              setActive={setModal3Active}
              activeButtonSpin={activeSpinner ? activeSpinner.value : -1}
              activeSpinner={activeSpinner}
              buyInStorageTrans={buyInStorageTrans}
              nowScore={nowScore}
            // modalContent={}
            ></Modal>
          ) : null}

          {modal4Active ? (
            <ModalClaim
              active={modal4Active}
              setActive={setModal4Active}
              claimAsset={claimAsset}
              claimTrans={claimTrans}
              scoreClame={scoreClame}
            ></ModalClaim>
          ) : null}

          {modal5Active ? (
            <ModalUnstake
              active={modal5Active}
              setActive={setModal5Active}
              remRunaTrans={remRunaTrans}
              allSpinnerInGame={allSpinnerInGame}
              activeBtnBurn={activeBtnBurn}
              activeSpinnerForRuna={activeSpinnerForRuna}
              addRunaTrans={addRunaTrans}
              addSpecRunaTrans={addSpecRunaTrans}
              allRunesInGame={allRunesInGame}
              allSpinner={allSpinner}
            ></ModalUnstake>
          ) : null}

          {modal6Active ? (
            <ModalUnstakeSpinney
              active={modal6Active}
              setActive={setModal6Active}
              remFromSlotTrans={remFromSlotTrans}
            ></ModalUnstakeSpinney>
          ) : null}

          {modal7Active ? (
            <ModalServer
              active={modal7Active}
              setActive={setModal7Active}
            ></ModalServer>
          ) : null}



          {modal9Active ? (
            <ModalSlots
              active={modal9Active}
              setActive={setModal9Active}
              userInGameState={userInGameState}
              buySlotTrans={buySlotTrans}
            ></ModalSlots>
          ) : null}

          {modal11Active ? (
            <ModalPremiumAccess
              active={modal11Active}
              setActive={setModal11Active}
            ></ModalPremiumAccess>
          ) : null}

          {modal12Active ?
            <ModalLogin
              active={modal12Active}
              setActive={setModal12Active}
              reguser={reguser}
            ></ModalLogin>
            : null}

          {modal13Active ? (
            <ModalRuna
              active={modal13Active}
              setActive={setModal13Active}
              reguser={reguser}
              activeSpinner={activeSpinner}
              buyInStorageTrans={buyInStorageTrans}
            ></ModalRuna>
          ) : null}
          {modal17Active ? (

            <ModalBorn
              active={modal17Active}
              setActive={setModal17Active}
              reguser={reguser}
              activeSpinner={activeSpinner}
              buyInStorageTrans={buyInStorageTrans}
              allSpinner={allSpinner}
              dispatchRemSpinneyFromWallet={dispatchRemSpinneyFromWallet}
              dispMultProfMult={dispMultProfMult}
            ></ModalBorn>
          ) : null}
          {modal18Active ? (
            <ModalAttention
              active={modal18Active}
              setActive={setModal18Active}
              spinTrans={spinTrans}
              claimAsset={claimAsset}
            ></ModalAttention>
          ) : null}
          {modal19Active ? (
            <ModalSpinAll
              active={modal19Active}
              setActive={setModal19Active}
              spinAll={spinAll}
              spinAllId={spinAllId}
              spinAllPoints={spinAllPoints}
            ></ModalSpinAll>
          ) : null}
          
        </div>
      </BrowserRouter>
    </>
  )
}
// ---

export default App
