import React, { useState, useEffect } from 'react'
import '../Modal/Modal.css'
import { Link } from 'react-router-dom'



const ModalRuna = ({
  spinnerValue,
  active,
  setActive,
  children,
  activeButtonSpin,
  activeSpinner,
  modalContent,
  buyInStorageTrans
}) => {

  const [activeSpin, setActiveSpin] = useState();

  useEffect(() => {
    let iconClass = '';
    let priceContent = '';

    if (activeSpinner?.rarity == 0) {
      iconClass = 'promoContentBlock';
      // priceContent = '192'
    } else if (activeSpinner?.rarity == 1) {
      iconClass = 'icon__card__Common'
      priceContent = '960'
    } else if (activeSpinner?.rarity == 2) {
      iconClass = 'icon__card__Uncommon'
      priceContent = '1920'
    } else if (activeSpinner?.rarity == 3) {
      iconClass = 'icon__card__Rare'
      priceContent = '3840'
    } else if (activeSpinner?.rarity == 4) {
      iconClass = 'icon__card__Epic'
      priceContent = '7680'
    } else if (activeSpinner?.rarity == 5) {
      iconClass = 'icon__card__Legend'
      priceContent = '15360'
    }
    // console.log(activeSpinner?.rarity)

    setActiveSpin({ iconClass, priceContent });
  }, [activeSpinner]);
  // console.log(activeSpin)



  const onClose = () => {
    setActive(false);
  };

  return (
    <div id="modal13" className={active ? "modal__content activerun" : "modal__content"} onClick={() => setActive(false)}>
      <div className={`modal ${active ? 'activerun' : ''}`} onClick={(event) => event.stopPropagation()}>
        {activeSpin && (
          <div className="container__modal">
            <div className={activeSpin.iconClass}></div>
            <div className="cintainer__purchase">
              <div className="title_purchase">Purchase</div>
              <div className="wallet__content">
                <div className="wallet__info">
                  <div className="name__wallet price">
                    <p>Price</p>
                  </div>
                  <div className="block__sum">
                    <div className="icon__sum">
                      <p>{activeSpin.priceContent}</p>
                    </div>
                    <div className="icon__spn__p">
                      <p>SPN</p>
                    </div>
                    <div className="icon__modal">
                      <div className="price__icon"></div>
                    </div>
                  </div>
                  <div className="q">Please confirm your purchase. Coins will be charged from your storage.</div>
                </div>
              </div>
              <div className="btn__modal">
                <button onClick={onClose}>
                  <span className='tokenDiscont'>Cancel</span>
                </button>
                <button onClick={() => buyInStorageTrans(activeSpinner.asset_id, "runes", activeSpin.priceContent)&& setActive(false)}>
                  <span>Confirm</span>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalRuna;
