import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Navcontent from '../Navcontent/Navcontent';
import UniversalSpin from '../Spinney/Spinner/UniversalSpin';
import '../BoxSpinners/BoxSpinners.css';
import { v4 as uuidv4 } from 'uuid';
import ModalUnstakeSpinney from '../Modal/ModalUnstakeSpinney';
import 'react-toastify/dist/ReactToastify.css';
import PreloaderSpinney from '../PreloaderSpinney/PreloaderSpinney';


const BoxSpinners = (props) => {

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 3000)
  }, [])


  const [allSinnersInGameLocal, setAllSinnersInGameLocal] = useState([]);

  useEffect(() => {
    setAllSinnersInGameLocal(props.allSpinnerInGame);
  }, [props.allSpinnerInGame]);


  const location = useLocation();
  const [selectedObjects, setSelectedObjects] = useState([]);

  useEffect(() => {
    const savedObjects = localStorage.getItem('selectedObjects');
    if (savedObjects) {
      setSelectedObjects(JSON.parse(savedObjects));
    }
  }, []);

  useEffect(() => {
    if (location.state && location.state.selectedObjects) {
      const newObjects = location.state.selectedObjects.filter(
        (newObject) => !selectedObjects.includes(newObject)
      );
      if (newObjects.length > 0) {
        const updatedObjects = [...selectedObjects, ...newObjects];
        setSelectedObjects(updatedObjects);
        localStorage.setItem('selectedObjects', JSON.stringify(updatedObjects));
      }
    }
  }, [location.state]);

  const addObject = (newObject) => {
    if (!selectedObjects.includes(newObject)) {
      const updatedObjects = [...selectedObjects, { id: uuidv4(), ...newObject }];
      setSelectedObjects(updatedObjects);
      localStorage.setItem('selectedObjects', JSON.stringify(updatedObjects));
    }
  };

  const handleAddObject = (newObjects) => {
    newObjects.forEach((newObject) => {
      addObject(newObject);
    });
  };

  const removeObject = (objectId) => {
    const updatedObjects = selectedObjects.filter((obj) => obj.id !== objectId);
    setSelectedObjects(updatedObjects);
    localStorage.setItem('selectedObjects', JSON.stringify(updatedObjects));
  };



  function compareRarity(a, b) {
    let res = b.rarity - a.rarity;

    if (a.rarity == 6 && b.rarity == 6) {
      res = 0;
    } else if (a.rarity == 6) {
      res = b.rarity - 0;
    } else if (b.rarity == 6) {
      res = 0 - a.rarity;
    }
    return res;
  }
  const spinButtonRef = useRef(null);

  const triggerSpinButtonClick = () => {
    if (spinButtonRef.current) {
      spinButtonRef.current.click();
    }
  };
  return (
    <>
      {/* {loading ? <PreloaderSpinney /> : ''}*/}

      <div className="main__content BoxSpinners">
        <Navcontent
          setModal2Active={props.setModal2Active}
          setModal8Active={props.setModal8Active}
          setModal9Active={props.setModal9Active}
          setModal19Active={props.setModal19Active}
          handleModalActivation={props.handleModalActivation}
          userInGameState={props.userInGameState}
          triggerSpinButtonClick={triggerSpinButtonClick}
          spinAllOpenModal={props.spinAllOpenModal}

        />
        <div className="BoxSpinnersContainer">
          {allSinnersInGameLocal && allSinnersInGameLocal.length ? allSinnersInGameLocal.filter(item => item.in_slot === 1).sort(compareRarity).map((selectedObject) => (
            <UniversalSpin
              spinButtonRef={spinButtonRef}
              key={selectedObject.asset_id}
              activeButtonSpin={selectedObject.rarity + 1}
              props={props}
              onRemove={() => removeObject(selectedObject.id)}
              activeSpinner={selectedObject}
              showCanvas={props.showCanvas}
              handleToggleDisplay={props.handleToggleDisplay}
              // setManyLeftDays={props.manyLeftDays}
              allSpinneyWorkPoints={props.allSpinneyWorkPoints}
              manyLeftDays={props.manyLeftDays}
              allSpecRunesInGame={props.allSpecRunesInGame}
            //spinTrans={spinTrans} 
            />
          )) : null}
        </div>
        {/* <div className="content BoxSpinners"> */}
        {/* {selectedObjects.map((selectedObject) => (
          <UniversalSpin key={selectedObject.id} activeButtonSpin={selectedObject + 1} props={props} />
        ))} */}



      </div>

    </>
  );
};

export default BoxSpinners;