import React from 'react';
import '../CraftDropNav/CraftDropNav.css';
import '../Chance/Chance.css'
import { useState, useEffect } from 'react';
import Leaderbord from './Leaderbord';
import LeaderbordVolume from './LeaderbordVolume';


const AllRefPage = ({ userInGameState,canvasRef, resDisp, allTopLocal, userInfoTop, setUserInfoTop }) => {

  const [activeMenuChance, setActiveMenuChance] = useState("boost1");

  const handleMenuClickChance = (menu) => {
    setActiveMenuChance(menu);
    localStorage.setItem('activeMenuChance', menu);
  };

  useEffect(() => {
    const savedActiveMenuChance = localStorage.getItem('activeMenuChance');
    if (savedActiveMenuChance) {
      setActiveMenuChance(savedActiveMenuChance);
    } else {
      setActiveMenuChance("boost1"); 
      localStorage.setItem('activeMenuChance', "boost1");
    }
  }, []);



  return (
        <div className="wrapAllRef">
      
        <div className="chance__nav  leaderbordVol">

          <div className="nav__top">
            <button
              className={`bt__nav ${activeMenuChance === "boost1" ? "active__top" : ""} `}
              onClick={() => handleMenuClickChance("boost1")} ><span className='tokenDiscont'>Ref Points</span></button>
            <button
              className={`bt__nav ${activeMenuChance === "offer1" ? "active__top" : ""} `}
              onClick={() => handleMenuClickChance("offer1")}><span className='tokenDiscont'>Volume</span>
            </button>
          
          </div>
          {activeMenuChance === "boost1" && (
            <div className="Leader1">
              <Leaderbord 
               allTopLocal={allTopLocal}
               resDisp={resDisp}
               userInGameState={userInGameState}
               userInfoTop={userInfoTop}
               setUserInfoTop={setUserInfoTop}/>
            </div>
          )}
          {activeMenuChance === "offer1" && (
            <div className="Leader2">
              <LeaderbordVolume
               allTopLocal={allTopLocal}
               resDisp={resDisp}
               userInGameState={userInGameState}
               userInfoTop={userInfoTop}
               setUserInfoTop={setUserInfoTop}/>
            </div>
          )}
        </div>
        </div>


  )
}


export default AllRefPage;

