import React from 'react';
import '../Preloader/Preloader.css'

const Preloader = () => {
  return (
    <div className="preloader">
      {/* <div className="video"></div> */}
    </div>
  );
};

export default Preloader;


