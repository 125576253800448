import React, { useState, useEffect } from 'react';
import '../RefPage/RefPage.css';
import useGetAllReferrals from '../../components/Wax/useGetAllReferrals';


const RefPage = ({ userInGameState, refreshAssets }) => {
    const [isCopied, setIsCopied] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [allRefCount, setAllRefCount] = useState(0);
    const [allRefferalsLocal, setAllRefferals] = useState(null);

    const { allReferralsGet } = useGetAllReferrals(userInGameState?.user);

    useEffect(() => {
        if (allReferralsGet && allReferralsGet.length) {
            setAllRefferals(allReferralsGet);
            refreshAssets("userinfo");

            let allRefCount = 0;
            allReferralsGet.map((user, index) => {
                allRefCount += user.count > 0 ? 1 : 0;
            });
            setAllRefCount(allRefCount);
        }

    }, [allReferralsGet]);

    //console.log("allRefferalsLocal", allRefferalsLocal);

    const handleCopy = () => {
        const text = `https://game.spinnia.io/?ref=${userInGameState?.user}`;
        navigator.clipboard
            .writeText(text)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            })
            .catch((err) => {
                console.log('err', err);
            });
    };

    const handleNextClick = () => {
        if (currentPage < 2) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevClick = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const totalBlocks = 10;

    const renderPageContent = () => {
        if (currentPage === 1) {
            return (
                <>
                    {/* {Array.from({ length: totalBlocks }, (_, index) => (
                        <div key={index} id={`WaxRef${index}`} className={`block__ref ${index === currentIndex ? '' : 'hidden'}`}>
                            <div id='WaxRef' className='block__ref'>
                                <div className='title__link'>Wallet.wax.Wam</div>
                                <div className='blockPoints'>
                                    <div className='priceRef'>999</div>
                                    <button className='btnPoints'>points</button>
                                </div>
                            </div>
                        </div>
                    ))} */}
                    {allRefferalsLocal && allRefferalsLocal.length ? allRefferalsLocal.map((user, index) => {

                        return (
                            <div key={index} id={`WaxRef${index}`} className={`block__ref ${index === currentIndex ? '' : 'hidden'}`}>
                                <div id='WaxRef' className='block__ref'>
                                    <div className='title__link'>{user.referral}</div>
                                    <div className='blockPoints'>
                                        <div className='priceRef'>{user.count}</div>
                                        <button className='btnPoints'>points</button>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    ) : null}

                </>
            );
        }
        // else if (currentPage === 2) {
        //     return (
        //         <>
        //             {Array.from({ length: totalBlocks }, (_, index) => (
        //                 <div key={index} id={`WaxRef${index}`} className={`block__ref ${index === currentIndex ? '' : 'hidden'}`}>
        //                     <div id='WaxRef' className='block__ref'>
        //                         <div className='title__link'>Wax.wallet.Wam</div>
        //                         <div className='blockPoints'>
        //                             <div className='priceRef'>999</div>
        //                             <button className='btnPoints'>points</button>
        //                         </div>
        //                     </div>
        //                 </div>
        //             ))}
        //         </>
        //     );
        // }
    };
    return (
        // <div className="main__content">
        <div className="containerRefPage ">
            <div className='refPage'>
                <div className="RefMenu">Referrals<div className='exit'></div></div>

                <div className='ref__content'>

                    <div className="boxLeftReferal">
                        <div className="top__menu__name"><div className="title">Referrals</div></div>
                        <div className="block__ref">
                            <div className="refNameLink"></div>
                            <div className="block__ref2">
                                <div className="title__link ico">Ref Link: </div>
                                <div className="copy__div">
                                    <div className="copy__link" onClick={handleCopy}>https://game.spinnia.io/?ref={userInGameState?.user}</div>
                                    {isCopied && <div className="popup__link">Link copy</div>}
                                </div>
                            </div>
                        </div>
                        <div className="ref__info firstTopRef">
                            <div className="block__ref bottom__width ">
                                <div className="refCount"></div>
                                <div id='count' className="block__ref2">
                                    <div className="title__link">{allRefferalsLocal && allRefferalsLocal.length}</div>
                                    <div className="title__link">Referral count</div>

                                </div>
                            </div>
                            <div className="block__ref bottom__width allRef">
                                <div className="refActiv"></div>
                                <div id='referrals' className="block__ref2">
                                    <div className="title__link">{allRefCount}</div>
                                    <div className="title__link">Active referrals</div>

                                </div>
                            </div>
                        </div>
                        <div className="lastRefMultip">
                            <div className="top__menu__name"><div className="title">Referral Points Progress</div></div>
                            <div className="ref__info lastRef">
                                <div className="block__ref bottom__width">
                                    <div className="refRp"></div>
                                    <div id='count' className="block__ref2">
                                        <div className="title__link">{userInGameState?.numberref}</div>
                                        <div className="title__link">Your Referral Points</div>

                                    </div>
                                </div>
                                <div className="block__ref bottom__width">
                                    <div className="refCalc"></div>
                                    <div id='referrals' className="block__ref2">
                                        <div className="title__link">{userInGameState && userInGameState.ref_bonus !== undefined ? parseFloat(userInGameState.ref_bonus, 2).toFixed(2) : 0}</div>
                                        <div className="title__link">Your Referral multiplier</div>
                                    </div>
                                </div>
                            </div>
                            <div className="ProgressRefMultip">
                                <div className="progress-barRefMultip">
                                    <div className="blockRefMultip block-25 activeRef111" >
                                        <div className='RefMultipProgress'
                                            style={{
                                                width: userInGameState?.numberref >= 0 ? 100 / 20 * userInGameState?.numberref + "%" : 0 + "%",
                                                background: 'var(--important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%))',
                                                transition: 'width 0.5s',
                                            }}>
                                        </div>
                                        <span>20 Rp</span>
                                    </div>
                                    <div className="blockRefMultip block-50">
                                        <div className='RefMultipProgress'
                                            style={{
                                                width: userInGameState?.numberref >= 20 ? 100 / 20 * (userInGameState?.numberref - 20) + "%" : 0 + "%",
                                                background: 'var(--important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%))',
                                                transition: 'width 0.5s',
                                            }}>
                                        </div>
                                        <span>40 Rp</span>
                                    </div>
                                    <div className="blockRefMultip block-75">
                                        <div className='RefMultipProgress'
                                            style={{
                                                width: userInGameState?.numberref >= 40 ? 100 / 20 * (userInGameState?.numberref - 40) + "%" : 0 + "%",
                                                background: 'var(--important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%))',
                                                transition: 'width 0.5s',
                                            }}>
                                        </div>
                                        <span>80 Rp</span>
                                    </div>
                                    <div className="blockRefMultip block-100">
                                        <div className='RefMultipProgress'
                                            style={{
                                                width: userInGameState?.numberref >= 80 ? 100 / 20 * (userInGameState?.numberref - 80) + "%" : 0 + "%",
                                                background: 'var(--important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%))',
                                                transition: 'width 0.5s',
                                            }}
                                        >
                                        </div>
                                        <span>160 Rp</span>
                                    </div>
                                </div>

                                <div className="contMultInfo__0">
                                    <div className={`multLvl ${userInGameState?.numberref >= 20 ? 'activ' : " "}`}><p>multiplier</p><span>1.05</span></div>
                                </div>
                                <div className="contMultInfo__1">
                                    <div className={`multLvl ${userInGameState?.numberref >= 40 ? 'activ' : " "}`}><p>multiplier</p><span>1.10</span></div>
                                </div>
                                <div className="contMultInfo__2">
                                    <div className={`multLvl ${userInGameState?.numberref >= 80 ? 'activ' : " "}`}><p>multiplier</p><span>1.15</span></div>
                                </div>
                                <div className="contMultInfo__3">
                                    <div className={`multLvl ${userInGameState?.numberref >= 160 ? 'activ' : " "}`}><p>multiplier</p><span>1.20</span></div>
                                </div>


                            </div>
                        </div>

                    </div>
                    <div className="contentRefPage">
                        {renderPageContent()}







                        {allRefferalsLocal && allRefferalsLocal.length > 10 ?
                            <div className='bottomRef'>
                                <button className='refbtn' onClick={handlePrevClick}></button>
                                <button className='refbtn refRight' onClick={handleNextClick}></button>

                            </div>
                            : null}
                    </div>
                </div>

            </div>
        </div>
        // </div>

        // update

    );
};

export default RefPage;