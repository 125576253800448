import "../Navfooter/Navfooter.css";
import { Link, NavLink, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";

// const NavItem = ({ to, activeItem, position, onClick, children, activePositionMenu }) => {

//   const isActive = activeItem === to;
//   const className = 'menu__item';
//   const classLink = `menu__item bottom${isActive ? ' active' : ''}`;
//   const handleClick = () => {
//     onClick(to, position);
//   };

//   return (
//     <div className={className} style={{ left: position }}>
//       <NavLink className={classLink} to={to} onClick={handleClick}>
//         <div className="nav__top__footer">
//           {children}
//         </div>
//       </NavLink>
//     </div>
//   );
// };

const Navfooter = ({
  activeItem,
  menuPosition,
  updateActiveItem,
  activePositionMenu,
  handleClickAudio,
  userInfoTop,
}) => {
  //const [path, setPath] = useState(null);
  const [position, setPosition] = useState(null);
  const [hide, setHide] = useState(false);
  const location = useLocation();

  const menulink = [
    {
      name: "Spinney",
      key: 1,
      pos: 0,
      path: "/",
      ico: "icon__spin",
      info: "infoBtnLeader",
    },
    {
      name: "Bank",
      key: 2,
      pos: 72,
      path: "/bankpage/assets",
      ico: "icon__bank",
      info: "infoBtnLeader",
    },
    {
      name: "Craft",
      key: 3,
      pos: 144,
      path: "/craft",
      ico: "icon__craft",
      info: "infoBtnLeader",
    },
    {
      name: "Games",
      pos: 218,
      path: "/games",
      ico: "icon__chance",
      info: "infoBtnLeader",
    },
    // {
    //   name: "Partners",
    //   pos: 292,
    //   path: "/partners",
    //   ico: "icon__partners",
    //   //info: 'infoBtnLeader'
    // },
    // {
    //   name: "Burn PA",
    //   pos: 291,
    //   path: "/burnsale",
    //   ico: "icon__burn",
    //   //info: 'infoBtnLeader'
    // },
    {
      name: "Liquidity",
      pos: 291,
      path: "/liquidity",
      ico: "icon__liq",
      //info: 'infoBtnLeader'
    },
    {
      name: "Top",
      pos: 363,
      path: "/allleaderboard",
      ico: "icon__leader",
      //появляется тогда когда есть что клеймить
      info: "infoBtnLeader activeBgTic",
    },
    {
      name: "Free SPN",
      pos: 437,
      path: "/freespn",
      ico: "icon__free",
      info: "infoBtnLeader",
    },
  ];

  /*
  292
  364
  438
  510
  */

  React.useEffect(() => {
    const partslocation = location.pathname.split("/"); // тут я из пути например /bankpage/assets делаю такой масив ["", "bankpage", "assets"]
    let index = menulink.findIndex((link) => {
      return link.path.split("/")[1] === partslocation[1];
    }); // а уже тут этот масив тоже сравниваю точно так но с активным пунктом меню
    if (index !== -1) {
      let pos = menulink[index].pos;
      setPosition(pos);
      setHide(false);
    } else {
      setHide(true);
    }
  }, [location]);

  //console.log("NavLink", NavLink);

  const handleItemClick = (newActiveItem, newPosition) => {
    updateActiveItem(newActiveItem, newPosition);
  };

  useEffect(() => {
    if (activePositionMenu) {
      //updateActiveItem(activePositionMenu.to, activePositionMenu.position);
    }
  }, [activePositionMenu]);

  return (
    <div className="bottom__nav">
      <div className="container__footer">
        <div className="block__footer">
          {!hide ? (
            <div className="menu__bg" style={{ left: position }}></div>
          ) : null}

          {menulink.map((oneMenu, index) => {
            //console.log("oneMenu.pos == position", oneMenu.pos == position)
            const isActive = oneMenu.pos == position;
            return (
              <div key={index} className={"menu__item"} style={{ left: 0 }}>
                <NavLink
                  onClick={handleClickAudio}
                  className={`menu__item ${isActive && !hide ? "active" : ""}`}
                  to={oneMenu.path}
                >
                  <div className="nav__top__footer">
                    <div className={oneMenu.ico}>
                      {/* //появляется тогда когда есть что клеймить */}
                      {userInfoTop &&
                      userInfoTop.user !== undefined &&
                      userInfoTop.prisecount[0] ? (
                        <div className={oneMenu.info}>!</div>
                      ) : null}
                      {/*  */}
                    </div>
                    <div>{oneMenu.name}</div>
                  </div>
                </NavLink>
              </div>
            );
          })}

          {/* <Link to="/bankpage/assets" activeItem={activeItem} position={72} onClick={handleItemClick}>
            <div className="icon__bank"></div>
            <div>Bank</div>
          </Link>
          <Link to="/craftdrop" activeItem={activeItem} position={145} onClick={handleItemClick}>
            <div className="icon__craft"></div>
            <div>Craft</div>
          </Link>
          <Link to="/games" activeItem={activeItem} position={218} onClick={handleItemClick}>
            <div className="icon__chance"></div>
            <div>Games</div>
          </Link> */}
        </div>
      </div>
    </div>
  );
};

export default Navfooter;
