import React, { useState, useEffect } from 'react';
import '../RefPage/RefPage.css';
import '../RegisteredList/RegisteredList.css';
import useLotteryOfferList from '../Wax/useLotteryOfferList';
import {
    Link,
    useParams,
} from "react-router-dom";




const RegisteredList = ({ userInGameState, props }) => {



    const handleNextClick = () => {
        if (currentPage < 2) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevClick = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };



    const iconData = [
        {
            images: 'common__1',
        },
        {
            images: 'uncommon__2',
        },
        {
            images: 'frosty__3',
        },
        {
            images: 'violett__4',
        },
        {
            images: 'legend__5',
        }
    ];

    const choosePriseNft = (priseTmpl) => {
        let imagesWinner;
        switch (priseTmpl) {
            case parseInt(process.env.REACT_APP_SPINNEY_COMMON):
                imagesWinner = 'Winner__common';
                break;
            case parseInt(process.env.REACT_APP_SPINNEY_UNCOMMON):
                imagesWinner = 'Winner__leaf';
                break;
            case parseInt(process.env.REACT_APP_SPINNEY_RARE):
                imagesWinner = 'Winner__frosty';
                break;
            case parseInt(process.env.REACT_APP_SPINNEY_EPIC):
                imagesWinner = 'Winner__violet';
                break;
            case parseInt(process.env.REACT_APP_SPINNEY_LEGEND):
                imagesWinner = 'Winner__sunch';
                break;
            case parseInt(process.env.REACT_APP_BOX_STARTER):
                imagesWinner = 'Winner__starter';
                break;
            case parseInt(process.env.REACT_APP_BOX_MYSTERY):
                imagesWinner = 'Winner__mystery';
                break;
            case parseInt(process.env.REACT_APP_BOX_INFINITY):
                imagesWinner = 'Winner__infinity';
                break;

            default:
                break;
        }
        return imagesWinner;
    }

    let { id } = useParams();

    useEffect(() => {
    }, [id]);

    const [isCopied, setIsCopied] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [allRefCount, selAllRefCount] = useState(0);
    const [date, setDate] = useState(null);
    //const [allRefferalsLocal, setAllRefferals] = useState(null);

    //const { allReferralsGet } = useGetAllReferrals(userInGameState?.user);
    const [allLotteryOfferListGetLocal, setAllLotteryOfferListGetLocal] = useState(null);

    const { allLotteryOfferListGet } = useLotteryOfferList(userInGameState?.user);

    useEffect(() => {
        if (allLotteryOfferListGet && allLotteryOfferListGet.length) {
            const count = allLotteryOfferListGet[id].userlist.filter(user => user === userInGameState?.user).length;
            selAllRefCount(count);
            setDate(allLotteryOfferListGet[id].time);
            setAllLotteryOfferListGetLocal(allLotteryOfferListGet);
        }
    }, [allLotteryOfferListGet]);


    const totalBlocks = 10;

    const renderPageContent = () => {
        if (currentPage === 1) {
            return (
                <>
                    {allLotteryOfferListGetLocal && allLotteryOfferListGetLocal.length ? allLotteryOfferListGetLocal[id].userlist.map((user, index) => {

                        const icon = iconData[index % iconData.length];


                        return (
                            <div key={index} id={`WaxRef${index}`} className={`block__ref ${user === userInGameState?.user ? 'winner' : 'hidden'}`}>

                                <div id='WaxRef' className='block__ref'>
                                    <div className='title__link'>{user}</div>
                                    {/* <div className='blockPoints'>
                                        <div className='title__link'>{user.referral}</div>
                                        <div className={`${icon.images} iconPlayerInList`}></div>
                                    </div> */}
                                </div>
                            </div>
                        )
                    }
                    ) : null}

                </>
            );
        }

    };
    return (
        <div className="main__content">
            <div id='Lotery' className='refPage ContentList'>
                <div className="blockTopLotery">
                    <div className="RefMenu">Registered list</div>
                    <Link className='Back' to="/games">
                        <div className="iconBack"></div>
                        <div className='titleBack'>Back</div>
                    </Link>
                </div>
                <div className='ref__content regListTop'>

                    <div className="ref__info RegisteredList" style={{ marginBottom: 10 }}>
                        <div className="block__ref bottom__width">
                            <div className="refCount BoostUpIcon"></div>
                            <div id='count' className="block__ref2">
                                <div className="title__link">Offer Tag</div>
                            </div>
                        </div>
                        <div className="block__ref bottom__width">
                            <div className="refActiv idLink"></div>
                            <div id='referrals' className="block__ref2">
                                <div className="title__link">{new Date(date * 1000).toLocaleString()}</div>
                            </div>
                        </div>
                    </div>
                    <div className="ref__info" style={{ marginBottom: 30 }}>
                        <div className="block__ref bottom__width">
                            <div className="refCount"></div>
                            <div id='count' className="block__ref2">
                                <div className="title__link">{allLotteryOfferListGetLocal && allLotteryOfferListGetLocal[id].userlist.length}</div>
                                <div className="title__link">Total Registered</div>

                            </div>
                        </div>
                        <div className="block__ref bottom__width">
                            <div className="refActiv"></div>
                            <div id='referrals' className="block__ref2">
                                <div className="title__link">{allRefCount}</div>
                                <div className="title__link">My registrations</div>

                            </div>
                        </div>
                    </div>
                    {renderPageContent()}


                </div>

                {allLotteryOfferListGetLocal && allLotteryOfferListGetLocal.length > 10 ?
                    <div className='bottomRef'>
                        <button className='refbtn' onClick={handlePrevClick}></button>
                        <button className='refbtn refRight' onClick={handleNextClick}></button>

                    </div>
                    : null}


            </div>
        </div>



    );
};

export default RegisteredList;