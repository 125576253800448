import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "./Assetspage.css";
import "../../Craft/Craft.css";
import { useParams } from "react-router-dom";
import { UALContext } from "ual-reactjs-renderer";

import { Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/mousewheel";
import "swiper/css/grid";
import { Mousewheel } from "swiper";
import { Grid } from "swiper";

const Assetspage = ({
  allSpinner,
  allSpinnerInGame,
  allRunesInGame,
  setChoosesAssetOnWallet,
  setChoosesAssetOnGame,
  setAllNftSpinerGame,
  transferNftToTheGame,
  withdrawnft,
  allNftSpinerGame,
  allNftRunesGame,
  setAllNftRunesGame,
  setAllNftSpinerGameFiltered,
  allNftSpinerGameFiltered,
  refreshAssets,
  choosesAssetOnGame,
}) => {
  const ual = useContext(UALContext);
  const gridRows = window.innerWidth < 610 ? 2 : 3;
  const [allTypeNftConcat, setAllTypeNftConcat] = useState([]);

  const [isAllSelectedGame, setIsAllSelectedGame] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedCards, setSelectedCards] = useState([]);

  const [shouldReloadContent, setShouldReloadContent] = useState(true);
  const [shouldReloadWalletContent, setShouldReloadWalletContent] =
    useState(true);
  const [loadingFromWallet, setLoadingFromWallet] = useState(false);
  const [loadingFromGame, setLoadingFromGame] = useState(false);
  const [loadingBtnWallet, setLoadingBtnWallet] = useState(false);
  const [loadingBtnGame, setLoadingBtnGame] = useState(false);

  function compareRarity(a, b) {
    return b.rarity - a.rarity;
  }

  const clearClass = () => {
    const elements = document.querySelectorAll(".bg__nft");
    elements.forEach((element) => {
      element.classList.remove("bg__nft");
    });
  };

  useEffect(() => {
    setAllNftSpinerGame(allSpinnerInGame);
    clearClass();
  }, [allSpinnerInGame]);

  useEffect(() => {
    setAllNftRunesGame(allRunesInGame);
  }, [allRunesInGame]);
  useEffect(() => {
    // let array = [];
    // if (Array.isArray(allNftSpinerGame)) array = [...array, ...allNftSpinerGame];
    // if (Array.isArray(allNftRunesGame)) array = [...array, ...allNftRunesGame];

    //const sortedItems = items.sort(compareRarity);

    const array = [
      ...(Array.isArray(allNftSpinerGame)
        ? allNftSpinerGame.sort(compareRarity)
        : []),
      ...(Array.isArray(allNftRunesGame)
        ? allNftRunesGame.sort(compareRarity)
        : []),
    ];
    setAllTypeNftConcat(array);
    // console.log("array", array);
    // console.log("allNftSpinerGame", allNftSpinerGame);
    // console.log("allNftRunesGame", allNftRunesGame);
  }, [allNftSpinerGame, allNftRunesGame]);

  useEffect(() => {
    let allItems = [];
    if (allSpinner && allSpinner[process.env.REACT_APP_SCHEMA_RUNAS]) {
      allItems = allItems.concat(
        allSpinner[process.env.REACT_APP_SCHEMA_RUNAS]
      );
    }
    if (allSpinner && allSpinner[process.env.REACT_APP_SCHEMA_SPINER]) {
      allItems = allItems.concat(
        allSpinner[process.env.REACT_APP_SCHEMA_SPINER]
      );
    }
    // if (allSpinner && allSpinner["expendable"]) {
    //     allItems = allItems.concat(allSpinner["expendable"]);
    // }

    // console.log("process.env.REACT_APP_SCHEMA_RUNAS", process.env.REACT_APP_SCHEMA_RUNAS);
    // console.log("allSpinner", allSpinner);
    // console.log("allItems", allItems);
    setAllNftSpinerGameFiltered(allItems);
    clearClass();
  }, [allSpinner]);

  //
  // console.log("allSpinner 2", allSpinner)
  // console.log("allSpinnerInGame 2", allNftSpinerGame)
  // console.log("choosesAssetOnGame", choosesAssetOnGame)

  //console.log("choosesAssetOnWallet", choosesAssetOnWallet);

  // const handleClick = () => {
  //     const nftPersons = document.querySelectorAll('.all__bg_right');
  //     setIsAllSelected(!isAllSelected);

  //     if (!isAllSelected) {
  //         document.querySelector('.radio__btn__chek.w').classList.add('radio__btn__chek--bg__nft');
  //         nftPersons.forEach(person => person.classList.add('active'));

  //         const assetIds = Object.values(allSpinner)
  //             .flat()
  //             .filter(item => item.schema.schema_name === "spinney" || item.schema.schema_name === "runes")
  //             .map(item => item.asset_id);
  //         setChoosesAssetOnWallet(assetIds);
  //     } else {
  //         document.querySelector('.radio__btn__chek.w').classList.remove('radio__btn__chek--bg__nft');
  //         nftPersons.forEach(person => person.classList.remove('active'));
  //         nftPersons.forEach(person => person.classList.remove('bg__nft'));
  //         setChoosesAssetOnWallet([]);

  //     }
  // };

  const handleClick = () => {
    const nftPersons = document.querySelectorAll(".all__bg_right");
    setIsAllSelected(!isAllSelected);

    if (!isAllSelected) {
      document
        .querySelector(".radio__btn__chek.w")
        .classList.add("radio__btn__chek--bg__nft");
      nftPersons.forEach((person) => {
        person.classList.add("bg__nft");
      });

      const assetIds = Object.values(allSpinner)
        .flat()
        .filter(
          (item) =>
            item.schema.schema_name === "spinney" ||
            item.schema.schema_name === "runes"
        )
        .map((item) => item.asset_id);
      setChoosesAssetOnWallet(assetIds);
    } else {
      document
        .querySelector(".radio__btn__chek.w")
        .classList.remove("radio__btn__chek--bg__nft");
      nftPersons.forEach((person) => {
        person.classList.remove("active");
        person.classList.remove("bg__nft");
      });
      setChoosesAssetOnWallet([]);
    }
  };

  const handleClickOneInWallet = (event, id) => {
    event.currentTarget.classList.toggle("bg__nft");
    setChoosesAssetOnWallet((prevChooses) => {
      if (prevChooses.includes(id)) {
        // Удаление id из массива, если оно уже есть
        return prevChooses.filter((item) => item !== id);
      } else {
        // Добавление id в массив, если его нет
        return [...prevChooses, id];
      }
    });
  };

  const handleClickGame = () => {
    const nftPersonsGame = document.querySelectorAll(
      ".all__bg.nft__personGame"
    );
    setIsAllSelected(!isAllSelectedGame);

    if (!isAllSelectedGame) {
      document
        .querySelector(".radio__btn__chek__game")
        .classList.add("radio__btn__chek__game--bg__nft");
      nftPersonsGame.forEach((person) => {
        if (!person.classList.contains("stakeNft")) {
          person.classList.add("active");
          person.classList.remove("bg__nft");
        }
      });
    } else {
      document
        .querySelector(".radio__btn__chek__game")
        .classList.remove("radio__btn__chek__game--bg__nft");
      nftPersonsGame.forEach((person) => {
        if (!person.classList.contains("stakeNft")) {
          person.classList.remove("active");
          person.classList.add("bg__nft");
        }
      });
    }
  };

  const [isNftInUse, setIsNftInUse] = useState(false);
  const handleClickOneInGame = (event, id) => {
    if (!isNftInUse) {
      //event.currentTarget.classList.toggle('bg__nft');
      setChoosesAssetOnGame((prevChooses) => {
        console.log("prevChooses", prevChooses);
        if (prevChooses.includes(id)) {
          // Удаление id из массива, если оно уже есть
          return prevChooses.filter((item) => item !== id);
        } else {
          // Добавление id в массив, если его нет
          return [...prevChooses, id];
        }
      });
    }
  };

  const handleClickGameAll = () => {
    const nftPersons = document.querySelectorAll(".nft__personGame.notinslot");
    setIsAllSelectedGame(!isAllSelectedGame);

    if (!isAllSelectedGame) {
      document
        .querySelector(".radio__btn__chek__game.g")
        .classList.add("radio__btn__chek--bg__nft");
      nftPersons.forEach((person) => person.classList.add("bg__nft"));

      const assetIds = Object.values(allTypeNftConcat)
        .flat()
        .filter(
          (item) =>
            (item.in_slot !== undefined && item.in_slot === 0) ||
            (item.in_spiner !== undefined && item.in_spiner === 0)
        )
        .map((item) => item.asset_id);
      setChoosesAssetOnGame(assetIds);
    } else {
      document
        .querySelector(".radio__btn__chek__game.g")
        .classList.remove("radio__btn__chek--bg__nft");
      nftPersons.forEach((person) => person.classList.remove("bg__nft"));
      setChoosesAssetOnGame([]);
    }
  };
  // ----

  const chooseColor = (rar) => {
    var id;
    var classN;
    switch (rar) {
      case 0:
      case "Promo":
      case process.env.REACT_APP_SPINNEY_PROMO:
        id = "promo__1";
        classN = "nft_mag";
        break;
      case 6:
      case "Promo2":
      case process.env.REACT_APP_SPINNEY_PROMO2:
        id = "promo__2";
        classN = "nft_jingl";
        break;
      case 1:
      case "Common":
      case process.env.REACT_APP_SPINNEY_COMMON:
        id = "common__2";
        classN = "nft_steel";
        break;
      case 2:
      case "Uncommon":
      case process.env.REACT_APP_SPINNEY_UNCOMMON:
        id = "uncommon__3";
        classN = "nft_leaf";
        break;
      case 3:
      case "Rare":
      case process.env.REACT_APP_SPINNEY_RARE:
        id = "rare__4";
        classN = "nft_frost";
        break;
      case 4:
      case "Epic":
      case process.env.REACT_APP_SPINNEY_EPIC:
        id = "violetta__6";
        classN = "nft_violetta";
        break;
      case 5:
      case "Legendary":
      case "Legend":
      case process.env.REACT_APP_SPINNEY_LEGEND:
        id = "sunshin__5";
        classN = "nft_legend";
        break;

      default:
        break;
    }
    return { id: id, classN: classN };
  };
  const chooseColorRuna = (rar) => {
    //console.log("rar", rar);

    // console.log(
    //   "process.env.REACT_APP_RUNES_PROMO2",
    //   process.env.REACT_APP_RUNES_PROMO2
    // );
    var id;
    var classN;
    switch (rar) {
      case 0:
      case "Promo":
      case process.env.REACT_APP_RUNES_PROMO:
        id = "promo__1";
        classN = "nft_mag promo__test";
        break;
      case 1:
      case "Common":
      case process.env.REACT_APP_RUNES_COMMON:
        id = "common__2";
        classN = "nft_steel common__test";
        break;
      case 2:
      case "Uncommon":
      case process.env.REACT_APP_RUNES_UNCOMMON:
        id = "uncommon__3";
        classN = "nft_leaf uncommon__test";
        break;
      case 3:
      case "Rare":
      case process.env.REACT_APP_RUNES_RARE:
        id = "rare__4";
        classN = "nft_frost rare__test";
        break;
      case 4:
      case "Epic":
      case process.env.REACT_APP_RUNES_EPIC:
        id = "violetta__6";
        classN = "nft_violetta epic__test";
        break;
      case 5:
      case "Legendary":
      case "Legend":
        id = "sunshin__5";
      case process.env.REACT_APP_RUNES_LEGEND:
        classN = "nft_legend legend__test";
        break;
      case 6:
      case "Promo2":
      case "Legend":

      case process.env.REACT_APP_RUNES_PROMO2:
        id = "promo_2";
        classN = "nft_jingl jingl_test";

        break;

      default:
        break;
    }
    return { id: id, classN: classN };
  };

  const handleReloadClick = () => {
    setSelectedCards([]);
    setChoosesAssetOnGame([]);
    setShouldReloadContent(true);
    setLoadingFromGame(true);

    setLoadingBtnGame(true);
    setTimeout(() => {
      setLoadingFromGame(false);
      setLoadingBtnGame(false);
      refreshAssets("gamespin");
    }, 2000);

    clearClass();
  };
  const handleReloadWalletClick = () => {
    setChoosesAssetOnWallet([]);
    setShouldReloadWalletContent(true);
    setLoadingFromWallet(true);

    setLoadingBtnWallet(true);
    setTimeout(() => {
      setLoadingFromWallet(false);
      setLoadingBtnWallet(false);
      refreshAssets("walletspin");
    }, 2000);

    clearClass();
  };

  return (
    <div id="e" className="bank__content">
      <div id="assets" className="assetspage">
        <div className="assets">
          <div className="nft__card">
            <div className="top__menu__name">
              <div
                id="topAssets"
                className="player__assets"
                onClick={() => handleReloadClick()}
              >
                Reload
              </div>
              <div className="title">In Game</div>
            </div>

            <div id="nft__card__assets" className="nft__card__content">
              {loadingFromGame ? <div className="preloader-local"></div> : null}
              <div className="fon__slider"></div>
              <div className="nft__card__slider">
                <div className="slider__container">
                  <div id="nft__block" className="nft__person__card nft__block">
                    <div className="craft__cards">
                      <div className="craft__card__slider">
                        {shouldReloadContent && (
                          <div className="spinney">
                            {allTypeNftConcat && allTypeNftConcat.length
                              ? allTypeNftConcat.map((item, index) => (
                                  <button
                                    key={index}
                                    id="available__leaf__2"
                                    className={`all__bg nft__personGame ${
                                      choosesAssetOnGame.includes(item.asset_id)
                                        ? "active"
                                        : ""
                                    } ${
                                      item.in_slot || item.in_spiner
                                        ? "ingameslot"
                                        : "notinslot"
                                    }`}
                                    onClick={(e) =>
                                      handleClickOneInGame(e, item.asset_id)
                                    }
                                  >
                                    {item.in_slot ||
                                    item.in_spiner ||
                                    item.inlottery ? (
                                      <div
                                        className={`stakeNft ${
                                          isNftInUse ? "active" : ""
                                        }`}
                                        onMouseEnter={() => setIsNftInUse(true)}
                                        onMouseLeave={() =>
                                          setIsNftInUse(false)
                                        }
                                      >
                                        <span id="stakeNft">IN USE</span>
                                      </div>
                                    ) : null}
                                    <div
                                      className={`${
                                        item.speed !== undefined
                                          ? chooseColor(item.rarity).classN
                                          : item.speed === undefined
                                          ? chooseColorRuna(item.rarity).classN
                                          : null
                                      }`}
                                    >
                                      <div
                                        id={`${chooseColor(item.rarity).id}`}
                                        className="nft__person_btn"
                                      >
                                        <span>{item.asset_id}</span>
                                      </div>
                                    </div>
                                  </button>
                                ))
                              : null}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bottom__menu">
                  <div className="bottom__menu__chek__game">
                    <div
                      className={`radio__btn__game${
                        isAllSelectedGame ? " radio__btn__game--bg__nft" : ""
                      }`}
                      onClick={handleClickGameAll}
                    >
                      <div
                        className={`radio__btn__chek__game g${
                          isAllSelectedGame
                            ? " radio__btn__chek__game--bg__nft"
                            : ""
                        }`}
                      ></div>
                    </div>
                    <div
                      className="radio__btn__text"
                      onClick={handleClickGameAll}
                    >
                      Select All
                    </div>
                  </div>
                  <button id="bt" onClick={() => withdrawnft()}>
                    <span className="tokenDiscont">Withdraw</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="blockWallet">
          <div className="nft__card">
            <div className="top__menu__name">
              <div
                id="topAssets"
                className="player__assets"
                onClick={() => handleReloadWalletClick()}
              >
                Reload
              </div>
              <div className="title">Wallet</div>
            </div>

            <div className="nft__card__content">
              {loadingFromWallet ? (
                <div className="preloader-local"></div>
              ) : null}
              <div className="fon__slider"></div>
              <div id="nft__block" className="nft__card__slider">
                <div className="slider__container">
                  <div id="nft__block" className="nft__person__card">
                    <div className="craft__cards">
                      <div className="craft__card__slider">
                        {shouldReloadWalletContent && (
                          <div className="spinney">
                            {allNftSpinerGameFiltered
                              ? allNftSpinerGameFiltered.map((item, index) => (
                                  <button
                                    id="available__leaf__2"
                                    onClick={(e) => {
                                      handleClickOneInWallet(e, item.asset_id);
                                    }}
                                    className={`all__bg_right ${
                                      selectedCards.includes(6) ? "active" : ""
                                    }`}
                                    key={index}
                                  >
                                    {/* {console.log("item", item)}
                                    {console.log(
                                      "item.template.template_id",
                                      item.template.template_id
                                    )} */}
                                    <div
                                      className={`${
                                        item &&
                                        item.schema &&
                                        item.schema.schema_name ===
                                          process.env.REACT_APP_SCHEMA_SPINER
                                          ? chooseColor(
                                              item.template.template_id
                                            ).classN
                                          : item.schema.schema_name ===
                                            process.env.REACT_APP_SCHEMA_RUNAS
                                          ? chooseColorRuna(
                                              item.template.template_id
                                            ).classN
                                          : null
                                      }`}
                                      style={
                                        {
                                          // backgroundImage: `url(https://atomichub-ipfs.com/ipfs/${item.data.img})`,
                                          // backgroundSize: "contain"
                                        }
                                      }
                                    ></div>
                                    <div
                                      id={`${chooseColor(item.data.rarity).id}`}
                                      className="nft__person_btn"
                                    >
                                      <span>{item.asset_id}</span>
                                    </div>
                                  </button>
                                ))
                              : null}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bottom__menu">
                <div className="bottom__menu__chek">
                  <div
                    className={`radio__btn${
                      isAllSelected ? " radio__btn--bg__nft" : ""
                    }`}
                    onClick={handleClick}
                  >
                    <div
                      className={`radio__btn__chek w${
                        isAllSelected ? " radio__btn__chek--bg__nft" : ""
                      }`}
                    ></div>
                  </div>
                  <div className="radio__btn__text" onClick={handleClick}>
                    Select All
                  </div>
                </div>
                <button id="bt" onClick={() => transferNftToTheGame()}>
                  <span className="tokenDiscont">Deposit</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Assetspage;
