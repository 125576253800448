import React from 'react';
import '../Modal/ModalClaim.css';
import '../Modal/Modal.css';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

const ModalClaim = ({ active, setActive, children, claimAsset, claimTrans, scoreClame }) => {
    const [isActive, setIsActive] = useState(active);
    const [storageLocal, setStorageLocal] = useState(active);
    const OnClose = () => {
        setActive(false);
    }

    useEffect(() => {

        if (claimAsset.vip && claimAsset.runes !== 0) {
            setStorageLocal(scoreClame.toFixed(4));

        } else {
            setStorageLocal((claimAsset.storage / 10000).toFixed(4));
        }

    }, [scoreClame, claimAsset]);





    return <div id="modal2" className={active ? "modal__content activerun" : "modal__content"} onClick={() => setActive(false)} >
        {children}
        <div id='claim' className={active ? "modal activerun" : "modal"} onClick={(event) => event.stopPropagation()}>
            <div className="icon__claim"></div>
            <div className="q">Are you sure you want to stop and claim? <br /> This action will reset the speed progress of your Spinney.</div>
            <div className="claim__price">
                <div className="claim__stor">Storage</div>
                <div className="clame__wallet">
                    <div className="many__wallet">{storageLocal}</div>
                    <div className="name__price">SPN</div>
                </div>
            </div>

            <div className="btn__modal">
                <button onClick={OnClose}><span className='tokenDiscont'>Cancel</span></button>
                <button onClick={() => claimTrans(claimAsset.asset_id, storageLocal) && setActive(false)} style={{ color: "#fff", fontFamily: "'Dongle', sans-serif", fontSize: "20px" }}>Claim</button>
            </div>
        </div>

    </div>
}


export default ModalClaim;