import React, { useState, useEffect } from 'react'
import { InView } from 'react-intersection-observer';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const OnePartners = ({ item, withdrawnft, index, swichOtherCol }) => {
    const [isVisible, setIsVisible] = useState(false);

    const handleVisibilityChange = (inView) => {
        setIsVisible(inView);
    };

    console.log("item", item)

    return (
        // <InView as="div" onChange={handleVisibilityChange}>
        //   <div className={`lines ${isVisible ? "visible" : "hidden"}`}>
        //       {/* Ваш контент */}
        //   </div>
        // </InView>
        <InView as="div" onChange={handleVisibilityChange}
        // rootMargin="100px" threshold="1"
        >
            <div className={`cardPartFirst ${index}`}>
                <div className="bodyCardFirst">

                    {isVisible ?
                        <div className={`lines ${isVisible ? "show" : "hide"}`}>
                            <div className="left l1"><span></span></div>
                            <div className="left l2"><span></span></div>
                            <div className="left l3"><span></span></div>
                            <div className="left l4"><span></span></div>
                            <div className="left l5"><span></span></div>

                            <div className="right l6"><span></span></div>
                            <div className="right l7"><span></span></div>
                            <div className="right l8"><span></span></div>
                            <div className="right l9"><span></span></div>
                        </div>
                        : null}



                    <div className="iconNftPart loop-ico"
                    // style={{
                    //     backgroundImage: `url(https://cloudflare-ipfs.com/ipfs/${item.data.img})`
                    // }}
                    >
                        <LazyLoadImage
                            src={`https://cloudflare-ipfs.com/ipfs/${item.data.img}`}
                            effect="blur"
                        />
                    </div>

                    {/* <div className="fonBlTop"></div> */}


                    <div className="fonViolCircl"></div>
                </div>
                <div className="bottomCardFirst">
                    <div id='activeBg' className="PartNum">{swichOtherCol(item.template.template_id)}</div>
                    <div className="userPart">
                        <div className="usNameWax">{item.collection}</div>
                        <div className="usIdWax">{item.asset_id}</div>

                    </div>
                    <div className="closeCard" onClick={() => withdrawnft(item.asset_id)}></div>
                </div>
            </div>
        </InView>
    );
};

export default OnePartners;