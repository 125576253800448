import React from 'react';
import LoginPage from '../StartPage/LoginPage';
import LoginVideo from '../../video/LoginVideoBG.mp4'; // Импорт видео

const StartPage = (props) => {
    return  (<>
                <div className="video-background">
                    <video autoPlay loop muted>
                    <source src={LoginVideo} type="video/mp4" />
                    </video>
                </div>
              <div className="StartLogin">
              <LoginPage/>  
              </div>
</>
    )
}


export default StartPage;