import React, { useContext } from 'react';
import '../Header/Header.css';
import MenuWallet from '../MenuWallet/MenuWallet';
//import ModalPremiumAccess from '../Modal/ModalPremiumAccess';
import { UALContext } from "ual-reactjs-renderer";
import { Link, NavLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const displayFirstBlock = false;


const Header = (props, updateActiveItem, getActiveMenuItem, allResInGameLocal, onlyTicket, isAnimating, startAnimation, stopAnimation) => {

    // console.log('isMuted:', props.isMuted);
    const { isMuted, handleClickAudio, handleToggleMute, manyLeftDays, isMuted3, isMuted2, handleToggleMute2, handleToggleMute3, handleToggleDisplay } = props;
    const [showPremInfo, setShowPremInfo] = useState(false);
    const premInfoClass = `prem__infom ${showPremInfo ? 'show' : ''}`;

    const [showTicketInfo, setShowTicketInfo] = useState(false);
    const ticketInfoClass = `ticket__infom ${showTicketInfo ? 'show' : ''}`;
    const [avatar, setAvatar] = useState(false);



    // async function getAvatarUrl(username) {
    //     const response = await fetch(`https://profile.api.atomichub.io/v1/profiles/chain/wax-mainnet/account/z1wk..c.wam/avatar`);
    //     console.log("response", response);
    //     const data = await response.json();

    //     // Предполагается, что в ответе содержится URL аватара в поле "image"
    //     if (data.success && data.data.length > 0) {
    //         console.log(data);
    //         //return data.data[0].data.image;
    //     } else {
    //         return null; // Если аватар не найден
    //     }
    // }
    // Адрес API AtomicHub


    // Функция для получения URL аватара по имени пользователя
    // async function getAvatarBlob(username) {
    //     const response = await fetch(`https://profile.api.atomichub.io/v1/profiles/chain/wax-mainnet/account/${username}/avatar`);
    //     const blob = await response.blob();
    //     return blob;
    // }

    // async function displayAvatar(username) {
    //     const avatarBlob = await getAvatarBlob(username);

    //     console.log("avatarBlob", avatarBlob)

    //     setAvatar(URL.createObjectURL(avatarBlob));

    //     // if (avatarBlob) {
    //     //     const img = document.createElement('img');
    //     //     img.src = URL.createObjectURL(avatarBlob);
    //     //     document.body.appendChild(img);
    //     // } else {
    //     //     console.log(`Аватар пользователя ${username} не найден.`);
    //     // }
    // }



    // useEffect(() => {
    //     console.log("userInGameState", props.accountName)
    //     if (props.accountName) {
    //         displayAvatar(props.accountName).catch(error => console.error('Ошибка при отображении аватара:', error));
    //     }

    // }, [props.accountName]);

    //getAvatarUrl();


    const ual = useContext(UALContext);
    //const accountName = ual?.activeUser?.accountName;

    const [isOpen, setIsOpen] = useState(false);
    const [lvl, setLvl] = useState(0);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (props.userInGameState) {
            var sum = parseFloat(props?.userInGameState?.many_spnmult / 10000);
            if (sum >= 5000) {
                setLvl(5);
            } else if (sum >= 4000) {
                setLvl(4);
            } else if (sum >= 3000) {
                setLvl(3);
            } else if (sum >= 2000) {
                setLvl(2);
            } else if (sum >= 1000) {
                setLvl(1);
            } else {
                setLvl(0);
            }
        }

    }, [props.userInGameState]);



    //console.log("props.allLeftPoints 123", props.allLeftPoints)

    return (
        <div className="container__header">
            <div className="mob__menu__container">
                <button className="menu-toggle__mob mOpen" onClick={toggleMenu}></button>
                <div className={`menu__mob ${isOpen ? 'open__mob' : ''}`}>
                    {displayFirstBlock ? (
                        <div className="spinner__card__free">
                            <div className="icon__spinner__pro"></div>
                        </div>

                    ) : (
                        <div className="spinner__card__pro">
                            <div className="icon__spinner__pro"></div>
                        </div>
                    )}
                    <button className="mClose" onClick={toggleMenu}></button>
                    <div className="top__mobMenu">

                        <div className="wax__link" onClick={toggleMenu}>{props.accountName}</div>

                        {props.allLeftPoints !== undefined ? (
                            props.allLeftPoints > 0 ? (
                                <div onClick={() => props.handleModalActivation(7)} className="shared__account__premium">
                                    <div className="icon__account"></div>
                                    <div className="prem__account">Premium </div>

                                </div>
                            ) : (
                                <div className="wax__link">
                                    <div className="prem__account OllFirst">Welcome</div>
                                </div>
                            )
                        ) : (
                            0
                        )}
                        {/* </div>
                    )} */}
                        <Link to='/profillevelpage' className="boxLavel">
                            <div className="lvl">Lv.{lvl}</div>
                            <div className="topLevel">Improve your level  <div className='vector_level'></div></div>
                        </Link>
                    </div>

                    <Link to="/bankpage/token"
                        //updateActiveItem={updateActiveItem}
                        onClick={() => { props.getActiveMenuItem("/bankpage/assets", 72); }}
                        className="header__cash ">
                        <MenuWallet getActiveMenuItem={props.getActiveMenuItem} balance={props.balance} userInGameState={props.userInGameState} />
                    </Link>
                    {/* <div className="header__cash">
                        <MenuWallet
                            balance={props.balance}
                            userInGameState={props.userInGameState}
                            getActiveMenuItem={getActiveMenuItem}
                        />
                    </div> */}
                    <div className="conteinerHeaderRight">
                        <div onClick={() => { props.handleModalActivation(7); }} className="papBoxHeader">
                            <div className="titlePapBlock">PAP</div>

                            <div className="boxCurrentBalance">
                                <div className="titleCurrentBalance">Current <br />balance</div>
                                <div className="nambTicket">{props.allLeftPoints ? props.allLeftPoints <= 0 ? 0 : props.allLeftPoints : 0}</div>
                            </div>
                            <div className="boxCurrentBalance">
                                <div className="titleCurrentBalance">Usage<br /> Per Day</div>
                                <div className="nambTicket">{
                                    props.reallSpendCredit == 0 ?
                                        props.allSpinneyWorkPoints / 10
                                        :
                                        0
                                }</div>
                            </div>
                            <div className="boxCurrentBalance">
                                <div className="titleCurrentBalance">Expiration<br /> days</div>
                                <div id={`${manyLeftDays > 5 ? "activeBg" : props.allSpinneyWorkPoints !== 0 && props.reallSpendCredit == 0 ? "activeBg2" : ""}`} className="nambTicket">{manyLeftDays > 0 ? manyLeftDays : 0}</div>
                            </div>
                        </div>
                        <Link
                            className="boxTicketHeader"
                            to="/bankpage/tickets"
                            //updateActiveItem={updateActiveItem}
                            onClick={() => { props.getActiveMenuItem("/bankpage/assets", 72); }}>
                            <div className="iconTicketHeader"></div>
                            <div className="nambTicket">{props.allResInGameLocal && props.allResInGameLocal.user !== undefined ? props.allResInGameLocal.res[0] : 0}</div>
                        </Link>

                    </div>
                    <div className="bottom__mobMenu">
                        {displayFirstBlock ? (
                            <div className="wax__link">
                                <div className="settins">
                                    <div className="icon__settins"></div>
                                </div>Settings<div className='vector_level'></div>
                            </div>
                        ) : (
                            <div className="wax__link" onClick={() => { handleToggleMute3(); handleToggleDisplay();; }} >
                                <div className="settins" onClick={handleToggleMute3}>
                                    {isMuted3 ? (
                                        <div className='icon__sound'></div>
                                    ) : (
                                        <div className='icon__soundoff'></div>
                                    )}
                                </div>Video switch<div className='vector_level'></div>
                            </div>
                        )}
                        <div className="wax__link" onClick={() => { handleToggleMute2();; }}>
                            <div className="settins" onClick={() => { handleToggleMute2();; }}>
                                {isMuted2 ? (
                                    <div className='icon__volumeOff'></div>
                                ) : (
                                    <div className='icon__volume'></div>

                                )}
                            </div>Music switch<div className='vector_level'></div>
                        </div>


                        {displayFirstBlock ? (
                            <div className="wax__link">
                                <div onClick={() => props.handleModalActivation(7) && toggleMenu} className="menu__link">
                                    <div onClick={() => props.handleModalActivation(7) && toggleMenu} className="icon__menu__link"></div></div>Premium<div className='vector_level'></div>
                            </div>
                        ) : (
                            <div className="wax__link" onClick={handleToggleMute}>
                                <div>

                                </div>
                                <div onClick={() => { handleToggleMute();; }} className="menu__link">
                                    {isMuted ? (
                                        <div className='activeStop'></div>
                                    ) : (
                                        <div className='icon__aminoff'></div>
                                    )}
                                </div>Sound switch<div className='vector_level'></div>
                            </div>

                        )}
                        <div onClick={() => props.handleModalActivation(6) && toggleMenu} className="wax__link">
                            <div onClick={() => props.handleModalActivation(6) && toggleMenu} className="map__link">
                                <div className="icon__map__link"></div>
                            </div>Change server<div className='vector_level'></div></div>
                        <Link to="/refpage" onClick={toggleMenu} className="wax__link">
                            <div className="log_out">
                                <div className="icon__refimg"></div>
                            </div>
                            Referrals <div className='vector_level'></div>
                        </Link >
                        <div onClick={() => props.logout()} className="wax__link">
                            <div onClick={() => props.logout()} className="log_out">
                                <div className="icon__log_out"></div>
                            </div>Log out</div>
                    </div>
                </div>
            </div>

            {/* DT header */}
            <div className="boxInform">
                <div className={premInfoClass}>
                    Premium Access Points
                </div>
                <div className={ticketInfoClass}>
                    Available tickets
                </div>
            </div >
            <header className="header">
                <div className="header__logo">

                    <div className="block__logo__premium">
                        {props.userInGameState && (
                            <div className="nambTicket">
                                {props.allLeftPoints !== undefined ? (
                                    props.allLeftPoints > 0 ? (
                                        <div className="shared__account__premium">
                                            <div className="icon__account"></div>
                                            <div className="prem__account">Premium</div>
                                        </div>
                                    ) : (
                                        <div className="shared__account">Welcome</div>
                                    )
                                ) : (
                                    0
                                )}
                            </div>
                        )}
                        <div className="wax__link">
                            {props.accountName}
                        </div>
                        <div className="content__logo">

                            {displayFirstBlock ? (
                                <div onClick={handleClickAudio} className="settins">
                                    <div className="icon__settins"></div>
                                </div>
                            ) : (
                                <div onClick={() => { handleToggleMute3(); handleToggleDisplay();; }} className="settins">
                                    {isMuted3 ? (
                                        <div className='icon__sound'></div>
                                    ) : (
                                        <div className='icon__soundoff'></div>
                                    )}

                                </div>

                            )}
                            <div onClick={() => { handleToggleMute();; }} className='menu__link'>
                                {isMuted ? (
                                    <div className='activeStop'></div>
                                ) : (
                                    <div className='icon__aminoff'></div>
                                )}
                            </div>
                            <div onClick={() => { handleToggleMute2();; }} className='menu__link'>
                                {isMuted2 ? (
                                    <div className='icon__volumeOff'></div>

                                ) : (
                                    <div className='icon__volume'></div>
                                )}
                            </div>
                            <div onClick={() => { props.handleModalActivation(6); }} className="map__link">
                                <div className="icon__map__link"></div>
                            </div>

                            <div onClick={() => { props.logout(); }} className="log_out">
                                <div className="icon__log_out"></div>
                            </div>
                            {displayFirstBlock ? (
                                <div className="spinner__card__free">
                                    <div className="icon__spinner__pro">

                                    </div>
                                </div>
                            ) : (
                                <div className="spinner__card__pro">
                                    <div className="icon__spinner__pro">

                                    </div>
                                </div>
                            )}
                        </div>

                    </div>
                </div>
                <div className="conteinerHeaderRight">
                    <div onClick={() => { props.handleModalActivation(7); }} className="papBoxHeader">
                        <div className="titlePapBlock">PAP</div>

                        <div className="boxCurrentBalance">
                            <div className="titleCurrentBalance">Current <br />balance</div>
                            <div className="nambTicket">{props.allLeftPoints ? props.allLeftPoints <= 0 ? 0 : props.allLeftPoints : 0}</div>
                        </div>
                        <div className="boxCurrentBalance">
                            <div className="titleCurrentBalance">Usage<br /> Per Day</div>
                            <div className="nambTicket">
                                {props.reallSpendCredit == 0 ?
                                    props.allSpinneyWorkPoints / 10
                                    :
                                    0}
                            </div>
                        </div>
                        <div className="boxCurrentBalance">
                            <div className="titleCurrentBalance">Expiration<br /> days</div>
                            <div id={`${manyLeftDays > 5 ? "activeBg" : props.allSpinneyWorkPoints !== 0 ? "activeBg2" : ""}`} className="nambTicket">{manyLeftDays > 0 ? manyLeftDays : 0}</div>
                        </div>
                    </div>
                    {/* #activeBg- подсветка зеленым когда больше 5 дней для према./ #activeBg2 - когда меньше либо равно 5 дням */}
                    {/* Расчитывается по формуле. Текущий баланс/Использование в день= */}
                    <Link
                        className="boxTicketHeader"
                        to="/bankpage/tickets"
                        //updateActiveItem={updateActiveItem}
                        onClick={() => { props.getActiveMenuItem("/bankpage/assets", 72); }}>
                        <div className="iconTicketHeader"></div>
                        <div className="nambTicket">{props.allResInGameLocal && props.allResInGameLocal.user !== undefined ? props.allResInGameLocal.res[0] : 0}</div>
                    </Link>

                    <Link to="/bankpage/token"
                        //updateActiveItem={updateActiveItem}
                        onClick={() => { props.getActiveMenuItem("/bankpage/assets", 72); }}
                        className="header__cash ">
                        <MenuWallet getActiveMenuItem={props.getActiveMenuItem} balance={props.balance} userInGameState={props.userInGameState} />
                    </Link>
                </div>

            </header >
        </div >
    )
}


export default Header;