export const wombattrans = async (name, quest, trans, typeuser) => {

    try {
        //4xvmk.c.wam
        //let trans = "283fe3b4f833093c2d575429d5b329f82969cff499b11ae2a49f6c5c643fba8b"; // register
        //let trans = "507b21243775e2ebef542c1707c4724b9e3fd2165b1ac3f3fdefad0712757ebe"; // buyslot
        //let trans = "7b8f9d55fc3c921c656b7b457e35287eb0779ae65335646b1dd504ed7714f8b1"; // addtoslot
        //let trans = "82ae46f2252f629763191f6f65c6aca450d7355d2b7bf7df5223042902c33a9e"; // deposit
        const response = await fetch('https://spinnia.xyz/user', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name, quest, trans, typeuser }),
        });

        if (!response.ok) {
            throw new Error('The network response was not ok.');
        }

        const userData = await response.json();
        console.log("userData", userData);
    } catch (err) {
        console.log('Error processing request: ' + err.message);
    }
};