import React, { useContext, useState, useEffect } from 'react';
import '../Bankpage/Token/Tokenpage.css';
import '../Bankpage/Ticket/Ticket.css';
import '../Bankpage/Ticket/Ticket.css';
import './SpnToken.css';
import MenuWallet from '../MenuWallet/MenuWallet';
// import useGetInfoStatDaily from '../../Wax/useGetInfoStatDaily';
import { useRef } from 'react';
import { UALContext } from 'ual-reactjs-renderer';
import { ToastContainer, toast } from 'react-toastify';
import { transferToTheGame, withdrawTicket } from '../../components/Wax/Transactions';
import { buyPack } from '../../components/Wax/Transactions';

import useGetUserCanBuySpn from '../../components/Wax/useGetUserCanBuySpn';



const SpnToken = ({ balance, props, userInGameState, tokenDepositeTrans, tokenWithdrawTrans, allSpinner, allResInGameLocal, id, resDisp, balanceDisp }) => {
    const notify = (e) => toast(e);
    const ual = useContext(UALContext);

    useEffect(() => {
    }, [id]);

    const [onlyTicket, setOnlyTicket] = useState(null);
    const [valueDeposit, setValueDeposit] = useState("");
    const [valueWithdraw, setValueWithdraw] = useState("");
    const [allUserCanBuySpnLocal, setAllUserCanBuySpnLocal] = useState(null);
    const [isPurchaseButtonDisabled, setIsPurchaseButtonDisabled] = useState(true);


    const { allUserCanBuySpn } = useGetUserCanBuySpn(userInGameState?.user);
    useEffect(() => {
        setAllUserCanBuySpnLocal(allUserCanBuySpn);
        console.log("allUserCanBuySpnLocal", allUserCanBuySpnLocal);
    }, [allUserCanBuySpn]);


    console.log("allUserCanBuySpn", allUserCanBuySpn);
    console.log("allUserCanBuySpnLocal", allUserCanBuySpnLocal);

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        const maxValue = onlyTicket && onlyTicket.length ? onlyTicket.length : 0;

        if (newValue === "" || (/^[0-9]+$/.test(newValue) && (!maxValue || parseInt(newValue) <= maxValue))) {
            setValueDeposit(newValue);
            setIsPurchaseButtonDisabled(false);
        }
        else {
            setIsPurchaseButtonDisabled(true);
        }
    };

    const depoitAll = () => {
        const maxValue = onlyTicket && onlyTicket.length ? onlyTicket.length : 0;

        setValueDeposit(maxValue);
    };

    const handleInputChangeWithdraw = (e) => {
        const newValue = e.target.value;
        const maxValue = allResInGameLocal && allResInGameLocal.user !== undefined ? allResInGameLocal.res[0] : 0;

        if (newValue === "" || (/^[0-9]+$/.test(newValue) && (!maxValue || parseInt(newValue) <= maxValue))) {
            setValueWithdraw(newValue);
            setIsPurchaseButtonDisabled(false);
        }
        else {
            setIsPurchaseButtonDisabled(true);
        }
    };
    // 
    const withdrawAll = () => {
        const maxValue = allUserCanBuySpnLocal?.canbuy / 10000 ?? 0;
        setValueWithdraw(maxValue);
        setIsPurchaseButtonDisabled(false);
    };

    // const anotherFunction = (id) => {
    //     console.log(`Вызвана функция с id=${2}`);
    // };


    useEffect(() => {
        let allItems = [];
        if (allSpinner && allSpinner[process.env.REACT_APP_SCHEMA_PRO]) {
            allItems = allSpinner[process.env.REACT_APP_SCHEMA_PRO];
        }
        const objectsToRemove = allItems.filter(item => item.template.template_id == process.env.REACT_APP_TICKET);
        //console.log("objectsToRemove", objectsToRemove);
        setOnlyTicket(objectsToRemove);
    }, [allSpinner]);


    const buyPackTrans = async (quant) => {
        try {
            const { activeUser } = ual;
            const { accountName } = activeUser;
            const memo = 'buyspn ';
            const detalPrice = parseFloat(quant).toFixed(8) + " WAX"
            const transactionPayload = buyPack(accountName, detalPrice, memo);

            const transaction = await activeUser.signTransaction(transactionPayload, {
                blocksBehind: 3,
                expireSeconds: 30,
            });

            balanceDisp(true, quant);

            console.log("transactionPayload", transactionPayload);

            notify("Transaction ID: " + transaction?.transactionId);
        } catch (e) {
            toast(e.toString(), {
                progressClassName: 'error-progress',
            });
            console.log(e);
            // if (e instanceof RpcError)
            //   console.log(JSON.stringify(e.json, null, 2));
        }
    }


    return (
        <div id='t' className="bank__content SpnToken">

            <div className="assetspage">
                <div className="assets">

                    <div className="nft__card">
                        <div className='top__menu__name'>
                            <div className="title">SPN Token Purchase</div>
                        </div>

                        <div id='token__card' className="nft__card__content">
                            <div className="nft__card__token">
                                <div className="ticket">
                                    <p>The amount of tokens available for purchase has <br /> been determined based on your staked Spinneys as <br /> of 12:00 UTC on October 4th.</p>
                                </div>
                                <div className="containerPurchase">
                                    <div className="BoxPurchase">
                                        <div className="textTicket"><span>Available to purchase:</span></div>
                                        <div className="SpmSumm">
                                            <div className="Craft__price">
                                                <div className="block__sum">
                                                    <div className="icon__sum"><p>{allUserCanBuySpnLocal && allUserCanBuySpnLocal.canbuy != undefined ? allUserCanBuySpnLocal.canbuy / 10000 : 0}</p></div>
                                                    <div className="icon__spn__p"><p>SPN</p></div>
                                                    <div className="icon__modal">
                                                        <div className="price__coin"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="Craft__price">
                                                <div className="block__sum SpmSummToken">
                                                    <div className="name__wallet price"><p>Price</p></div>
                                                    <div className="leftSumm">
                                                        <div className="icon__sum"><p>1</p></div>
                                                        <div className="icon__spn__p"><p>SPN</p></div>
                                                    </div>

                                                    <div className="icon__sum equals ">=</div>

                                                    <div className="rightSumm">
                                                        <div className="icon__sum"><p>1</p></div>
                                                        <div className="icon__spn__p"><p>WAX</p></div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="boxIconPurchase">
                                        <div className="iconBigCoin"></div>
                                        <div className="iconCirclFon"></div>

                                    </div>
                                </div>




                                <div className="contentBlockTicket">
                                    <div className="textTicket"><span>Quantity to purchase:</span></div>
                                    <div className="fonTicketTop">
                                        <input placeholder='Amount' className='placeholder' type="number" name="" id="" value={valueWithdraw}
                                            onChange={handleInputChangeWithdraw} />
                                        <button className='btnTicket' onClick={() => withdrawAll()}>Max</button>
                                    </div>
                                </div>
                            </div>
                            <div className="bottom__menu">
                                <button disabled={isPurchaseButtonDisabled} id='b' onClick={() => buyPackTrans(valueWithdraw)}><span className='tokenDiscont'>Purchase</span></button>
                            </div>
                        </div>
                    </div>

                </div>

            </div>


        </div>

    )
}


export default SpnToken;