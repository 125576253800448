import React from 'react';
import '../Modal/ModalListRuna.css';
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/mousewheel';
import { Mousewheel } from 'swiper';
import { Scrollbar } from 'swiper';
import { useNavigate } from 'react-router-dom';
import { chooseRar, specruntime } from '../../components/Wax/Variables';

const ModalListRuna = ({
  active,
  setActive,
  children,
  allRunesInGame,
  addRunaTrans,
  activeSpinnerForRuna,
  allSpinner,
  addSpecRunaTrans,
  props,
  showModal,
  leftPartVisible,
  rightPartVisible

}) => {
  const [isSlideSelected, setIsSlideSelected] = useState(false)
  const [isSlideSelectedSpec, setIsSlideSelectedSpec] = useState(false)
  const [choosedId, setChoosedId] = useState(0)
  const [choosedIdSpec, setChoosedIdSpec] = useState(0)

  const [isActive, setIsActive] = useState(active)
  const [showFirstDiv, setShowFirstDiv] = useState(true)
  const OnClose = () => {
    setActive(false)
  }
  const [selectedSlide, setSelectedSlide] = useState()
  const handleSlideClick = (index, id) => {
    setSelectedSlide(index)
    setIsSlideSelected(true)
    setChoosedId(id)
  }

  const [selectedSlideSpec, setSelectedSlideSpec] = useState()
  const [activeRar, setActiveRar] = useState("")
  const handleSlideClickSpec = (index, id, rar) => {
    setSelectedSlideSpec(index)
    setIsSlideSelectedSpec(true)
    setChoosedIdSpec(id)
    setActiveRar(rar);
  }

  //

  const navigate = useNavigate()

  const handleStakeClick = () => {
    setActive(false)

  }

  const [activeTable, setActiveTable] = useState(0)
  useEffect(() => {
    if (activeSpinnerForRuna /*&& activeSpinnerForRuna.length*/) {
      console.log(".activeSpinnerForRuna", activeSpinnerForRuna)
      setActiveTable(specruntime(activeSpinnerForRuna.rarity));
    }
  }, [activeSpinnerForRuna]);




  return (
    <>

      <div
        id="modal3"
        className={active ? 'modalRuna activerun' : 'modalRuna'}
        onClick={() => setActive(false)}
      >
        {leftPartVisible && (
          <div
            className={
              active ? 'modal__contentRuna activerun' : 'modal__contentRuna'
            }
            onClick={(e) => e.stopPropagation()}
          >
            {children}
            <div className="icon__modalRuna"></div>
            <div className="title__modalRuna">Choose the Infinity Rune</div>
            <div className="parag__modalRuna firstPrag">
              (NFT’s must be in the game to be displayed here)
            </div>
            <div className="parag__modalRuna">
              Infinity Runes are used to energize Spinney. With their help, Spinney stops consuming SPN token and starts generating it indefinitely.
            </div>
            <div className="parag__modalRuna">
              To enjoy these advantages, you need to activate a premium account and allocate a Rune in a matching Spinney slot based on its Rarity. Once done, your Spinney card will undergo a visual transformation and commence a continuous generation of SPN. You can remove the rune at any time.
            </div>
            <div className="slider__modalRuna">
              <Swiper
                id="runs__slider"
                modules={[Scrollbar, Mousewheel]}
                mousewheel={true}
                spaceBetween={8}
                direction={'horizontal'}
                scrollbar={{
                  draggable: true,

                }}

                breakpoints={{

                  610: {
                    slidesPerView: 1
                  },
                  1024: {
                    slidesPerView: 4
                  }
                }}
              >
                {allRunesInGame &&
                  allRunesInGame.length &&
                  //allRunesInGame.filter((item) => item.in_spiner === 0).map((runa, index) => (
                  allRunesInGame.filter((item) => item.in_spiner === 0 && item.rarity === activeSpinnerForRuna.rarity).map((runa, index) => (
                    <SwiperSlide

                      onClick={() => handleSlideClick(index, runa.asset_id)}
                    >
                      <div
                        className={
                          'slide__' +
                          (runa.rarity + 1) +
                          (selectedSlide === index ? ' selected' : '')
                        }
                      >
                        <div className="bg__id__runs"></div>
                        <div className="id__runs">{runa.asset_id}</div>
                      </div>
                    </SwiperSlide>
                  ))}
                <div className="swiper-scrollbar"></div>
              </Swiper>
            </div>
            <div className="btn__runs">
              <button onClick={OnClose} className="cancelRuna">
                Cancel
              </button>

              <button
                onClick={() => {
                  addRunaTrans(choosedId);
                  handleStakeClick();

                }}
                className={`stakeRuna ${isSlideSelected ? '' : 'disabled'}`}
                disabled={!isSlideSelected}
              >
                Stake
              </button>
            </div>

          </div>

        )}
        {rightPartVisible && (
          <div id='burnRuns'
            className={
              active ? 'modal__contentRuna activerun' : 'modal__contentRuna'
            }
            onClick={(e) => e.stopPropagation()}
          >
            {children}
            <div className="icon_bornNft"></div>
            <div className="title__modalRuna">Burn Spinney to get a soul</div>
            <div className="parag__modalRuna">
              Soul powers your Spinney and cancels the SPN token cost for that Spinney. It works like a Rune. The only difference is that it is not infinite.
            </div>
            <div className="burnRunsCateg">
              <div className={`fonSpn ${activeRar == "Legend" ? "burnLoaderBg" : ""}`}>Legend Spinney
                <div className='contPriceSpn'>
                  <div className="icon__spn"> <p><span className='priceSpnColor'></span>{activeTable.legendary} Days</p></div>
                </div>
              </div>
              <div className={`fonSpn ${activeRar == "Epic" ? "burnLoaderBg" : ""}`}>Epic Spinney
                <div className='contPriceSpn'>
                  <div className="icon__spn"> <p><span className='priceSpnColor'></span>{activeTable.epic} Days</p></div>
                </div>
              </div>
              <div className={`fonSpn ${activeRar == "Rare" ? "burnLoaderBg" : ""}`}>Rare Spinney
                <div className='contPriceSpn'>
                  <div className="icon__spn"> <p><span className='priceSpnColor'></span>{activeTable.rare} Days</p></div>
                </div>
              </div>
              <div className={`fonSpn ${activeRar == "Uncommon" ? "burnLoaderBg" : ""}`}>Uncommon Spinney
                <div className='contPriceSpn'>
                  <div className="icon__spn"> <p><span className='priceSpnColor'></span>{activeTable.uncommon} Days</p></div>
                </div></div>
              <div className={`fonSpn ${activeRar == "Common" ? "burnLoaderBg" : ""}`}>Common Spinney
                <div className='contPriceSpn'>
                  <div className="icon__spn"> <p><span className='priceSpnColor'></span>{activeTable.common} Days</p></div>
                </div>
              </div>
            </div>
            <div className="slider__modalRuna">
              <Swiper
                id="runs__slider"
                modules={[Scrollbar, Mousewheel]}
                mousewheel={true}
                spaceBetween={8}
                direction={'horizontal'}
                scrollbar={{ draggable: true }}
                breakpoints={{

                  610: {
                    slidesPerView: 1
                  },
                  1024: {

                    slidesPerView: 4
                  }
                }}
              >
                {allSpinner &&
                  allSpinner.spinney && allSpinner.spinney.length &&
                  //allRunesInGame.filter((item) => item.in_spiner === 0).map((runa, index) => (
                  allSpinner.spinney.filter((item) => item.data.rarity !== "Promo").map((item, index) => (
                    <SwiperSlide
                      key={index}
                      onClick={() => handleSlideClickSpec(index, item.asset_id, item.data.rarity)}
                    >

                      <div className={`slide__player_${chooseRar(item.data.rarity).number} ${selectedSlideSpec === index ? ' selected' : ''}`}>

                        <div className="bg__id__runs"></div>
                        <div className="id__runs">{item.asset_id}</div>
                      </div>
                    </SwiperSlide>
                  ))}
                <div className="swiper-scrollbar"></div>
              </Swiper>

              {/* 
            
            <SwiperSlide
                    onClick={() => handleSlideClickSpec(index, runa.asset_id)}
                  >
                    <div
                      className={
                        'slide__' +
                        (runa.rarity + 1) +
                        (selectedSlideSpec === index ? ' selected' : '')
                      }
                    >
                      <div className="bg__id__runs"></div>
                      <div className="id__runs">{runa.asset_id}</div>
                    </div>
                  </SwiperSlide>
            
            <Swiper
              id='spinn__slider'
              modules={[Scrollbar, Mousewheel]}
              mousewheel={true}
              spaceBetween={8}
              breakpoints={{

                1100: {
                  slidesPerView: 1
                },
                1200: {
                  slidesPerView: 4
                }
              }}
              direction={'horizontal'}
              scrollbar={{ draggable: true }}>
              {console.log("allSpinner", allSpinner)}

              {allSpiney && allSpiney.length ? allSpiney.map((item, index) => (
                <SwiperSlide
                  key={index}
                  onClick={() => handleClickSpinBurn(index, item.asset_id, item.data.rarity)}
                >

                  <div className={`slide__player_${chooseRar(item.data.rarity).number} ${activeIds.includes(item.asset_id) ? 'selected' : ''}`}>

                    <div className="bg__id__runs"></div>
                    <div className="id__runs">{item.asset_id}</div>
                  </div>
                </SwiperSlide>
              )) : null}


            </Swiper> */}
            </div>
            <div className="btn__runs">
              <button onClick={OnClose} className="cancelRuna">
                Cancel
              </button>

              <button
                onClick={() => {
                  addSpecRunaTrans(choosedIdSpec);
                  //handleSlideClickSpec();

                }}
                className={`stakeRuna ${isSlideSelectedSpec ? '' : 'disabled'}`}
                disabled={!isSlideSelectedSpec}
              >
                Burn
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default ModalListRuna