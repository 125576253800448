import React from 'react';
import '../Modal/ModalClaim.css';
import '../Modal/Modal.css';
import { Link } from 'react-router-dom';
import { useState, useEffect } from "react";


const ModalLogin = ({ active, setActive, children }) => {
  const [isActive, setIsActive] = useState(active);
  const OnClose = () => {
    setActive(false);
  }
  const serverOptions = ["Server 1", "Server 2", "Server 3", "Server 4", "Server 5", "Server 6", "Server 7"];


  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    toggleOpen();
  };



  return (
    <>

      <div id="modal5" className={active ? "modal__content activerun" : "modal__content"}   onClick={() => setActive(false) }>
        <div id='claim__server' className={active ? "modal activerun" : "modal"} onClick={(event) => event.stopPropagation()}>


          <div className="q server">Sign in</div>
          <div className="lin_bg"></div>

          <div className="choose__server">Select Your Wallet</div>
          <button className="cloudwallet"></button>
          <button className="anchor"></button>

          <div className="choose__server bottomserv">Choose server</div>
          <div className="claim__price">
            <div id='serv' className="clame__wallet " style={{ height: isOpen ? "120px" : "20px" }}>
              <div className="dropdown__wrapper">
                <div className="dropdown__header" onClick={toggleOpen}>
                  {selectedOption === "" ? "Server 1" : selectedOption}
                  <div className={`vector__icon ${isOpen ? "open" : ""}`} />
                </div>
                {isOpen && (
                  <div className="dropdown__options">
                    {serverOptions.map((option) => (
                      <div className="option" key={option} onClick={() => handleOptionClick(option)}>
                        {option}
                      </div>
                    ))}
                  </div>
                )}
                <div className={`vector__open ${isOpen ? "upside-down" : ""}`}></div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </>
  );

}
export default ModalLogin;