import { useEffect, useState } from 'react';
import { JsonRpc } from "eosjs";
import { arrRpc, savedOption } from './../Wax/Variables';

var numRpc = 0;

const useGetSpnWax = (accountName, refreshKey4) => {
    const [tacoCurr, settacoCurr] = useState({});
    const [hasError, setHasError] = useState(false);
    const [errorText, setErrorText] = useState('');

    const GetUserInGame = async () => {
        var link = !savedOption || savedOption === "dynamic" ? arrRpc[numRpc] : savedOption;
        try {
            var rpcc = new JsonRpc("https://" + link);
            await rpcc
                ?.get_table_rows({
                    json: true,
                    code: "swap.taco", // contract who owns the table
                    scope: "swap.taco", // scope of the table
                    table: "pairs", // name of the table as specified by the contract abi
                    key_type: "i64",
                    lower_bound: "SPNWAX",
                    upper_bound: "SPNWAX",
                    limit: 1,
                    //reverse: !0,
                    //show_payer: false,
                    //index_position: 2,
                })
                .then((results) => {
                    var resultRow = null;

                    if (results && results.rows && results.rows.length) {
                        resultRow = results.rows[0];
                    }
                    //console.log("results", results);

                    settacoCurr(resultRow);
                });
        } catch (e) {
            //console.warn(e);
            setHasError(true);
            setErrorText(e.message);
            setTimeout(() => {

                if (numRpc == 8) {
                    numRpc = 0;
                } else {
                    GetUserInGame();
                    numRpc++;
                }
            }, 1000);
            console.log("\nCaught exception: " + e);
        }
    }

    useEffect(() => {
        if (accountName) {
            GetUserInGame();
        }
    }, [accountName, refreshKey4]);

    // Верните JSX или null, если вы не хотите отображать ничего в компоненте
    return { tacoCurr };

}

export default useGetSpnWax;
