import React, { useState, useEffect } from 'react';
import '../RefPage/RefPage.css';
import '../RegisteredList/RegisteredList.css';
import useLotteryBoostList from '../Wax/useLotteryBoostList';
import {
    Link,
    useParams,
} from "react-router-dom";



const RegisteredListBoost = ({ userInGameState, index }) => {

    const chooseActiveInLotteryNft = (priseTmpl) => {
        let images;
        switch (priseTmpl) {
            case parseInt(process.env.REACT_APP_SPINNEY_COMMON):
                break;
            case parseInt(process.env.REACT_APP_SPINNEY_UNCOMMON):
                images = 'common__1';
                break;
            case parseInt(process.env.REACT_APP_SPINNEY_RARE):
                images = 'uncommon__2';
                break;
            case parseInt(process.env.REACT_APP_SPINNEY_EPIC):
                images = 'frosty__3';
                break;
            case parseInt(process.env.REACT_APP_SPINNEY_LEGEND):
                images = 'violett__4';
                break;

            default:
                break;
        }
        return images;
    }

    const iconData = [
        {
            images: 'common__1',
        },
        {
            images: 'uncommon__2',
        },
        {
            images: 'frosty__3',
        },
        {
            images: 'violett__4',
        },
        {
            images: 'legend__5',
        }
    ];

    let { id } = useParams();

    useEffect(() => {
    }, [id]);

    const [isCopied, setIsCopied] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [allRefCount, selAllRefCount] = useState(0);
    //const [allRefferalsLocal, setAllRefferals] = useState(null);

    //const { allReferralsGet } = useGetAllReferrals(userInGameState?.user);
    const [allLotteryBoostListGetLocal, setAllLotteryBoostListGetLocal] = useState(null);

    const { allLotteryBoostListGet } = useLotteryBoostList(userInGameState?.user);

    function sortBySpecificId(array, specificId) {
        return array.sort((a, b) => {
            if (a.tmpl_access === specificId) {
                return -1; // a всегда будет перед b, если у a id равен нужному id
            } else if (b.tmpl_access === specificId) {
                return 1; // b будет перед a, если у b id равен нужному id
            }
            return 0; // если id не совпадает с нужным, порядок остается неизменным
        });
    }

    useEffect(() => {
        if (allLotteryBoostListGet && allLotteryBoostListGet.length) {
            let specificId = parseInt(process.env.REACT_APP_SPINNEY_UNCOMMON);
            let sortedItems = sortBySpecificId(allLotteryBoostListGet, specificId);
            const count = allLotteryBoostListGet[id].userlist.filter(user => user === userInGameState?.user).length;
            selAllRefCount(count);

            setAllLotteryBoostListGetLocal(sortedItems);
        }

    }, [allLotteryBoostListGet]);

    const totalBlocks = 10;

    const renderPageContent = () => {
        if (currentPage === 1) {
            return (
                <>

                    {allLotteryBoostListGetLocal && allLotteryBoostListGetLocal.length ? allLotteryBoostListGetLocal[id].userlist.map((user, index) => {
                        const icon = chooseActiveInLotteryNft(allLotteryBoostListGetLocal[id].tmpl_access); // Access the icon data from the array
                        return (
                            <div key={index} id={`WaxRef${index}`} className={`block__ref ${user === userInGameState?.user ? 'winner' : 'hidden'}`}>
                                <div id='WaxRef' className='block__ref'>
                                    <div className='title__link'>{user}</div>
                                    <div className='blockPoints'>
                                        <div className='title__link'>{allLotteryBoostListGetLocal[id].assetIds[index]} {user.referral}</div>
                                        <div className={`${icon} iconPlayerInList`}></div>
                                    </div>
                                </div>
                            </div>
                        )
                    }) : null}

                </>
            );
        }

    };
    return (
        <div className="main__content">
            <div id='Lotery' className='refPage ContentList'>
                <div className="blockTopLotery">
                    <div className="RefMenu">Registered list</div>
                    <Link className='Back' to="/games">
                        <div className="iconBack"></div>
                        <div className='titleBack'>Back</div>
                    </Link>
                </div>

                <div className='ref__content regListTop'>

                    <div className="ref__info RegisteredList" style={{ marginBottom: 10 }}>
                        <div className="block__ref bottom__width">
                            <div className="refCount BoostUpIcon"></div>
                            <div id='count' className="block__ref2">
                                <div className="title__link">Boost Up</div>
                            </div>
                        </div>
                        <div className="block__ref bottom__width">
                            <div className="refActiv idLink"></div>
                            <div id='referrals' className="block__ref2">
                                <div className="title__link">22.10.2023 19:59</div>
                            </div>
                        </div>
                    </div>
                    <div className="ref__info" style={{ marginBottom: 30 }}>
                        <div className="block__ref bottom__width">
                            <div className="refCount"></div>
                            <div id='count' className="block__ref2">
                                <div className="title__link">{allLotteryBoostListGetLocal && allLotteryBoostListGetLocal[id].userlist.length}</div>
                                <div className="title__link">Total Registered</div>

                            </div>
                        </div>
                        <div className="block__ref bottom__width">
                            <div className="refActiv"></div>
                            <div id='referrals' className="block__ref2">
                                <div className="title__link">{allRefCount}</div>
                                <div className="title__link">My registrations</div>

                            </div>
                        </div>
                    </div>
                    {renderPageContent()}


                </div>

            </div>
        </div>

    );
};

export default RegisteredListBoost;