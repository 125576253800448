import '../ProfileLevelPage/ProfilLevelPage.css';
import '../RefPage/RefPage.css';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from "react";



const ProfilLevelPage = (props) => {
    // когда класс .multLvl.activ меняется фон карточки Multiplier.

    // когда .btnProgress .btn__0.activ меняется фон уровня на активный.

    //для десктопа .progressLevel .headbody width=143px уровень 1 и .btnProgress .btn__1.activ,width=281px  уровень 2 и .btnProgress .btn__2.activ,width=420px  уровень 3 .btnProgress .btn__3.activ,width=562px  уровень 4 и.btnProgress .btn__4.activ,width=700px  уровень 5 .btnProgress .btn__5.activ.

    // для моб-ки
    //мобилка прогрессбар .progressLevel .headbody width=143px уровень 1 и .btnProgress .btn__1.activ,width=281px  уровень 2 и .btnProgress .btn__2.activ,width=339px  уровень 3.
    //мобилка прогрессбар .mobProgLevel .headbody  width=62px уровень 3 и .btnProgress .btn__3.activ,width=200px  уровень 4 и .btnProgress .btn__4.activ,width=343px  уровень 5 и .btnProgress .btn__5.activ.
    // 

    const [value, setValue] = useState(0);
    const [mult, setMult] = useState(0);
    const [lvl, setLvl] = useState(0);

    useEffect(() => {
        if (props.userInGameState) {
            var num;
            var sum = parseFloat(props?.userInGameState?.many_spnmult / 10000);
            if (sum >= 5000) {
                setLvl(5);
                setMult(1.25);
            } else if (sum >= 4000) {
                setLvl(4);
                setMult(1.20);
            } else if (sum >= 3000) {
                setLvl(3);
                setMult(1.15);
            } else if (sum >= 2000) {
                setLvl(2);
                setMult(1.10);
            } else if (sum >= 1000) {
                setLvl(1);
                setMult(1.05);
            } else {
                setLvl(0);
                setMult(1.00);
            }


        }

    }, [props.userInGameState]);

    const handleChange = (event) => {
        const inputValue = event.target.value;

        // Проверяем, является ли ввод числом и больше ли оно или равно 0
        if (!isNaN(inputValue) && inputValue >= 0) {
            setValue(inputValue);
        }
    };

    return (
        <>
        <div className="main__content">
        <div className="LevelPage">
                <div className="RefMenu">Profile level</div>

                <div className="headerLevel">Profile level </div>
                <div className="progressLevel">
                    <div className='headProg'
                        style={{
                            height: 10,
                            background: 'var(--dark-elements, rgba(0, 0, 0, 0.20))',
                            transition: 'width 0.5s',
                        }}
                    >
                        <div className='headbody DtProgress'
                            style={{
                                width: `${100 / 5000 * (props?.userInGameState?.many_spnmult / 10000)}%`,
                                height: '10px',
                                background: 'var(--important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%))',
                                transition: 'width 0.5s',
                                borderRadius: '6px',
                            }}
                        />
                        <div className='headbody mobBodyProg'
                            style={{
                                width: `${100 / 5000 * (props?.userInGameState?.many_spnmult / 10000)}%`,
                                height: '10px',
                                background: 'var(--important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%))',
                                transition: 'width 0.5s',
                                
                            }}
                        />
                    </div>

                    <div className="contMultInfo__0">
                        <div className={`multLvl ${parseFloat(props?.userInGameState?.many_spnmult / 10000) >= 1 && parseFloat(props?.userInGameState?.many_spnmult / 10000) <= 1000 ? "activ" : "no"}`}><button className={`btnProgress btn__0 ${parseFloat(props?.userInGameState?.many_spnmult / 10000) >= 1 ? "activ" : "no"}`}>0</button><p>multiplier</p><span>1.00</span></div>
                        <div className="statusSpn"><p>0</p><span>SPN</span></div>
                    </div>
                    <div className="contMultInfo__1">
                        <div className={`multLvl ${parseFloat(props?.userInGameState?.many_spnmult / 10000) >= 1000 && parseFloat(props?.userInGameState?.many_spnmult / 10000) < 2000 ? "activ" : "no"}`}><button className={`btnProgress btn__1 ${parseFloat(props?.userInGameState?.many_spnmult / 10000) >= 1000 ? "activ" : "no"}`}>1</button><p>multiplier</p><span>1.05</span></div>
                        <div className="statusSpn"><p>1000</p><span>SPN</span></div>
                    </div>
                    <div className="contMultInfo__2">
                        <div className={`multLvl ${parseFloat(props?.userInGameState?.many_spnmult / 10000) >= 2000 && parseFloat(props?.userInGameState?.many_spnmult / 10000) < 3000 ? "activ" : "no"}`}><button className={`btnProgress btn__2 ${parseFloat(props?.userInGameState?.many_spnmult / 10000) >= 2000 ? "activ" : "no"}`}>2</button><p>multiplier</p><span>1.10</span></div>
                        <div className="statusSpn"><p>2000</p><span>SPN</span></div>
                    </div>
                    <div className="contMultInfo__3">
                        <div className={`multLvl ${parseFloat(props?.userInGameState?.many_spnmult / 10000) >= 3000 && parseFloat(props?.userInGameState?.many_spnmult / 10000) < 4000 ? "activ" : "no"}`}><button className={`btnProgress btn__3 ${parseFloat(props?.userInGameState?.many_spnmult / 10000) >= 3000 ? "activ" : "no"}`}>3</button><p>multiplier</p><span>1.15</span></div>
                        <div className="statusSpn"><p>3000</p><span>SPN</span></div>
                    </div>
                    <div className="contMultInfo__4">
                        <div className={`multLvl ${parseFloat(props?.userInGameState?.many_spnmult / 10000) >= 4000 && parseFloat(props?.userInGameState?.many_spnmult / 10000) < 5000 ? "activ" : "no"}`}><button className={`btnProgress btn__4 ${parseFloat(props?.userInGameState?.many_spnmult / 10000) >= 4000 ? "activ" : "no"}`}>4</button><p>multiplier</p><span>1.20</span></div>
                        <div className="statusSpn"><p>4000</p><span>SPN</span></div>
                    </div>
                    <div className="contMultInfo__5">
                        <div className={`multLvl ${parseFloat(props?.userInGameState?.many_spnmult / 10000) >= 5000 ? "activ" : "no"}`}><button className={`btnProgress btn__5 ${parseFloat(props?.userInGameState?.many_spnmult / 10000) >= 5000 ? "activ" : "no"}`}>5</button><p>multiplier</p><span>1.25</span></div>
                        <div className="statusSpn"><p>5000</p><span>SPN</span></div>
                    </div>
                </div>
                <div className="progressLevel mobProgLevel">
                    <div className='headProg'
                        style={{
                            height: 10,
                            background: 'var(--dark-elements, rgba(0, 0, 0, 0.20))',
                            transition: 'width 0.5s',
                        }}
                    >
                        <div className='headbody'
                            style={{
                                width: `${100/ 5000 * (props?.userInGameState?.many_spnmult / 10000)}%`,
                                height: '10px',
                                background: 'var(--important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%))',
                                transition: 'width 0.5s',
                            }}
                        />
                    </div>
                </div>

                <div className="boxLevel">
                    <div className="infoLevelLeft">
                        <div className="topContLevel">
                            <div className="block__ref bottom__width levelFirst">
                                <div className="bgLevel">Your level</div>
                                <div id='referrals' className="block__ref2">
                                    <div className="title__link"></div>
                                    <div className="title__link NumLink">{lvl}</div>

                                </div>
                            </div>
                            <div className="block__ref bottom__width levelSecond">
                                <div className="bgLevel">Multiplier</div>
                                <div id='referrals' className="block__ref2">
                                    <div className="title__link"></div>
                                    <div className="title__link NumLink"><span>{mult}</span></div>

                                </div>
                            </div>
                            <div className="block__ref bottom__width levelThird">
                                <div className=" Burned"><p>Burned</p></div>
                                <div id='referrals' className="block__ref2">
                                    <div className="title__link"></div>
                                
                                    <div className="title__link NumLink"> <span>{(props?.userInGameState?.many_spnmult / 10000).toFixed(4)}</span></div>
                                    <div className='icon__spn'>
                                        <p>SPN</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bottomContLavel">
                            <p>
                                By enhancing your level through SPN token burning,
                                you have the opportunity to elevate your personal
                                account multiplier. This multiplier directly impacts
                                the profitability of your Spinney when the premium
                                feature is enabled. The extent of your multiplier increase
                                is determined by the quantity of SPN tokens you burn.
                            </p>

                        </div>
                    </div>
                    <div className="containerBurn">
                    <div className="rightBurnSpn">
                        <div className="headBurnSpn">Burn SPN</div>
                        <div className="bottomBurnSpn">
                            <div className="textBurnSpn"><p>Your SPN must be in game balance to burn</p></div>
                            <div className="burnInput">
                                <input
                                    placeholder='Amount:'
                                    id="inp"
                                    value={value}
                                    onChange={handleChange}
                                    min="0"
                                />
                                <div className='icon__spn'>
                                    <p>SPN</p>
                                </div>
                            </div>
                            <button className='burn' onClick={() => props.buyMultTrans(value)}>Burn</button>
                        </div>
                    </div>
                    <div className="rightBurnSpn">
                        <div className="headBurnSpn">Burn NFT</div>
                        <div className="bottomBurnSpn">
                            <div className="textBurnSpn burnNft"><p>When you burn an NFT you are credited with an SPN according to the rarity of your Spinney.The SPN you receive counts as burned to increase your profile.</p></div>
                            
                            <button className='burn' onClick={() => { props.handleModalActivation(15); }}>Select NFT</button>
                        </div>
                    </div>
                    </div>
                </div>

            </div>
        </div>
           

        </>


    );
}

export default ProfilLevelPage;