import React from 'react';
import '../Modal/ModalClaim.css';
import '../StartPage/LoginPage.css';
import '../RegisterForm/RegisterForm.css';
import '../Modal/Modal.css';
import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';


const RegisterForm = ({ setActive, accountName, reguser }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [refferal, setRefferal] = useState('');
  const [confirmRefferal, setConfirmRefferal] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const location = useLocation();

  useEffect(() => {
    // Получаем параметры запроса из URL
    const queryParams = new URLSearchParams(window.location.search);
    const refId = queryParams.get('ref');

    // Проверяем, есть ли реферальный ID в localStorage
    const storedRefId = localStorage.getItem('referral');

    // Если в localStorage нет сохранённого реферального ID, то проверяем URL
    if (!storedRefId) {
      if (refId) {
        // Если refId существует и его нет в localStorage, сохраняем его
        localStorage.setItem('referral', refId);
        // Устанавливаем реферальный ID через setRefferal
        setRefferal(refId);
      }
    } else {
      // Если реферальный ID уже сохранен в localStorage, то просто устанавливаем его
      setRefferal(storedRefId);
    }
  }, [location]);

  const handleSubmit = (e) => {

    e.preventDefault();

    // Проверка наличия имени
    if (name.trim() === '') {
      setErrorMessage('');
      return;
    }


    // if (!validateEmail(email)) {
    //   setErrorMessage('');
    //   return;
    // }

    // Проверка длины пароля
    if (refferal.length < 6) {
      setErrorMessage('');
      return;
    }

    // Проверка совпадения паролей
    // if (refferal !== confirmRefferal) {
    //   setErrorMessage('');
    //   return;
    // }

    // Все данные введены корректно
    setErrorMessage('');
    // Отправка данных на сервер или дальнейшие действия
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <div className="block__formLog">
      <div className="container__form">
        <div className='LoginContent top__menu__name'>
          <div className='title'>Sign up</div>
        </div>
        <div id='form' className='LogiContent '>
          <div id='claim__server' className="modal">

            <div className='wallet__reg'>
              <div className="choose__server">Your wallet:</div>
              <input
                type="text"
                placeholder={accountName}
                value={accountName}
                readOnly={true}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            {/* <div>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div> */}
            <div>
              <div className="choose__server">Your ref code:</div>
              <input
                type="text"
                placeholder="Nan"
                value={refferal}
                readOnly
                onChange={(e) => setRefferal(e.target.value)}
              />
            </div>
            {/* <div>
              <input
                type="refferal"
                placeholder="Подтвердите пароль"
                value={confirmRefferal}
                onChange={(e) => setConfirmRefferal(e.target.value)}
              />
            </div> */}
            <div className='reg'>
              <button className='btn__register' onClick={() => reguser(refferal)}>Register</button>
            </div>
            {errorMessage && <div>{errorMessage}</div>}

          </div>
        </div>
      </div>
    </div>


  );
};



export default RegisterForm;