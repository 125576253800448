export const arrRpc = ['wax.cryptolions.io', 'wax.greymass.com', 'api.wax.alohaeos.com', 'api.waxsweden.org', 'wax.eosphere.io', 'api.wax.greeneosio.com', 'wax.eu.eosamsterdam.net', 'api.wax.bountyblok.io'];
export const savedOption = localStorage.getItem('selectedServer');
export const savedOptionAt = localStorage.getItem('selectedServerAtomic');

export const mult = [
    [0, 1.1, 1.325, 1.55, 1.775, 2],
    [0, 1.1, 1.325, 1.55, 1.775, 2],
    [0, 1.1, 1.35, 1.6, 1.85, 2.1],
    [0, 1.1, 1.375, 1.65, 1.925, 2.2],
    [0, 1.1, 1.4, 1.7, 2, 2.3],
    [0, 1.1, 1.45, 1.8, 2.15, 2.5],
    [0, 1.1, 1.325, 1.55, 1.775, 2],
];

export const arrRpcAt = ["wax.api.atomicassets.io", "aa-wax-public1.neftyblocks.com", "api.wax-aa.bountyblok.io", "aa.dapplica.io", "api.atomic.greeneosio.com", "atomic.wax.eosrio.io", "wax-aa.eu.eosamsterdam.net", "atomic-wax-mainnet.wecan.dev", "atomic.sentnl.io", "atomic.tokengamer.io", "atomic.ledgerwise.io", "api-wax-aa.eosarabia.net", "wax.hkeos.com/aa", "wax-atomic.wizardsguild.one", "wax-atomic.eosiomadrid.io", "aa.wax.blacklusion.io", "wax-atomic-api.eosphere.io", "wax-aa.eosdublin.io"]

export const chooseRar = (rar) => {
    var number;
    let coinBurn;
    switch (rar) {
        case 0: case "Promo":
            number = 0;
            coinBurn = 200;
            break;
        case 1: case "Common":
            number = 1;
            coinBurn = 40;
            break;
        case 2: case "Uncommon":
            number = 2;
            coinBurn = 80;
            break;
        case 3: case "Rare":
            number = 3;
            coinBurn = 160;
            break;
        case 4: case "Epic":
            number = 4;
            coinBurn = 320;
            break;
        case 5: case "Legendary": case "Legend":
            number = 5;
            coinBurn = 640;
            break;
        default:
            break;
    }
    return {
        number: number + 1,
        coinBurn: coinBurn,
    };
}

export const specruntime = (rarspin) => {
    //let time = 0;
    let day = 1; // second in one day
    let timedef;
    let listDays = {}

    if (process.env.REACT_APP_CONTRACT == "spinniaworld") {
        timedef = 60 * day;
    } else {
        timedef = 60 * day;
    }

    if (rarspin == 1 || rarspin == "Common") {
        listDays = {
            common: timedef,
            uncommon: timedef * 2,
            rare: timedef * 4,
            epic: timedef * 8,
            legendary: timedef * 16,
        }
    } else if (rarspin == 2 || rarspin == "Uncommon") {
        listDays = {
            common: timedef / 2,
            uncommon: timedef,
            rare: timedef * 2,
            epic: timedef * 4,
            legendary: timedef * 8,
        }
    } else if (rarspin == 3 || rarspin == "Rare") {
        listDays = {
            common: timedef / 4,
            uncommon: timedef / 2,
            rare: timedef,
            epic: timedef * 2,
            legendary: timedef * 4,
        }
    } else if (rarspin == 4 || rarspin == "Epic") {
        listDays = {
            common: timedef / 8,
            uncommon: timedef / 4,
            rare: timedef / 2,
            epic: timedef,
            legendary: timedef * 2,
        }
    } else if (rarspin == 5 || rarspin == "Legendary") {
        listDays = {
            common: timedef / 16,
            uncommon: timedef / 8,
            rare: timedef / 4,
            epic: timedef / 2,
            legendary: timedef,
        }
    } else {

    }

    return listDays;
}

export const specRunTimeDisp = (rarspin, rarburn) => { //"5", "comon", 
    let time = 0;
    let day = 86400;
    let timedef = 60 * day;

    if (rarburn == "Common") {
        if (rarspin == 1 || rarspin == "Common") {
            time = timedef;
        } else if (rarspin == 2 || rarspin == "Uncommon") {
            time = timedef / 2;
        } else if (rarspin == 3 || rarspin == "Rare") {
            time = timedef / 4;
        } else if (rarspin == 4 || rarspin == "Epic") {
            time = timedef / 8;
        } else if (rarspin == 5 || rarspin == "Legend") {
            time = timedef / 16;
        }
    } else if (rarburn == "Uncommon") {
        if (rarspin == 1 || rarspin == "Common") {
            time = timedef * 2;
        } else if (rarspin == 2 || rarspin == "Uncommon") {
            time = timedef;
        } else if (rarspin == 3 || rarspin == "Rare") {
            time = timedef / 2;
        } else if (rarspin == 4 || rarspin == "Epic") {
            time = timedef / 4;
        } else if (rarspin == 5 || rarspin == "Legend") {
            time = timedef / 8;
        }
    } else if (rarburn == "Rare") {
        if (rarspin == 1 || rarspin == "Common") {
            time = timedef * 4;
        } else if (rarspin == 2 || rarspin == "Uncommon") {
            time = timedef * 2;
        } else if (rarspin == 3 || rarspin == "Rare") {
            time = timedef;
        } else if (rarspin == 4 || rarspin == "Epic") {
            time = timedef / 2;
        } else if (rarspin == 5 || rarspin == "Legend") {
            time = timedef / 4;
        }
    } else if (rarburn == "Epic") {
        if (rarspin == 1 || rarspin == "Common") {
            time = timedef * 8;
        } else if (rarspin == 2 || rarspin == "Uncommon") {
            time = timedef * 4;
        } else if (rarspin == 3 || rarspin == "Rare") {
            time = timedef * 2;
        } else if (rarspin == 4 || rarspin == "Epic") {
            time = timedef;
        } else if (rarspin == 5 || rarspin == "Legend") {
            time = timedef / 2;
        }
    } else if (rarburn == "Legend") {
        if (rarspin == 1 || rarspin == "Common") {
            time = timedef * 16;
        } else if (rarspin == 2 || rarspin == "Uncommon") {
            time = timedef * 8;
        } else if (rarspin == 3 || rarspin == "Rare") {
            time = timedef * 4;
        } else if (rarspin == 4 || rarspin == "Epic") {
            time = timedef * 2;
        } else if (rarspin == 5 || rarspin == "Legend") {
            time = timedef;
        }
    }

    return time;
}