import { useEffect, useState } from 'react';
import { arrRpcAt, savedOptionAt } from './../Wax/Variables';
const { ExplorerApi, RpcApi } = require("atomicassets");
//const fetch = require("node-fetch");

var numRpc = 0;



const useGetAllNftSpinnerOnWallet = (accountName, refreshKey1) => {
    //console.log(accountName, refreshKey1);
    const [allSpinnerGet, setallSpinner] = useState({});
    const [hasError, setHasError] = useState(false);
    const [errorText, setErrorText] = useState('');

    //console.log("accountName", accountName);

    const getSpinnerOnWallet = async (page = 1, limit = 1000) => {
        var link = !savedOptionAt || savedOptionAt === "dynamic" ? arrRpcAt[numRpc] : savedOptionAt;
        try {
            const fetchAssets = async (page) => {

                const api = new ExplorerApi(
                    //"https://" + "wax.api.atomicassets.io",
                    "https://" + link,
                    //"https://wax-atomic.eosiomadrid.io",
                    "atomicassets",
                    { fetch, rateLimit: 4 }
                );

                console.log("link", link)

                const params = {
                    limit: limit,
                    page: page,
                    owner: accountName,
                    collection_name: process.env.REACT_APP_COLLECTION,
                    //schema_name: ["arms.worlds", "crew.worlds"],
                };



                const assets = await api.getAssets(params);

                if (assets && assets.length) {
                    //console.log("assets", assets);
                    const filteredAssets = assets
                        // .filter((item) => {
                        //     return caseSpinerTmpl(Number(item.template.template_id)); // caseSpinerTmpl должна быть определена
                        // })
                        .map((item) => {
                            return {
                                asset_id: item.asset_id,
                                schema: {
                                    schema_name: item.schema.schema_name
                                },
                                template: {
                                    template_id: item.template.template_id,
                                },
                                data: {
                                    rarity: item.data.Rarity,
                                    img: item.data.img,
                                    name: item.data.name,
                                },
                            };
                        });
                    //console.log("filteredAssets", filteredAssets);



                    return filteredAssets;
                }

                return [];
            };

            const allAssets = [];
            let currentPage = page;
            let hasMorePages = true;

            while (hasMorePages) {
                const assets = await fetchAssets(currentPage);

                //console.log("assets", assets)

                if (assets.length === 0) {
                    hasMorePages = false;
                } else {
                    allAssets.push(...assets);
                    if (assets.length === limit) {
                        currentPage += 1;
                    } else {
                        hasMorePages = false;
                    }
                }
            }

            let groupedAssets = {};
            allAssets.forEach((asset) => {
                const { schema_name } = asset.schema;
                if (!groupedAssets[schema_name]) {
                    groupedAssets[schema_name] = [];
                }
                groupedAssets[schema_name].push(asset);
            });
            //console.log("groupedAssets", groupedAssets);

            setallSpinner(groupedAssets);
            // this.GetAlienListOnGame(); // Вызовите эту функцию, если она определена
        } catch (e) {
            ////console.warn(e);
            //setHasError(true);
            //setErrorText(e.message);
            setTimeout(() => {

                if (numRpc == 8) {
                    numRpc = 0;
                } else {
                    getSpinnerOnWallet();
                    numRpc++;
                }
            }, 1000);
            console.log("\nCaught exception: " + e);
        }
    }

    useEffect(() => {
        if (accountName) {
            getSpinnerOnWallet();
        }
    }, [accountName, refreshKey1]);

    // Верните JSX или null, если вы не хотите отображать ничего в компоненте
    return { allSpinnerGet };

}

export default useGetAllNftSpinnerOnWallet;
