import React, { useContext, useState, useEffect, useRef } from "react";
import "../Partners/PartnersPage.css";
import "../Partners/ProvideLiquidity.css";
import { UALContext } from "ual-reactjs-renderer";
import { ToastContainer, toast } from "react-toastify";
import ModalLiquidity from "../Modal/ModalLiquidity";
import useGetSpnWax from "../../components/Wax/useGetSpnWax";
import useGetRateList from "../../components/Wax/useGetRateList";
import useGetStakeList from "../../components/Wax/useGetStakeList";
import { addliquidity, remliquidity } from "../../components/Wax/Transactions";
import Countdown from "react-countdown";

const formatTime = (time) => (time < 10 ? `0${time}` : time);

const ProvideLiquidity = ({ userInGameState, balanceDisp }) => {
  const [modal26Active, setModal26Active] = useState(false);

  const notify = (e) => toast(e);
  const ual = useContext(UALContext);
  const [refreshKey1, setRefreshKey1] = useState(0);
  const [isTimerActive, setIsTimerActive] = useState(true);
  const [localListStake, setLocalListStake] = useState(null);
  const [localListRate, setLocalListRate] = useState(null);
  const [localListRateGroup, setLocalListRateGroup] = useState(null);
  const [timer, setTimer] = useState(false);

  const [tacoInfo, setTacoInfo] = useState(null);
  const [formData, setFormData] = useState({
    wax: 0,
    spn: 0,
  });

  let { tacoCurr } = useGetSpnWax(userInGameState?.user);
  let { allStakeRate } = useGetRateList(userInGameState?.user);
  let { allStakeList } = useGetStakeList(userInGameState?.user);

  useEffect(() => {
    if (allStakeRate && allStakeRate.length && localListRateGroup == null) {
      //console.log("allStakeRAte", allStakeRate);
      function compareRarity(a, b) {
        return b.rarity - a.rarity;
      }

      let groupedAssets = {};
      allStakeRate.forEach((asset) => {
        const tmpl = asset.tmpl;
        if (!groupedAssets[tmpl]) {
          groupedAssets[tmpl] = [];
        }
        groupedAssets[tmpl].push(asset);
      });

      const sortedKeys = Object.keys(groupedAssets).sort((a, b) => {
        const idnumA = groupedAssets[a][0]?.idnum || 0;
        const idnumB = groupedAssets[b][0]?.idnum || 0;
        return idnumA - idnumB;
      });

      // Создаем новый объект с отсортированными ключами
      const sortedObj = [];
      sortedKeys.forEach((key) => {
        sortedObj.push(groupedAssets[key]);
      });

      setLocalListRate(allStakeRate);
      setLocalListRateGroup(sortedObj);
    }
  }, [allStakeRate]);

  useEffect(() => {
    if (allStakeList && allStakeList.length && localListStake == null) {
      setLocalListStake(allStakeList);
    }
  }, [allStakeList]);

  useEffect(() => {
    if (tacoCurr && tacoCurr.id !== undefined) {
      //setSpnCurr(tacoCurr);
      console.log("tacoCurr", tacoCurr);

      let spn = parseInt(tacoCurr.pool1.quantity.replace(/\D/g, "")) * 10000;
      let wax = parseInt(tacoCurr.pool2.quantity.replace(/\D/g, ""));

      function truncateToDecimalPlace(num, decimalPlaces) {
        const power = Math.pow(10, decimalPlaces);
        const truncatedNum = Math.floor(num * power) / power;
        return truncatedNum.toFixed(decimalPlaces);
      }

      setTacoInfo({
        spnLiq: parseFloat(spn),
        waxLiq: parseFloat(wax),
        spn: parseFloat(spn / wax),
        wax: parseFloat(wax / spn),
      });
    }
  }, [tacoCurr]);

  const getDuration = (idnum) => {
    //console.log("localListRate", localListRate)
    if (localListRate && localListRate.length) {
      let dur = localListRate.find((item) => item.idnum == idnum);
      //console.log("dur.namerate", dur.namerate)
      return {
        tmpl: dur.tmpl,
        duration: dur.duration / 86400,
        names: dur.namerate,
      };
    }
  };

  const refreshAssets = () => {
    setRefreshKey1((prevKey) => prevKey + 1);
  };

  const truncateToDecimalPlace = (num, decimalPlaces) => {
    const power = Math.pow(10, decimalPlaces);
    const truncatedNum = Math.floor(num * power) / power;
    return truncatedNum.toFixed(decimalPlaces);
  };

  const soldSpn = (totalVolume, type) => {
    console.log("totalVolume", totalVolume);
    function roundUp(num, precision) {
      let multiplier = Math.pow(10, precision);
      return Math.ceil(num * multiplier) / multiplier;
    }

    function calculateCorrectedQuantityAndPricePerUnit(tacoInfo, totalVolume) {
      const correctedQuantity = totalVolume / tacoInfo[type];
      const roundedCorrectedQuantity = truncateToDecimalPlace(
        correctedQuantity,
        8
      );
      return roundedCorrectedQuantity;
    }

    const result = calculateCorrectedQuantityAndPricePerUnit(
      tacoInfo,
      totalVolume
    );
    return result;
  };

  const inputs = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      [name == "wax" ? "spn" : "wax"]: soldSpn(value, "spn"),
    }));
  };

  const curChanger = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      [name == "wax" ? "spn" : "wax"]: soldSpn(value, "spn"),
    }));
    return {
      [name]: value,
      [name == "wax" ? "spn" : "wax"]: soldSpn(value, "spn"),
    };
  };

  const getActiveLiq = (spnwax) => {
    let remLiqSpn = 0;
    let remLiqWax = 0;
    if (tacoCurr && tacoCurr.supply !== undefined) {
      var AllSpnWax = parseFloat(tacoCurr.supply.split(" ")); //28293589.1261;
      var lqWax = tacoInfo.waxLiq / 100000000; //230716.90122675 //lq-wax
      var lqSpn = tacoInfo.spnLiq / 100000000; //353810.7447 //lq-spn
      remLiqSpn = (parseFloat(lqSpn) * parseFloat(spnwax / 10000)) / AllSpnWax;
      remLiqWax = (parseFloat(lqWax) * parseFloat(spnwax / 10000)) / AllSpnWax;
    }
    return {
      remLiqSpn: remLiqSpn,
      remLiqWax: remLiqWax,
    };
  };

  const addliquidityTrans = async (nameCurr, valueCurr, idnum) => {
    //accountName, wax, spn, spnwax
    try {
      const { activeUser } = ual;
      const { accountName } = activeUser;

      //setLodaingPage(true);

      //console.log("tacoInfo", tacoInfo)
      //console.log("formData", formData)

      var formData = curChanger(nameCurr, valueCurr);
      //console.log("formData", formData)

      // spnLiq: spn,
      // waxLiq: wax,
      // spn: spn / wax,
      // wax: wax / spn

      var AllSpnWax = parseFloat(tacoCurr.supply.split(" ")); //28293589.1261;
      var lqWax = tacoInfo.waxLiq / 100000000; //230716.90122675 //lq-wax
      var lqSpn = tacoInfo.spnLiq / 100000000; //353810.7447 //lq-spn
      var a = (formData.spn * parseFloat(AllSpnWax)) / lqSpn; // 879.6496011984029
      //console.log("a", a)
      var b = (formData.wax * parseFloat(AllSpnWax)) / parseFloat(lqWax);
      //console.log("b", b)
      var res = 10000 * (a > b ? b : a);
      //console.log("res", res)
      var res2 = res - (100 * res + 9999) / 10000;
      //console.log("res2", res2);
      //console.log("must be", "79.1465");

      var spnwax = 0 === res ? 0 : res2 / 10000;
      // console.log("spnwax", spnwax)

      // console.log("lqSpn", lqSpn);
      // console.log("lqWax", lqWax);
      // console.log("AllSpnWax", AllSpnWax);

      var remLiqSpn = (parseFloat(lqSpn) * parseFloat(7.2026)) / AllSpnWax;
      var remLiqWax = (parseFloat(lqWax) * parseFloat(7.2026)) / AllSpnWax;

      // console.log("from1", remLiqSpn)
      // console.log("to1", remLiqWax)

      // console.log("from", remLiqSpn * 100 / 100)
      // console.log("to", remLiqWax * 100 / 100)

      // console.log("test")

      // console.log("remLiqSpn", remLiqSpn);
      // console.log("remLiqWax", remLiqWax);

      //console.log("new - old", news - old)
      let wax = parseFloat(formData.wax);
      let spn = parseFloat(formData.spn);
      //let spnwax = truncateToDecimalPlace((Math.sqrt((wax * 0.99) * (spn * 0.99)) * 100), 8);

      // console.log("userInGameState", userInGameState);
      // console.log("spnwax", spnwax)

      //const transactionPayload = addliquidity(userInGameState.user, truncateToDecimalPlace(wax, 8), truncateToDecimalPlace(spn, 4), spnwax.toFixed(5));
      const transactionPayload = addliquidity(
        userInGameState.user,
        wax.toFixed(8),
        spn.toFixed(4),
        spnwax.toFixed(4),
        idnum
      );
      //console.log("transactionPayload", transactionPayload);

      const transaction = await activeUser.signTransaction(transactionPayload, {
        blocksBehind: 3,
        expireSeconds: 30,
      });

      toast("Transaction ID: " + transaction?.transactionId);

      let updatedLocalListStake = [];
      if (localListStake !== null && localListStake !== undefined) {
        updatedLocalListStake = [...localListStake];
      }
      let findTime = localListRate.find((item) => item.idnum == idnum);
      let objAdd = {
        user: userInGameState.user,
        enddate: parseInt(Date.now() / 1000) + findTime.duration,
        idnum: idnum,
        pricespnwax: parseFloat(spnwax * 10000),
      };
      updatedLocalListStake.push(objAdd);

      setLocalListStake(updatedLocalListStake);
    } catch (e) {
      toast(e.toString(), {
        progressClassName: "error-progress",
      });
      console.log(e);
    }
  };

  const remliquidityTrans = async (enddate) => {
    //accountName, wax, spn, spnwax
    try {
      const { activeUser } = ual;
      const { accountName } = activeUser;

      const transactionPayload = remliquidity(userInGameState.user, enddate);
      console.log("transactionPayload", transactionPayload);

      const transaction = await activeUser.signTransaction(transactionPayload, {
        blocksBehind: 3,
        expireSeconds: 30,
      });

      let updatedLocalListStake = [...localListStake];
      let spinnerIndex = localListStake.findIndex(
        (item) => item.enddate == enddate
      );
      updatedLocalListStake.splice(spinnerIndex, 1);

      balanceDisp(
        true,
        getActiveLiq(localListStake[spinnerIndex].pricespnwax).remLiqSpn
      );
      setLocalListStake(updatedLocalListStake);

      toast("Transaction ID: " + transaction?.transactionId);
    } catch (e) {
      toast(e.toString(), {
        progressClassName: "error-progress",
      });
      console.log(e);
    }
  };

  const openModalLiquidity = (type) => {
    if (type === "open") {
      setModal26Active(true);
    }
  };

  return (
    <>
      <div id="Liquidity" className="Part_content ">
        <div className="topContentPartners">
          <div className="logoWaxSpn"></div>
          <div className="boxlw">
            <div className="pt titLeft">Provide liquidity for NFT rewards</div>
            <div
              onClick={() => openModalLiquidity("open")}
              className="pt btnRig"
            >
              Choose a staking plan
            </div>
          </div>
        </div>

        <div className="centerContentPartners liqWrap">
          {localListStake &&
            localListRate &&
            localListRate.length &&
            localListStake.map((item) => {
              return (
                <div className="cardPartFirstWrap">
                  <div className="cardPartFirst">
                    <div className="bodyCardFirst">
                      <div
                        className="boxIcCards violetta__one__image"
                        style={{
                          backgroundImage: `url(/img/tmpl/${
                            getDuration(item.idnum).tmpl
                          }.webp)`,
                        }}
                      ></div>
                      <div className={`lines`}>
                        <div className="left l1">
                          <span></span>
                        </div>
                        <div className="left l2">
                          <span></span>
                        </div>
                        <div className="left l3">
                          <span></span>
                        </div>
                        <div className="left l4">
                          <span></span>
                        </div>
                        <div className="left l5">
                          <span></span>
                        </div>

                        <div className="right l6">
                          <span></span>
                        </div>
                        <div className="right l7">
                          <span></span>
                        </div>
                        <div className="right l8">
                          <span></span>
                        </div>
                        <div className="right l9">
                          <span></span>
                        </div>
                      </div>
                      {/* <div className="fonBlCircl"> */}
                      <div className="fonViolCircl"></div>
                    </div>
                    <div className="bottomCardFirst">
                      <div className="topC">
                        <div className="userPart">
                          <div className="usNameWax">
                            {getDuration(item.idnum).names.split(" ")[0]}
                          </div>
                          <div className="usIdWax">
                            {parseInt(getDuration(item.idnum)?.duration)} Days
                            Plan
                          </div>
                        </div>
                        {/* btn */}
                        {parseInt(item.enddate - Date.now() / 1000) > 0 ? (
                          <div className="clBtn">
                            {/* <Countdown
                                                        daysInHours
                                                        date={item.enddate * 1000}
                                                        onComplete={(e) => {
                                                            //setTimer(false)
                                                        }}

                                                    /> */}

                            <Countdown
                              daysInHours
                              date={item.enddate * 1000}
                              onComplete={(e) => {
                                setTimer(false);
                              }}
                              renderer={({
                                days,
                                hours,
                                minutes,
                                seconds,
                                completed,
                              }) => {
                                if (completed) {
                                  return <span>00:00:00</span>;
                                } else {
                                  return (
                                    <>
                                      {days + ":"}
                                      {formatTime(hours)}:{formatTime(minutes)}:
                                      {formatTime(seconds)}
                                    </>
                                  );
                                }
                              }}
                            />
                          </div>
                        ) : (
                          <div
                            id="activeBg"
                            className="clBtn bt-only"
                            onClick={() => remliquidityTrans(item.enddate)}
                          >
                            Claim
                          </div>
                        )}

                        {/* <div id='activeBg' className="clBtn">Claim</div> */}

                        {/*  */}
                      </div>
                      <div className="botC">
                        <div className="BoxTokens">
                          <div className="pt">Your locked tokens</div>
                          <div className="pt">
                            <div className="iconWp"></div>
                            {getActiveLiq(item.pricespnwax).remLiqWax.toFixed(
                              4
                            )}
                          </div>
                          <div className="pt">
                            <div className="iconSp"></div>
                            {getActiveLiq(item.pricespnwax).remLiqSpn.toFixed(
                              4
                            )}
                          </div>
                        </div>
                        <div className="boxDays">
                          <div className="pt">Days left:</div>
                          <div className="pt">
                            {parseInt(item.enddate - Date.now() / 1000) > 86400
                              ? parseInt(
                                  (item.enddate - Date.now() / 1000) / 86400
                                )
                              : 0}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          {/* id='activeBg' */}
          {/* .fonGrTop   .fonViolTop   .fonBlTop */}
          {/* .fonGrCircl .fonViolCircl .fonBlCircl */}
          {/* card */}
        </div>
      </div>
      {modal26Active ? (
        <ModalLiquidity
          active={modal26Active}
          setActive={setModal26Active}
          openModalLiquidity={openModalLiquidity}
          localListRateGroup={localListRateGroup}
          soldSpn={soldSpn}
          addliquidityTrans={addliquidityTrans}
        ></ModalLiquidity>
      ) : null}
    </>
  );
};

export default ProvideLiquidity;
