import React, { useContext, useState, useEffect } from 'react';
import '../RefPage/RefPage.css';
import { useRef } from 'react';
import { UALContext } from 'ual-reactjs-renderer';
import { ToastContainer, toast } from 'react-toastify';
import {
    Link,
    useParams,
} from "react-router-dom";
import { gettopfprise } from '../Wax/Transactions';

const LeaderbordVolume = ({ userInGameState, resDisp, allTopLocal, userInfoTop, setUserInfoTop }) => {

    const notify = (e) => toast(e);
    const ual = useContext(UALContext);

    const [allUsers, setAllUsers] = useState(null);
    const [place, setPlace] = useState(0);



    // useEffect(() => {
    //     if (allTopLocal && allTopLocal.length) {
    //         let spinnerIndex = allTopLocal.findIndex(user => user.user == userInGameState.user);
    //         setPlace(spinnerIndex + 1);
    //     }

    // }, [allTopLocal]);
    const getAllSales = (page = 1, allData = []) => {
        const url = `https://wax.api.atomicassets.io/atomicmarket/v2/sales?state=3&collection_name=spinniaworld&after=1701270000000&before=1702220400000&page=${page}&limit=100&order=desc&sort=updated`;

        return fetch(url)
            .then((response) => response.json())
            .then((data) => {
                console.log("Data from page", page, data);

                // Добавляем полученные данные к общему массиву
                if (data.data.length === 0) {
                    return allData;
                }

                // Добавляем полученные данные к общему массиву
                allData = allData.concat(data.data);

                // Вызываем getAll для следующей страницы
                return getAllSales(page + 1, allData);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const getAllOffers = (page = 1, allData = []) => {
        const url = `https://wax.api.atomicassets.io/atomicmarket/v1/buyoffers?state=3&collection_name=spinniaworld&after=1701270000000&before=1702220400000&page=${page}&limit=100&order=desc&sort=updated`;

        return fetch(url)
            .then((response) => response.json())
            .then((data) => {
                console.log("Data from page", page, data);

                // Добавляем полученные данные к общему массиву
                if (data.data.length === 0) {
                    return allData;
                }

                // Добавляем полученные данные к общему массиву
                allData = allData.concat(data.data);

                // Вызываем getAll для следующей страницы
                return getAllOffers(page + 1, allData);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const processData = (data) => {
        const userPurchases = {};

        data.forEach(item => {
            const buyer = item.buyer;
            const price = parseInt(item.price.amount);

            if (buyer == "wun4a.c.wam") {
                console.log("item", item)
            }

            if (userPurchases[buyer]) {
                userPurchases[buyer] += price;
            } else {
                userPurchases[buyer] = price;
            }
        });

        const sortedPurchases = Object.entries(userPurchases)
            .map(([buyer, total]) => ({ buyer, total }))
            .sort((a, b) => b.total - a.total);

        return sortedPurchases;
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [sellData, offerData] = await Promise.all([
                    getAllSales(),
                    getAllOffers(),
                ]);

                const allData = [...sellData, ...offerData]

                const userPurchases = processData(allData);
                setAllUsers(userPurchases);

                console.log("sellData", sellData);
                console.log("offerData", offerData);
                console.log("allData", allData);

                // Обработка данных sellData и offerData
            } catch (error) {
                // Обработка ошибок
                console.error('Ошибка при получении данных:', error);
            }
        };

        // Вызов асинхронной функции
        fetchData();
        // getAll()
        //     .then((allData) => {
        //         console.log("allData", allData)
        //         const userPurchases = processData(allData);
        //         setAllUsers(userPurchases);
        //         console.log("Purchases by user:", userPurchases);
        //     })
        //     .catch(error => {
        //         console.error("Ошибка при получении шаблонов: ", error);
        //     });
    }, []);







    return (

        <div id='Leaderbord' className="leaderboard-wrapper">
            <div className="Leaderbord">
                <div className="blockTopLeaderbord">
                </div>
                <div className="boxTopBord">
                    <div className="titleLeaderbordVolume"> </div>
                    <div className="boxInfoLeaderbord">
                        <div className="infoLeaderbord">
                            The top 20 positions by volume of Spinnia World collection purchases on <Link className='linkLeader' target="_blank" to="https://wax.atomichub.io/market?collection_name=spinniaworld&order=desc&primary_chain=wax-mainnet&sort=created&symbol=WAX">AtomicHub</Link> between 11/29/2023 and 12/10/2023 will be awarded.
                        </div>
                    </div>


                    <div className="bgBlock"></div>
                    <div className="infoTopContent">
                        <div className="infoNumb">№</div>
                        <div className="infoWallet">Wallet</div>
                        <div className="infoReward">Reward</div>
                        <div className="infoPoints">Volume</div>
                    </div>

                </div>
                <div className="wrapperContentLeader">


                    <div className="contentLeaderbord ">
                        {/* winnerLoaderBg класс добавляем к boxLeaderbord только для своего ака в списке   */}
                        {allUsers && allUsers.length ?
                            allUsers.map((user, index) => {

                                return (
                                    <div key={index} className={`boxLeaderbord ${user.buyer == userInGameState?.user ? "winnerLoaderBg" : ""}`}>
                                        {/* <div key={index} className={`boxLeaderbord ${index < 30 ? "winnerLoaderBg" : 0}`}> */}
                                        <div className="boxNumdLeader">{index + 1}</div>
                                        <div className="walletNameWax">{user.buyer}</div>

                                        {/*  класс для изображения приза бокса . место 7-9 winnerBoxStarter /место 4-6 winnerBoxMystery /  место 1-3 winnerBoxInfinity или тикет 10-20 winnerIconTicket*/}
                                        {index >= 0 && index <= 2 ? (
                                            <div className="winnerBoxInfinity"></div>
                                        ) : index >= 3 && index <= 5 ? (
                                            <div className="winnerBoxMystery"></div>
                                        ) : index >= 6 && index <= 8 ? (
                                            <div className="winnerBoxStarter"></div>
                                        ) : index >= 9 && index <= 19 ? (
                                            <div className="winnerIconTicket">
                                                <div className="numWinTicket">x10</div>
                                            </div>
                                        ) : (
                                            <div className="winnerBoxInfinity" style={{ opacity: 0 }}></div>
                                        )}
                                        <div className="borderBox"></div>

                                        <div className="numberLeader  volNumb">{(user.total / 100000000).toFixed(0)}</div>
                                        <div className="buttonRp">WAX</div>
                                    </div>)
                            })
                            : null
                        }

                    </div>
                </div>
            </div>
        </div >








    );
};

export default LeaderbordVolume;