import React from 'react';
import '../Navcontent/Navcontent.css';
import { useState, useRef } from "react";
import ModalPremium from '../Modal/ModalPremium';
import { Link, NavLink } from 'react-router-dom';
import soundFile from '../../components/audio/Switch Plastic.mp3';
import ModalSpinAll from '../Modal/ModalSpinAll';




const Navcontent = (props) => {
  const [activeLink, setActiveLink] = useState(0);

  const handleMouseEnter = (linkNumber) => {
    setActiveLink(linkNumber);
  }

  const handleMouseLeave = () => {
    setActiveLink(0);
  }

  // 
  return (
    <>
      <div className="nav__content">
        {/* <div className="navWinter"></div> */}
        {/* <div className="navWinter1"></div>
        <div className="navWinter2"></div>
        <div className="navWinter3"></div> */}
       
        <div className="ProfileMultiplier">
          <div className="title">Profile multiplier</div>
          <Link to='/profillevelpage' className="boxStateMult ">
            <div className="nambTicket">{props.userInGameState && props.userInGameState.mult_bonus !== undefined ? parseFloat(props.userInGameState.mult_bonus, 2) : 0}</div>
            <div className='iconStateMult'></div>
          </Link>
        </div>
        <button
          className={`nav__content__link2 ${activeLink === 2 ? "active__link" : ""}`}
          onMouseEnter={() => handleMouseEnter(2)}
          onMouseLeave={handleMouseLeave}
          onClick={() => setActiveLink(2) || props.handleModalActivation(8)}
        >

        </button>
        <button
          className={`nav__content__link1 ${activeLink === 1 ? "active__link" : ""}`}
          onMouseEnter={() => handleMouseEnter(1)}
          onMouseLeave={handleMouseLeave}
          onClick={() => setActiveLink(1) || props.handleModalActivation(1)}
        >

        </button>
        <button
          className={`nav__content__link3 ${activeLink === 3 ? "active__link" : ""}`}
          onMouseEnter={() => handleMouseEnter(3)}
          onMouseLeave={handleMouseLeave}
          onClick={() => setActiveLink(3) || props.handleModalActivation(7)}
        >

        </button>
        <div className="ReferralMultiplier">
        {/* <div className="navWinter4"></div> */}
          <NavLink to='/refpage' className="boxStateMult ">
         
            <div className="nambTicket">{props.userInGameState && props.userInGameState.ref_bonus !== undefined ? parseFloat(props.userInGameState.ref_bonus, 2) : 0}</div>
            <div className='iconStateRef'></div>
          </NavLink>
        
          <div className="title">Referral multiplier</div>
          <div onClick={() => props.spinAllOpenModal()} className="spinAll"><button
          >Spin All</button> 
          
          </div>
        </div>


      </div>

    </>
  );
}

export default Navcontent;