import React, { useState, useEffect } from 'react';
import '../RefPage/RefPage.css';
import '../RegisteredList/RegisteredList.css';
import '../WinnersList/WinnersList.css';
import useGetAllReferrals from '../Wax/useGetAllReferrals';
import {
    Link,
    useParams,
} from "react-router-dom";


const WinnersList = ({ userInGameState,props }) => {

    const iconData = [
        {
            id: 0,
            images: 'common__1',
        },
        {
            id: 1,
            images: 'uncommon__2',
        },
        {
            id: 2,
            images: 'frosty__3',
        },
        {
            id: 3,
            images: 'violett__4',
        },
        {
            id: 4,
            images: 'legend__5',
        }
    ];
    const [isCopied, setIsCopied] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [allRefferalsLocal, setAllRefferals] = useState(null);

    const { allReferralsGet } = useGetAllReferrals(userInGameState?.user);

    useEffect(() => {
        setAllRefferals(allReferralsGet);
        console.log("allReferralsGet", allReferralsGet);
    }, [allReferralsGet]);

    console.log("allRefferalsLocal", allRefferalsLocal)

    const handleNextClick = () => {
        if (currentPage < 2) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevClick = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

 
    const totalBlocks = 10;
    let allRefCount = 0;

    const renderPageContent = () => {
        if (currentPage === 1) {
            return (
                <>
                

                    {allRefferalsLocal && allRefferalsLocal.length ? allRefferalsLocal.map((user, index) => {
                        allRefCount += user.count > 0 ? 1 : 0;
                        const icon = iconData[index % iconData.length];
                        return (
                            <div key={index} id={`WaxRef${index}`} className={`block__ref winner${index === currentIndex ? '' : 'hidden'}`}>
                                <div id='WaxRef' className='block__ref'>
                                    <div className='title__link'>{user}</div>
                                    <div className='blockPoints'>
                                        <div className='title__link'>111232222221{user}</div>
                                        <div className={`${icon.images} iconPlayerInList`}></div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    ) : null}
               
                </>
            );
        }

    };
    return (
        <div id='BoUp' className='refPage ContentLists '>
            <div className="blockTopLotery">
                <div className="RefMenu">Winners list</div>
                <Link className='Back' to="/games">
                    <div className="iconBack"></div>
                    <div className='titleBack'>Back</div>
                </Link>
            </div>
            <div className='ref__content WinnerList '>

                <div className="ref__info RegisteredList">
                    <div className="block__ref bottom__width">
                        <div className="refCount BoostUpIcon"></div>
                        <div id='count' className="block__ref2">
                            <div className="title__link centerLink">Offer Tag Winners list</div>
                        </div>
                    </div>
                </div>

                {renderPageContent()}


            </div>
            {allRefferalsLocal && allRefferalsLocal.length > 10 ?
                <div className='bottomRef'>
                    <button className='refbtn' onClick={handlePrevClick}></button>
                    <button className='refbtn refRight' onClick={handleNextClick}></button>

                </div>
                : null}
        </div>

    );
};

export default WinnersList;