import React, { useContext, useState, useEffect, useRef } from 'react';
import './Taco.css'
//import useGetBonusUser from '../Wax/useGetBonusUser';
//import useGetJackpot from '../Wax/useGetJackpot';
//import useGetJackpotWinnersList from '../Wax/useGetJackpotWinnersList';
import { UALContext } from 'ual-reactjs-renderer';
import { ToastContainer, toast } from 'react-toastify';
import { addliquidity, remliquidity } from '../../components/Wax/Transactions';
import Countdown from 'react-countdown';
import Preloader from '../Preloader/Preloader';
import ModalJackpot from '../Modal/ModalJackpot';
import useGetSpnWax from '../../components/Wax/useGetSpnWax';
import useGetRateList from '../../components/Wax/useGetRateList';
import useGetStakeList from '../../components/Wax/useGetStakeList';

const Taco = ({ userInGameState, canvasRef }) => {

    const notify = (e) => toast(e);
    const ual = useContext(UALContext);
    const [refreshKey1, setRefreshKey1] = useState(0);
    const [isTimerActive, setIsTimerActive] = useState(true);
    const [localListStake, setLocalListStake] = useState(null);
    const [localListRate, setLocalListRate] = useState(null);

    const [tacoInfo, setTacoInfo] = useState(null);
    const [formData, setFormData] = useState({
        wax: 0,
        spn: 0
    });

    let { tacoCurr } = useGetSpnWax(userInGameState?.user);
    let { allStakeRate } = useGetRateList(userInGameState?.user);
    let { allStakeList } = useGetStakeList(userInGameState?.user);

    useEffect(() => {
        if (allStakeRate && allStakeRate.length && localListRate == null) {
            setLocalListRate(allStakeRate);
        }
    }, [allStakeRate]);

    useEffect(() => {
        if (allStakeList && allStakeList.length && localListStake == null) {
            setLocalListStake(allStakeList);
        }
    }, [allStakeList]);

    useEffect(() => {
        if (tacoCurr && tacoCurr.id !== undefined) {
            //setSpnCurr(tacoCurr);
            console.log("tacoCurr", tacoCurr)

            let spn = parseInt(tacoCurr.pool1.quantity.replace(/\D/g, '')) * 10000;
            let wax = parseInt(tacoCurr.pool2.quantity.replace(/\D/g, ''));

            function truncateToDecimalPlace(num, decimalPlaces) {
                const power = Math.pow(10, decimalPlaces);
                const truncatedNum = Math.floor(num * power) / power;
                return truncatedNum.toFixed(decimalPlaces);
            }

            setTacoInfo({
                spnLiq: parseFloat(spn),
                waxLiq: parseFloat(wax),
                spn: parseFloat(spn / wax),
                wax: parseFloat(wax / spn)
            })
        }
    }, [tacoCurr]);

    const refreshAssets = () => {
        setRefreshKey1(prevKey => prevKey + 1);
    };

    const truncateToDecimalPlace = (num, decimalPlaces) => {
        const power = Math.pow(10, decimalPlaces);
        const truncatedNum = Math.floor(num * power) / power;
        return truncatedNum.toFixed(decimalPlaces);
    }

    const soldSpn = (totalVolume, type) => {
        function roundUp(num, precision) {
            let multiplier = Math.pow(10, precision);
            return Math.ceil(num * multiplier) / multiplier;
        }

        function calculateCorrectedQuantityAndPricePerUnit(tacoInfo, totalVolume) {
            const correctedQuantity = totalVolume / tacoInfo[type];
            const roundedCorrectedQuantity = truncateToDecimalPlace(correctedQuantity, 8);

            // Расчет цены за единицу с учетом скорректированного количества
            // const correctedPricePerUnit = totalVolume * tacoInfo[type];
            // const roundedCorrectedPricePerUnit = truncateToDecimalPlace(correctedPricePerUnit, 8);
            return roundedCorrectedQuantity;
            // return {
            //     correctedQuantityWax: roundedCorrectedQuantity,
            //     correctedQuantitySPN: roundedCorrectedPricePerUnit
            // };
        }

        const result = calculateCorrectedQuantityAndPricePerUnit(tacoInfo, totalVolume);
        return result;
    }

    const inputs = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
            [name == "wax" ? "spn" : "wax"]: soldSpn(value, "spn")
        }));
    };

    const curChanger = (name, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
            [name == "wax" ? "spn" : "wax"]: soldSpn(value, "spn")
        }));
        return {
            [name]: value,
            [name == "wax" ? "spn" : "wax"]: soldSpn(value, "spn")
        };
    };

    const addliquidityTrans = async (nameCurr, valueCurr, idnum) => {

        //accountName, wax, spn, spnwax
        try {
            const { activeUser } = ual;
            const { accountName } = activeUser;
            //setLodaingPage(true);

            console.log("tacoInfo", tacoInfo)
            console.log("formData", formData)

            var formData = curChanger(nameCurr, valueCurr);
            console.log("formData", formData)

            // spnLiq: spn,
            // waxLiq: wax,
            // spn: spn / wax,
            // wax: wax / spn

            var AllSpnWax = parseFloat(tacoCurr.supply.split(" ")) //28293589.1261;
            var lqWax = tacoInfo.waxLiq / 100000000//230716.90122675 //lq-wax
            var lqSpn = tacoInfo.spnLiq / 100000000//353810.7447 //lq-spn
            var a = formData.spn * parseFloat(AllSpnWax) / lqSpn; // 879.6496011984029
            //console.log("a", a)
            var b = formData.wax * parseFloat(AllSpnWax) / parseFloat(lqWax)
            //console.log("b", b)
            var res = 10000 * (a > b ? b : a)
            //console.log("res", res)
            var res2 = res - (100 * res + 9999) / 10000
            //console.log("res2", res2);
            //console.log("must be", "79.1465");

            var spnwax = 0 === res ? 0 : res2 / 10000;
            console.log("spnwax", spnwax)

            console.log("lqSpn", lqSpn);
            console.log("lqWax", lqWax);
            console.log("AllSpnWax", AllSpnWax);

            var remLiqSpn = parseFloat(lqSpn) * parseFloat(7.2026) / AllSpnWax;
            var remLiqWax = parseFloat(lqWax) * parseFloat(7.2026) / AllSpnWax;

            console.log("from1", remLiqSpn)
            console.log("to1", remLiqWax)

            console.log("from", remLiqSpn * 100 / 100)
            console.log("to", remLiqWax * 100 / 100)

            console.log("test")

            console.log("remLiqSpn", remLiqSpn);
            console.log("remLiqWax", remLiqWax);


            //console.log("new - old", news - old)
            let wax = parseFloat(formData.wax);
            let spn = parseFloat(formData.spn);
            //let spnwax = truncateToDecimalPlace((Math.sqrt((wax * 0.99) * (spn * 0.99)) * 100), 8);

            // console.log("userInGameState", userInGameState);
            // console.log("spnwax", spnwax)

            //const transactionPayload = addliquidity(userInGameState.user, truncateToDecimalPlace(wax, 8), truncateToDecimalPlace(spn, 4), spnwax.toFixed(5));
            const transactionPayload = addliquidity(userInGameState.user, wax.toFixed(8), spn.toFixed(4), spnwax.toFixed(4), idnum);
            console.log("transactionPayload", transactionPayload);

            const transaction = await activeUser.signTransaction(transactionPayload, {
                blocksBehind: 3,
                expireSeconds: 30,
            });

            toast("Transaction ID: " + transaction?.transactionId);

        } catch (e) {
            toast(e.toString(), {
                progressClassName: 'error-progress',
            });
            console.log(e);
        }
    }

    const remliquidityTrans = async (enddate) => {

        //accountName, wax, spn, spnwax
        try {
            const { activeUser } = ual;
            const { accountName } = activeUser;

            const transactionPayload = remliquidity(userInGameState.user, enddate);
            console.log("transactionPayload", transactionPayload);

            const transaction = await activeUser.signTransaction(transactionPayload, {
                blocksBehind: 3,
                expireSeconds: 30,
            });

            toast("Transaction ID: " + transaction?.transactionId);

        } catch (e) {
            toast(e.toString(), {
                progressClassName: 'error-progress',
            });
            console.log(e);
        }
    }

    //const oneUserRef = useRef(oneUser);

    // useEffect(() => {
    //     oneUserRef.current = oneUser;
    // }, [oneUser]);


    return (
        <>

            <div className='jackpot-top' >
                <canvas className='fonWinterH' ref={canvasRef} />
                {/* {loadingPage ? <Preloader /> : null} */}




                <div className="jackpot-main">
                    <div className="centerBlockJack">
                        {!isTimerActive ?
                            <div className="circleFon"></div>
                            : null
                        }
                        <div className="topJTitle">
                            <div className="title-jackpot"></div>
                        </div>

                        <button className="btnNumberSpnTimer" onClick={() => addliquidityTrans("spn", formData.spn, 1)}>CLAIM</button>

                        <form >
                            <label>
                                wax:
                                <input type="number" name="wax" value={formData.wax} onChange={inputs} />
                            </label>
                            <label>
                                spm:
                                <input type="number" name="spn" value={formData.spn} onChange={inputs} />
                            </label>
                        </form>

                        <div className="ratelist">
                            <table style={{ color: "#fff" }}>
                                <tr>
                                    <td>ID</td>
                                    <td>NAME</td>
                                    <td>PRICE</td>
                                    <td>DURATION</td>
                                    <td>BUTTON</td>
                                </tr>

                                {localListRate ? localListRate.map((item) => {
                                    return (
                                        <tr>
                                            <td>{item.idnum}</td>
                                            <td>{item.namerate}</td>
                                            <td>{item.pricespn}</td>
                                            <td>{item.duration}</td>
                                            <td><button onClick={() => addliquidityTrans(
                                                item.pricespn !== 0 ? "spn" : "wax",
                                                item.pricespn !== 0 ? item.pricespn / 10000 : item.pricewax / 10000,
                                                item.idnum
                                            )
                                            }>SEND</button></td>
                                        </tr>
                                    )
                                }) : null}
                            </table>
                            <hr />
                            <hr />
                            <hr />
                            <hr />
                            <hr />
                            <table style={{ color: "#fff" }}>
                                <tr>
                                    <td>user</td>
                                    <td>data end</td>
                                    <td>ID num</td>
                                    <td>WAXSPN</td>
                                    <td>BUTTON</td>
                                </tr>
                                {localListStake ? localListStake.map((item) => {
                                    return (
                                        <tr>
                                            <td>{item.user}</td>
                                            <td>{item.enddate}</td>
                                            <td>{item.idnum}</td>
                                            <td>{item.pricespnwax}</td>
                                            <td><button onClick={() => remliquidityTrans(
                                                item.enddate
                                            )
                                            }>GET</button></td>
                                        </tr>
                                    )
                                }) : null}

                            </table>
                        </div>

                    </div>
                    {/* <button onClick={() => openModalJackpot("open")}> ModalJackpot</button> */}
                </div>

            </div>





        </>
    )
}


export default Taco;