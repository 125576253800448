import React, { useContext, useState, useEffect, useRef } from "react";
import "./Jackpot.css";
import useGetBonusUser from "../Wax/useGetBonusUser";
import useGetJackpot from "../Wax/useGetJackpot";
import useGetJackpotWinnersList from "../Wax/useGetJackpotWinnersList";
import { UALContext } from "ual-reactjs-renderer";
import { ToastContainer, toast } from "react-toastify";
import { getbonus } from "../../components/Wax/Transactions";
import Countdown from "react-countdown";
import Preloader from "../Preloader/Preloader";
import ModalJackpot from "../Modal/ModalJackpot";

const Jackpot = ({ userInGameState, canvasRef, props }) => {
  const openModalJackpot = (type) => {
    if (type === "open") {
      setModal20Active(true);
    }
  };

  const [modal20Active, setModal20Active] = useState(false);
  const notify = (e) => toast(e);
  const ual = useContext(UALContext);
  const [loadingPage, setLodaingPage] = useState(false);
  const [oneUserTop, setOneUserTop] = useState([]);
  const [jackpotlocal, setJackpotlocal] = useState([]);
  const [jackpotWinnersLocal, setJackpotWinnersLocal] = useState([]);
  const [refreshKey1, setRefreshKey1] = useState(0);
  const [isTimerActive, setIsTimerActive] = useState(true);

  const { oneUser } = useGetBonusUser(userInGameState?.user, refreshKey1);
  const { jackpot } = useGetJackpot(userInGameState?.user, refreshKey1);
  const { jackpotWinners } = useGetJackpotWinnersList(
    userInGameState?.user,
    refreshKey1
  );

  useEffect(() => {
    if (
      (oneUser && oneUser.length && !oneUserTop.length) ||
      oneUser?.timer !== oneUserTop?.timer
    ) {
      console.log("oneUser", oneUser);
      setOneUserTop(oneUser);
    }
  }, [oneUser]);

  useEffect(() => {
    if (jackpot && jackpot.length && !jackpotlocal.length) {
      setJackpotlocal(...jackpot);
    }
  }, [jackpot]);

  useEffect(() => {
    if (
      jackpotWinners &&
      jackpotWinners.length &&
      !jackpotWinnersLocal.length
    ) {
      setJackpotWinnersLocal(jackpotWinners);
    }
  }, [jackpotWinners]);

  const refreshAssets = () => {
    setRefreshKey1((prevKey) => prevKey + 1);
  };

  const getbonusTrans = async (user, prisecount) => {
    try {
      const { activeUser } = ual;
      const { accountName } = activeUser;
      //setLodaingPage(true);

      console.log("userInGameState", userInGameState);

      const transactionPayload = getbonus(userInGameState.user);
      console.log("transactionPayload", transactionPayload);

      const transaction = await activeUser.signTransaction(transactionPayload, {
        blocksBehind: 3,
        expireSeconds: 30,
      });

      toast("Transaction ID: " + transaction?.transactionId);

      setModal20Active(true);
      setLodaingPage(true);
      startPolling();
    } catch (e) {
      setModal20Active(false);
      setLodaingPage(false);
      toast(e.toString(), {
        progressClassName: "error-progress",
      });
      console.log(e);
    }
  };

  const oneUserRef = useRef(oneUser);

  useEffect(() => {
    oneUserRef.current = oneUser;
  }, [oneUser]);

  const startPolling = () => {
    const interval = setInterval(() => {
      // Предполагаем, что oneUser обновляется автоматически
      // (например, через WebSocket или другой механизм подписки)

      if (
        oneUserRef.current &&
        oneUserRef.current.timer !== undefined &&
        oneUserRef.current.timer &&
        oneUserRef.current.timer > Math.floor(Date.now() / 1000)
      ) {
        clearInterval(interval);
        setLodaingPage(false);
        setIsTimerActive(true);
        //setModal20Active(false);
        // Выполните действия после достижения условия
      }
      refreshAssets();
    }, 2000); // Проверка каждую секунду
  };

  return (
    <>
      <div className="jackpot-top">
        {/* <canvas className='fonWinterH' ref={canvasRef}/> */}
        {/* {loadingPage ? <Preloader /> : null} */}

        <div className="jackpot-main">
          <div className="centerBlockJack">
            {!isTimerActive ? <div className="circleFon"></div> : null}
            <div className="topJTitle">
              <div className="title-jackpot"></div>
            </div>

            <div className="btnNumberSpn">
              {" "}
              {jackpotlocal && jackpotlocal.prise !== undefined
                ? jackpotlocal.prise / 10000
                : 0}{" "}
              SPN
            </div>
            <div className="contentCenterJackpot">
              <div className="leftInfoJackpot">
                <div className="boxLeftFitstJackpot">
                  <span>
                    Claim your free SPN by clicking on the Claim button.
                    According to the chances below you will get the payout
                    amount in SPN. These SPN are credited to your Craft balance.
                    Once you have accumulated a certain number of SPN, you can
                    craft your own NFT and start playing for free.
                  </span>
                </div>
                <div className="boxLeftJackpot">
                  <div className="leftJ">
                    1<span className="linkLeader">SPN</span>
                  </div>
                  <div className="rightJ">95%</div>
                </div>
                <div className="boxLeftJackpot">
                  <div className="leftJ">
                    10<span className="linkLeader">SPN</span>
                  </div>
                  <div className="rightJ">4,5%</div>
                </div>
                <div className="boxLeftJackpot">
                  <div className="leftJ">
                    100<span className="linkLeader">SPN</span>
                  </div>
                  <div className="rightJ">0,5%</div>
                </div>
                <div className="boxLeftJackpot">
                  <div className="leftJ">
                    <span className="linkLeader">Jackpot</span>
                  </div>
                  <div className="rightJ">0,01%</div>
                </div>
              </div>
              <div className="jackpot-coin"></div>
              <div className="rightInfoJackpot">
                <div className="boxRightFitstJackpot">
                  <div style={{ paddingTop: "8px" }} className="">
                    Craft Balance
                  </div>
                  <div className="boxStor">
                    <div className="leftAllStop">
                      <div className="storBalance">
                        {oneUserTop?.token
                          ? (oneUserTop?.token / 10000).toFixed(4)
                          : 0}
                      </div>
                      <div className="spnText">SPN</div>
                    </div>
                    <div className="rightIconSpn"></div>
                  </div>
                </div>
                <div className="boxRightSecondJackpot">
                  {" "}
                  <span>
                    You also have a chance to win the jackpot. Every time
                    players hit the Claim button, 10% of the SPN goes into the
                    jackpot. If you manage to hit 0.01% the jackpot is yours.
                    But don't lose your head at such riches!
                  </span>
                </div>
                <div className="jackpotRightBlock">
                  {jackpotWinnersLocal && jackpotWinnersLocal.length
                    ? jackpotWinnersLocal.map((item) => {
                        return (
                          <div className="boxLeftJackpot">
                            <div className="leftJ">
                              <span className="linkLeader">jackpot winner</span>
                            </div>
                            <div className="centerJ">{item.user}</div>
                            <div className="rightJ">
                              {parseInt(item.reward) / 10000}
                              <span className="linkLeader">SPN</span>
                            </div>
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
            </div>

            {oneUserTop && oneUserTop.timer !== undefined ? (
              Math.floor(Date.now() / 1000) >= oneUserTop.timer ? (
                // класс disab неактивна кнопка
                <button
                  className="btnNumberSpnTimer"
                  onClick={() => getbonusTrans()}
                >
                  CLAIM
                </button>
              ) : isTimerActive ? (
                <button className="btnNumberSpnTimer disab">
                  <Countdown
                    daysInHours
                    date={oneUserTop.timer * 1000}
                    onComplete={() => setIsTimerActive(false)}
                  />
                </button>
              ) : (
                <button
                  className="btnNumberSpnTimer"
                  onClick={() => getbonusTrans()}
                >
                  CLAIM
                </button>
              )
            ) : (
              <button
                className="btnNumberSpnTimer"
                onClick={() => getbonusTrans()}
              >
                CLAIM
              </button>
            )}
          </div>
          {/* <button onClick={() => openModalJackpot("open")}> ModalJackpot</button> */}
        </div>
      </div>

      {modal20Active ? (
        <ModalJackpot
          jackpotlocal={jackpotlocal}
          oneUserTop={oneUserTop}
          loadingPage={loadingPage}
          active={modal20Active}
          setActive={setModal20Active}
          openModalJackpot={openModalJackpot}
        ></ModalJackpot>
      ) : null}
    </>
  );
};

export default Jackpot;
