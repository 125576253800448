import React from 'react';
import '../Modal/ModalClaim.css';
import '../Modal/Modal.css';


const ModalSpinAll = ({ active, spinAll, setActive, children, userInGameState, buySlotTrans, spinAllId, spinAllPoints }) => {


  const OnClose = () => {
    setActive(false);
  }

  return (
    <div id="modal19" className={active ? "modal__content activerun" : "modal__content"} onClick={() => setActive(false)}>
      <div id='slots' className={active ? "modal activerun" : "modal"} onClick={(event) => event.stopPropagation()}>
        <div className="icon__claim iconSpinnAll"></div>
        <div className="title__slots">Spin All</div>
        <div className="content__slot">By confirming you spin all your Spinneys that don't have a countdown and also don't have runes.</div>

        <div className="sots__block">
          <div className="content__slots">Spinney for spin: <div className="total__s">{spinAllId.length}</div></div>
          <div className="content__slots sBottom">Total consumption: <div className="total__s">{spinAllPoints ? spinAllPoints : 0} <div className="spnBg">SPN</div></div></div>
        </div>
        <div className="btn__modal">
          <button onClick={OnClose}><span className='tokenDiscont'>Cancel</span></button>
          <button onClick={spinAll}>Confirm</button>
        </div>
      </div>
    </div>
  );

}
export default ModalSpinAll;