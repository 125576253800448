import React from 'react';
import './Bankpage.css';
import Assetspage from './Assets/Assetspage';
import Tokenpage from './Token/Tokenpage';
import Ticket from './Ticket/Ticket';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link, NavLink } from 'react-router-dom';

const Bankpage = ({ allSpinner, allSpinnerInGame, allRunesInGame, balance, userInGameState, tokenDepositeTrans, setChoosesAssetOnWallet, setChoosesAssetOnGame, setAllNftSpinerGame, transferNftToTheGame, withdrawnft, allNftSpinerGame, setAllNftSpinerGameFiltered, allNftSpinerGameFiltered, setAllNftRunesGame, allNftRunesGame, tokenWithdrawTrans, allResInGameLocal, resDisp, refreshAssets, dispatchRemSpinneyFromWallet, choosesAssetOnGame }) => {
  let { id } = useParams();

  useEffect(() => {
  }, [id]);


  const [activeMenuBankpage, setActiveMenuBankpage] = useState("Assets1");




  const handleMenuClick = (menu) => {
    setActiveMenuBankpage(menu);
    localStorage.setItem('activeMenuBankpage', menu);
    if (menu === "Assets1") {
    }
  };

  useEffect(() => {
    const savedActiveMenuBankpage = localStorage.getItem('activeMenuBankpage');
    if (savedActiveMenuBankpage) {
      setActiveMenuBankpage(savedActiveMenuBankpage);
    }
  }, []);

  useEffect(() => {
    if (id == "token") {
      setActiveMenuBankpage("Token1");
    } else if (id == "tickets") {
      setActiveMenuBankpage("Ticket1");
    } else {
      setActiveMenuBankpage("Assets1");
    }
  }, [id]);





  return (

    <div className="main__content">
      <div id='Bank_1' className="contentBankpage">
        <div className="nav__top">
          <div className="nav__top_container">
            <button className={`bt__nav ${activeMenuBankpage === "Assets1" ? "active__top" : ""} `}>
              <Link className='j'
                to="/bankpage/assets" >Assets
              </Link>
            </button>

            <button className={`bt__nav ${activeMenuBankpage === "Token1" ? "active__top" : ""}`}>
              <Link className='j'
                to="/bankpage/token">Token
              </Link>
            </button>
            <button className={`bt__nav ${activeMenuBankpage === "Ticket1" ? "active__top" : ""} `}>
              <Link className='j'
                to="/bankpage/tickets">Tickets
              </Link>
            </button>

          </div>

        </div>
        {activeMenuBankpage === "Assets1" && (
          <div className="Assets1">
            <Assetspage

              allSpinner={allSpinner}
              allSpinnerInGame={allSpinnerInGame}
              allRunesInGame={allRunesInGame}

              setChoosesAssetOnWallet={setChoosesAssetOnWallet}
              setChoosesAssetOnGame={setChoosesAssetOnGame}
              setAllNftSpinerGame={setAllNftSpinerGame}
              allNftSpinerGame={allNftSpinerGame}
              transferNftToTheGame={transferNftToTheGame}
              withdrawnft={withdrawnft}
              setAllNftSpinerGameFiltered={setAllNftSpinerGameFiltered}
              allNftSpinerGameFiltered={allNftSpinerGameFiltered}
              setAllNftRunesGame={setAllNftRunesGame}
              allNftRunesGame={allNftRunesGame}
              refreshAssets={refreshAssets}
              choosesAssetOnGame={choosesAssetOnGame}
            />
          </div>
        )}
        {activeMenuBankpage === "Token1" && (
          <div className="Token1">
            <Tokenpage

              balance={balance}
              userInGameState={userInGameState}
              tokenDepositeTrans={tokenDepositeTrans}
              tokenWithdrawTrans={tokenWithdrawTrans}
            />
          </div>
        )}
        {activeMenuBankpage === "Ticket1" && (
          <div className="Ticket1">
            <Ticket
              dispatchRemSpinneyFromWallet={dispatchRemSpinneyFromWallet}
              balance={balance}
              userInGameState={userInGameState}
              tokenDepositeTrans={tokenDepositeTrans}
              tokenWithdrawTrans={tokenWithdrawTrans}
              allSpinner={allSpinner}
              allResInGameLocal={allResInGameLocal}
              resDisp={resDisp}
            />
          </div>
        )}

      </div>

    </div>

  )
}


export default Bankpage;

