import React from 'react';
import '../MenuWallet/MenuWallet.css';
import { Link, NavLink } from 'react-router-dom';


const MenuWallet = (props) => {
    const {handleClickAudio } = props;
    return <div className="wallet__content" onClick={() => props.getActiveMenuItem("/bankpage", 72)}>
        {/* <div className='icon__cash'></div> */}
        <div className="header__SpnToken BuySpn">
            <div className='boxSpnWallet'>
                <div className="price__coin"></div>
                <div className="nambTicket ">SPN</div>
            </div>
        </div>
        <div className="wallet__info">
            <div className="block__sum">
            <div className='name__wallet'>
                <p>Wallet:</p>
            </div>
                <div id='walle' className='icon__sum'>
                    {/* <p>{props.balance && props.balance !== "NaN" ? props.balance : 0}</p> */}
                    <p>{props.balance && props.balance !== "NaN" ? parseFloat(props.balance).toFixed(4) : "0.0000"}</p>
                </div>
            </div>
            <div className="block__sum">
            <div className='name__wallet'>
                <p>Game:</p>
            </div>
                <div className='icon__sum'>
                    <p id='game'>{props.userInGameState && props.userInGameState.balance !== undefined ? parseFloat(props.userInGameState.balance / 10000).toFixed(4) : 0}</p>
                </div>
             
            </div>
        </div>



    </div>
}


export default MenuWallet;


