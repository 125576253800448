import React from 'react';
import './Tokenpage.css';
import { useState, useEffect } from 'react'
import MenuWallet from '../../MenuWallet/MenuWallet';
import useGetInfoStatDaily from '../../../components/Wax/useGetInfoStatDaily';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import useGetSpnWax from '../../../components/Wax/useGetSpnWax';

const Tokenpage = ({ balance, userInGameState, tokenDepositeTrans, tokenWithdrawTrans }) => {
    let { id } = useParams();

    useEffect(() => {
    }, [id]);

    const [withdrawalAmount, setWithdrawalAmount] = useState('');
    const [withdrawalAmounte, setWithdrawalAmounte] = useState('');
    const [showResult, setShowResult] = useState(false);
    const [showResulte, setShowResulte] = useState(false);
    const [activeCard, setActiveCard] = useState("");
    const [activeCardWallet, setActiveCardWallet] = useState("");
    const [shouldReloadContent, setShouldReloadContent] = useState(true);
    const [shouldReloadWalletContent, setShouldReloadWalletContent] = useState(true);

    const [loadingFromWallet, setLoadingFromWallet] = useState(false);
    const [loadingFromGame, setLoadingFromGame] = useState(false);


    const [infoDaylyStatLocal, setInfoDailyStatLocal] = useState(null);
    const [fee, setFee] = useState(2);

    const withdrawalAmountRef = useRef(null);

    const { infoStatDaily } = useGetInfoStatDaily(userInGameState?.user);

    const [spnCurr, setSpnCurr] = useState(null);
    const [refreshKey4, setRefreshKey4] = useState(0);
    const [waxprice, setWaxPrice] = useState(0);
    let { tacoCurr } = useGetSpnWax(userInGameState.user, refreshKey4);


    useEffect(() => {
        if (tacoCurr && tacoCurr.id !== undefined) {
            setSpnCurr(tacoCurr);

            let spn = parseInt(tacoCurr.pool1.quantity.replace(/\D/g, '')) * 10000;
            let wax = parseInt(tacoCurr.pool2.quantity.replace(/\D/g, ''));

            function truncateToDecimalPlace(num, decimalPlaces) {
                const power = Math.pow(10, decimalPlaces);
                const truncatedNum = Math.floor(num * power) / power;
                return truncatedNum.toFixed(decimalPlaces);
            }

            //let cut = truncateToDecimalPlace(wax / spn, 8);

            setWaxPrice(wax / spn);
        }
    }, [tacoCurr]);

    useEffect(() => {
        setInfoDailyStatLocal(infoStatDaily);
        //console.log("infoStatDaily", infoStatDaily);
    }, [infoStatDaily]);

    useEffect(() => {
        //console.log("infoDaylyStatLocal", infoDaylyStatLocal)
        if (infoDaylyStatLocal && infoDaylyStatLocal.hourly_shifted != undefined) {
            percenForNow();
        }
    }, [infoDaylyStatLocal, withdrawalAmount]);

    const truncateToDecimalPlace = (num, decimalPlaces) => {
        const power = Math.pow(10, decimalPlaces);
        const truncatedNum = Math.floor(num * power) / power;
        return truncatedNum.toFixed(decimalPlaces);
    }

    // ln geme

    const isAmountMatching = (percentage, cardNumber) => {
        //console.log("userInGameState", userInGameState);
        if (userInGameState?.balance === 0) {
            return false;
        }

        const calculatedAmount = Math.round((userInGameState.balance * percentage) / 100) / 10000;
        return Math.abs(withdrawalAmount - calculatedAmount) < 0.0001;
    };


    const isAmountMatchinge = (percentage, cardNumber) => {
        if (balance === 0) {
            return false;
        }

        const calculatedAmounte = Math.round((balance * percentage) / 100 * 10000) / 10000;
        return Math.abs(withdrawalAmounte - calculatedAmounte) < 0.0001;
    };



    const handleCardClick = (cardNumber) => {
        if (activeCardWallet === cardNumber) {
            setShowResult(false);
            setActiveCard(null);
        } else {
            setActiveCardWallet(cardNumber);
            setShowResulte(true);
        }

    };

    // const percenForNow = () => {
    //     const now = new Date();
    //     const currentHourUTC = now.getUTCHours();
    //     const copyInfoDaylyStatLocal = JSON.parse(JSON.stringify(infoDaylyStatLocal));

    //     const lastUpdatedDate = new Date(copyInfoDaylyStatLocal.last_updated * 1000);
    //     const hoursElapsed = (now.getTime() - lastUpdatedDate.getTime()) / (1000 * 60 * 60);

    //     console.log("copyInfoDaylyStatLocal.hourly_shifted 1", copyInfoDaylyStatLocal.hourly_shifted)

    //     if (hoursElapsed >= 24) {
    //         copyInfoDaylyStatLocal.hourly_shifted = Array(24).fill(0);

    //         // Начало добавленного блока
    //         let daysMissed = Math.floor(hoursElapsed / 24);
    //         for (let i = 0; i < daysMissed && i < 30; i++) {
    //             copyInfoDaylyStatLocal.daily_shifted.unshift(0);
    //             if (copyInfoDaylyStatLocal.daily_shifted.length > 30) {
    //                 copyInfoDaylyStatLocal.daily_shifted.pop();
    //             }
    //         }
    //         // Конец добавленного блока
    //     } else {
    //         let shiftBy = Math.floor(hoursElapsed);
    //         for (let i = 0; i < shiftBy; i++) {
    //             copyInfoDaylyStatLocal.hourly_shifted.unshift(0);
    //             if (copyInfoDaylyStatLocal.hourly_shifted.length > 24) {
    //                 copyInfoDaylyStatLocal.hourly_shifted.pop();
    //             }
    //         }
    //     }

    //     console.log("copyInfoDaylyStatLocal.hourly_shifted 2", copyInfoDaylyStatLocal.hourly_shifted)

    //     let sumHourly = withdrawalAmount * 10000;
    //     for (let val of copyInfoDaylyStatLocal.hourly_shifted) {
    //         sumHourly += val;
    //     }
    //     const avgHourly = sumHourly / copyInfoDaylyStatLocal.hourly_shifted.length;

    //     let sumFirst5 = 0;
    //     for (let i = 0; i < 5; i++) {
    //         sumFirst5 += copyInfoDaylyStatLocal.daily_shifted[i];
    //     }
    //     const avgFirst5 = sumFirst5 / 5;

    //     let withdrawPercent = 0; // инициализация значения

    //     console.log("avgHourly", avgHourly);
    //     console.log("avgFirst5", avgFirst5);

    //     if (avgHourly > avgFirst5 * 3.0) { // + 200% от Ср.Вывод.Час
    //         withdrawPercent = 8;
    //     } else if (avgHourly > avgFirst5 * 2.5) { // + 150% от Ср.Вывод.Час
    //         withdrawPercent = 7;
    //     } else if (avgHourly > avgFirst5 * 2.0) { // + 100% от Ср.Вывод.Час
    //         withdrawPercent = 6;
    //     } else if (avgHourly > avgFirst5 * 1.6) { // + 60% от Ср.Вывод.Час
    //         withdrawPercent = 5;
    //     } else if (avgHourly > avgFirst5 * 1.3) { // + 30% от Ср.Вывод.Час
    //         withdrawPercent = 4;
    //     } else if (avgHourly >= avgFirst5 * 0.7 && avgHourly < avgFirst5 * 1.3) {  // от -30% до +30% от Ср.Вывод.Час
    //         withdrawPercent = 3;
    //     } else if (avgHourly < avgFirst5 * 0.7) { // - 30% от Ср.Вывод.Час
    //         withdrawPercent = 2;
    //     } else if (avgFirst5 == 0 || avgHourly > avgFirst5) {
    //         withdrawPercent = 8;
    //     }

    //     console.log("withdrawPercent:", withdrawPercent);
    //     setFee(withdrawPercent);
    // };
    const percenForNow = () => {
        const now = new Date(); // будет содержать текущее время в локальном часовом поясе
        console.log("now", now.toUTCString()); // лог текущего времени в UTC

        console.log("infoDaylyStatLocal", infoDaylyStatLocal)



        const currentDayUTC = Math.floor(now.getTime() / (1000 * 60 * 60 * 24));
        const copyInfoDaylyStatLocal = JSON.parse(JSON.stringify(infoDaylyStatLocal));

        const lastUpdatedDate = new Date(copyInfoDaylyStatLocal.last_updated * 1000);
        const dayLastAct = Math.floor(lastUpdatedDate.getTime() / (1000 * 60 * 60 * 24));

        const daysElapsed = currentDayUTC - dayLastAct;

        if (daysElapsed >= 1) {
            for (let i = 0; i < daysElapsed && i < 30; i++) {
                const dailyTotal = copyInfoDaylyStatLocal.current_day.reduce((acc, val) => acc + val, 0);

                copyInfoDaylyStatLocal.daily_shifted.unshift(dailyTotal);
                if (copyInfoDaylyStatLocal.daily_shifted.length > 30) {
                    copyInfoDaylyStatLocal.daily_shifted.pop();
                }

                copyInfoDaylyStatLocal.current_day = Array(24).fill(0);
            }

            copyInfoDaylyStatLocal.hourly_shifted = Array(24).fill(0);
        }

        const currentHourUTC = now.getUTCHours();
        const lastUpdatedHourUTC = lastUpdatedDate.getUTCHours();
        const shiftBy = (currentHourUTC + 24 - lastUpdatedHourUTC) % 24;

        for (let i = 0; i < shiftBy; i++) {
            copyInfoDaylyStatLocal.hourly_shifted.unshift(0);
            if (copyInfoDaylyStatLocal.hourly_shifted.length > 24) {
                copyInfoDaylyStatLocal.hourly_shifted.pop();
            }
        }

        let sumHourly = withdrawalAmount * 10000;
        for (let val of copyInfoDaylyStatLocal.hourly_shifted) {
            sumHourly += val;
        }
        const avgHourly = sumHourly; /*/ copyInfoDaylyStatLocal.hourly_shifted.length*/

        let sumFirst30 = 0;
        for (let i = 0; i < 30; i++) {
            sumFirst30 += copyInfoDaylyStatLocal.daily_shifted[i];
        }
        const avgFirst30 = sumFirst30 / 30;

        let withdrawPercent = 0;

        // console.log("Withdrawal amount", withdrawalAmount);
        // console.log("sumFirst30", sumFirst30);
        // console.log("copyInfoDaylyStatLocal.daily_shifted", copyInfoDaylyStatLocal.daily_shifted);
        // console.log("avgHourly", avgHourly);
        // console.log("copyInfoDaylyStatLocal", copyInfoDaylyStatLocal);
        // console.log("avgFirst30", avgFirst30);

        if (avgHourly > avgFirst30 * 3.5) {
            withdrawPercent = 10;
        } else if (avgHourly > avgFirst30 * 3.0) {
            withdrawPercent = 9;
        } else if (avgHourly > avgFirst30 * 2.5) {
            withdrawPercent = 8;
        } else if (avgHourly > avgFirst30 * 2.0) {
            withdrawPercent = 7;
        } else if (avgHourly > avgFirst30 * 1.6) {
            withdrawPercent = 6;
        } else if (avgHourly > avgFirst30 * 1.3) {
            withdrawPercent = 5;
        } else if (avgHourly >= avgFirst30 * 0.7 && avgHourly < avgFirst30 * 1.3) {
            withdrawPercent = 4;
        } else if (avgHourly < avgFirst30 * 0.7) {
            withdrawPercent = 3;
        } else if (avgFirst30 == 0 || avgHourly > avgFirst30) {
            withdrawPercent = 8;
        }

        // if (avgHourly > avgFirst30 * 3.0) {
        //     withdrawPercent = 8;
        // } else if (avgHourly > avgFirst30 * 2.5) {
        //     withdrawPercent = 7;
        // } else if (avgHourly > avgFirst30 * 2.0) {
        //     withdrawPercent = 6;
        // } else if (avgHourly > avgFirst30 * 1.6) {
        //     withdrawPercent = 5;
        // } else if (avgHourly > avgFirst30 * 1.3) {
        //     withdrawPercent = 4;
        // } else if (avgHourly >= avgFirst30 * 0.7 && avgHourly < avgFirst30 * 1.3) {
        //     withdrawPercent = 3;
        // } else if (avgHourly < avgFirst30 * 0.7) {
        //     withdrawPercent = 2;
        // } else if (avgFirst30 == 0 || avgHourly > avgFirst30) {
        //     withdrawPercent = 8;
        // }

        //setFee(withdrawPercent);
        setFee(10);
    };

    const calculateAmount = (percentage, cardNumber) => {
        const calculatedAmount =
            Math.round((userInGameState.balance * percentage) / 100) / 10000;
        setWithdrawalAmount(calculatedAmount);

        if (withdrawalAmount > balance) {
            setActiveCard(cardNumber);
        } else {
            setActiveCardWallet(cardNumber);
        }

        setShowResult(true);
    };

    // wallet



    const handleCardClickWallet = (cardNumber) => {
        if (activeCardWallet === cardNumber) {
            setShowResulte(false);
            setActiveCardWallet(null);
        } else {
            setActiveCardWallet(cardNumber);
            setShowResulte(true);
        }
    };
    const calculateAmounte = (percentage, cardNumber) => {
        const calculatedAmounte =
            Math.round((balance * percentage) / 100 * 10000) / 10000;
        setWithdrawalAmounte(calculatedAmounte);

        if (Math.abs(withdrawalAmounte - calculatedAmounte) < 0.0001) {
            setActiveCard(cardNumber);
        } else {
            setActiveCardWallet(cardNumber);
            setShowResulte(true);
        }
    };

    const handleReloadClick = () => {
        setWithdrawalAmount('');
        setShouldReloadContent(true);
        setLoadingFromGame(true);

        setTimeout(() => {
            setLoadingFromGame(false);
        }, 2000);
    };
    const handleReloadWalletClick = () => {
        setWithdrawalAmounte('');
        setShouldReloadWalletContent(true);
        setLoadingFromWallet(true);

        setTimeout(() => {
            setLoadingFromWallet(false);
        }, 2000);
    };

    return <div id='t' className="bank__content">
        <div className="assetspage">
            <div className="assets">

                <div className="nft__card">
                    <div className='top__menu__name'>
                        <div id='topAssets' className='player__assets' onClick={() => handleReloadClick()}>Reload

                        </div>
                        <div className='title'> In Game</div>
                    </div>
                    {shouldReloadContent && (
                        <>
                            <div id='token__card' className="nft__card__content">
                                {loadingFromGame ? <div className='preloader-local'></div> : null}
                                <div className="nft__card__token">

                                    <div className="header__cash">
                                        <MenuWallet
                                            userInGameState={userInGameState} />
                                    </div>
                                    <div className="total">
                                        <p>Withdrawal amount</p>
                                        <div className="total__spn">
                                            <div >
                                                {/*div onClick={clearResults} */}
                                                {showResult ? (
                                                    <div>
                                                        <input
                                                            type="text"
                                                            value={withdrawalAmount}
                                                            ref={withdrawalAmountRef}
                                                            onChange={(e) => setWithdrawalAmount(e.target.value ? e.target.value : 0)}
                                                            onBlur={(e) => {
                                                                const inputValue = e.target.value;
                                                                const newValue = inputValue.replace(",", ".");
                                                                setWithdrawalAmount(newValue ? newValue : 0);
                                                            }} />
                                                    </div>
                                                ) : (

                                                    <input
                                                        placeholder='Amount:'
                                                        id="inp"
                                                        type="text"
                                                        value={withdrawalAmount}
                                                        ref={withdrawalAmountRef}
                                                        onChange={(e) => setWithdrawalAmount(e.target.value)}
                                                        onBlur={(e) => {
                                                            const inputValue = e.target.value;
                                                            const newValue = inputValue.replace(",", ".");
                                                            setWithdrawalAmount(newValue);
                                                        }} />

                                                )}
                                            </div>
                                            <p className='pSpn'>SPN</p>
                                        </div>

                                    </div>

                                    <div className="total fees-bank">
                                        <p>Fees</p>
                                        <div className="total__spn">
                                            <div >
                                                {/*div onClick={clearResults} */}
                                                <div>
                                                    <input
                                                        type="text"
                                                        placeholder='0'
                                                        readOnly
                                                        value={withdrawalAmount && (withdrawalAmount * 0.1).toFixed(4)}
                                                    />
                                                </div>

                                            </div>
                                            <p className='pSpn'>SPN</p>
                                        </div>

                                        <div className="total__spn">
                                            <div >
                                                <div>
                                                    <input
                                                        type="text"
                                                        placeholder='0'
                                                        readOnly
                                                        value={withdrawalAmount && truncateToDecimalPlace(parseFloat((withdrawalAmount * 0.05)) * waxprice, 8)}
                                                    />
                                                </div>
                                            </div>
                                            <p className='pSpn'>WAX</p>
                                        </div>

                                    </div>
                                </div>

                                <div className="bottom__menu">
                                    <button disabled={!withdrawalAmount} onClick={() => tokenWithdrawTrans(withdrawalAmount, fee, truncateToDecimalPlace(parseFloat((withdrawalAmount * 0.05)) * waxprice, 8))} id='b'><span className='tokenDiscont'>Withdraw</span></button>

                                </div>
                                {/* <div className="costs"><p>Any withdrawal transaction costs 25 WAX</p></div> */}
                            </div>

                            <div className="info-tken-card">
                                <p>
                                    Attention, there is a 15% fee for token withdrawal. 10% in SPN token and 5% in WAX. Make sure you have enough WAX on your wallet before withdrawing. All WAX from fees is sent to liquidity.
                                </p>
                            </div>
                        </>
                    )}
                </div>

            </div>

            <div className='w'>
                <div className="nft__card">
                    <div className='top__menu__name'>
                        <div id='topAssets' className='player__assets' onClick={() => handleReloadWalletClick()}>Reload

                        </div>
                        <div className='title'> Wallet</div>
                    </div>
                    {shouldReloadWalletContent && (
                        <div className="nft__card__content">
                            {loadingFromWallet ? <div className='preloader-local'></div> : null}
                            <div className="nft__card__token">
                                {/* <div className="token__title">
                                </div> */}
                                <div className="header__cash">
                                    <MenuWallet balance={balance} />
                                </div>
                                <div className="total">
                                    <p>Deposit amount</p>
                                    <div className="total__spn">
                                        <div >
                                            {/*  onClick={clearResultsSecond} */}
                                            {showResulte ? (

                                                <input
                                                    type="text"
                                                    value={withdrawalAmounte}
                                                    ref={withdrawalAmountRef}
                                                    onChange={(e) => setWithdrawalAmounte(e.target.value)}
                                                    onBlur={(e) => {
                                                        const inputValue = e.target.value;
                                                        const newValue = inputValue.replace(",", ".");
                                                        setWithdrawalAmounte(newValue);
                                                    }} />


                                            ) : (
                                                <div>
                                                    <input
                                                        placeholder='Amount:'
                                                        id="inp"
                                                        type="text"
                                                        value={withdrawalAmounte}
                                                        ref={withdrawalAmountRef}
                                                        onChange={(e) => setWithdrawalAmounte(e.target.value)}
                                                        onBlur={(e) => {
                                                            const inputValue = e.target.value;
                                                            const newValue = inputValue.replace(",", ".");
                                                            setWithdrawalAmounte(newValue);
                                                        }} />

                                                </div>
                                            )}
                                        </div>
                                        <p>SPN</p>
                                    </div>
                                    <div className="amount">
                                        <button
                                            className={isAmountMatchinge(10, 1) ? "active" : ""}
                                            onClick={() => {
                                                calculateAmounte(10, 1);
                                                handleCardClickWallet(1);
                                            }}
                                        >
                                            <span className='tokenDiscont'>10%</span>
                                        </button>
                                        <button
                                            className={isAmountMatchinge(25, 2) ? "active" : ""}
                                            onClick={() => {
                                                calculateAmounte(25, 2);
                                                handleCardClickWallet(2);
                                            }}
                                        >
                                            <span className='tokenDiscont'>25%</span>
                                        </button>
                                        <button
                                            className={isAmountMatchinge(50, 3) ? "active" : ""}
                                            onClick={() => {
                                                calculateAmounte(50, 3);
                                                handleCardClickWallet(3);
                                            }}
                                        >
                                            <span className='tokenDiscont'>50%</span>
                                        </button>
                                        <button className={isAmountMatchinge(100, 4) || (parseInt(withdrawalAmounte) > balance) ? "active" : ""} onClick={() => { calculateAmounte(100, 4); handleCardClickWallet(4); }}>
                                            <span className='tokenDiscont'> 100%</span>
                                        </button>

                                    </div>
                                </div>
                            </div>

                            <div className="bottom__menu">
                                <button disabled={!withdrawalAmounte} onClick={() => tokenDepositeTrans(withdrawalAmounte, "", "deposit")}><span className='tokenDiscont'>Deposit</span></button>
                            </div>
                        </div>
                    )}
                </div>

            </div>

        </div>


    </div>
}


export default Tokenpage;