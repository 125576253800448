import { useState, useEffect, useContext } from "react";

import '../Modal/ModalClaim.css';
import '../StartPage/LoginPage.css';
import '../Modal/Modal.css';
import { Link } from 'react-router-dom';
import { UALContext } from "ual-reactjs-renderer";


const LoginPage = (setActive, reguser, logout) => {

  const ual = useContext(UALContext);
  const loginWithWax = async () => {
    console.log("login WAX");
    console.log("ual", ual);

    const waxAuthenticator = ual.availableAuthenticators.find(auth => auth.wax);
    console.log("waxAuthenticator", waxAuthenticator);
    if (waxAuthenticator && typeof waxAuthenticator.getStyle === 'function') {
      await waxAuthenticator.login();
      ual.authenticateWithoutAccountInput(waxAuthenticator);
    } else {
      console.log("Wax Authenticator not found or getStyle method is not available");
    }
  }


  const loginWithAnchor = async () => {

    console.log("login Anch");
    const anchorAuthenticator = ual.availableAuthenticators.find(auth => auth.link);
    if (anchorAuthenticator && typeof anchorAuthenticator.getStyle === 'function') {
      await anchorAuthenticator.login();
      ual.authenticateWithoutAccountInput(anchorAuthenticator);
    }
  }

  const loginWithWombat = async () => {

    console.log("login Anch");
    console.log(" ual.availableAuthenticators", ual.availableAuthenticators);
    const wombatAuthenticator = ual.availableAuthenticators.find(auth => auth.scatter);
    console.log("wombatAuthenticator", wombatAuthenticator);
    if (wombatAuthenticator && typeof wombatAuthenticator.getStyle === 'function') {
      await wombatAuthenticator.scatter.login();
      ual.authenticateWithoutAccountInput(wombatAuthenticator);
    }
  }


  const OnClose = () => {
    setActive(false);
  }
  const serverOptions = ['dynamic', 'wax.pink.gg', 'wax.cryptolions.io', 'wax.greymass.com', 'api.wax.alohaeos.com', 'api.waxsweden.org', 'wax.eosphere.io', 'api.wax.greeneosio.com', 'wax.eu.eosamsterdam.net', 'api.wax.bountyblok.io'];


  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const savedOption = localStorage.getItem('selectedServer');
    if (savedOption) {
      setSelectedOption(savedOption);
    }
  }, []);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    localStorage.setItem('selectedServer', option);
    toggleOpen();
  };
  return (
    <>
      <div className="boxLogin">
        <div className='LoginContent top__menu__name'>
          <div className='title'>Sign in</div>
        </div>
        <div className='LogiContent'>
          <div id='claim__server' className="modal">
            {/* <div className="q server">Sign in</div> */}
            {/* <div className="lin_bg"></div> */}

            <div className="choose__server oneTitle">Connect your wallet:</div>
            {ual?.activeUser ?
              <>
                <div>
                  <div>Welcome, {ual.activeUser.accountName}</div>
                  <div><button onClick={() => logout()}>Logout</button></div>
                  <button onClick={() => reguser()}>Registration</button>
                </div>
              </>
              :
              <>
                <button className="cloudwallet" onClick={() => loginWithWax()}> <span className='arrowRight'></span> </button>
                <button className="anchor" onClick={() => loginWithAnchor()}> <span className='arrowRight'></span></button>
                <button className="wombat" onClick={() => loginWithWombat()}> <span className='arrowRight'></span></button>
              </>


            }

            <div className="choose__server bottomserv">Select server:</div>
            <div className="claim__price">
              <div id='serv' className="clame__wallet " style={{ height: isOpen ? "auto" : "30px" }}>
                <div className="dropdown__wrapper">
                  <div className="dropdown__header" onClick={toggleOpen}>
                    {selectedOption === "" ? "dynamic" : selectedOption}
                    <div className={`vector__icon ${isOpen ? "open" : ""}`} />
                  </div>

                  <div className={`vector__open ${isOpen ? "upside-down" : ""}`}></div>
                </div>
                {isOpen && (
                  <div className="dropdown__options">
                    {serverOptions.map((option) => (
                      <div className="option" key={option} onClick={() => handleOptionClick(option)}>
                        {option}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

          </div>
        </div>

      </div>

    </>
  );

}
export default LoginPage;