import React, { useContext, useState, useEffect } from 'react';
import '../Token/Tokenpage.css';
import './Ticket.css';
import MenuWallet from '../../MenuWallet/MenuWallet';
import useGetInfoStatDaily from '../../Wax/useGetInfoStatDaily';
import { useRef } from 'react';
import { UALContext } from 'ual-reactjs-renderer';
import { ToastContainer, toast } from 'react-toastify';
import { transferToTheGame, withdrawTicket } from '../../../components/Wax/Transactions';

import { useNavigate } from 'react-router-dom'
import { Scrollbar } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/scrollbar'
import 'swiper/css/mousewheel'
import { Mousewheel } from 'swiper'


const Ticket = ({ active, balance, props, userInGameState, tokenDepositeTrans, tokenWithdrawTrans, allSpinner, allResInGameLocal, allSpinnerInGame, id, resDisp, dispatchRemSpinneyFromWallet }) => {

    // const [activeButtonSpin, setActiveButtonSpin] = useState(1)
    // const [stakeClicked, setStakeClicked] = useState(false)
    // const [selectedSlide, setSelectedSlide] = useState(null)
    // const [activeIds, setActiveIds] = useState([])



    const [availSlots, setAvailSlots] = useState(active);

    const navigate = useNavigate()

    //const [selectedSlides, setSelectedSlides] = useState([]);

    // const handleClickSpin = (index, id) => {
    //     setSelectedSlide(index)
    //     // setActiveIds(id)
    //     if (selectedSlides.length === availSlots && !selectedSlides.includes(index)) {
    //         return; // Предотвращаем добавление класса "selected"
    //     }

    //     const selected = selectedSlides.includes(index);
    //     if (selected) {
    //         setSelectedSlides(prevSelectedSlides => prevSelectedSlides.filter(item => item !== index));
    //     } else {
    //         setSelectedSlides(prevSelectedSlides => [...prevSelectedSlides, index]);
    //     }

    //     if (activeIds.includes(id)) {
    //         setActiveIds(activeIds.filter((slideIndex) => slideIndex !== id));
    //     } else {
    //         setActiveIds([...activeIds, id]);
    //     }

    // };



    // const handleStakeClick = () => {
    //     setStakeClicked(true)
    // }

    //const handleClose = () => {
    // setActive(false)
    //}
    const disabledButtonStyle = {
        pointerEvents: 'none',
    }

    useEffect(() => {
        setAvailSlots(userInGameState?.open_slots - userInGameState?.all_slot.length);

    }, [userInGameState]);

    const notify = (e) => toast(e);

    const ual = useContext(UALContext);

    useEffect(() => {
    }, [id]);

    const [onlyTicket, setOnlyTicket] = useState(null);
    const [valueDeposit, setValueDeposit] = useState("");
    const [valueWithdraw, setValueWithdraw] = useState("");
    const [isDepositButtonDisabled, setIsDepositButtonDisabled] = useState(true);
    const [isDepositDisabled, setIsDepositDisabled] = useState(true);
    const [shouldReloadContent, setShouldReloadContent] = useState(true);
    const [shouldReloadWalletContent, setShouldReloadWalletContent] = useState(true);
    const [loadingFromWallet, setLoadingFromWallet] = useState(false);
    const [loadingFromGame, setLoadingFromGame] = useState(false);

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        const maxValue = onlyTicket && onlyTicket.length ? onlyTicket.length : 0;

        if (newValue === "" || (/^[0-9]+$/.test(newValue) && (!maxValue || parseInt(newValue) <= maxValue))) {
            setValueDeposit(newValue);
            setIsDepositButtonDisabled(false);
        }
        else {
            setIsDepositButtonDisabled(true);
        }
    };

    const depoitAll = () => {
        const maxValue = onlyTicket && onlyTicket.length ? onlyTicket.length : 0;
        setIsDepositButtonDisabled(false);
        setValueDeposit(maxValue);
    };

    const handleInputChangeWithdraw = (e) => {
        const newValue = e.target.value;
        const maxValue = allResInGameLocal && allResInGameLocal.user !== undefined ? allResInGameLocal.res[0] : 0;

        if (newValue === "" || (/^[0-9]+$/.test(newValue) && (!maxValue || parseInt(newValue) <= maxValue))) {
            setValueWithdraw(newValue);
            setIsDepositDisabled(false);
        }
        else {
            setIsDepositDisabled(true);
        }
    };

    const withdrawAll = () => {
        const maxValue = allResInGameLocal && allResInGameLocal.user !== undefined ? allResInGameLocal.res[0] : 0;
        setValueWithdraw(maxValue);
        setIsDepositDisabled(false);
    };

    const [allSpiney, setAllSpinney] = useState([]);

    useEffect(() => {
        let allItems = [];
        if (allSpinner && allSpinner[process.env.REACT_APP_SCHEMA_PRO]) {
            allItems = allSpinner[process.env.REACT_APP_SCHEMA_PRO];
        }
        const objectsToRemove = allItems.filter(item => item.template.template_id == process.env.REACT_APP_TICKET);
        //console.log("objectsToRemove", objectsToRemove);
        setOnlyTicket(objectsToRemove);

        if (allSpinner && allSpinner.spinney !== undefined) {
            setAllSpinney(allSpinner.spinney.filter(item => item.template.template_id !== process.env.REACT_APP_SPINNEY_PROMO && item.template.template_id !== process.env.REACT_APP_SPINNEY_PROMO2));
        }

    }, [allSpinner]);

    const sendTicketToGameTrans = async (count) => {
        try {
            const { activeUser } = ual;
            const { accountName } = activeUser;

            if (onlyTicket && onlyTicket.length) {
                let choosesAssetOnWallet = onlyTicket.slice(0, count).map(item => item.asset_id);
                const transactionPayload = transferToTheGame(accountName, choosesAssetOnWallet, "");

                console.log("transactionPayload", transactionPayload);

                const transaction = await activeUser.signTransaction(transactionPayload, {
                    blocksBehind: 3,
                    expireSeconds: 30,
                });

                let items = [...onlyTicket];
                items = items.slice(count);
                setOnlyTicket(items);
                resDisp(true, count);
                toast("Transaction ID: " + transaction?.transactionId);
            }



        } catch (e) {
            toast(e.toString(), {
                progressClassName: 'error-progress',
            });
            console.log(e);
        }
    }

    const withdrawTicketTrans = async (count) => {
        try {
            const { activeUser } = ual;
            const { accountName } = activeUser;


            const transactionPayload = withdrawTicket(accountName, count);

            console.log("transactionPayload", transactionPayload);

            const transaction = await activeUser.signTransaction(transactionPayload, {
                blocksBehind: 3,
                expireSeconds: 30,
            });

            let items = [...onlyTicket];
            //console.log("items 1", items)
            for (let index = 0; index < count; index++) {
                items.push({ asset_id: "00000000" });
            }

            //console.log("items 2", items)
            setOnlyTicket(items);
            resDisp(false, count);
            toast("Transaction ID: " + transaction?.transactionId);

        } catch (e) {
            toast(e.toString(), {
                progressClassName: 'error-progress',
            });
            console.log(e);
        }
    }

    const handleReloadClick = () => {
        setValueWithdraw('');
        setShouldReloadContent(true);
        setLoadingFromGame(true);

        setTimeout(() => {
            setLoadingFromGame(false);
        }, 2000);
    };
    const handleReloadWalletClick = () => {
        setValueDeposit('');
        setShouldReloadWalletContent(true);
        setLoadingFromWallet(true);

        setTimeout(() => {
            setLoadingFromWallet(false);
        }, 2000);

    };

    const chooseRar = (rar) => {
        var number;
        let coinBurn;
        switch (rar) {
            //   case 0: case "Promo":
            //     number = 0;
            //     coinBurn = 10;
            //     break;
            case 1: case "Common":
                number = 1;
                coinBurn = 20;
                break;
            case 2: case "Uncommon":
                number = 2;
                coinBurn = 40;
                break;
            case 3: case "Rare":
                number = 3;
                coinBurn = 80;
                break;
            case 4: case "Epic":
                number = 4;
                coinBurn = 160;
                break;
            case 5: case "Legendary": case "Legend":
                number = 5;
                coinBurn = 320;
                break;
            default:
                break;
        }
        return {
            number: number + 1,
            coinBurn: coinBurn,
        };
    }

    const [activeIdsSpinn, setActiveIdsSpinn] = useState([]);
    const [totalCoinBurn, setTotalCoinBurn] = useState(0);

    const handleClickSpinBurn = (event, id, rar) => {
        setActiveIdsSpinn(prevChooses => {
            if (prevChooses.includes(id)) {
                // Удаление id из массива, если оно уже есть
                setTotalCoinBurn(
                    prevKey => prevKey - chooseRar(rar).coinBurn
                )
                return prevChooses.filter(item => item !== id);
            } else {
                // Добавление id в массив, если его нет
                setTotalCoinBurn(
                    prevKey => prevKey + chooseRar(rar).coinBurn
                )
                return [...prevChooses, id];
            }
        });

        //dispatchRemSpinneyFromWallet([id]);
    };

    const burn = async (ids) => {
        try {
            const { activeUser } = ual;
            const { accountName } = activeUser;

            const transactionPayload = transferToTheGame(accountName, ids, "burning ticket ");

            console.log("transactionPayload", transactionPayload);

            const transaction = await activeUser.signTransaction(transactionPayload, {
                blocksBehind: 3,
                expireSeconds: 30,
            });

            dispatchRemSpinneyFromWallet(ids);
            resDisp(true, totalCoinBurn);
            toast("Transaction ID: " + transaction?.transactionId);

        } catch (e) {
            toast(e.toString(), {
                progressClassName: 'error-progress',
            });
            console.log(e);
        }
    }

    return <div id='t' className="bank__content">

        <div className="assetspage">
            <div className="assets">

                <div className="nft__card">
                    <div className='top__menu__name'>
                        <div id='topAssets' className='player__assets' onClick={() => handleReloadClick()}>Reload
                        </div>
                        <div className='title'> In Game</div>
                    </div>

                    {shouldReloadContent && (
                        <div id='token__card' className="nft__card__content">
                            {loadingFromGame ? <div className='preloader-local'></div> : null}
                            <div className="nft__card__token">
                                <div className="ticket">
                                    <p>You will have to wait a while for NFT tickets to <br /> be generated when you withdraw them.</p>
                                </div>
                                <div className="contentBlockTicket">
                                    <div className="textTicket"><span>Available tickets:</span></div>
                                    <div className="fonTicketTop">
                                        <div className="nambTicket">{allResInGameLocal && allResInGameLocal.user !== undefined ? allResInGameLocal.res[0] : 0}</div>
                                        <div className="icon__smoll__tic"></div>
                                    </div>
                                </div>
                                <div className="contentBlockTicket">
                                    <div className="textTicket"><span>Withdrawal amount:</span></div>
                                    <div className="fonTicketTop">
                                        <input placeholder='Amount' className='placeholder' type="number" name="" id="" value={valueWithdraw}
                                            onChange={handleInputChangeWithdraw} />
                                        <button className='btnTicket' onClick={() => withdrawAll()}>Max</button>
                                    </div>
                                </div>
                            </div>
                            <div className="bottom__menu">
                                <button disabled={isDepositDisabled} id='b' onClick={() => withdrawTicketTrans(valueWithdraw)}><span className='tokenDiscont'>Withdraw</span></button>
                            </div>
                        </div>
                    )}
                </div>

            </div>

            <div className='w'>
                <div className="nft__card">
                    <div className='top__menu__name'>
                        <div id='topAssets' className='player__assets' onClick={() => handleReloadWalletClick()}>Reload

                        </div>
                        <div className='title'> Wallet</div>
                    </div>

                    {shouldReloadWalletContent && (
                        <div className="nft__card__content">
                            {loadingFromWallet ? <div className='preloader-local'></div> : null}
                            <div className="nft__card__token">
                                <div className="ticket">
                                    <p>Your tickets will be burned and you will receive virtual <br /> tickets when you deposit them into the game.</p>
                                </div>
                                <div className="contentBlockTicket">
                                    <div className="textTicket"><span>Available tickets:</span></div>
                                    <div className="fonTicketTop">
                                        <div className="nambTicket">{onlyTicket && onlyTicket.length ? onlyTicket.length : 0}</div>
                                        <div className="icon__smoll__tic"></div>
                                    </div>
                                </div>
                                <div className="contentBlockTicket">
                                    <div className="textTicket"><span>Deposit amount:</span></div>
                                    <div className="fonTicketTop">
                                        <input placeholder='Amount' className='placeholder' type="number" name="" id="" value={valueDeposit}
                                            onChange={handleInputChange} />
                                        <button className='btnTicket' onClick={() => depoitAll()}>Max</button>
                                    </div>
                                </div>
                            </div>

                            <div className="bottom__menu">
                                <button disabled={isDepositButtonDisabled} onClick={() => sendTicketToGameTrans(valueDeposit)}><span className='tokenDiscont'>Deposit</span></button>
                            </div>
                        </div>
                    )}
                </div>

            </div>

        </div>
        <div className="bottomAssetsPage">
            <div className="assetspage">
                <div className="assets">

                    <div className="nft__card__bottomAssets">
                        <div className="ticket">
                            <p>You can burn the Spinney NFT to get tickets</p>
                        </div>
                        <div className="born__ticket">
                            {/* <div className="fon__born__tocket">
                            <div className="nameSpinneyBurn">Ancient Spinney</div>
                            <div className="ticketAll">
                                10 Tickets
                            </div>
                        </div> */}
                            <div className="fon__born__tocket">
                                <div className="nameSpinneyBurn">Common Spinney</div>
                                <div className="ticketAll">
                                    20 Tickets
                                </div>
                            </div>
                            <div className="fon__born__tocket">
                                <div className="nameSpinneyBurn">Uncommon Spinney</div>
                                <div className="ticketAll">
                                    40 Tickets
                                </div>
                            </div>
                            <div className="fon__born__tocket">
                                <div className="nameSpinneyBurn">Rare Spinney</div>
                                <div className="ticketAll">
                                    80 Tickets
                                </div>
                            </div>
                            <div className="fon__born__tocket">
                                <div className="nameSpinneyBurn">Epic Spinney</div>
                                <div className="ticketAll">
                                    160 Tickets
                                </div>
                            </div>
                            <div className="fon__born__tocket">
                                <div className="nameSpinneyBurn">Legend Spinney</div>
                                <div className="ticketAll">
                                    320 Tickets
                                </div>
                            </div>
                        </div>



                    </div>

                </div>

                <div className='w bornTicket'>
                    <div className="nft__card">
                        <div className="nft__card__bottomAssets">
                            <div className="title__modalRuna">Burn Spinney</div>
                        </div>
                        <div className="sliderBornTicket">
                            <Swiper
                                id="spinn__slider"
                                modules={[Scrollbar, Mousewheel]}
                                mousewheel={true}
                                spaceBetween={8}
                                direction={'horizontal'}
                                scrollbar={{ draggable: true }}
                                breakpoints={{

                                    610: {
                                        slidesPerView: 1
                                    },
                                    1024: {
                                        slidesPerView: 3
                                    }
                                }}
                            >
                                {allSpiney &&
                                    allSpiney.length &&
                                    allSpiney.map((item, index) => (

                                        <SwiperSlide
                                            key={index}
                                            onClick={() => handleClickSpinBurn(index, item.asset_id, item.data.rarity)}
                                        >

                                            <div className={`slide__player_${chooseRar(item.data.rarity).number} ${activeIdsSpinn.includes(item.asset_id) ? 'selected' : ''}`}>

                                                <div className="bg__id__runs"></div>
                                                <div className="id__runs">{item.asset_id}</div>
                                            </div>
                                        </SwiperSlide>
                                    ))
                                }
                            </Swiper>
                        </div>
                        <div className="btn__runs ticketPap">
                            <div className="contentBlockTicket">
                                <div className="textTicket"><span>You'll get:</span>
                                </div>
                                <div className="fonTicketTop">
                                    <div className="nambTicket">{totalCoinBurn} Tickets</div>
                                </div>
                            </div>
                            {totalCoinBurn === 0 ? (
                                <button disabled className='stakeRuna'>Burn</button>
                            ) : (
                                <button
                                    onClick={() => burn(activeIdsSpinn)}
                                    className='stakeRuna'
                                    disabled={totalCoinBurn === 0}
                                    style={totalCoinBurn === 0 ? disabledButtonStyle : null}
                                >Burn</button>
                            )}
                        </div>
                    </div>

                </div>

            </div>
        </div>


    </div>
    // 
}


export default Ticket;