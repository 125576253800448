import React, { useContext, useState, useEffect, useRef } from "react";
import "../Partners/PartnersPage.css";
import ModalPartners from "../Modal/ModalPartners";
import {
  transferToTheGame,
  withdrawCollNftFromGame,
} from "./../Wax/Transactions";
import { UALContext } from "ual-reactjs-renderer";
import { ToastContainer, toast } from "react-toastify";
import OnePartners from "./OnePartners";

import useGetUserCollectionInfo from "./../Wax/useGetUserCollectionInfo";
import useGetAllNftPartnerInGame from "./../Wax/useGetAllNftPartnerInGame";
import useGetAllNftPartner from "./../Wax/useGetAllNftPartner";

const partnerTmpl = [739864, 739865, 766980, 698098];

const swichOtherCol = (tmpl) => {
  let val;
  switch (parseInt(tmpl)) {
    case 739864:
      val = 0.002;
      break;
    case 739865:
      val = 0.01;
      break;

    // prod
    case 766980:
      val = 0.002;
      break;
    case 698098:
      val = 0.01;
      break;

    default:
      break;
  }

  return val;
};

const PartnersPage = ({ userInGameState }) => {
  const ual = useContext(UALContext);
  const notify = (e) => toast(e);

  // const { ref, inView, entry } = useInView({
  //     /* Optional options */
  //     threshold: 0,
  // });

  const [modal25Active, setModal25Active] = useState(false);
  const [infoUser, setInfoUser] = useState({});
  const [allNftInGame, setAllNftInGame] = useState(null);
  const [refreshKey1, setRefreshKey1] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [allNftModal, setAllNftModal] = useState(null);

  const [assetsFromAtomic, setAssetsFromAtomic] = useState([]);

  const { allPartnerNftInGameGet } = useGetAllNftPartnerInGame(
    userInGameState?.user
  );
  const { userColInfo } = useGetUserCollectionInfo(userInGameState?.user);
  // const { allPartnerGet: get2 } = useGetAllNftPartner(userInGameState?.user, refreshKey1, 0, allNftInGame);
  // const { allPartnerGet: get1 } = useGetAllNftPartner(userInGameState?.user, refreshKey1, partnerTmpl);

  // Первая переменная
  const allPartnerGet2 = useGetAllNftPartner(
    userInGameState?.user /*"3qsb4.wam"*/,
    refreshKey1,
    partnerTmpl,
    null
  );
  const allPartnerGet = useGetAllNftPartner(
    userInGameState?.user,
    refreshKey1,
    null,
    allNftInGame
  );

  // console.log("allPartnerGet", allPartnerGet)
  //console.log("allPartnerGet2", allPartnerGet2)
  // console.log("allNftInGame", allNftInGame)

  useEffect(() => {
    if (
      userColInfo &&
      userColInfo.user !== undefined &&
      infoUser.user === undefined
    ) {
      setInfoUser(userColInfo);
    }
  }, [userColInfo]);

  useEffect(() => {
    if (allPartnerNftInGameGet != null) {
      let filter = allPartnerNftInGameGet.map((item) => item.asset_id);
      //console.log("filter", filter)
      setAllNftInGame(filter);
    }
  }, [allPartnerNftInGameGet]);

  //console.log("allNftInGame", allNftInGame)

  useEffect(() => {
    if (
      userColInfo &&
      userColInfo.user !== undefined &&
      infoUser.user === undefined
    ) {
      setInfoUser(userColInfo);
    }
  }, [userColInfo]);

  useEffect(() => {
    if (allPartnerGet && allPartnerGet.allPartnerGet != null && !loading) {
      // console.log("aaaa")
      // console.log("allPartnerGet.allPartnerGet", allPartnerGet.allPartnerGet)
      setAssetsFromAtomic(allPartnerGet.allPartnerGet);
      setLoading(true);
    }
  }, [allPartnerGet]);

  useEffect(() => {
    if (allPartnerGet2 && allPartnerGet2.allPartnerGet != null && !loading2) {
      //console.log("allPartnerGet2.allPartnerGet", allPartnerGet2.allPartnerGet)
      setAllNftModal(allPartnerGet2.allPartnerGet);
      setLoading2(true);
    }
  }, [allPartnerGet2]);

  const openModalPartners = (type) => {
    if (type === "open") {
      setModal25Active(true);
    }
  };

  const transferNftToTheGame = async (choosesAssetOnWallet) => {
    try {
      const { activeUser } = ual;
      const { accountName } = activeUser;

      const transactionPayload = transferToTheGame(
        accountName,
        choosesAssetOnWallet,
        "partner "
      );

      console.log("transactionPayload", transactionPayload);

      if (activeUser) {
        //

        // let updatedAssetsFromAtomic = [...assetsFromAtomic];
        // let indexNft = assetsFromAtomic.findIndex(item => item.asset_id == id);
        // updatedAssetsFromAtomic.splice(indexNft, 1);
        // setAssetsFromAtomic(updatedAssetsFromAtomic);

        const transaction = await activeUser.signTransaction(
          transactionPayload,
          {
            blocksBehind: 3,
            expireSeconds: 30,
          }
        );

        setModal25Active(false);
        let updatedAssetsFromAtomic = [...assetsFromAtomic];
        console.log("updatedAssetsFromAtomic", updatedAssetsFromAtomic);
        let updatedAssetsInModal = [...allNftModal];
        console.log("updatedAssetsInModal", updatedAssetsInModal);
        for (let index = 0; index < choosesAssetOnWallet.length; index++) {
          let asset = allNftModal.find(
            (spinner) => spinner.asset_id == choosesAssetOnWallet[index]
          );
          let spinnerIndex = allNftModal.findIndex(
            (spinner) => spinner.asset_id == choosesAssetOnWallet[index]
          );
          console.log("spinnerIndex", spinnerIndex);
          if (spinnerIndex !== -1) {
            updatedAssetsFromAtomic.push(asset);
            updatedAssetsInModal.splice(spinnerIndex, 1);
          }
        }
        setAllNftModal(updatedAssetsInModal);
        setAssetsFromAtomic(updatedAssetsFromAtomic);
        console.log("updatedAssetsInModal2", updatedAssetsInModal);

        notify("Transaction ID: " + transaction?.transactionId);
      }
    } catch (e) {
      toast(e.toString(), {
        progressClassName: "error-progress",
      });
      console.log(e);
    }
  };

  const withdrawnft = async (id) => {
    try {
      const { activeUser } = ual;

      const { accountName } = activeUser;

      const transactionPayload = withdrawCollNftFromGame(accountName, [id]);

      const transaction = await activeUser.signTransaction(transactionPayload, {
        blocksBehind: 3,
        expireSeconds: 30,
      });

      //console.log(transaction);
      notify("Transaction ID: " + transaction?.transactionId);

      let updatedAssetsFromAtomic = [...assetsFromAtomic];
      let updatedAssetsInModal = [...allNftModal];
      let asset = assetsFromAtomic.find((item) => item.asset_id == id);
      let indexNft = assetsFromAtomic.findIndex((item) => item.asset_id == id);
      updatedAssetsFromAtomic.splice(indexNft, 1);

      if (indexNft !== -1) {
        updatedAssetsInModal.push(asset);
      }

      setAllNftModal(updatedAssetsInModal);
      setAssetsFromAtomic(updatedAssetsFromAtomic);
    } catch (e) {
      toast(e.toString(), {
        progressClassName: "error-progress",
      });
      console.log(e);
    }
  };

  return (
    <>
      <div className="Part_content">
        <div className="topContentPartners">
          <div className="pt titLeft">
            Boost your multiplier with our partners NFT staking
          </div>
          <div className="centerContentPart">
            <div className="pt">Current Partner Multiplier</div>
            <div id="activeBg" className="PartNum">
              {infoUser && infoUser.user !== undefined
                ? parseFloat(infoUser.mult).toFixed(4) > 2
                  ? 2
                  : parseFloat(parseFloat(infoUser.mult) - 1).toFixed(3)
                : 0}
            </div>
            <div className="maxPartNum">Max 0.1</div>
          </div>
          <div onClick={() => openModalPartners("open")} className="pt btnRig">
            Stake
          </div>
        </div>

        <div className="centerContentPartners">
          {assetsFromAtomic && loading
            ? //allNftModal.map((item, index) => {
              assetsFromAtomic.map((item, index) => {
                if (index < 50) {
                  return (
                    <OnePartners
                      key={index}
                      index={index}
                      item={item}
                      withdrawnft={withdrawnft}
                      swichOtherCol={swichOtherCol}
                    />
                  );
                }
              })
            : // <div className="preloader-local"></div>
              null}
          {/* card */}
          {/* .fonGrTop   .fonViolTop   .fonBlTop */}
          {/* .fonGrCircl .fonViolCircl .fonBlCircl */}
          {/* <div className="cardPartFirst">
                        <div className="bodyCardFirst">

                            <div className="lines">
                                <div className="left l1"><span></span></div>
                                <div className="left l2"><span></span></div>
                                <div className="left l3"><span></span></div>
                                <div className="left l4"><span></span></div>
                                <div className="left l5"><span></span></div>

                                <div className="right l6"><span></span></div>
                                <div className="right l7"><span></span></div>
                                <div className="right l8"><span></span></div>
                                <div className="right l9"><span></span></div>
                            </div>

                            <div className="iconNftPart"></div>

                            <div className="fonBlTop"></div>


                            <div className="fonViolCircl"></div>
                        </div>
                        <div className="bottomCardFirst">
                            <div id='activeBg' className="PartNum">0.04</div>
                            <div className="userPart">
                                <div className="usNameWax">WaxApes</div>
                                <div className="usIdWax">1099919442083</div>

                            </div>
                            <div className="closeCard"></div>
                        </div>
                    </div> */}
          {/*                     
                    <div className="cardPartFirst">
                        <div className="bodyCardFirst">
                            <div className="iconNftPart"></div>
                            <div className="fonGrTop"></div>
                            <div className="fonGrCircl"></div>

                        </div>
                        <div className="bottomCardFirst">
                            <div id='activeBg' className="PartNum">0.04</div>
                            <div className="userPart">
                                <div className="usNameWax">WaxApes</div>
                                <div className="usIdWax">1099919442083</div>
                            </div>
                            <div className="closeCard"></div>
                        </div>
                    </div>

                    <div className="cardPartFirst">
                        <div className="bodyCardFirst">
                            <div className="iconNftPart"></div>
                            <div className="fonBlTop"></div>
                            <div className="spark"></div>
                            <div className="fonBlCircl"></div>
                        </div>
                        <div className="bottomCardFirst">
                            <div id='activeBg' className="PartNum">0.04</div>
                            <div className="userPart">
                                <div className="usNameWax">WaxApes</div>
                                <div className="usIdWax">1099919442083</div>
                            </div>
                            <div className="closeCard"></div>
                        </div>
                    </div>
                    <div className="cardPartFirst">
                        <div className="bodyCardFirst">
                            <div className="iconNftPart"></div>
                            <div className="animation">
                                <div className="fff"></div>
                                <div className="fff1"></div>
                                <div className="fff2"></div>
                                <div className="fff3"></div>
                                <div className="fff4"></div>
                            </div>
                            <div className="fonViolCircl"></div>
                        </div>
                        <div className="bottomCardFirst">
                            <div id='activeBg' className="PartNum">0.04</div>
                            <div className="userPart">
                                <div className="usNameWax">WaxApes</div>
                                <div className="usIdWax">1099919442083</div>
                            </div>
                            <div className="closeCard"></div>
                        </div>
                    </div> */}
        </div>
      </div>
      {modal25Active && allNftModal ? (
        <ModalPartners
          active={modal25Active}
          setActive={setModal25Active}
          openModalPartners={openModalPartners}
          transferNftToTheGame={transferNftToTheGame}
          userInGameState={userInGameState}
          infoUser={infoUser}
          allNftModal={allNftModal}
          swichOtherCol={swichOtherCol}
        ></ModalPartners>
      ) : null}
    </>
  );
};

export default PartnersPage;
