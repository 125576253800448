import { useEffect, useState } from 'react';
import { JsonRpc } from "eosjs";
import { arrRpc, savedOption } from './../Wax/Variables';

var numRpc = 0;


const useGetFirstTop = (accountName) => {

    const [allTop, setAllTop] = useState(null);
    const [hasError, setHasError] = useState(false);
    const [errorText, setErrorText] = useState('');

    const GetAllTop = async () => {
        var link = !savedOption || savedOption === "dynamic" ? arrRpc[numRpc] : savedOption;
        try {
            var rpcc = new JsonRpc("https://" + link);
            await rpcc
                ?.get_table_rows({
                    json: true,
                    code: process.env.REACT_APP_CONTRACT, // contract who owns the table
                    scope: process.env.REACT_APP_CONTRACT, // scope of the table
                    table: "firsttop", // name of the table as specified by the contract abi
                    key_type: "i64",
                    //lower_bound: accountName,
                    //upper_bound: accountName,
                    limit: -1,
                    reverse: 1,
                    show_payer: false,
                    index_position: 2,
                })
                .then((results) => {
                    var resultRow = [];

                    //console.log("results", results)

                    if (results && results.rows && results.rows.length) {
                        resultRow = results.rows;
                    }

                    setAllTop(resultRow);
                });
        } catch (e) {
            //console.warn(e);
            setHasError(true);
            setErrorText(e.message);
            setTimeout(() => {

                if (numRpc == 8) {
                    numRpc = 0;
                } else {
                    GetAllTop();
                    numRpc++;
                }
            }, 1000);
            console.log("\nCaught exception: " + e);
        }
    }

    useEffect(() => {
        if (accountName) {
            GetAllTop();
        }
    }, [accountName]);

    // Верните JSX или null, если вы не хотите отображать ничего в компоненте
    return { allTop };

}

export default useGetFirstTop;
