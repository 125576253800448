import React from 'react';
import '../Modal/ModalClaim.css';
import '../Modal/Modal.css';
import { Link } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';


const ModalServer = ({ active, setActive, children }) => {
  const OnClose = () => {
    setActive(false);
  }

  const serverOptions = ['dynamic', 'wax.pink.gg', 'wax.cryptolions.io', 'wax.greymass.com', 'api.wax.alohaeos.com', 'api.waxsweden.org', 'wax.eosphere.io', 'api.wax.greeneosio.com', 'wax.eu.eosamsterdam.net', 'api.wax.bountyblok.io'];
  const serverOptionsAtomic = ["dynamic", "wax.api.atomicassets.io", "aa-wax-public1.neftyblocks.com", "api.wax-aa.bountyblok.io", "aa.dapplica.io", "api.atomic.greeneosio.com", "atomic.wax.eosrio.io", "wax-aa.eu.eosamsterdam.net", "atomic-wax-mainnet.wecan.dev", "atomic.sentnl.io", "atomic.tokengamer.io", "atomic.ledgerwise.io", "api-wax-aa.eosarabia.net", "wax.hkeos.com/aa", "wax-atomic.wizardsguild.one", "wax-atomic.eosiomadrid.io", "aa.wax.blacklusion.io", "wax-atomic-api.eosphere.io", "wax-aa.eosdublin.io"];


  const [isOpen, setIsOpen] = useState(false);
  const [isOpenAt, setIsOpenAt] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOptionAtomic, setSelectedOptionAtomic] = useState("");


  const toggleOpen = () => {
    setIsOpen(!isOpen); 
  };
  const toggleOpenAt = () => {
    setIsOpenAt(!isOpenAt);
  };

  useEffect(() => {
    const savedOption = localStorage.getItem('selectedServer');
    if (savedOption) {
      setSelectedOption(savedOption);
    }

    const savedOptionAt = localStorage.getItem('selectedServerAtomic');
    if (savedOptionAt) {
      setSelectedOptionAtomic(savedOptionAt);
    }
  }, []);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    localStorage.setItem('selectedServer', option);
    toggleOpen();

  };
  const handleOptionClickAtomic = (optionAt) => {
    setSelectedOptionAtomic(optionAt);
    localStorage.setItem('selectedServerAtomic', optionAt);
    toggleOpenAt();
 
  };

  const handleReload = () => {
    window.location.reload();
  };



  return (
    <div id="modal8" className={active ? "modal__content activerun" : "modal__content"} onClick={() => setActive(false) } >
      <div id='claim' className={active ? "modal activerun" : "modal"} onClick={(event) => event.stopPropagation() }>
        <div className="icon__claim icon_server"></div>
        <div className="contentServer">
          <div className="firstBoxServ">
            <div className="q server">Server change</div>
            <div className="claim__price">
              <div id='serv' className="clame__wallet " >
                <div className="dropdown__wrapper">
                  <div className="dropdown__header" onClick={toggleOpen}>
                    {selectedOption === "" ? "dynamic" : selectedOption}
                    <div className={`vector__icon ${isOpen ? "open" : ""}`} />
                  </div>

                  <div className={`vector__open ${isOpen ? "upside-down" : ""}`}></div>
                </div>

              </div>
            </div>
          </div>
          {/* Atomic */}
          <div className="secondServ">
            <div className="q server">Atomic Node change</div>
            <div className="claim__price">
              <div id='serv' className="clame__wallet " >
                <div className="dropdown__wrapper" >
                  <div className="dropdown__header"
                    onClick={toggleOpenAt}
                    >
                    {selectedOptionAtomic === "" ? "dynamic" : selectedOptionAtomic}
                    <div className={`vector__icon ${isOpenAt ? "open" : ""}`} />
                  </div>

                  <div className={`vector__open ${isOpenAt ? "upside-down" : ""}`}></div>
                </div>

              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="btn__modal">
          <button onClick={OnClose}><span className='tokenDiscont'>Cancel</span></button>
          <button><span className='tokenDiscont' onClick={handleReload}>Change</span></button>
        </div>
        {isOpen && (
          <div className="dropdown__options" style={{ height: isOpen ? "auto" : "20px" }}>
            {serverOptions.map((option) => (
              <div className="option" key={option} onClick={() => handleOptionClick(option)}>
                {option}
              </div>
            ))}
          </div>
        )}
        {isOpenAt && (
          <div className="dropdown__optionsAtomic" style={{ height: isOpenAt ? "200px" : "20px" }}>
            {serverOptionsAtomic.map((optionAt) => (
              <div className="optionAt" 
               key={optionAt} onClick={() => handleOptionClickAtomic(optionAt)}>
                {optionAt}
              </div>
            ))}
          </div>
        )}
      </div>

    </div >
  );

}
export default ModalServer;

