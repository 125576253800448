import React, { useState, useEffect, useRef, useContext } from 'react';
import '../../App.css'
import Header from '../../components/Header/Header'
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import UserBalance from '../../components/Wax/UserBalance';
import useGetAllNftSpinnerOnWallet from '../../components/Wax/useGetAllNftSpinnerOnWallet';
import useGetAllSpecRunesInGame from '../../components/Wax/useGetAllSpecRunesInGame';
import useGetAllSpinnerInGame from '../../components/Wax/useGetAllSpinnerInGame';
import useGetAllResInGame from '../../components/Wax/useGetAllResInGame';
import useGetAllRunesInGame from '../../components/Wax/useGetAllRunesInGame';
import useGetUserInfoInGame from '../../components/Wax/useGetUserInfoInGame';
import { UALContext } from "ual-reactjs-renderer";
import { transferToTheGame, withdrawNftFromGame, registration, buySlot, addToSlot, tokenDeposite, remFromSlot, claim, addRuna, remRuna, actvipspin, tokenWithdraw, buyInStorage, craft, buyMult } from '../../components/Wax/Transactions';

import BoxSpinners from '../../components/BoxSpinners/BoxSpinners'


import soundFile from '../../../src/components/audio/Switch Plastic.mp3';
import soundFon from '../../../src/components/audio/RebeccaMardal.mp3';

// push 2
const chooseSpinParam = (tmpl) => {
    switch (tmpl) {
        case process.env.REACT_APP_SPINNEY_PROMO: // promo
            return {
                rarity: 0,
                cons: 0.2,
                countdown: 3600 * 3,
                speed_up: 40,
                mult: 1.1
            }

            break;

        case process.env.REACT_APP_SPINNEY_PROMO2: // promo
            return {
                rarity: 6,
                cons: 0.2,
                countdown: 3600 * 3,
                speed_up: 40,
                mult: 1.1
            }

            break;
        case process.env.REACT_APP_SPINNEY_COMMON: // common
            return {
                rarity: 1,
                cons: 1,
                countdown: 3600 * 3,
                speed_up: 40,
                mult: 1.1,
            }

            break;
        case process.env.REACT_APP_SPINNEY_UNCOMMON: // Uncomon
            return {
                rarity: 2,
                cons: 2,
                countdown: 3600 * 3,
                speed_up: 40,
                mult: 1.1,
            }
            break;
        case process.env.REACT_APP_SPINNEY_RARE: // Rare
            return {
                rarity: 3,
                cons: 8,
                countdown: 3600 * 6,
                speed_up: 20,
                mult: 1.1,
            }
            break;
        case process.env.REACT_APP_SPINNEY_EPIC: // Epic
            return {
                rarity: 4,
                cons: 16,
                countdown: 3600 * 6,
                speed_up: 20,
                mult: 1.1,
            }
            break;
        case process.env.REACT_APP_SPINNEY_LEGEND: // Legend
            return {
                rarity: 5,
                cons: 64,
                countdown: 3600 * 12,
                speed_up: 10,
                mult: 1.1,
            }
            break;

        default:

            break;
    }
}

const chooseRunasParam = (tmpl) => {
    var rar;
    switch (tmpl) {
        case process.env.REACT_APP_RUNES_PROMO: // promo
            rar = 0;
            break;
        case process.env.REACT_APP_RUNES_COMMON: // common
            rar = 1;
            break;
        case process.env.REACT_APP_RUNES_UNCOMMON: // Uncomon
            rar = 2;
            break;
        case process.env.REACT_APP_RUNES_RARE: // Rare
            rar = 3;
            break;
        case process.env.REACT_APP_RUNES_EPIC: // Epic
            rar = 4;
            break;
        case process.env.REACT_APP_RUNES_LEGEND: // Legend
            rar = 5;
            break;
        default:
            break;
    }
    return { rarity: rar };

}

const pointNoRunas = (rar) => {
    let points = 0;

    switch (rar) {
        case 0:
            points = 1;
            break;
        case 1:
            points = 5;
            break;
        case 2:
            points = 10;
            break;
        case 3:
            points = 20;
            break;
        case 4:
            points = 40;
            break;
        case 5:
            points = 80;
            break;

        default:
            points = 5;
            break;
    }
    return points;
}

const pointWithRunas = (rar) => {
    let points = 0;

    switch (rar) {
        case 1:
            points = 15;
            break;
        case 2:
            points = 30;
            break;
        case 3:
            points = 60;
            break;
        case 4:
            points = 120;
            break;
        case 5:
            points = 240;
            break;

        default:
            points = 15;
            break;
    }
    return points;
}


const Admin = () => {

    let { id } = useParams();

    useEffect(() => {
    }, [id]);

    useEffect(() => {
        document.querySelector(".app-wrapper").classList.add('adminwrap');
    }, []);

    const audio = new Audio(soundFile);

    const handleClickAudioBtn = () => {
        audio.volume = isMuted ? 0 : 0.3;
        audio.play();
    };

    const [isMuted, setIsMuted] = useState(() => {
        const storedIsMuted = localStorage.getItem('isMuted');
        return storedIsMuted ? JSON.parse(storedIsMuted) : false;

    });

    const handleToggleMute = () => {
        setIsMuted(prevIsMuted => !prevIsMuted);
        // console.log("isMuted updated:", isMuted);
    };

    useEffect(() => {
        localStorage.setItem('isMuted', isMuted.toString());
    }, [isMuted]);
    // audio btn

    // audio2 

    const audio2 = new Audio(soundFon);

    const handleClickAudioBtn2 = () => {
        audio2.volume = isMuted2 ? 0 : 0.3;
        audio2.play();
    };

    const [isMuted2, setIsMuted2] = useState(() => {
        const storedIsMuted2 = localStorage.getItem('isMuted2');
        return storedIsMuted2 ? JSON.parse(storedIsMuted2) : false;

    });

    const handleToggleMute2 = () => {
        setIsMuted2(prevIsMuted2 => !prevIsMuted2);
        console.log("isMuted updated:", isMuted);
    };

    useEffect(() => {
        localStorage.setItem('isMuted2', isMuted2.toString());
    }, [isMuted2]);
    // audio2 

    // video 

    const [showCanvas, setShowCanvas] = useState(() => {
        const storedIsCanvas = localStorage.getItem('showCanvas');
        return storedIsCanvas ? JSON.parse(storedIsCanvas) : true;

    });


    const handleToggleDisplay = () => {
        setShowCanvas(!showCanvas);
        //  console.log("showCanvas updated:", showCanvas);
    };

    useEffect(() => {
        localStorage.setItem('showCanvas', showCanvas.toString());
    }, [showCanvas]);

    const [isMuted3, setIsMuted3] = useState(() => {
        const storedIsMuted3 = localStorage.getItem('isMuted3');
        return storedIsMuted3 ? JSON.parse(storedIsMuted3) : true;

    });

    const handleToggleMute3 = () => {
        setIsMuted3(prevIsMuted3 => !prevIsMuted3);

        //  console.log("isMuted3 updated:", isMuted3);
    };

    useEffect(() => {
        localStorage.setItem('isMuted3', isMuted3.toString());
    }, [isMuted3]);



    // video 


    const [activeItem, setActiveItem] = useState(() => {
        const value = localStorage.getItem('activeItem');
        return value !== null ? value : 'one';
    });

    const [menuPosition, setMenuPosition] = useState(() => {
        const value = localStorage.getItem('menuPosition');
        return value !== null ? parseInt(value, 10) : -1;
    });

    const updateActiveItem = (newActiveItem, newPosition) => {
        //setActiveItem(newActiveItem);
        //setMenuPosition(newPosition);
    };

    const [activePositionMenu, setActivePositionMenu] = useState(0);

    const getActiveMenuItem = (to, position) => {
        setActivePositionMenu({ position: position, to: to });
    }
    // navfooter-----------

    const notify = (e) => toast(e);

    // const [modal3Active, setModal3Active] = useState(false);
    const [activeSpinner, setActiveSpinner] = useState(null);


    const openModalMarket = (type, spinner) => {
        if (type === "open") {
            setModal3Active(true);
            setActiveSpinner(spinner);
        }
    };
    const openModalMarketRuna = (type, spinner) => {
        if (type === "open") {
            setModal13Active(true);
            setActiveSpinner(spinner);
        }
    };

    const ual = useContext(UALContext);
    const [accountName, setAccountName] = useState(id);
    const [accountBalanceState, setAccountBalanceState] = useState(null);
    const [userInGameState, setUserInGame] = useState(null);
    const [allSpinner, setAllSpinner] = useState(null);
    const [allSpinnerInGame, setAllSpinnerInGame] = useState(null);
    const [allResInGameLocal, setAllResInGameLocal] = useState(null);
    const [allRunesInGame, setAllRunesInGame] = useState(null);
    const [choosesAssetOnWallet, setChoosesAssetOnWallet] = useState([]);
    const [choosesAssetOnGame, setChoosesAssetOnGame] = useState([]);
    const [allNftSpinerGame, setAllNftSpinerGame] = useState(null);
    const [allNftSpinerGameFiltered, setAllNftSpinerGameFiltered] = useState(null);
    const [allNftRunesGame, setAllNftRunesGame] = useState(null);
    const [allSpendPoint, setAllSpendPoint] = useState(0);
    const [allLeftPoints, setAllLeftPoints] = useState(0);
    const [reallSpendCredit, setReallSpendCredit] = useState(0);
    //const [allSingleWorkDays, setAllSingleWorkDays] = useState(0);
    const [allSpecRunesInGame, setAllSpecRunesInGame] = useState([]);

    const [allRunesWorkMoney, setAllRunesWorkMoney] = useState(0);
    const [allRunesWorkDays, setAllRunesWorkDays] = useState(0);
    const [allSpinneyWorkPoints, setAllSpinneyWorkPoints] = useState(0);
    const [manyLeftDays, setManyLeftDays] = useState(0);

    useEffect(() => {
        if (allSpinneyWorkPoints == 0) {
            setManyLeftDays(0);
        } else {
            setManyLeftDays(Math.floor(allLeftPoints / (allSpinneyWorkPoints / 10)));
        }
    }, [allLeftPoints, allSpinneyWorkPoints]);

    const logout = () => {
        ual.logout();
        setAccountName(null);
    }

    useEffect(() => {
        if (ual?.activeUser?.accountName) {
            setAccountName(id);
        }
    }, [ual]);

    console.log("accountName", accountName)

    // useEffect(() => {
    //   if (ual?.activeUser?.accountName && accountName != undefined && accountName == ual?.loading) {
    //     const { allNft, hasError, errorText } = GetAllNftSpinner(accountName);
    //     console.log("allNft", allNft);
    //     setAllNftSpiner(allNft);
    //   }
    // }, [accountName]);

    const [refreshKey1, setRefreshKey1] = useState(0);
    const [refreshKey2, setRefreshKey2] = useState(0);
    const [refreshKey3, setRefreshKey3] = useState(0);
    const { allSpinnerGet } = useGetAllNftSpinnerOnWallet(accountName, refreshKey1);
    const { allSpinnerInGameGet } = useGetAllSpinnerInGame(accountName, refreshKey2);
    const { allResInGame } = useGetAllResInGame(accountName);
    const { allRunesInGameGet } = useGetAllRunesInGame(accountName);
    let { userInGame } = useGetUserInfoInGame(accountName, refreshKey3);
    const { allSpecRunesInGameGet } = useGetAllSpecRunesInGame(accountName);

    const refreshAssets = (type) => {
        if (type === "gamespin") {
            setRefreshKey2(prevKey => prevKey + 1);
        } else if (type === "walletspin") {
            setRefreshKey1(prevKey => prevKey + 1);
        } else if (type === "userinfo") {
            setRefreshKey3(prevKey => prevKey + 1);
        }
    };

    useEffect(() => {
        if (userInGame && userInGame.user !== undefined && userInGame.user == accountName && userInGameState === null) {
            setUserInGame(userInGame);
            //console.log("userInGame", userInGame);
            //console.log("test1");
        }
    }, [userInGame]);

    const hasUpdated = useRef(false);

    useEffect(() => {
        //console.log("allSpinnerInGameGet", allSpinnerInGameGet)
        //console.log("userInGameState", userInGameState)
        if (allSpinnerInGameGet && allSpinnerInGameGet.length && !hasUpdated.current && userInGameState !== null) {
            const objectsCopy = allSpinnerInGameGet.map(obj => {
                const newObj = { ...obj };
                newObj.lastact = userInGameState?.lastact;
                return newObj;
            });

            setAllSpinnerInGame(objectsCopy);
            hasUpdated.current = true;

            //console.log("test2");
        }
    }, [allSpinnerInGameGet, userInGameState?.lastact]);


    useEffect(() => {
        if (allResInGame) {
            setAllResInGameLocal(allResInGame);
            //console.log("test3");
        }
    }, [allResInGame]);

    useEffect(() => {
        if (allRunesInGameGet && allRunesInGameGet.length) {
            setAllRunesInGame(allRunesInGameGet);
            //console.log("test4");
        }
    }, [allRunesInGameGet]);

    useEffect(() => {
        if (allSpinnerGet) {
            setAllSpinner(allSpinnerGet);
            //console.log("test5");
        }
    }, [allSpinnerGet]);

    useEffect(() => {
        if (allSpecRunesInGameGet && allSpecRunesInGameGet.length) {
            setAllSpecRunesInGame(allSpecRunesInGameGet);
            console.log("test4Spec");
        }
    }, [allSpecRunesInGameGet]);

    useEffect(() => {

        if (allSpinnerInGame && allSpinnerInGame.length && userInGameState && userInGameState.user == accountName && allSpecRunesInGame) {

            console.log("allSpecRunesInGame", allSpecRunesInGame)

            //console.log("test6");
            let currentTime = Math.floor(Date.now() / 1000);

            let all_single_work_days = 0;
            let all_runes_work_days = 0;

            let all_single_work_money = 0;
            let all_runes_work_money = 0;

            let workers_single = [];
            let workers_runas = [];
            let all_workers = [];

            let activeUseADay = 0;


            allSpinnerInGame.filter((item) => item.vip !== 0).forEach((item) => {

                let work_days = 0;
                if (item.vip && item.runes == 0 && item.lastact != 0) {
                    let day_lastact = Math.floor(item.lastacttime / 86400);
                    let day_currentTime = Math.floor(currentTime / 86400);

                    for (; day_lastact < day_currentTime; day_lastact++) {
                        work_days++;
                        all_single_work_days++;
                        all_single_work_money += pointNoRunas(item.rarity);
                    }

                    workers_single.push({ asset_id: item.asset_id, days_worked: work_days, last_time: item.lastacttime, runes: 0, rarity: item.rarity });
                    all_workers.push({ asset_id: item.asset_id, days_worked: work_days, last_time: item.lastacttime, runes: 0, rarity: item.rarity });

                    activeUseADay += pointNoRunas(item.rarity);
                } else if (item.vip && item.runes != 0) {
                    let day_lastact = Math.floor(item.lastact / 86400);  // Целочисленное деление
                    let day_currentTime = Math.floor(currentTime / 86400);  // Целочисленное деление

                    if (item.runes == 111111111111111) {
                        let specIndex = allSpecRunesInGame.findIndex(spinner => spinner.spinner_id == item.asset_id);

                        if (specIndex !== -1) {
                            if (allSpecRunesInGame[specIndex].endtime > currentTime) { // если срок больше чем курент тайм
                                for (; day_lastact < day_currentTime; day_lastact++) {
                                    work_days++;
                                    all_runes_work_days++;
                                    all_runes_work_money += pointWithRunas(item.rarity);
                                }
                                activeUseADay += pointWithRunas(item.rarity);
                            }
                            else {
                                let day_lastact_spec = Math.floor(allSpecRunesInGame[specIndex].endtime / 86400);
                                for (; day_lastact < day_currentTime; day_lastact++) {
                                    if (day_lastact < day_lastact_spec) {
                                        work_days++;
                                        all_runes_work_days++;
                                        all_runes_work_money += pointWithRunas(item.rarity);
                                    }
                                    else {
                                        work_days++;
                                        all_single_work_days++;
                                        all_single_work_money += pointNoRunas(item.rarity);

                                        workers_single.push({ asset_id: item.asset_id, days_worked: work_days, last_time: item.lastacttime, runes: 0, rarity: item.rarity });
                                    }

                                }
                                activeUseADay += pointNoRunas(item.rarity);
                            }

                        }
                    } else {
                        for (; day_lastact < day_currentTime; day_lastact++) {
                            work_days++;
                            all_runes_work_days++;
                            all_runes_work_money += pointWithRunas(item.rarity);
                        }
                        activeUseADay += pointWithRunas(item.rarity);
                    }

                    workers_runas.push({ asset_id: item.asset_id, days_worked: work_days, last_time: item.lastact, runes: item.runes, rarity: item.rarity });
                    all_workers.push({ asset_id: item.asset_id, days_worked: work_days, last_time: item.lastact, runes: item.runes, rarity: item.rarity });


                }

            });

            function compareRarity(a, b) {
                return b.rarity - a.rarity;
            }

            let type;
            if (workers_runas.length > 0 && workers_single.length > 0) {
                workers_single.sort(compareRarity);
                workers_runas.sort(compareRarity);

                if (workers_single[0].last_time < workers_runas[0].last_time) {
                    type = "single";
                    //console.log("1-1");
                } else if (workers_single[0].last_time > workers_runas[0].last_time) {
                    type = "withRunes";
                    //console.log("1-2");
                } else {
                    type = "nooactive";
                    //console.log("1-2-2");
                }
            } else if (workers_runas.length > 0 && workers_single.length == 0) {
                workers_single.sort(compareRarity);
                type = "single";
                //console.log("1-3");
            } else if (workers_runas.length == 0 && workers_single.length > 0) {
                workers_runas.sort(compareRarity);
                type = "withRunes";

                //console.log("1-4");
            } else {
                workers_single.sort(compareRarity);
                workers_runas.sort(compareRarity);
                type = "nooactive";
                //console.log("1-5");
            }

            let maxDaysWork = all_single_work_days > all_runes_work_days ? all_single_work_days : all_runes_work_days;

            let vipcreditForLoop = userInGameState?.vipcredit;
            let singleForCurrentDay = 0;
            let runesForCurrentDay = 0;
            let singleForCurrentMoney = 0;
            let runesForCurrentMoney = 0;
            let allWorkersNum = all_workers.length;
            let day_currentTime_for_loop = Math.floor(currentTime / 86400);

            for (let day = 1; day <= maxDaysWork && vipcreditForLoop > 0; ++day) {
                let totalPayForCurrentDay = 0;

                // Подсчет общей стоимости оплаты всех работников за день
                for (let i = 0; i < allWorkersNum; ++i) {
                    if (all_workers[i].days_worked >= (maxDaysWork - day + 1)) {
                        let specNumber = maxDaysWork - day + 1;
                        if (all_workers[i].runes) {
                            if (all_workers[i].runes == 111111111111111) {
                                var specrun_itr = allSpecRunesInGame.find(spinner => spinner.spinner_id == all_workers[i].asset_id);
                                if (specrun_itr) {
                                    let day_lastact_spec = Math.floor(specrun_itr.endtime / 86400);

                                    if (day_lastact_spec > day_currentTime_for_loop - specNumber) {
                                        totalPayForCurrentDay += pointWithRunas(all_workers[i].rarity)
                                    } else {
                                        totalPayForCurrentDay += pointNoRunas(all_workers[i].rarity);
                                    }
                                }
                            } else {
                                totalPayForCurrentDay += pointWithRunas(all_workers[i].rarity)
                            }
                        } else {
                            totalPayForCurrentDay += pointNoRunas(all_workers[i].rarity);
                        }
                    }
                }

                if (vipcreditForLoop >= totalPayForCurrentDay) {
                    for (let i = 0; i < allWorkersNum; ++i) {
                        if (all_workers[i].days_worked >= (maxDaysWork - day + 1)) {
                            let specNumber = maxDaysWork - day + 1;
                            if (all_workers[i].runes) {
                                if (all_workers[i].runes == 111111111111111) {
                                    var specrun_itr = allSpecRunesInGame.find(spinner => spinner.spinner_id == all_workers[i].asset_id);
                                    if (specrun_itr) {
                                        let day_lastact_spec = Math.floor(specrun_itr.endtime / 86400);
                                        if (day_lastact_spec > day_currentTime_for_loop - specNumber) {
                                            runesForCurrentDay++;
                                            runesForCurrentMoney += pointWithRunas(all_workers[i].rarity);
                                        } else {
                                            singleForCurrentDay++;
                                            singleForCurrentMoney += pointNoRunas(all_workers[i].rarity);
                                        }
                                    }
                                } else {
                                    runesForCurrentDay++;
                                    runesForCurrentMoney += pointWithRunas(all_workers[i].rarity);
                                }
                            } else {
                                singleForCurrentDay++;
                                singleForCurrentMoney += pointNoRunas(all_workers[i].rarity);
                            }
                        }
                    }
                    vipcreditForLoop -= totalPayForCurrentDay;
                } else {
                    // Если денег не хватает на оплату всех за данный день, то пропускаем этот день
                    continue;
                }
            }


            console.log("all_runes_work_days", all_runes_work_days);
            console.log("vipcreditForLoop", vipcreditForLoop)

            console.log("singleForCurrentMoney", singleForCurrentMoney);
            console.log("runesForCurrentMoney", runesForCurrentMoney);

            console.log("-----------------");

            console.log("all_single_work_money", all_single_work_money);
            console.log("all_runes_work_money", all_runes_work_money);

            console.log("vipcreditForLoop", vipcreditForLoop)
            console.log("singleForCurrentDay", singleForCurrentDay);
            console.log("runesForCurrentDay", runesForCurrentDay);
            console.log("activeUseADay", activeUseADay);
            console.log("reallSpendCredit", (all_single_work_money + all_runes_work_money) - (singleForCurrentMoney + runesForCurrentMoney))

            if ((all_single_work_money + all_runes_work_money) > vipcreditForLoop) {
                setReallSpendCredit((all_single_work_money + all_runes_work_money) - (singleForCurrentMoney + runesForCurrentMoney));
            }

            let day_lastact = userInGameState?.lastact / 86400; // Находим день последнего действия с рунами
            let day_currentTime = currentTime / 86400; // Находим текущий день
            let seconds_passed_today_head = userInGameState?.lastact % 86400; // Количество секунд, прошедших с начала дня до lastact
            let seconds_until_midnight = 86400 - seconds_passed_today_head; // Количество секунд от lastact до полуночи


            setAllRunesWorkDays(runesForCurrentDay);
            setAllRunesWorkMoney(runesForCurrentMoney);
            setAllSpinneyWorkPoints(activeUseADay);

            let restFinal = userInGameState?.vipcredit - (singleForCurrentMoney + runesForCurrentMoney);
            if (restFinal <= 0) {
                setAllLeftPoints(0);
            } else {
                setAllLeftPoints(restFinal / 10);
            }


        }

    }, [allSpinnerInGame, userInGameState, allSpecRunesInGame]);



    const balance = UserBalance(accountName);

    useEffect(() => {
        setAccountBalanceState(balance);
    }, [balance]);

    const balanceDisp = (type, quant) => {
        //console.log("parseFloat(quant) * 10000", parseFloat(quant) * 10000);
        if (type) {
            setUserInGame(prevState => ({
                ...prevState,
                balance: prevState.balance + parseFloat(quant) * 10000
            }));
        } else {
            setUserInGame(prevState => ({
                ...prevState,
                balance: prevState.balance - parseFloat(quant) * 10000
            }));
        }

    }



    const actvipspinTrans = async (score, localActiveSpiner, e, spinSpeedBuffer, allSumSpinsBuffer) => {
        try {
            const { activeUser } = ual;
            const { accountName } = activeUser;

            const transactionPayload = actvipspin(accountName, localActiveSpiner.asset_id, e);

            const transaction = await activeUser.signTransaction(transactionPayload, {
                blocksBehind: 3,
                expireSeconds: 30,
            });

            //console.log(transaction);
            let spinnerIndex = allSpinnerInGame.findIndex(spinner => spinner.asset_id == localActiveSpiner.asset_id);
            if (spinnerIndex !== -1) {
                const updatedSpinner = {
                    ...allSpinnerInGame[spinnerIndex],
                    vip: e,
                    storage: parseInt((score.toFixed(4)) * 10000),
                    speed: spinSpeedBuffer,
                    spins: allSumSpinsBuffer
                };
                const updatedAllSinnersInGameLocal = [...allSpinnerInGame];
                updatedAllSinnersInGameLocal[spinnerIndex] = updatedSpinner;
                setAllSpinnerInGame(updatedAllSinnersInGameLocal);
            }

            if (e) {
                let updatedUserSpiners = userInGameState.vipcredit;
                updatedUserSpiners -= 1;
                //console.log("userInGameState.vipcredit", userInGameState.vipcredit)
                //console.log("updatedUserSpiners", updatedUserSpiners)
                setUserInGame({
                    ...userInGameState,
                    vipcredit: updatedUserSpiners,
                    lastact: Math.floor(Date.now() / 1000)
                });
            }
            notify("Transaction ID: " + transaction?.transactionId);
        } catch (e) {
            toast(e.toString(), {
                progressClassName: 'error-progress',
            });

            console.log(e);
            // if (e instanceof RpcError)
            //   console.log(JSON.stringify(e.json, null, 2));
        }
    }



    const confirmUnstake = (e, asset_id) => {
        setModal6Active(e);
        setRemId(asset_id);
    }

    const confirmClaim = (e, asset, scoreClame) => {
        handleModalActivation(e);
        setClaimAsset(asset);
        setScoreClame(scoreClame);
    }

    const openRunaModal = (type, e, asset) => {
        if (type === "stake") {
            setModal1Active(e);
            setActiveSpinnerForRuna(asset);
        } else if (type === "unstake") {
            setModal5Active(e);
            setActiveSpinnerForRuna(asset);
        }
    }


    // console.log("balance", balance);
    // console.log("accountName", accountName);

    const [remId, setRemId] = useState(0)
    const [claimAsset, setClaimAsset] = useState(0)
    const [scoreClame, setScoreClame] = useState(0)
    const [activeSpinnerForRuna, setActiveSpinnerForRuna] = useState(0)


    const [modal1Active, setModal1Active] = useState(false)
    const [modal2Active, setModal2Active] = useState(false)
    const [modal3Active, setModal3Active] = useState(false)
    const [modal4Active, setModal4Active] = useState(false)
    const [modal5Active, setModal5Active] = useState(false)
    const [modal6Active, setModal6Active] = useState(false)
    const [modal7Active, setModal7Active] = useState(false)
    const [modal8Active, setModal8Active] = useState(false)
    const [modal9Active, setModal9Active] = useState(false)
    const [modal10Active, setModal10Active] = useState(false)
    const [modal11Active, setModal11Active] = useState(false)
    const [modal12Active, setModal12Active] = useState(false)
    const [modal13Active, setModal13Active] = useState(false)
    const [modal15Active, setModal15Active] = useState(false)
    const [modal16Active, setModal16Active] = useState(false)
    const [modal17Active, setModal17Active] = useState(false)

    const handleModalActivation = (modalNumber) => {
        const modalStates = [
            setModal1Active,
            setModal2Active,
            setModal3Active,
            setModal4Active,
            setModal5Active,
            setModal6Active,
            setModal7Active,
            setModal8Active,
            setModal9Active,
            setModal10Active,
            setModal11Active,
            setModal12Active,
            setModal13Active,
            //setModal14Active,
            setModal15Active,
            setModal16Active
        ]

        modalStates.forEach((setModalState, index) => {
            if (index === modalNumber) {
                setModalState(true)
            } else {
                setModalState(false)
            }
        })
    }

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }, [])



    return (
        <>
            {
                userInGameState && userInGameState.all_slot !== undefined && userInGameState.all_slot.length ?
                    <>
                        <div className='header-admin' style={{ position: "fixed", top: 0, left: 0, width: "100%" }}>
                            <Header
                                activeItem={activeItem}
                                menuPosition={menuPosition}
                                updateActiveItem={updateActiveItem}
                                setShowCanvas={showCanvas}
                                handleModalActivation={handleModalActivation}
                                balance={accountBalanceState}
                                userInGameState={userInGameState}
                                logout={logout}
                                allLeftPoints={allLeftPoints}
                                getActiveMenuItem={getActiveMenuItem}
                                allResInGameLocal={allResInGameLocal}
                                handleToggleMute={handleToggleMute}
                                handleToggleMute2={handleToggleMute2}
                                handleToggleMute3={handleToggleMute3}
                                handleClickAudio={handleClickAudioBtn}
                                handleToggleDisplay={handleToggleDisplay}
                                isMuted={isMuted}
                                isMuted2={isMuted2}
                                isMuted3={isMuted3}
                                allSpinneyWorkPoints={allSpinneyWorkPoints}
                                manyLeftDays={manyLeftDays}
                                accountName={accountName}
                                reallSpendCredit={reallSpendCredit}

                            />
                        </div>

                        <BoxSpinners
                            handleModalActivation={handleModalActivation}
                            setModal1Active={setModal1Active}
                            setModal2Active={setModal2Active}
                            setModal3Active={setModal3Active}
                            setModal4Active={setModal4Active}
                            setModal5Active={setModal5Active}
                            setModal6Active={setModal6Active}
                            setModal8Active={setModal8Active}
                            setModal9Active={setModal9Active}
                            setModal13Active={setModal13Active}
                            allSpinnerInGame={allSpinnerInGame}
                            userInGameState={userInGameState}
                            confirmUnstake={confirmUnstake}
                            confirmClaim={confirmClaim}
                            openRunaModal={openRunaModal}
                            openModalMarket={openModalMarket}
                            openModalMarketRuna={openModalMarketRuna}
                            actvipspinTrans={actvipspinTrans}
                            allSpendPoint={allSpendPoint}
                            handleClickAudio={handleClickAudioBtn}
                            balanceDisp={balanceDisp}
                            showCanvas={showCanvas}
                            allLeftPoints={allLeftPoints}
                            reallSpendCredit={reallSpendCredit}
                            allRunesWorkDays={allRunesWorkDays}
                            allSpecRunesInGame={allSpecRunesInGame}
                        />
                    </>
                    : null
            }
        </>

    );
};

export default Admin;